import moment from "moment";
import { ExpanderComponentProps } from "react-data-table-component";

const formatKey = (key: string) => {
  // Remove prefix
  let formattedKey = key.replace(/^(tx|vc|dt)/i, '');

  // Add space before uppercase letters and make the first letter uppercase
  formattedKey = formattedKey.replace(/([A-Z])/g, ' $1').trim();
  formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);

  return formattedKey;
}

const formatValue = (key: string, value: any) => {
  if (value === null || value === undefined) {
    return '';
  } else if (typeof value === 'boolean') {
    return value ? 'SI' : 'NO';
  } else if (typeof value === 'number') {
    return value.toString();
  } else if (typeof value === 'string' && value.length > 10 && key.toLowerCase().startsWith('dt')) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
  } else if (typeof value === 'object' && value !== null) {
    const blacklist = ['color'];
    return (
      <ul>
        {Object.entries(value)
          .filter(([key]) => !blacklist.includes(key))
          .map(([key, value], index) => (
          <li key={index}>
            {formatKey(key)}: {formatValue(key, value)}
          </li>
        ))}
      </ul>
    );
  } else {
    return value.toString();
  }
}

const CustomExpanderGeneric = <T extends { [key: string]: any },>({ data }: ExpanderComponentProps<T>) => {
    const blacklist = ['usuarioId', 'usuarioActualId', 'personaWebId','personaIndividualId', 'seguimientos', 'reasignaciones','diasSinRespuestaColor'];
    
    return (
        <div>
            <p><b className="fw-bold text-azul-oscuro">Información adicional del caso:</b></p>
            {
                Object.entries(data)
                    .filter(([key]) => !blacklist.includes(key))
                    .map(([key, value]) => (
                        <p key={key}>
                            <b className="fw-bold text-azul-oscuro">{formatKey(key)}:</b> {formatValue(key, value)}
                        </p>
                    ))
            }
        </div>
    );
}

export default CustomExpanderGeneric;
