import axios from 'axios';
import { Form, Formik } from 'formik';
import moment from "moment";
import { useState } from "react";
import { Card } from "react-bootstrap";
import * as Yup from 'yup';
import { getOrquestadorApiUrl } from '../../../_metronic/helpers/ApiOrquestadorHelpers';
import { getParametroApiUrl } from   '../../../_metronic/helpers/ApiParametroHelpers';
import { CrudButton } from '../../components/CrudButton';
import { handleDownloadExcelBase64 } from '../../components/Excel';
import { FormikInput } from '../../components/FormikInput';
import { FormikSelect } from '../../components/FormikSelect';
import { SwalToast } from '../../components/SwalToast';
import {getAdministradorApiUrl} from "../../utilities";

const AUTH_LOCAL_STORAGE_KEY = 'sc-auth-react-v'



const reportSchema = Yup.object().shape({
    DtFechaInicio: Yup.date()
        .required('Campo obligatorio')
        .test('valida_fecha', 'Fecha inicio no puede ser mayor a fecha fin', function (dtFechaInicio: any) {
            const refDtFechaFin: string = this.resolve(Yup.ref('DtFechaFin'));
            var momentA = moment(dtFechaInicio, "DD/MM/YYYY");
            var momentB = moment(refDtFechaFin, "DD/MM/YYYY");
            if (momentA > momentB) {

                return false;
            }
            return true;
        }),
    DtFechaFin: Yup.date()
        .required('Campo obligatorio')
        .test('valida_fecha', 'Fecha fin no puede ser menor a fecha inicio', function (dtFechaFin: any) {
            const refDtFechaInicio: string = this.resolve(Yup.ref('DtFechaInicio'));
            var momentA = moment(dtFechaFin, "DD/MM/YYYY");
            var momentB = moment(refDtFechaInicio, "DD/MM/YYYY");

            if (momentA < momentB) {

                return false;
            }
            return true;
        }),
    ReporteId: Yup.number()
});

export function ModuloReportes(props: any) {
    const [dataTypeReports, setDataTypeReports] = useState([
        {
            value: 111,
            codigo: 'gds',
            label: 'Gestión de seguimiento',
        },
        {
            value: 222,
            codigo: 'ow',
            label: 'Orientación Web',
        },
        {
            value: 333,
            codigo: 'oyp',
            label: 'Orientación y problematica',
        },
        {
            value: 444,
            codigo: 'gru',
            label: 'Grupales',
        },
    ]);

    const getInfoRepor = async (data: any) => {
        let getItemTemp = (JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || ''))
        let vcCodigo: any = ''
        const dataArray: any = [];
       //await axios.get(getParametroApiUrl(`ParametroDetalle/porParametroId/${getItemTemp.entidadId}`))
        //await axios.get(getOrquestadorApiUrl(`ParametroDetalle/GetParametroDetalleId/${getItemTemp.entidadId}`))
        await axios.get(getParametroApiUrl(`ParametroDetalle/GetParametroDetalleId/${getItemTemp.entidadId}`))
        .then((response: any) => {
             vcCodigo = response.data.model.vcCodigoInterno;
             console.log(response)
        })
        .catch(e => {
            // Capturamos los errores
            console.log(e);
        });
        if (vcCodigo == '')
            vcCodigo = 'NA'
        //await axios.get(getAdministradorApiUrl(`Usuario/porEntidad/${getItemTemp.entidadId}`))
        await axios.get(getAdministradorApiUrl(`Usuario/porEntidad/${getItemTemp.entidadId}?vcCodigo=${vcCodigo}`))
            .then((response) => {
                const data: any = response.data.models || [];
                data.forEach((element: any) => {
                    dataArray.push(`&usuarioId=${element.id}`);
                });
            })
            .catch(e => {
                console.log(e);
            });


        let usuariosIdTemp = dataArray.toString().replace(',', '')
        
        await axios.get(getOrquestadorApiUrl(`Reportes/${data.ReporteId}?fechaInicio=${data.DtFechaInicio}&fechaFinal=${data.DtFechaFin}${usuariosIdTemp}`))
            .then((response: any) => {
                if (response?.data.codigo == 200) {
                    SwalToast.fire({
                        icon: 'success',
                        title: response.data.mensaje
                    });

                    handleDownloadExcelBase64(response.data.excel, response.data.nombreArchivo);
                } else {
                    SwalToast.fire({
                        icon: 'warning',
                        title: response.data.mensaje
                    });
                }
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }



    return <>
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    ReporteId: undefined,
                    DtFechaInicio: '',
                    DtFechaFin: '',
                }}
                onReset={(values, formProps) => {

                }}
                validationSchema={reportSchema}
                onSubmit={(values: any, { setSubmitting }) => {
                    getInfoRepor(values);
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    setFieldValue,
                    values
                }) => (
                    <Form>
                        <h6>Generación de Reportes</h6>
                        <Card className="mt-5">
                            <Card.Header>
                                <Card.Title>Reportes</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <FormikSelect
                                        options={dataTypeReports}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("ReporteId", selectedOption.value);
                                        }}
                                        value={values.ReporteId}
                                        labelName='Seleccione el reporte'
                                        nameSelect='ReporteId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                    />
                                    <FormikInput
                                        type='date'
                                        labelName='Fecha de inicio'
                                        nameSelect='DtFechaInicio'
                                        className='col-12 col-md-6 mt-5'
                                        required={true}
                                    />

                                </div>

                                <div className="row">
                                    <div className='col-12 col-md-6 mt-5'></div>
                                    <FormikInput
                                        type='date'
                                        labelName='Fecha de fin'
                                        nameSelect='DtFechaFin'
                                        className='col-12 col-md-6 mt-5'
                                        required={true}
                                    />
                                </div>

                                <div className="form-group row mt-5">
                                    <div className="col-12 d-flex justify-content-center mt-5">
                                        <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end col-4 mt-5" text="Descargar" disabled={isSubmitting} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form>
                )}
            </Formik>
            <Card className="mt-5">
                <Card.Header>
                    <Card.Title>Acceso a sistema legado</Card.Title>
                </Card.Header>
                <Card.Body>
                    <CrudButton type={'button'} className="btn-sm btn-primaryCustom  col-3 mt-5" 
                        text="Ir SiCuéntanos / SIDMA" 
                        onClick={() => window.open('http://app.saludcapital.gov.co/sidma/', '_blank')}
                    />
                </Card.Body>
            </Card>


        </div>
    </>
}

