import {Form, Formik, FormikProps} from 'formik';
import {useContext, useEffect} from "react";
import {Card} from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import {CrudButton} from "../../../../components/CrudButton";
import {FormConstantsValidation} from "../../../../components/FormConstantsValidation";
import {FormikInput} from '../../../../components/FormikInput';
import {FormikSelect} from '../../../../components/FormikSelect';
import {AtencionesIndividualesDI} from "./AtencionesIndividualesDI";
import {AtencionesIndividualesContextInterface, FormConstantsAtencionIndividual} from "./AtencionesIndividualesTypes";
import {AtencionIndividualContext} from './IngresarInformacion';
import moment from "moment";
import {safeOnChange} from 'src/app/utilities';




const PersonaSquema = Yup.object().shape({
    VcPrimerNombre: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
        })
        .required('Campo obligatorio'),
    VcSegundoNombre: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
        }),
    VcPrimerApellido: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
        })
        .required('Campo obligatorio'),
    VcSegundoApellido: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
        }),
    GeneroId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    VcOtroGenero: Yup.string(),
    VcNombreIdentitario: Yup.string(),
    OrientacionSexualId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    VcOtraOrientacionSexual: Yup.string(),
    SexoId: Yup.number().required('Campo obligatorio'),

    DtFechaNacimiento: Yup.string()
        .nullable()
        .when('esLSPTField', {
            is: false,
            then: Yup.string().required('Campo obligatorio para DSC')
        })
        .test('fecha-nacimiento', 'La fecha de nacimiento no puede ser posterior a la fecha actual', function (value) {
            if (!value) {
                // Si el campo es nulo, no se realiza la validación
                return true;
            }

            const fechaNacimiento = moment(value, 'YYYY-MM-DD'); // Parseamos la fecha de nacimiento con Moment.js
            const fechaActual = moment();

            // Se compara la fecha de nacimiento con la fecha actual
            if (fechaNacimiento.isAfter(fechaActual, 'day')) {
                return false;
            }

            return true;
        }),
    EnfoquePoblacionalId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    HechoVictimizanteId: Yup.number(),
    DepartamentoOrigenVictimaId: Yup.number(),
    MunicipioOrigenVictimaId: Yup.number(),
    EtniaId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    SubEtniaId: Yup.number(),
    PoblacionPrioritariaId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    SubPoblacionPrioritariaId: Yup.number(),
    RegimenId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    AseguradoraId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    EstadoAfiliacionId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    NivelSisbenId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    InstitucionInstrumentoVinculadoId: Yup.number(),
    PaisId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    DepartamentoId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    MunicipioId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    LocalidadId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    UpzId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value)),
    BarrioId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value)),
    ZonaId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    VcCorreo: Yup.string()
        .required('Campo obligatorio')
        .email('Correo no válido'),
    viaId: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string().required('Campo obligatorio para DSC')
        }),
    vcNumeroVia: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string()
                .required('Campo obligatorio para DSC')
                .test('', 'Debe ser mayor a cero', function (f: any) {
                    return f > 0
                })
        }),
    letraViaId: Yup.string(),
    adicionalLetraViaId: Yup.string(),
    orientacionViaId: Yup.string(),
    vcNumMarca: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string()
                .required('Campo obligatorio para DSC')
                .test('', 'Debe ser mayor a cero', function (f: any) {
                    return f > 0
                })
        }),
    letraNumMarcaId: Yup.string(),
    vcNumeroCasa: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string()
                .required('Campo obligatorio para DSC')
                .test('', 'Debe ser mayor a cero', function (f: any) {
                    return f > 0
                })
        })
    ,
    orientacionCasaId: Yup.string(),
    TxDatosContactoAclaraciones: Yup.string(),
    VcTelefono1: Yup.string()
        .required('Campo obligatorio')
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
    VcTelefono2: Yup.string()
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
});

const PersonaSquema2 = Yup.object().shape({
    VcPrimerNombre: Yup.string().required('Campo obligatorio'),
    VcCorreoEntidad: Yup.string().email('Correo no válido'),
    VcNombreContacto: Yup.string().required('Campo obligatorio'),
    VcTelefonoContacto: Yup.string()
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres").required('Campo obligatorio'),

    /*
    EnfoquePoblacionalId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    HechoVictimizanteId: Yup.number(),
    DepartamentoOrigenVictimaId: Yup.number(),
    MunicipioOrigenVictimaId: Yup.number(),
    EtniaId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    SubEtniaId: Yup.number(),
    PoblacionPrioritariaId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    SubPoblacionPrioritariaId: Yup.number(),
    RegimenId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    AseguradoraId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    EstadoAfiliacionId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    NivelSisbenId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    InstitucionInstrumentoVinculadoId: Yup.number(),
    PaisId: Yup.number()
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    DepartamentoId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    MunicipioId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    LocalidadId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    UpzId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value)),
    BarrioId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value)),
    ZonaId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    VcCorreo: Yup.string()
        .required('Campo obligatorio')
        .email('Correo no válido'),
    viaId: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string().required('Campo obligatorio para DSC')
        }),
    vcNumeroVia: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string()
                .required('Campo obligatorio para DSC')
                .test('', 'Debe ser mayor a cero', function (f: any) {
                    return f > 0
                })
        }),
    letraViaId: Yup.string(),
    adicionalLetraViaId: Yup.string(),
    orientacionViaId: Yup.string(),
    vcNumMarca: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string()
                .required('Campo obligatorio para DSC')
                .test('', 'Debe ser mayor a cero', function (f: any) {
                    return f > 0
                })
        }),
    letraNumMarcaId: Yup.string(),
    vcNumeroCasa: Yup.string()
        .when('esLSPTField', {
            is: false,
            then: Yup.string()
                .required('Campo obligatorio para DSC')
                .test('', 'Debe ser mayor a cero', function (f: any) {
                    return f > 0
                })
        })
    ,
    orientacionCasaId: Yup.string(),
    TxDatosContactoAclaraciones: Yup.string(),
    VcTelefono1: Yup.string()
        .required('Campo obligatorio')
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
    VcTelefono2: Yup.string()
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),


    */
});

export function FormularioPersona() {
    const context = useContext(AtencionIndividualContext) as AtencionesIndividualesContextInterface;


    // Usar la referencia del formulario y guardarla en la referencia de contexto
    const formikRef = (formikProps: FormikProps<any>) => {
        context.formRef.current = formikProps;
    };

    return (
        <>
            < AtencionesIndividualesDI/>
            <div>
                <Formik
                    innerRef={formikRef}
                    enableReinitialize={true}
                    initialValues={{
                        ...context.dataSourcePersona,
                        unidadPrestacionServicios: context.userDto?.unidadPrestacionServiciosId
                    }}
                    validationSchema={
                        context.dataSourcePersona.TipoDocumentoId != 8 ? (PersonaSquema): PersonaSquema2}
                    onReset={(values, formProps) => {

                    }}
                    onSubmit={(values, {setSubmitting, setFieldValue}) => {

                        Swal.fire({
                            title: 'Confirmar guardado',
                            text: "¿Desea guardar datos de la persona?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#2d496f',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sí, guardar',
                            cancelButtonText: 'Cancelar'
                        }).then(async (result: any) => {
                            if (result.isConfirmed) {
                                console.log(values);
                                if(values.TipoDocumentoId == 8){
                                    values.VcTelefonoContacto = values.VcTelefonoContacto.toString();
                                }

                                if (!values.DtFechaNacimiento) values.DtFechaNacimiento = null;
                                values.VcTelefono1 = values.VcTelefono1.toString();
                                values.VcTelefono2 = values.VcTelefono2.toString();
                                values.VcDireccion = JSON.stringify(FormConstantsValidation.elementsToArray(`#camposDireccion`));
                                context.setDataSourcePersona(values);
                                context.create(values);
                            }
                        });
                        setSubmitting(false);
                    }}
                >
                    {({
                          isSubmitting,
                          setFieldValue,
                          resetForm,
                          values
                      }) => (
                        <Form autoComplete='off'>
                            {/* Datos de persona */}
                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId != 8 ? (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos de la persona</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <FormikInput
                                                labelName='Primer apellido'
                                                nameSelect='VcPrimerApellido'
                                                className='col-12 col-md-3 mt-5'
                                                required={true}
                                                onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcPrimerApellido.toUpperCase();
                                                    values.VcPrimerApellido = values.VcPrimerApellido.toUpperCase();
                                                }}
                                            />
                                            <FormikInput
                                                labelName='Segundo apellido'
                                                nameSelect='VcSegundoApellido'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcSegundoApellido.toUpperCase();
                                                    values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                                                }}
                                            />
                                            <FormikInput
                                                labelName='Primer nombre'
                                                nameSelect='VcPrimerNombre'
                                                className='col-12 col-md-3 mt-5'
                                                required={true}
                                                onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcPrimerNombre.toUpperCase();
                                                    values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                                                }}
                                            />
                                            <FormikInput
                                                labelName='Segundo nombre'
                                                nameSelect='VcSegundoNombre'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcSegundoNombre.toUpperCase();
                                                    values.VcSegundoNombre = values.VcSegundoNombre.toUpperCase();
                                                }}
                                            />
                                        </div>

                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataGenero}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("GeneroId", selectedOption.value);
                                                }}
                                                value={values.GeneroId}
                                                labelName='Genero'
                                                nameSelect='GeneroId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            {values.GeneroId === FormConstantsAtencionIndividual.OTRO_GENERO && (
                                                <FormikInput
                                                    labelName='Otro genero'
                                                    nameSelect='VcOtroGenero'
                                                    className='col-12 col-md-3 mt-5'
                                                />
                                            )}
                                            {values.GeneroId === FormConstantsAtencionIndividual.TRANSGENERO_GENERO && (
                                                <FormikInput
                                                    labelName='Nombre identitario'
                                                    nameSelect='VcNombreIdentitario'
                                                    className='col-12 col-md-3 mt-5'
                                                />
                                            )}
                                        </div>

                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataOrientSexual}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("OrientacionSexualId", selectedOption.value);
                                                }}
                                                value={values.OrientacionSexualId}
                                                labelName='Orientación sexual'
                                                nameSelect='OrientacionSexualId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            {values.OrientacionSexualId === FormConstantsAtencionIndividual.OTRA_ORIENTACION_SEXUAL && (
                                                <FormikInput
                                                    labelName='Otra orientación sexual'
                                                    nameSelect='VcOtraOrientacionSexual'
                                                    className='col-12 col-md-3 mt-5'
                                                />
                                            )}
                                            <FormikSelect
                                                options={context.dataSexo}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("SexoId", selectedOption.value);
                                                }}
                                                value={values.SexoId}
                                                labelName='Sexo'
                                                nameSelect='SexoId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            <FormikInput
                                                type='date'
                                                labelName='Fecha de nacimiento'
                                                nameSelect='DtFechaNacimiento'
                                                className='col-12 col-md-3 mt-5'
                                                required={!context.esLSPT}

                                            />
                                            <input type="hidden" value="testing" name="esLSPTField"/>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ) : null
                            }

                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId == 8 ? (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos de la entidad</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <FormikInput
                                                labelName='Nombre Entidad'
                                                nameSelect='VcPrimerNombre'
                                                className='col-12 col-md-3 mt-5'
                                                required={true}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcPrimerNombre.toUpperCase();
                                                    values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                                                }}
                                            />
                                            <FormikInput
                                                labelName='Correo'
                                                nameSelect='VcCorreoEntidad'
                                                className='col-12 col-md-3 mt-5'
                                            />
                                            <FormikInput
                                                labelName='Nombre Contacto'
                                                nameSelect='VcNombreContacto'
                                                className='col-12 col-md-3 mt-5'
                                                required={true}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcNombreContacto.toUpperCase();
                                                    values.VcNombreContacto = values.VcNombreContacto.toUpperCase();
                                                }}
                                            />
                                            <FormikInput
                                                type='number'
                                                labelName='Teléfono Contacto'
                                                nameSelect='VcTelefonoContacto'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                required={true}
                                            />
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12">
                                                <CrudButton
                                                    type={'submit'}
                                                    className="btn-sm btn-primaryCustom float-end mx-2"
                                                    text="Actualizar y Continuar"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setFieldValue("esLSPTField", context.esLSPT);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <input type="hidden" value="testing" name="esLSPTField"/>
                                    </Card.Body>
                                </Card>
                            ) : null
                            }

                            {/* Datos de enfoque poblacional y social */}
                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId != 8 ?(
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos de enfoque poblacional y social</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataEnfoqPoblSoc}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("EnfoquePoblacionalId", selectedOption.value);
                                                }}
                                                value={values.EnfoquePoblacionalId}
                                                labelName='Enfoque poblacional'
                                                nameSelect='EnfoquePoblacionalId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            {FormConstantsAtencionIndividual.ARRAY_ENFOQUE_VICTIMA.some((e) => e === values.EnfoquePoblacionalId) && (
                                                <>
                                                    <FormikSelect
                                                        options={context.dataHechVictim}
                                                        onChange={(selectedOption: any) => {
                                                            setFieldValue("HechoVictimizanteId", selectedOption.value);
                                                            context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bDepartamento', FormConstantsAtencionIndividual.CODIGO_PAIS_COLOMBIA, context.setDataDepartamentoVictima);
                                                            setFieldValue("DepartamentoOrigenVictimaId", '');
                                                            setFieldValue("MunicipioOrigenVictimaId", '');
                                                        }}
                                                        value={values.HechoVictimizanteId}
                                                        labelName='Hechos victimizantes'
                                                        nameSelect='HechoVictimizanteId'
                                                        className='col-12 col-md-3 mt-5'
                                                        placeHolder='Seleccione'
                                                    />
                                                    <FormikSelect
                                                        options={context.dataDepartamentoVictima}
                                                        onChange={(selectedOption: any) => {
                                                            setFieldValue("DepartamentoOrigenVictimaId", selectedOption.value);
                                                            setFieldValue("MunicipioOrigenVictimaId", '');
                                                            context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bMunicipio', selectedOption.value, context.setDataMunicipioVictima);
                                                        }}
                                                        value={values.DepartamentoOrigenVictimaId}
                                                        labelName='Departamento origen víctima'
                                                        nameSelect='DepartamentoOrigenVictimaId'
                                                        className='col-12 col-md-3 mt-5'
                                                        placeHolder='Seleccione'
                                                    />
                                                    <FormikSelect
                                                        options={context.dataMunicipioVictima}
                                                        onChange={(selectedOption: any) => {
                                                            setFieldValue("MunicipioOrigenVictimaId", selectedOption.value);
                                                        }}
                                                        value={values.MunicipioOrigenVictimaId}
                                                        labelName='Municipio origen víctima'
                                                        nameSelect='MunicipioOrigenVictimaId'
                                                        className='col-12 col-md-3 mt-5'
                                                        placeHolder='Seleccione'
                                                    />
                                                </>
                                            )}
                                        </div>

                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataEtnia}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("EtniaId", selectedOption.value);
                                                    setFieldValue("SubEtniaId", 0);
                                                    context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bSubtipoEtnia', selectedOption.value, context.setDataSubTipoEtnia);
                                                }}
                                                value={values.EtniaId}
                                                labelName='Etnia'
                                                nameSelect='EtniaId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            <div className="col-12 col-md-3 mt-5"></div>
                                            <FormikSelect
                                                options={context.dataSubTipoEtnia}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("SubEtniaId", selectedOption.value);
                                                }}
                                                value={values.SubEtniaId}
                                                labelName='Subtipo etnia'
                                                nameSelect='SubEtniaId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                            />
                                        </div>

                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataPoblPrioInteres}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("PoblacionPrioritariaId", selectedOption.value);
                                                    setFieldValue("SubPoblacionPrioritariaId", 0);
                                                    context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bSubtipoPoblaciónPrioritaria', selectedOption.value, context.setDataSubTipoPobPrio);
                                                }}
                                                value={values.PoblacionPrioritariaId}
                                                labelName='Población prioritaria / de interés'
                                                nameSelect='PoblacionPrioritariaId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            <div className="col-12 col-md-3 mt-5"></div>
                                            <FormikSelect
                                                options={context.dataSubTipoPobPrio}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("SubPoblacionPrioritariaId", selectedOption.value);
                                                }}
                                                value={values.SubPoblacionPrioritariaId}
                                                labelName='Subtipo población prioritaria'
                                                nameSelect='SubPoblacionPrioritariaId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            ): null}

                            {/* Datos de afiliación */}
                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId != 8 ? (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos de afiliación</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="form-label text-azul-oscuro mt-5">Comprobar estado de
                                                    afiliación</label>
                                                <div className="">
                                                    <CrudButton
                                                        type={'button'}
                                                        className="btn-sm btn-primaryCustom"
                                                        text="Comprobar"
                                                        onClick={() => {
                                                            context.obtenerDatosComprobador(values.VcDocumento, setFieldValue)
                                                        }}
                                                    />
                                                </div>

                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        {context.textoComprobador}
                                                    </div>
                                                </div>
                                            </div>
                                            <FormikSelect
                                                options={context.dataRegimen}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("RegimenId", selectedOption.value);
                                                    setFieldValue("AseguradoraId", '');
                                                    context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bAseguradora', selectedOption.value, context.setDataAseguradora);
                                                }}
                                                value={values.RegimenId}
                                                labelName='Régimen'
                                                nameSelect='RegimenId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                readOnly={context.tituloComprobador}
                                            />
                                            <FormikSelect
                                                options={context.dataAseguradora}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("AseguradoraId", selectedOption.value);
                                                }}
                                                value={values.AseguradoraId}
                                                labelName='Aseguradora'
                                                nameSelect='AseguradoraId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                readOnly={context.tituloComprobador}
                                            />
                                            <FormikSelect
                                                options={context.dataEstadoAfiliacion}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("EstadoAfiliacionId", selectedOption.value);
                                                }}
                                                value={values.EstadoAfiliacionId}
                                                labelName='Estado de afiliación'
                                                nameSelect='EstadoAfiliacionId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                readOnly={context.tituloComprobador}
                                            />
                                        </div>

                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataNivelSisben}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("NivelSisbenId", selectedOption.value);
                                                    setFieldValue("InstitucionInstrumentoVinculadoId", 0);
                                                    context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bInstituciónInstrumentoVinculado', selectedOption.value, context.setDataInstInstrVincul);
                                                }}
                                                value={values.NivelSisbenId}
                                                labelName='Nivel sisben'
                                                nameSelect='NivelSisbenId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                readOnly={context.tituloComprobador}
                                            />
                                            <div className="col-12 col-md-3 mt-5"></div>
                                            {values.NivelSisbenId === FormConstantsAtencionIndividual.SISBEN_ESTUDIO_SOCIAL_CASO && (
                                                <FormikSelect
                                                    options={context.dataInstInstrVincul}
                                                    onChange={(selectedOption: any) => {
                                                        setFieldValue("InstitucionInstrumentoVinculadoId", selectedOption.value);
                                                    }}
                                                    value={values.InstitucionInstrumentoVinculadoId}
                                                    labelName='Institución instrumento vinculado'
                                                    nameSelect='InstitucionInstrumentoVinculadoId'
                                                    className='col-12 col-md-3 mt-5'
                                                    placeHolder='Seleccione'
                                                    readOnly={context.tituloComprobador}
                                                />
                                            )}
                                        </div>

                                        <div className='col-12 col-md-12 mt-15'>
                                            <span>Enlaces de consulta a otras fuentes de comprobación de derechos</span>
                                        </div>

                                        <div className="row mt-10">
                                            <a href="https://www.adres.gov.co/consulte-su-eps"
                                               className='col-12 col-md-3 d-flex flex-center' target={'_blank'}>
                                                <div className='col h-30px' style={{
                                                    backgroundImage: `url(/media/logos/logo-adres.svg)`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center'
                                                }}>
                                                </div>
                                            </a>
                                            <a href="https://www.sisben.gov.co/Paginas/consulta-tu-grupo.aspx"
                                               className='col-12 col-md-3 d-flex flex-center' target={'_blank'}>
                                                <div className='col h-50px' style={{
                                                    backgroundImage: `url(/media/logos/logo-sisben.png)`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center'
                                                }}>
                                                </div>
                                            </a>
                                            <a href="https://appb.saludcapital.gov.co/Comprobadordederechos/Consulta.aspx"
                                               className='col-12 col-md-6 d-flex flex-center' target={'_blank'}>
                                                <div className='col h-50px' style={{
                                                    backgroundImage: `url(/media/logos/logo-bogota.png)`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center'
                                                }}>
                                                </div>
                                            </a>

                                        </div>
                                    </Card.Body>
                                </Card>
                            ) : null}

                            {/* Datos de ubicación */}
                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId != 8 ?  (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos de ubicación</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataPais}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("PaisId", selectedOption.value);
                                                    context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bDepartamento', selectedOption.value, context.setDataDepartamento);
                                                }}
                                                value={values.PaisId}
                                                labelName='Pais'
                                                nameSelect='PaisId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            <FormikSelect
                                                options={context.dataDepartamento}

                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("DepartamentoId", selectedOption.value);
                                                    setFieldValue('MunicipioId', null);
                                                    setFieldValue('LocalidadId', null);
                                                    setFieldValue('UpzId', null);
                                                    setFieldValue('BarrioId', null);
                                                    context.llenarMunicipios(selectedOption.value);
                                                }}
                                                value={values.DepartamentoId}
                                                labelName='Departamento'
                                                nameSelect='DepartamentoId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                SelectRef={context.selectDepartamentoRef}
                                            />
                                            <FormikSelect
                                                options={context.dataMunicipio}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("MunicipioId", selectedOption.value);
                                                    setFieldValue('LocalidadId', null);
                                                    setFieldValue('UpzId', null);
                                                    setFieldValue('BarrioId', null);
                                                    context.llenarLocalidades(selectedOption.value);
                                                    //obtenerDataParametroDetallePorCodigoInternoDetallePadreDefault('bLocalidad', selectedOption.value, setDataLocalidad);
                                                }}
                                                value={values.MunicipioId}
                                                labelName='Municipio'
                                                nameSelect='MunicipioId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                SelectRef={context.selectMunicipioRef}
                                            />
                                            <FormikSelect
                                                options={context.dataLocalidad}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("LocalidadId", selectedOption.value);
                                                    setFieldValue('UpzId', null);
                                                    setFieldValue('BarrioId', null);
                                                    context.llenarUpz(selectedOption.value);
                                                    //obtenerDataParametroDetallePorCodigoInternoDetallePadre('bUpz', selectedOption.value, setDataUpz);
                                                }}
                                                value={values.LocalidadId}
                                                labelName='Localidad'
                                                nameSelect='LocalidadId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                SelectRef={context.selectLocalidadRef}
                                                required={true}
                                            />
                                        </div>

                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataUpz}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("UpzId", selectedOption.value);
                                                    context.llenarBarrio(selectedOption.value);
                                                    //obtenerDataParametroDetallePorCodigoInternoDetallePadre('bBarrio', selectedOption.value, setDataBarrio);
                                                }}
                                                value={values.UpzId}
                                                labelName='UPZ'
                                                nameSelect='UpzId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                SelectRef={context.selectUpzRef}
                                            />
                                            <FormikSelect
                                                options={context.dataBarrio}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("BarrioId", selectedOption.value);
                                                }}
                                                value={values.BarrioId}
                                                labelName='Barrio'
                                                nameSelect='BarrioId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                            />
                                            <FormikSelect
                                                options={context.dataZona}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("ZonaId", selectedOption.value);
                                                }}
                                                value={values.ZonaId}
                                                labelName='Zona'
                                                nameSelect='ZonaId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            />
                                            <FormikInput
                                                labelName='Correo'
                                                nameSelect='VcCorreo'
                                                className='col-12 col-md-3 mt-5'
                                                required={true}
                                            />
                                        </div>

                                        <label className="form-label text-azul-oscuro mt-5">Dirección</label>
                                        <div className="col-12 border rounded my-5 p-10">

                                            <div className="row" id="camposDireccion">

                                                <FormikSelect
                                                    options={context.dataVia}
                                                    onChange={safeOnChange(setFieldValue, 'viaId')}
                                                    value={values.viaId}
                                                    labelName='Vía Principal'
                                                    nameSelect='viaId'
                                                    className='col-12 col-md-2'
                                                    placeHolder=''
                                                    required={!context.esLSPT}
                                                    isClearable={true}
                                                />
                                                <FormikInput
                                                    type='number'
                                                    labelName='No.'
                                                    nameSelect='vcNumeroVia'
                                                    className='col-12 col-md-2'
                                                    placeHolder=''
                                                    onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                    required={!context.esLSPT}
                                                />
                                                <FormikSelect
                                                    options={context.dataLeteraVia}
                                                    onChange={safeOnChange(setFieldValue, 'letraViaId')}
                                                    value={values.letraViaId}
                                                    labelName='Letra'
                                                    nameSelect='letraViaId'
                                                    className='col-12 col-md-2 mt-3'
                                                    placeHolder=''
                                                    isClearable={true}
                                                />
                                                <FormikSelect
                                                    options={context.dataLetraAdicionalVial}
                                                    onChange={safeOnChange(setFieldValue, 'adicionalLetraViaId')}
                                                    value={values.adicionalLetraViaId}
                                                    labelName='Bis'
                                                    nameSelect='adicionalLetraViaId'
                                                    className='col-12 col-md-2 mt-3'
                                                    placeHolder=''
                                                    isClearable={true}
                                                />
                                                <FormikSelect
                                                    options={context.dataOrientacionVia}
                                                    onChange={safeOnChange(setFieldValue, 'orientacionViaId')}
                                                    value={values.orientacionViaId}
                                                    labelName='Cardinalidad'
                                                    nameSelect='orientacionViaId'
                                                    className='col-12 col-md-2 mt-3'
                                                    placeHolder=''
                                                    isClearable={true}
                                                />
                                                <div className="col-12 col-md-2 mt-5" style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    #
                                                </div>
                                                <FormikInput
                                                    type='number'
                                                    labelName='Complemento'
                                                    nameSelect='vcNumMarca'
                                                    className='col-12 col-md-2'
                                                    placeHolder=''
                                                    onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                    required={!context.esLSPT}
                                                />
                                                <FormikSelect
                                                    options={context.dataLetraNumMarca}
                                                    onChange={safeOnChange(setFieldValue, 'letraNumMarcaId')}
                                                    value={values.letraNumMarcaId}
                                                    labelName='Letra'
                                                    nameSelect='letraNumMarcaId'
                                                    className='col-12 col-md-2 mt-3'
                                                    placeHolder=''
                                                    isClearable={true}
                                                />
                                                <div className="mt-5" style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '2%'
                                                }}>
                                                    -
                                                </div>
                                                <FormikInput
                                                    type='number'
                                                    labelName='Placa'
                                                    nameSelect='vcNumeroCasa'
                                                    className='col-12 col-md-2'
                                                    placeHolder=''
                                                    onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                    required={!context.esLSPT}
                                                />
                                                <FormikSelect
                                                    options={context.dataOrientacionCasa}
                                                    onChange={safeOnChange(setFieldValue, 'orientacionCasaId')}
                                                    value={values.orientacionCasaId}
                                                    labelName='Cardinalidad'
                                                    nameSelect='orientacionCasaId'
                                                    className='col-12 col-md-2 mt-3'
                                                    placeHolder=''
                                                    isClearable={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            {values.ZonaId === FormConstantsAtencionIndividual.ZONA_DIRECCION && (
                                                <FormikInput
                                                    labelName='Datos de contacto - aclaraciones'
                                                    nameSelect='TxDatosContactoAclaraciones'
                                                    className='col-12 col-md-6 mt-5'
                                                />
                                            )}
                                            <FormikInput
                                                type='number'
                                                labelName='Teléfono 1'
                                                nameSelect='VcTelefono1'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                required={true}
                                            />
                                            <FormikInput
                                                type='number'
                                                labelName='Teléfono 2'
                                                nameSelect='VcTelefono2'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                            />
                                        </div>

                                        <div className="row mt-5">
                                            <div className="col-12">
                                                <CrudButton
                                                    type={'submit'}
                                                    className="btn-sm btn-primaryCustom float-end mx-2"
                                                    text="Actualizar y Continuar"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        setFieldValue("esLSPTField", context.esLSPT);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ) : null}
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
