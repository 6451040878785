import Select, { components } from "react-select";
import { ErrorMessage } from 'formik';

export function FormikSelect(props) {

    const { onChange, options, value, className, nameSelect, labelName, placeHolder, 
        required = false, readOnly = false, tabIndex, onBlur, SelectRef, onFocus, isClearable = false } = props;

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value === value) || "" : "";
    };


    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className="text-danger">*</label> : null}</label>
            <Select
                name={nameSelect}
                isSearchable="true"
                placeholder={placeHolder}
                getOptionValue={(props) => props.value}
                getOptionLabel={(props) => props.label}
                onChange={value => {
                    if (onChange) {
                        onChange(value)
                    }
                }}
                options={options}
                value={defaultValue(options, value)}
                tabIndex={tabIndex}
                onBlur={value => {
                    if (onBlur) {
                        onBlur(value)
                    }
                }}
                onFocus={value => {
                    if (onFocus) {
                        onFocus(value)
                    }
                }}
                ref={SelectRef}
                isDisabled={readOnly}
                isClearable={isClearable}
            />
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                    <ErrorMessage name={nameSelect} component="div" />
                </div>
            </div>
        </div>



    );
}

export function FormikSelectIcon(props) {

    const { onChange, options, value, className, nameSelect, labelName, placeHolder, required = false, tabIndex, isClearable=false } = props;

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            <div className='select-icon'>
                <i className={props.data.icon}></i>
                <span>{props.data.label}</span>
            </div>
        </Option>
    );

    const defaultValue = (options, value) => {
        return options ? options.find(option => option.value === value) : "";
    };

    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className="text-danger">*</label> : null}</label>
            <Select
                name={nameSelect}
                placeholder={placeHolder}
                getOptionValue={(props) => props.value}
                getOptionLabel={(props) => {
                    const { icon, label } = props;
                    return (
                        <div className='select-icon'>
                            <i className={icon}></i>
                            <span>{label}</span>
                        </div>
                    );
                }}
                onChange={value => {
                    if (onChange) {
                        onChange(value)
                    }
                }}
                components={{ Option: IconOption }}
                options={options}
                value={defaultValue(options, value)}
                tabIndex={tabIndex}
                isClearable={isClearable}

            />
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                    <ErrorMessage name={nameSelect} component="div" />
                </div>
            </div>
        </div>
    );
}
