import { useState, useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import Swal from "sweetalert2";
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikInput, FormikTextArea } from '../../../components/FormikInput';
import { CrudButton } from "../../../components/CrudButton";
import { getOrquestadorApiUrl } from "../../../../_metronic/helpers/ApiOrquestadorHelper";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getOrientacionEInformacionApiUrl } from "../../../../_metronic/helpers/ApiOrientacionEInformacionHelpers";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles, NoRecords, PaginationProperties } from "../../../components/DataTableConfig";
import { FormConstantsValidation } from "../../../components/FormConstantsValidation";
import { InputFilter } from "../../../components/Input";

const AtencionWebSchema = Yup.object().shape({
    UsuarioAsigna: Yup.string().required('Campo obligatorio'),
    UsuarioActualId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    UsuarioAsignaId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        })
});

export function AtencionesWebFormReasignar(props: any) {
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceFilter, setDataSourceFilter] = useState([]);
    const [valueFilter, setValueFilter] = useState('');

    const obtenerDatosUsuarios = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data: any = response.data.models || [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcPrimerNombre + ' ' + element.vcPrimerApellido });
                });
                setDataUsuarios(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosReasignados = async () => {
        setDataSource([]);
        setDataSourceFilter([]);
        setValueFilter('');
        await axios.get(getOrquestadorApiUrl(`AtencionWebReasignacion/porAtencionWebId/${props.AtencionWebId}`))
            .then((response) => {
                // Obtenemos los datos
                const data = response.data?.models || [];
                data.map((item: any) => {
                    item.dtFechaReAsignacion = FormConstantsValidation.getIfDate(item.dtFechaReAsignacion.toString())
                })
                setDataSource(data);
                setDataSourceFilter(data);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const create = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl(`AtencionWebReasignacion/crear/`), data)
            .then((response) => {
                const data = response.data;
                mostrarMensaje(data.titulo, data.mensaje, '1');
                obtenerDatosReasignados();
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo === 400) {
                    mostrarMensaje(data.titulo, data.mensaje, '4');
                }
            });
    }

    const mostrarMensaje = (titulo: string, mensaje: string, icon: string) => {
        Swal.fire({
            title: titulo,
            text: mensaje,
            icon: icon == '1' ? 'success' : icon === '2' ? 'info' : icon === '3' ? 'warning' : 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        });
    }

    useEffect(() => {
        obtenerDatosReasignados();
        obtenerDatosUsuarios();
    }, []);

    const initialValues = {
        Id: 0,
        AtencionWebId: props.AtencionWebId,
        UsuarioAsigna: props.UsuarioLogueado,
        UsuarioAsignaId: props.UsuarioId,
        UsuarioActualId: undefined
    }

    interface DataRow {
        usuarioAsigna: string;
        usuarioActual: string;
        dtFechaAsignacion: string;
        dtFechaReAsignacion: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Usuario Asigna',
            cell: (element) => <p>{element.usuarioAsigna}</p>,
            sortable: false,
        },
        {
            name: 'Usuario Actual',
            cell: (element) => <p>{element.usuarioActual}</p>,
            sortable: false,
        },
        {
            name: 'Fecha Asignación',
            cell: (element) => <p>{element.dtFechaAsignacion.toString().substring(0, 10)}</p>,
            sortable: true,
        },
        {
            name: 'Fecha Reasignación',
            cell: (element) => <p>{element.dtFechaReAsignacion}</p>,
            sortable: true,
        },
    ];

    const filterDataTable = (value: string) => {
        const dataArray = dataSource.filter((item: any) => {
            return item.usuarioAsigna?.toLowerCase().includes(value) ||
                item.usuarioActual?.toLowerCase().includes(value) ||
                item.dtFechaAsignacion?.toString().substring(0, 10).includes(value) ||
                item.dtFechaReAsignacion?.toLowerCase().includes(value);
        });
        setDataSourceFilter(dataArray);
    }

    return <>
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...initialValues }}
                onReset={(values, formProps) => {

                }}
                validationSchema={AtencionWebSchema}
                onSubmit={(values: any, { setSubmitting }) => {
                    Swal.fire({
                        title: 'Confirmar guardado',
                        text: "¿Desea guardar estos datos?",
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí, guardar',
                        cancelButtonText: 'Cancelar'
                    }).then(async (result: any) => {
                        if (result.isConfirmed) {
                            const payload: any = {
                                Id: 0,
                                AtencionWebId: props.AtencionWebId,
                                UsuarioActualId: values.UsuarioActualId,
                                UsuarioAsignaId: values.UsuarioAsignaId,
                            }
                            create(payload);
                        }
                    })
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    setFieldValue,
                    values
                }) => (
                    <Form>
                        <div className="row">
                            <FormikInput
                                labelName='Id del caso'
                                nameSelect='AtencionWebId'
                                className='col-12 col-md-3 mt-5'
                                readOnly={true}
                                required={true}
                            />
                            <FormikInput
                                labelName='Usuario Actual'
                                nameSelect='UsuarioAsigna'
                                className='col-12 col-md-3 mt-5'
                                readOnly={true}
                                required={true}
                            />
                            <FormikSelect
                                options={dataUsuarios}
                                onChange={(selectedOption: any) => {
                                    setFieldValue("UsuarioActualId", selectedOption.value);
                                }}
                                value={values.UsuarioActualId}
                                labelName='Asignar a'
                                nameSelect='UsuarioActualId'
                                className='col-12 col-md-6 mt-5'
                                placeHolder='Seleccione'
                                required={true}
                            />
                            <div className="col-12 col-md-4 mt-5">
                                <label className="form-label text-azul-oscuro d-block">Asignar</label>
                                <CrudButton type={'submit'} className="btn-sm btn-primaryCustom" text="Guardar" disabled={isSubmitting} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            {dataSource.length > 0 ?
                <InputFilter
                    valueFilter={valueFilter}
                    setValueFilter={setValueFilter}
                    onChange={filterDataTable}
                    nameFile="Reasignaciones Atenciones Web"
                    dataSource={dataSourceFilter}
                />
                : null
            }
            <Container className="form-group row mt-8">
                <Col>
                    <DataTable
                        columns={columns}
                        data={dataSourceFilter}
                        pagination
                        customStyles={customStyles}
                        striped={true}
                        paginationComponentOptions={PaginationProperties}
                        noDataComponent={<NoRecords />}
                    />
                </Col>
            </Container>
        </div>
    </>
}

