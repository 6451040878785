import {useState, useEffect} from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { MenuSC } from '../../../../app/services'
import {UserLogin, Menu} from '../../../../app/models'
import { getAuth } from "src/app/utilities";
import { SiCuentanosStore } from 'src/app/redux/store';
import { useSelector } from 'react-redux';

export function MenuInner() {
  const userDto = useSelector((state:SiCuentanosStore)=> state.user); 
  const [menu, setMenu] = useState<Menu[]>([]);

  const obtenerMenu = async(user:UserLogin) =>{
    const { call } = MenuSC(user);
    const response = await call;
    const menuArray: Menu[] = response.data.models;
    setMenu(menuArray);
  }

  function renderMenuItems(menu: Menu[]) {
    if(menu.length == 0)
      return false;

    return menu.map((item) => {
      if (item.hijos !==null) {
        return (
            <MenuInnerWithSub 
              key={item.id} 
              title={item.vcNombre} 
              to={item.vcRedireccion}               
              menuPlacement='right-start'
              menuTrigger={`{default:'click', lg: 'hover'}`}
              hasArrow={true}
              fontIcon={item.icono}
              >
              {renderMenuItems(item.hijos)}
            </MenuInnerWithSub>
        );
        }
      else {
        return (
            <MenuItem to={item.vcRedireccion} title={item.vcNombre} hasBullet={true} key={item.id}/>
        );
      }
    });
  }
  
  useEffect(() => {
    if(userDto != null && userDto != undefined)
      obtenerMenu(userDto);
  }, [userDto]);
  

  return (
    <>

      <MenuItem title='Inicio' to='/dashboard' />
      {renderMenuItems(menu)}     
    </>
  )
}
