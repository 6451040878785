import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { Formik, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import Swal from "sweetalert2";
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikInput, FormikTextArea, FormikInputFile } from '../../../components/FormikInput';
import { CrudButton } from "../../../components/CrudButton";
import { getParametroApiUrl } from "../../../../_metronic/helpers/ApiParametroHelpers";
import { getOrientacionEInformacionApiUrl } from "../../../../_metronic/helpers/ApiOrientacionEInformacionHelpers";
import moment from "moment";
import { FormConstantsValidation } from "../../../components/FormConstantsValidation";
import { AUTH_LOCAL_STORAGE_KEY } from "src/app/utilities";
import { useNavigate } from "react-router";

const currentDate = moment();
const OneMonthAgoDate = moment().add(-1, 'M').format('YYYY-MM-DD');

const AtencionGrupalSchema = Yup.object().shape({
    DtFechaOrientacion: Yup.date()
        .required('Campo obligatorio')
        .min(OneMonthAgoDate, 'La fecha no puede ser menor a la de un mes')
        .max(currentDate, 'La fecha no puede ser mayor a la fecha actual'),
    INumeroUsuarios: Yup.number()
        .required('Campo obligatorio')
        .min(1,'Debe ser mayor a 0')
        .max(9999,'Debe ser menor a 9999'),
    TiempoDuracionId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    TipoSolicitudId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    MotivoId: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    SubMotivoId: Yup.number().transform((value) => (isNaN(value) ? undefined : value))
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    TipoActividadId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    LocalidadId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    VcLugar: Yup.string()
            .required('Campo obligatorio')
            .min(5,"Mínimo 5 caracteres")
            .max(250,"Máximo 250 caracteres"),
    TxAclaracionMotivo: Yup.string()
            .required('Campo obligatorio')
            .min(50,"Mínimo 50 caracteres")
            .max(4000,"Máximo 4000 caracteres"),
    Anexo: Yup.mixed()
            .required('Campo obligatorio')
            .test('fileFormat', 'Formato de archivo debe ser pdf', value => {
                return !value ? true : FormConstantsValidation.TYPEPDF == value.type;
            })            
            .test('fileSize', 'El archivo debe ser de máximo 2MB', value => {
                return value && value.size <= FormConstantsValidation.TWOMBFILESIZE;
            }),
});

export function AtencionesGrupales(props: any) {
    const [userDto] = useState<any>(JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || ''))
    const [dataTemaBrindado, setDataTemaBrindado] = useState([]);
    const [dataTiempoDuracion, setDataTiempoDuracion] = useState([]);
    const [dataMotivo, setDataMotivo] = useState([]);
    const [dataSubMotivo, setDataSubMotivo] = useState([]);

    const [dataTipoActividad, setDataTipoActividad] = useState([]);
    const [dataLocalidad, setDataLocalidad] = useState([]);
    const formRef = useRef<FormikProps<any> | null>(null);
    const navigate = useNavigate();

    const obtenerDataParametroDetalle = async (codigo: any, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInterno/${codigo}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const create = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl(`AtencionGrupal/crear/`), data,{
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                const data = response.data;
                mostrarMensaje(data.titulo, data.mensaje, '1');
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo == 400) {
                    mostrarMensaje(data.titulo, data.mensaje, '4');
                }
            });
    }
    

    const mostrarMensaje = (titulo: string, mensaje: string, icon: string) => {
        Swal.fire({
            title: titulo,
            text: mensaje,
            icon: icon == '1' ? 'success' : icon == '2' ? 'info' : icon == '3' ? 'warning' : 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        }).then((result: any) => {
            if (result.isConfirmed) {
                if (icon == '1') {
                    formRef?.current?.resetForm();
                    formRef?.current?.setFieldValue("DtFechaOrientacion", '');
                    formRef?.current?.setFieldValue("INumeroUsuarios", '');
                    formRef?.current?.setFieldValue("Anexo", '');
                    navigate("/orientacion/grupales/registro");  
                    //window.location.reload();
                }
            }
        });
    }

    useEffect(() => {
        obtenerDataParametroDetalle('bTiempoDuracion', setDataTiempoDuracion);
        obtenerDataParametroDetalle('bTemaBrindado', setDataTemaBrindado);
        obtenerDataParametroDetalle('bTipoActividadAtencionGrupal', setDataTipoActividad);
        obtenerDataParametroDetalle('bLocalidad', setDataLocalidad);
    }, []);

    const obtenerDataParametroDetallePorPadre = async (idDetalle: number, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/getParametroDetallePorPadre/${idDetalle}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const initialValues = {
        Id: 0,
        DtFechaOrientacion: undefined,
        UsuarioId: userDto.id,
        INumeroUsuarios: undefined,
        TiempoDuracionId: undefined,
        TipoSolicitudId: 0,
        MotivoId: 0,
        SubMotivoId: 0,
        TipoActividadId: undefined,
        LocalidadId: undefined,
        VcLugar: '',
        TxAclaracionMotivo: '',
        Anexo: undefined
    }

    const formikRef = (formikProps: FormikProps<any>) => {
        formRef.current = formikProps;
    };

    return <>
        <div>
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{ ...initialValues }}
                onReset={(values, formProps) => {

                }}
                validationSchema={AtencionGrupalSchema}
                onSubmit={(values: any, { setSubmitting }) => {
                    Swal.fire({
                        title: 'Confirmar guardado',
                        text: "¿Desea guardar estos datos?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí, guardar',
                        cancelButtonText: 'Cancelar'
                    }).then(async (result: any) => {
                        if (result.isConfirmed) {
                            let formData = new FormData();
                            formData.append('Id', values.Id);
                            formData.append('DtFechaOrientacion', values.DtFechaOrientacion);
                            formData.append('UsuarioId', values.UsuarioId);
                            formData.append('INumeroUsuarios', values.INumeroUsuarios);
                            formData.append('TiempoDuracionId', values.TiempoDuracionId);
                            formData.append('TipoSolicitudId', values.TipoSolicitudId);
                            formData.append('MotivoId', values.MotivoId);
                            formData.append('SubMotivoId', values.SubMotivoId);
                            formData.append('TipoActividadId', values.TipoActividadId);
                            formData.append('LocalidadId', values.LocalidadId);
                            formData.append('VcLugar', values.VcLugar);
                            formData.append('TxAclaracionMotivo', values.TxAclaracionMotivo);
                            formData.append('Anexo', values.Anexo);
                            create(formData);
                        }
                    })
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    setFieldValue,
                    values
                }) => (
                    <Form>
                        <h6>Ingresar información de la orientación grupal o capacitación</h6>
                        <Card className="mt-5">
                            <Card.Header>
                                <Card.Title>Datos del caso</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="form-group row">
                                    <FormikInput
                                        type='date'
                                        labelName='Fecha de la orientación'
                                        nameSelect='DtFechaOrientacion'
                                        className='col-12 col-md-6'
                                        required={true}
                                    />
                                    <FormikInput
                                        type='number'
                                        labelName='Número de usuarios'
                                        nameSelect='INumeroUsuarios'
                                        className='col-12 col-md-6'
                                        required={true}
                                        onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                    />
                                    <FormikSelect
                                        options={dataTiempoDuracion}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("TiempoDuracionId", selectedOption.value);
                                        }}
                                        value={values.TiempoDuracionId}
                                        labelName='Tiempo de duración'
                                        nameSelect='TiempoDuracionId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                    />
                                    <FormikSelect
                                        options={dataTemaBrindado}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("TipoSolicitudId", selectedOption.value);
                                            setFieldValue("MotivoId", null);
                                            setFieldValue("SubMotivoId", null);
                                            obtenerDataParametroDetallePorPadre(selectedOption.value, setDataMotivo);
                                        }}
                                        value={values.TipoSolicitudId}
                                        labelName='Tema brindado'
                                        nameSelect='TipoSolicitudId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                    />
                                    <FormikSelect
                                        options={dataMotivo}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("MotivoId", selectedOption.value);
                                            setFieldValue("SubMotivoId", null);
                                            obtenerDataParametroDetallePorPadre(selectedOption.value, setDataSubMotivo);
                                        }}
                                        value={values.MotivoId}
                                        labelName='Motivo'
                                        nameSelect='MotivoId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                    />
                                    <FormikSelect
                                        options={dataSubMotivo}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("SubMotivoId", selectedOption.value);
                                        }}
                                        value={values.SubMotivoId}
                                        labelName='Sub motivo'
                                        nameSelect='SubMotivoId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                    />
                                    <FormikSelect
                                        options={dataTipoActividad}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("TipoActividadId", selectedOption.value);
                                        }}
                                        value={values.TipoActividadId}
                                        labelName='Tipo actividad'
                                        nameSelect='TipoActividadId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                    />
                                    <FormikSelect
                                        options={dataLocalidad}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("LocalidadId", selectedOption.value);
                                        }}
                                        value={values.LocalidadId}
                                        labelName='Localidad'
                                        nameSelect='LocalidadId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                    />
                                    <FormikInput
                                        labelName='Lugar'
                                        nameSelect='VcLugar'
                                        className='col-12 col-md-6 mt-5'
                                        required={true}
                                    />
                                    <FormikInputFile
                                        labelName='Cargar archivos'
                                        nameSelect='Anexo'
                                        className='col-12 mt-5'
                                        accept="application/pdf"
                                        onChange={(e: any) => {
                                            setFieldValue("Anexo", e.target.files[0]);
                                            //console.log(e)
                                        }}
                                        required={true}
                                    />
                                    <FormikTextArea
                                        labelName='Aclaraciones - gestión'
                                        nameSelect='TxAclaracionMotivo'
                                        className='col-12 col-md-6 mt-5'
                                        required={true}
                                    />
                                </div>
                                <div className="form-group row mt-5">
                                    <div className="col-12">
                                        <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end mx-2" text="Guardar" disabled={isSubmitting} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form>
                )}
            </Formik>
        </div>
    </>
}

