import axios, { AxiosRequestConfig } from 'axios';
import { ListModelResponse, ModelResponse, Rol } from 'src/app/models';
import { getAdministradorApiUrl, loadAbort } from 'src/app/utilities';




const rolPorInternoService = (vcCodigoInterno: string) => {

    const controller = loadAbort();
    return {
      call: axios.get<ModelResponse<Rol>>(`${getAdministradorApiUrl('Rol/porCodigoInterno')}/${vcCodigoInterno}`, { signal: controller.signal } as AxiosRequestConfig),
      controller
    };
};


const rolesPorUsuarioService = (usuarioId: number) => {

  const controller = loadAbort();
  return {
    call: axios.get<ListModelResponse<string>>(`${getAdministradorApiUrl('UsuarioRol/rolesCodigoporUsuarioId')}/${usuarioId}`, { signal: controller.signal } as AxiosRequestConfig),
    controller
  };
};

export  {
  rolPorInternoService,
  rolesPorUsuarioService
}