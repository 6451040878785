import React, { useState, useEffect } from "react";
import { Col, Container } from "react-bootstrap";
import axios from 'axios';
import { CrudButton } from "../../../components/CrudButton";
import { getOrquestadorApiUrl } from "../../../../_metronic/helpers/ApiOrquestadorHelper";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles, NoRecords, PaginationProperties } from "../../../components/DataTableConfig";

export function AtencionesWebFormAnexos(props: any) {
    const [dataSource, setDataSource] = useState([]);

    const obtenerDatosAnexos = async () => {
        setDataSource([]);
        await axios.get(getOrquestadorApiUrl(`AtencionWebAnexo/bandeja/${props.AtencionWebId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    useEffect(() => {
        obtenerDatosAnexos();
    }, []);

    interface DataRow {
        acciones: string;
        vcNombre: string;
        dtFechaRegistro: string;
        vcUsuario: string;
        vcDescripcion: string;
        iBytes: number;
        vcRuta: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Acciones',
            cell: (element) => <a className="btn-icon btn-sm btn-success" href={element.vcRuta} target="_blank"> <i className="bi bi-download"></i></a>,
            sortable: false,
        },
        {
            name: 'Nombre',
            cell: (element) => <p>{element.vcNombre}</p>,
            sortable: false,
        },
        {
            name: 'Fecha',
            cell: (element) => <p>{element.dtFechaRegistro.toString().substring(0, 10)}</p>,
            sortable: true,
        },
        {
            name: 'Usuario',
            cell: (element) => <p>{element.vcUsuario}</p>,
            sortable: true,
        },
        {
            name: 'Descripción',
            cell: (element) => <p>{element.vcDescripcion}</p>,
            sortable: true,
        },
        {
            name: 'Tamaño Bytes',
            cell: (element) => <p>{element.iBytes}</p>,
            sortable: true,
        },
    ];

    return <>
        <Container className="form-group row mt-8">
            <Col>
                <DataTable
                    columns={columns}
                    data={dataSource}
                    pagination
                    customStyles={customStyles}
                    striped={true}
                    paginationComponentOptions={PaginationProperties}
                    noDataComponent={<NoRecords />}
                />
            </Col>
        </Container>
    </>
}

