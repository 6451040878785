import { Modal } from "react-bootstrap";

export function ActionModal(props: any) {

    return (
        <>
            <Modal show={props.visible} size={props.size || ''}  fullscreen={props.fullscreen || ''} onHide={() => props.handleClose(false)}>
                <Modal.Header closeButton={props.handleClose}>
                    <h5 className="text-azul-oscuro">{props.title}</h5>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        </>
    );
}