import React, { useState, useEffect } from "react";
import {  Card, Col, Container } from "react-bootstrap";
import axios from 'axios';
import ModulosForm from "./ModulosForm";
import Swal from "sweetalert2";
import { ActionModal } from "../../../components/ActionModal";
import { CrudButton } from "../../../components/CrudButton";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getParametroApiUrl } from "../../../../_metronic/helpers/ApiParametroHelpers";
import DataTable, { TableColumn } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles } from "../../../components/DataTableConfig";
import { SwalToast } from "../../../components/SwalToast";


interface OptionIcono {
    value: number;
    label: string;
    icon: string;
};

interface FormularioModulo{
    Id: number;
    VcNombre: string;
    VcDescripcion: string;
    VcRedireccion: string;
    IconoId: number;
    BEstado: boolean;
    
}

interface ModuloContextInterface{
    dataIconos:any; 
    obtenerDatos:any; 
    setModalVisible:any; 
    data:any;
    create:any;
    update:any;
}


export const moduloContext = React.createContext<ModuloContextInterface | null>(null);

export function Modulos(props: any) {
    //const { dataSource, obtenerDatos } = useContext(moduloContext);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [dataIconos, setDataIconos] = useState<OptionIcono[]>([]);

    const obtenerDatos = async () => {
        await axios.get(getAdministradorApiUrl('Modulo'))
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerIconos = async () => {
        await axios.get(getParametroApiUrl('ParametroDetalle/porCodigoInterno/bIconos/'))
            .then((response) => {
                // Obtenemos los datos 
                const data: any = response.data.models ? response.data.models : [];
                const dataIconos: OptionIcono[] = [];
                data.forEach((element: any) => {
                    dataIconos.push({ value: element.id, label: element.vcNombre, icon: element.vcNombre });
                });
                setDataIconos(dataIconos);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const deleteModulo = async (id: any) => {
        await axios.delete(getAdministradorApiUrl(`Modulo/${id}`))
            .then((response) => {
                // Obtenemos los datos
                obtenerDatos(); 
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede eliminar debido a la integridad referencial'
                });                
            });
    } 

    useEffect(() => {
        obtenerDatos();
        obtenerIconos();
    }, []);

    const [form, setForm] = useState({
        Id: 0,
        VcNombre: '',
        VcDescripcion: '',
        VcRedireccion: '',
        IconoId: 0,
        BEstado: true
    });

    const nuevo = () => {
        setForm({
            Id: 0,
            VcNombre: '',
            VcDescripcion: '',
            VcRedireccion: '',
            IconoId: 0,
            BEstado: true
        });
        setModalTitle('AGREGAR MÓDULO');
        setModalVisible(true);
    }
    const editar = (registro: any) => {
        setForm({
            Id: registro.id,
            VcNombre: registro.vcNombre,
            VcDescripcion: registro.vcDescripcion,
            VcRedireccion: registro.vcRedireccion,
            IconoId: registro.iconoId,
            BEstado: registro.bEstado
        });
        setModalTitle('EDITAR MÓDULO');
        setModalVisible(true);
    }
    const eliminar = (registro: any) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteModulo(registro.id)
            }
        })
    }

    const asignarIcono = (iconoId: number) => {
        let icono = dataIconos.find(({ value }) => value === iconoId);
        return icono !== undefined ? icono.label : '';
    }

    const create = async (data: FormularioModulo) => {
        await axios.post(getAdministradorApiUrl('Modulo'), data)
          .then((response) => {
                obtenerDatos();
                setModalVisible(false);
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });       
                     
          })
          .catch(e => {
            // Capturamos los errores
            console.log(e);
            SwalToast.fire({
                icon: 'error',
                title: 'No se puede crear el registro'
            });  
          });
    }
    
    const update = async (data: FormularioModulo) => {
    await axios.put(getAdministradorApiUrl(`Modulo/${data.Id}`) , data)
        .then((response) => {
            obtenerDatos();
            setModalVisible(false);
            SwalToast.fire({
                icon: 'success',
                title: response.data.mensaje
            });
        })
        .catch(e => {
            // Capturamos los errores
            console.log(e);
            SwalToast.fire({
                icon: 'error',
                title: 'No se puede editar el registro'
            }); 
        });
    }

    
    const datosModuloContext: ModuloContextInterface = {
        dataIconos:dataIconos, 
        obtenerDatos:obtenerDatos, 
        setModalVisible:setModalVisible, 
        data:form,
        create: create,
        update: update,
    }

    interface DataRow {
        id: number;
        vcNombre: string;
        vcDescripcion: string;
        iconoId: number;
        bEstado: string;
        acciones: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Nombre',
            cell:(element) => <p>{element.vcNombre }</p>,
            sortable: true,
        },
        {
            name: 'Descripción',
            cell:(element) => <p>{element.vcDescripcion }</p>,
            sortable: true,
        },
        {
            name: 'Icono',
            cell:(element) =>  <span className="badge bg-success"><i className={`text-white ${asignarIcono(element.iconoId)}`}></i></span>,
            sortable: false,
        },
        {
            name: 'Estado',
            cell:(element) => <p>{element.bEstado ? 'Activo' : 'Inactivo'}</p>,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell:(element) =>  
                        <>
                            <CrudButton onClick={() => eliminar(element)} className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" />
                            {" "}
                            <CrudButton onClick={() => editar(element)} className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" />
                        </>,
            sortable: false,
        },
    ];


    return (
        <moduloContext.Provider value={datosModuloContext}>
            <Card>
                <Card.Header>
                    <Card.Title className="text-azul-oscuro">MÓDULOS</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container style={{ textAlign: "right" }}>
                        <CrudButton onClick={() => nuevo()} className="btn-sm btn-primaryCustom" text="Crear" icon="bi bi-plus-circle-fill" />
                    </Container>
                    <br />
                    <Container>
                        <Col>
                            <DataTable
                                columns={columns}
                                data={dataSource}
                                pagination
                                customStyles={customStyles}
                                striped={true}
                                paginationComponentOptions={PaginationProperties}
                                noDataComponent={<NoRecords />}
                            />
                        </Col>
                    </Container>

                </Card.Body>
            </Card>

            <ActionModal visible={modalVisible} title={modalTitle} handleClose={setModalVisible}>
                <ModulosForm  />
            </ActionModal>
        </moduloContext.Provider>
    );

}

