import axios from "axios";
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import * as Yup from 'yup';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { getAdministradorApiUrl } from "../../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";
import { CrudButton } from "../../../../components/CrudButton";
import { FormConstantsValidation } from "../../../../components/FormConstantsValidation";
import { FormikInput } from '../../../../components/FormikInput';
import { FormikSelect } from '../../../../components/FormikSelect';


const dataCitizenship = Yup.object().shape({
    VcPrimerNombre: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
        })
        .required('Campo obligatorio'),
    VcSegundoNombre: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
        }),
    VcPrimerApellido: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
        })
        .required('Campo obligatorio'),
    VcSegundoApellido: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
        }),
    GeneroId: Yup.string().required('Campo obligatorio'),
    VcOtroGenero: Yup.string(),
    VcNombreIdentitario: Yup.string(),
    OrientacionSexualId: Yup.string().required('Campo obligatorio'),
    VcOtraOrientacionSexual: Yup.string(),
    SexoId: Yup.string().required('Campo obligatorio'),
    DtFechaNacimiento: Yup.string().required('Campo obligatorio'),
    EnfoquePoblacionalId: Yup.string().required('Campo obligatorio'),
    HechoVictimizanteId: Yup.string(),
    DepartamentoOrigenVictimaId: Yup.string(),
    MunicipioOrigenVictimaId: Yup.string(),
    EtniaId: Yup.string().required('Campo obligatorio'),
    SubEtniaId: Yup.string(),
    PoblacionPrioritariaId: Yup.string().required('Campo obligatorio'),
    SubPoblacionPrioritariaId: Yup.string(),
    RegimenId: Yup.string().required('Campo obligatorio'),
    AseguradoraId: Yup.string().required('Campo obligatorio'),
    EstadoAfiliacionId: Yup.string().required('Campo obligatorio'),
    NivelSisbenId: Yup.string().required('Campo obligatorio'),
    InstitucionInstrumentoVinculadoId: Yup.string(),
    PaisId: Yup.string().required('Campo obligatorio'),
    DepartamentoId: Yup.string().required('Campo obligatorio'),
    MunicipioId: Yup.string().required('Campo obligatorio'),
    LocalidadId: Yup.string().required('Campo obligatorio'),
    UpzId: Yup.string(),
    BarrioId: Yup.string(),
    ZonaId: Yup.string().required('Campo obligatorio'),
    VcCorreo: Yup.string(),
    viaId: Yup.string().required('Campo obligatorio'),
    vcNumeroVia: Yup.string().required('Campo obligatorio'),
    letraViaId: Yup.string(),
    adicionalLetrViaId: Yup.string(),
    orientacionViaId: Yup.string(),
    vcNumMarca: Yup.string().required('Campo obligatorio'),
    letraNumMarcaId: Yup.string(),
    vcNumeroCasa: Yup.string().required('Campo obligatorio'),
    orientacionCasaId: Yup.string(),
    TxDatosContactoAclaraciones: Yup.string(),
    VcTelefono1: Yup.string()
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
    VcTelefono2: Yup.string()
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
});


export function CaracterizacionCaso(props: any) {
    const [dataSource, setDataSource] = useState<any>([]);

    // AtencionesIndividualesDP
    const [dataGenero, setDataGenero] = useState([]);
    const [dataOrientSexual, setDataOrientSexual] = useState([]);
    const [dataSexo, setdataSexo] = useState([]);

    // AtencionesIndividualesDEpS
    const [dataEnfoqPoblSoc, setDataEnfoqPoblSoc] = useState([]);
    const [dataHechVictim, setDataHechVictim] = useState([]);
    const [dataDepartamento, setDataDepartamento] = useState([]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataEtnia, setDataEtnia] = useState([]);
    const [dataSubTipoEtnia, setDdataSubTipoEtnia] = useState([]);
    const [dataPoblPrioInteres, setDataPoblPrioInteres] = useState([]);
    const [dataSubTipoPobPrio, setDataSubTipoPobPrio] = useState([]);

    // AtencionesIndividualesDA
    const [dataRegimen, setDataRegimen] = useState([]);
    const [dataAseguradora, setDataAseguradora] = useState([]);
    const [dataEstadoAfiliacion, setDataEstadoAfiliacion] = useState([]);
    const [dataNivelSisben, setDataNivelSisben] = useState([]);
    const [dataInstInstrVincul, setDataInstInstrVincul] = useState([]);

    // AtencionesIndividualesDU
    const [dataPais, setDataPais] = useState([]);
    // const [dataDepartamento, setDataDepartamento] = useState([]);
    // const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataLocalidad, setDataLocalidad] = useState([]);
    const [dataUpz, setDataUpz] = useState([]);
    const [dataBarrio, setDataBarrio] = useState([]);
    const [dataVia, setDataVia] = useState([]);
    const [dataLeteraVia, setDataLeteraVia] = useState([]);
    const [dataLetraAdicionalVial, setDataLetraAdicionalVial] = useState([]);
    const [dataOrientacionVia, setDataOrientacionVia] = useState([]);
    const [dataLetraNumMarca, setDataLEtraNumMarca] = useState([]);
    const [dataOrientacionCasa, setDataOrientacionCasa] = useState([]);


    const obtenerDataParametroDetalle = async (codigo: any, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInterno/${codigo}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                setData([]);
            });
    }

    const obtenerDataParametroDetallePorCodigoInternoDetallePadre = async (codigo: string, idDetalle: number, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInternoDetallePadre/${codigo}/${idDetalle}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                setData([]);
            });
    }

    const saveInfoCitizenship = async (data: any) => {
        await axios.post(getAdministradorApiUrl('Usuario'), data)
            .then((response) => {
                console.log(response);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }


    useEffect(() => {
        obtenerDataParametroDetalle('bGenero', setDataGenero);
        obtenerDataParametroDetalle('bOrientacionSexual', setDataOrientSexual);
        obtenerDataParametroDetalle('bSexo', setdataSexo);

        // obtenerDataParametroDetalle('bRegimen', setDataEnfoqPoblSoc);
        // obtenerDataParametroDetalle('bRegimen', setDataHechVictim);
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bDepartamento', 31, setDataDepartamento);
        obtenerDataParametroDetalle('bEtnia', setDataEtnia);
        obtenerDataParametroDetalle('bPoblacionPrioritaria', setDataPoblPrioInteres);

        obtenerDataParametroDetalle('bRegimen', setDataRegimen);
        obtenerDataParametroDetalle('bNivelSISBEN', setDataNivelSisben);
        obtenerDataParametroDetalle('bInstituciónInstrumentoVinculado ', setDataInstInstrVincul);

        obtenerDataParametroDetalle('bPais', setDataPais);
    }, []);

    const initialValues: any = {
        VcPrimerApellido: null,
        VcSegundoApellido: null,
        VcPrimerNombre: null,
        VcSegundoNombre: null,
        GeneroId: null,
        VcOtroGenero: null,
        VcNombreIdentitario: null,
        OrientacionSexualId: null,
        VcOtraOrientacionSexual: null,
        SexoId: null,
        DtFechaNacimiento: null,
        EnfoquePoblacionalId: null,
        HechoVictimizanteId: null,
        DepartamentoOrigenVictimaId: null,
        MunicipioOrigenVictimaId: null,
        EtniaId: null,
        SubEtniaId: null,
        PoblacionPrioritariaId: null,
        SubPoblacionPrioritariaId: null,
        RegimenId: null,
        AseguradoraId: null,
        EstadoAfiliacionId: null,
        NivelSisbenId: null,
        InstitucionInstrumentoVinculadoId: null,
        PaisId: null,
        DepartamentoId: null,
        MunicipioId: null,
        LocalidadId: null,
        UpzId: null,
        BarrioId: null,
        ZonaId: null,
        VcCorreo: null,
        viaId: null,
        vcNumeroVia: null,
        letraViaId: null,
        adicionalLetrViaId: null,
        orientacionViaId: null,
        vcNumMarca: null,
        letraNumMarcaId: null,
        vcNumeroCasa: null,
        orientacionCasaId: null,
        TxDatosContactoAclaraciones: null,
        VcTelefono1: null,
        VcTelefono2: null
    }

    return (
        <section>
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={dataCitizenship}
                    onReset={(values, formProps) => {

                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        saveInfoCitizenship(values);
                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        setFieldValue,
                        values
                    }) => (
                        <Form autoComplete='off'>
                            <h6>Caracterización del caso</h6>
                            {/* Datos de persona */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos de la persona</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <FormikInput
                                            labelName='Primer apellido'
                                            nameSelect='VcPrimerApellido'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoApellido.toUpperCase();
                                                values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                                            }}
                                            required={true}
                                        />
                                        <FormikInput
                                            labelName='Segundo apellido'
                                            nameSelect='VcSegundoApellido'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoApellido.toUpperCase();
                                                values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                                            }}
                                        />
                                        <FormikInput
                                            labelName='Primer nombre'
                                            nameSelect='VcPrimerNombre'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcPrimerNombre.toUpperCase();
                                                values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                                            }}
                                            required={true}
                                        />
                                        <FormikInput
                                            labelName='Segundo nombre'
                                            nameSelect='VcSegundoNombre'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoNombre.toUpperCase();
                                                values.VcSegundoNombre = values.VcSegundoNombre.toUpperCase();
                                            }}
                                        />
                                    </div>

                                    <div className="row">
                                        <FormikSelect
                                            options={dataGenero}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("GeneroId", selectedOption.value);
                                            }}
                                            value={values.GeneroId}
                                            labelName='Genero'
                                            nameSelect='GeneroId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        {values.GeneroId == '1592' && (
                                            <FormikInput
                                                labelName='Otro genero'
                                                nameSelect='VcOtroGenero'
                                                className='col-12 col-md-3 mt-5'
                                            />
                                        )}
                                        {values.GeneroId == '1591' && (
                                            <FormikInput
                                                labelName='Nombre identitario'
                                                nameSelect='VcNombreIdentitario'
                                                className='col-12 col-md-3 mt-5'
                                            />
                                        )}
                                    </div>

                                    <div className="row">
                                        <FormikSelect
                                            options={dataOrientSexual}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("OrientacionSexualId", selectedOption.value);
                                            }}
                                            value={values.OrientacionSexualId}
                                            labelName='Orientación sexual'
                                            nameSelect='OrientacionSexualId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        {values.OrientacionSexualId == '1599' && (
                                            <FormikInput
                                                labelName='Otra orientación sexual'
                                                nameSelect='VcOtraOrientacionSexual'
                                                className='col-12 col-md-3 mt-5'
                                            />
                                        )}
                                        <FormikSelect
                                            options={dataSexo}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("SexoId", selectedOption.value);
                                            }}
                                            value={values.SexoId}
                                            labelName='Sexo'
                                            nameSelect='SexoId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikInput
                                            type='date'
                                            labelName='Fecha de nacimiento'
                                            nameSelect='DtFechaNacimiento'
                                            className='col-12 col-md-3 mt-5'
                                            required={true}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* Datos de enfoque poblacional y social */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos de enfoque poblacional y social</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <FormikSelect
                                            options={dataEnfoqPoblSoc}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("EnfoquePoblacionalId", selectedOption.value);
                                            }}
                                            value={values.EnfoquePoblacionalId}
                                            labelName='Enfoque poblacional'
                                            nameSelect='EnfoquePoblacionalId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataHechVictim}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("HechoVictimizanteId", selectedOption.value);
                                                setFieldValue("DepartamentoOrigenVictimaId", '');
                                                setFieldValue("MunicipioOrigenVictimaId", '');
                                            }}
                                            value={values.HechoVictimizanteId}
                                            labelName='Hechos victimizantes'
                                            nameSelect='HechoVictimizanteId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                        <FormikSelect
                                            options={dataDepartamento}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("DepartamentoOrigenVictimaId", selectedOption.value);
                                                setFieldValue("MunicipioOrigenVictimaId", '');
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bMunicipio', selectedOption.value, setDataMunicipio);
                                            }}
                                            value={values.DepartamentoOrigenVictimaId}
                                            labelName='Departamento origen víctima'
                                            nameSelect='DepartamentoOrigenVictimaId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                        <FormikSelect
                                            options={dataMunicipio}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("MunicipioOrigenVictimaId", selectedOption.value);
                                            }}
                                            value={values.MunicipioOrigenVictimaId}
                                            labelName='Municipio origen víctima'
                                            nameSelect='MunicipioOrigenVictimaId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                    </div>

                                    <div className="row">
                                        <FormikSelect
                                            options={dataEtnia}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("EtniaId", selectedOption.value);
                                                setFieldValue("SubEtniaId", '');
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bSubtipoEtnia', selectedOption.value, setDdataSubTipoEtnia);
                                            }}
                                            value={values.EtniaId}
                                            labelName='Etnia'
                                            nameSelect='EtniaId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <div className="col-12 col-md-3 mt-5"></div>
                                        <FormikSelect
                                            options={dataSubTipoEtnia}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("SubEtniaId", selectedOption.value);
                                            }}
                                            value={values.SubEtniaId}
                                            labelName='Subtipo etnia'
                                            nameSelect='SubEtniaId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                    </div>

                                    <div className="row">
                                        <FormikSelect
                                            options={dataPoblPrioInteres}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("PoblacionPrioritariaId", selectedOption.value);
                                                setFieldValue("SubPoblacionPrioritariaId", '');
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bSubtipoPoblaciónPrioritaria', selectedOption.value, setDataSubTipoPobPrio);
                                            }}
                                            value={values.PoblacionPrioritariaId}
                                            labelName='Población prioritaria / de interés'
                                            nameSelect='PoblacionPrioritariaId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <div className="col-12 col-md-3 mt-5"></div>
                                        <FormikSelect
                                            options={dataSubTipoPobPrio}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("SubPoblacionPrioritariaId", selectedOption.value);
                                            }}
                                            value={values.SubPoblacionPrioritariaId}
                                            labelName='Subtipo población prioritaria'
                                            nameSelect='SubPoblacionPrioritariaId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* Datos de afiliación */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos de afiliación</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label text-azul-oscuro mt-5">Comprobar estado de afiliación</label>
                                            <CrudButton type={'button'} className="btn-sm btn-primaryCustom" text="Comprobar" disabled="true" />
                                        </div>
                                        <FormikSelect
                                            options={dataRegimen}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("RegimenId", selectedOption.value);
                                                setFieldValue("AseguradoraId", '');
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bAseguradora', selectedOption.value, setDataAseguradora);
                                            }}
                                            value={values.RegimenId}
                                            labelName='Régimen'
                                            nameSelect='RegimenId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataAseguradora}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("AseguradoraId", selectedOption.value);
                                            }}
                                            value={values.AseguradoraId}
                                            labelName='Aseguradora'
                                            nameSelect='AseguradoraId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataEstadoAfiliacion}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("EstadoAfiliacionId", selectedOption.value);
                                            }}
                                            value={values.EstadoAfiliacionId}
                                            labelName='Estado de afiliación'
                                            nameSelect='EstadoAfiliacionId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                    </div>

                                    <div className="row">
                                        <FormikSelect
                                            options={dataNivelSisben}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("NivelSisbenId", selectedOption.value);
                                            }}
                                            value={values.NivelSisbenId}
                                            labelName='Nivel sisben'
                                            nameSelect='NivelSisbenId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}

                                        />
                                        <div className="col-12 col-md-3 mt-5"></div>
                                        {values.NivelSisbenId == '1729' && (
                                            <FormikSelect
                                                options={dataInstInstrVincul}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("InstitucionInstrumentoVinculadoId", selectedOption.value);
                                                }}
                                                value={values.InstitucionInstrumentoVinculadoId}
                                                labelName='Institución instrumento vinculado'
                                                nameSelect='InstitucionInstrumentoVinculadoId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                            />
                                        )}
                                    </div>

                                    <div className='col-12 col-md-12 mt-15'>
                                        <span>Enlaces de consulta a otras fuentes de comprobación de derechos</span>
                                    </div>

                                    <div className="row mt-10">
                                        <div className='col-12 col-md-3 d-flex flex-center'>
                                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-adres.svg')} className='h-40px' />
                                        </div>
                                        <div className='col-12 col-md-3 d-flex flex-center'>
                                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-sisben.png')} className='h-70px' />
                                        </div>
                                        <div className='col-12 col-md-6 d-flex flex-center'>
                                            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-bogota.png')} className='h-85px' />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* Datos de ubicación */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos de ubicación</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <FormikSelect
                                            options={dataPais}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("PaisId", selectedOption.value);
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bDepartamento', selectedOption.value, setDataDepartamento);
                                            }}
                                            value={values.PaisId}
                                            labelName='Pais'
                                            nameSelect='PaisId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataDepartamento}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("DepartamentoId", selectedOption.value);
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bMunicipio', selectedOption.value, setDataMunicipio);
                                            }}
                                            value={values.DepartamentoId}
                                            labelName='Departamento'
                                            nameSelect='DepartamentoId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataMunicipio}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("MunicipioId", selectedOption.value);
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bLocalidad', selectedOption.value, setDataLocalidad);
                                            }}
                                            value={values.MunicipioId}
                                            labelName='Municipio'
                                            nameSelect='MunicipioId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataLocalidad}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("LocalidadId", selectedOption.value);
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bUpz', selectedOption.value, setDataUpz);
                                            }}
                                            value={values.LocalidadId}
                                            labelName='Localidad'
                                            nameSelect='LocalidadId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                    </div>

                                    <div className="row">
                                        <FormikSelect
                                            options={dataUpz}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("UpzId", selectedOption.value);
                                                obtenerDataParametroDetallePorCodigoInternoDetallePadre('bBarrio', selectedOption.value, setDataBarrio);
                                            }}
                                            value={values.UpzId}
                                            labelName='UPZ'
                                            nameSelect='UpzId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                        <FormikSelect
                                            options={dataBarrio}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("BarrioId", selectedOption.value);
                                            }}
                                            value={values.BarrioId}
                                            labelName='Barrio'
                                            nameSelect='BarrioId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                        />
                                        <div className='col-12 col-md-3 mt-5 align-items-end'>
                                            <label className="form-label text-azul-oscuro">Zona <label className='text-danger'>*</label></label>

                                            <div className='d-flex mt-5'>
                                                <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox'
                                                        value='rural'
                                                        onChange={e => {
                                                            setFieldValue("ZonaId", '')
                                                            if (e.target.checked) {
                                                                setFieldValue("ZonaId", e.target.value)
                                                            }
                                                        }}
                                                        checked={values.ZonaId == 'rural'}
                                                        name='ZonaId'
                                                    />
                                                    <span className='form-check-label'>Rural</span>
                                                </label>

                                                <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    <input
                                                        className='form-check-input'
                                                        type='checkbox' value='urbana'
                                                        onChange={e => {
                                                            setFieldValue("ZonaId", '')
                                                            if (e.target.checked) {
                                                                setFieldValue("ZonaId", e.target.value)
                                                            }
                                                        }}
                                                        checked={values.ZonaId == 'urbana'}
                                                        name='ZonaId'
                                                    />
                                                    <span className='form-check-label'>Urbana</span>
                                                </label>
                                            </div>
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <ErrorMessage name='ZonaId' component="div" />
                                                </div>
                                            </div>
                                        </div>
                                        <FormikInput
                                            labelName='Correo'
                                            nameSelect='VcCorreo'
                                            className='col-12 col-md-3 mt-5'
                                        />
                                    </div>

                                    <div className="col-12">
                                        <label className="form-label text-azul-oscuro mt-5">Dirección</label>
                                        <div className="row">
                                            <FormikSelect
                                                options={dataVia}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("viaId", selectedOption.value);
                                                }}
                                                value={values.viaId}
                                                labelName=' '
                                                nameSelect='viaId'
                                                className='col-12 col-md-1'
                                                placeHolder='KR'
                                                required={true}
                                            />
                                            <FormikInput
                                                type='number'
                                                labelName=' '
                                                nameSelect='vcNumeroVia'
                                                className='col-12 col-md-1'
                                                placeHolder='32'
                                                required={true}
                                            />
                                            <FormikSelect
                                                options={dataLeteraVia}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("letraViaId", selectedOption.value);
                                                }}
                                                value={values.letraViaId}
                                                labelName=' '
                                                nameSelect='letraViaId'
                                                className='col-12 col-md-1 mt-3'
                                                placeHolder='B'
                                            />
                                            <FormikSelect
                                                options={dataLetraAdicionalVial}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("adicionalLetrViaId", selectedOption.value);
                                                }}
                                                value={values.adicionalLetrViaId}
                                                labelName=' '
                                                nameSelect='adicionalLetrViaId'
                                                className='col-12 col-md-1 mt-3'
                                                placeHolder='BIS'
                                            />
                                            <FormikSelect
                                                options={dataOrientacionVia}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("orientacionViaId", selectedOption.value);
                                                }}
                                                value={values.orientacionViaId}
                                                labelName=' '
                                                nameSelect='orientacionViaId'
                                                className='col-12 col-md-1 mt-3'
                                                placeHolder='SUR'
                                            />
                                            <div className="mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '2%' }}>
                                                #
                                            </div>
                                            <FormikInput
                                                type='number'
                                                labelName=' '
                                                nameSelect='vcNumMarca'
                                                className='col-12 col-md-1'
                                                placeHolder='12'
                                                required={true}
                                            />
                                            <FormikSelect
                                                options={dataLetraNumMarca}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("letraNumMarcaId", selectedOption.value);
                                                }}
                                                value={values.letraNumMarcaId}
                                                labelName=' '
                                                nameSelect='letraNumMarcaId'
                                                className='col-12 col-md-1 mt-3'
                                                placeHolder='A'
                                            />
                                            <div className="mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '2%' }}>
                                                -
                                            </div>
                                            <FormikInput
                                                type='number'
                                                labelName=' '
                                                nameSelect='vcNumeroCasa'
                                                className='col-12 col-md-1'
                                                placeHolder='81'
                                                required={true}
                                            />
                                            <FormikSelect
                                                options={dataOrientacionCasa}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("orientacionCasaI", selectedOption.value);
                                                }}
                                                value={values.orientacionCasaId}
                                                labelName=' '
                                                nameSelect='orientacionCasaId'
                                                className='col-12 col-md-1 mt-3'
                                                placeHolder='ESTE'
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        {values.ZonaId == 'rural' && (
                                            <FormikInput
                                                labelName='Datos de contacto - aclaraciones'
                                                nameSelect='TxDatosContactoAclaraciones'
                                                className='col-12 col-md-6 mt-5'
                                            />
                                        )}
                                        <FormikInput
                                            type='number'
                                            labelName='Teléfono 1'
                                            nameSelect='VcTelefono1'
                                            className='col-12 col-md-3 mt-5'
                                        />
                                        <FormikInput
                                            type='number'
                                            labelName='Teléfono 2'
                                            nameSelect='VcTelefono2'
                                            className='col-12 col-md-3 mt-5'
                                        />
                                    </div>

                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end mx-2" text="Actualizar y Continuar" disabled={isSubmitting} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
}

