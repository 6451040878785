// Render Prop
import  { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikSelect, FormikSelectIcon } from '../../../components/FormikSelect';
import { FormikCheckbox, FormikInput, FormikTextArea } from '../../../components/FormikInput';
import { actividadContext } from './Actividades';

const ActividadSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Campo obligatorio'),
  ModuloId: Yup.string()
    .notOneOf([0, '0'], 'Campo obligatorio')
    .required('Campo obligatorio'),
  VcDescripcion: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(200, 'Máximo 200 caracteres')
    .required('Campo obligatorio'),
  VcRedireccion: Yup.string()
    .min(1, 'Mínimo 1 caracteres')
    .max(80, 'Máximo 80 caracteres')
    .required('Campo obligatorio'),
  IconoId: Yup.number()
    
});

export default function ActividadesForm() {

  const actividadCContext = useContext(actividadContext);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={actividadCContext.data}
        validationSchema={ActividadSchema}
        onReset={(values, formProps) => {
          actividadCContext.setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.PadreId = Number(values.PadreId);
          //console.log(values);
          if (values.Id == 0) {
            actividadCContext.create(values);
          } else {
            actividadCContext.update(values);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <div className="form-group row">
              <FormikInput
                  type='text'
                  labelName='Nombre'
                  nameSelect='VcNombre'
                  className='col-12'
                  required={true}
              />              
              <FormikSelect
                options={actividadCContext.dataSourceModulos}
                onChange={selectedOption => setFieldValue("ModuloId", selectedOption.value)}
                value={values.ModuloId}
                labelName='Módulo'
                nameSelect='ModuloId'
                className='col-12 mt-5'
                placeHolder='Seleccione un módulo'
                required={true}
              />
              <FormikTextArea
                labelName='Descripción'
                nameSelect='VcDescripcion'
                className='col-12 mt-5'
                required={true}
                rows="4"
              />
              <FormikInput
                  type='text'
                  labelName='Redirección'
                  nameSelect='VcRedireccion'
                  className='col-12 mt-5'
                  required={true}
                  placeholder="Redirección"
              /> 
              <FormikSelectIcon
                options={actividadCContext.dataIconos}
                onChange={selectedOption => setFieldValue("IconoId", selectedOption.value)}
                value={values.IconoId}
                labelName='Icono'
                nameSelect='IconoId'
                className='col-12 mt-5'
                placeHolder='Seleccione un icono'
              />
              <FormikSelect
                options={actividadCContext.dataPadre}
                onChange={selectedOption => setFieldValue("PadreId", selectedOption.value)}
                value={values.PadreId}
                labelName='Padre'
                nameSelect='PadreId'
                className='col-12 mt-5'
                placeHolder=''
              />

              <FormikCheckbox
                labelName='Estado'
                nameSelect='BEstado'
                className='col-6 mt-5'
                value={values.BEstado}
                required={true}
              />

                <FormikCheckbox
                labelName='Público'
                nameSelect='BPublico'
                className='col-6 mt-5'
                value={values.BPublico}
                required={true}
              />
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};