// Render Prop
import {useContext} from 'react';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikCheckbox, FormikInput } from '../../../components/FormikInput';
import { rolesContext } from './Roles';

const RolSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Campo obligatorio'),
  ModuloId: Yup.string()
    .notOneOf([0, '0'], 'Campo obligatorio')
    .required('Campo obligatorio'),
  VcCodigoInterno: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(50, 'Máximo 50 caracteres')
});


export default function RolesForm() {

  const rolesCContext = useContext(rolesContext);


  return (
    <div>
      <Formik
        initialValues={rolesCContext.data}
        validationSchema={RolSchema}
        onReset={(values, formProps) => {
          rolesCContext.setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values)
          if (values.Id === 0) {
            rolesCContext.createRol(values);
          } else {
            rolesCContext.updateRol(values);
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <div className="form-group row">
              <FormikInput
                  type='text'
                  labelName='Nombre'
                  nameSelect='VcNombre'
                  className='col-12'
                  required={true}
                  placeholder=""
              /> 
              <FormikSelect
                options={rolesCContext.dataSourceModulos}
                onChange={selectedOption => setFieldValue("ModuloId", selectedOption.value)}
                value={values.ModuloId}
                labelName='Módulo'
                nameSelect='ModuloId'
                className='col-12 mt-5'
                placeHolder='Seleccione'
              />
              <FormikInput
                  type='text'
                  labelName='Código interno'
                  nameSelect='VcCodigoInterno'
                  className='col-12 mt-5'
                  required={false}
                  placeholder=""
              />               
              <FormikCheckbox
                  labelName='Estado'
                  nameSelect='BEstado'
                  className='col-12 mt-5'
                  value={values.BEstado}
                  required={true}
                />
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );       
}
