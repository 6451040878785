import { loadAbort } from "../utilities/load-abort-axios.utility";
import axios, { AxiosRequestConfig } from 'axios';
import { EstadoUsuario, ListModelResponse, UserLogin } from "../models";
import { getAdministradorApiUrl } from "../utilities";
const usuarioPorEstadoSC = (estadoId: EstadoUsuario) => {
    const controller = loadAbort();
    return {
      call: axios.get<ListModelResponse<UserLogin>>(`${getAdministradorApiUrl('Usuario/porEstado')}/${estadoId}`, { signal: controller.signal } as AxiosRequestConfig),
      controller
    };
};

export default usuarioPorEstadoSC;