import { loadAbort } from "../utilities/load-abort-axios.utility";
import axios, { AxiosRequestConfig } from 'axios';
import { ComprobadorResponse,  ModelResponse } from "../models";
import { getOrquestadorApiUrl } from "../utilities";
import {getOrientacionEInformacionApiUrl} from "../../_metronic/helpers/ApiOrientacionEInformacionHelpers";
const comprobadorDerechosSC = (vcDocumento: string) => {
    const controller = loadAbort();
    return {
      call: axios.get<ModelResponse<ComprobadorResponse>>(`${getOrientacionEInformacionApiUrl('Persona/comprobador')}/${vcDocumento}`, { signal: controller.signal } as AxiosRequestConfig),
      controller
    };
};

export default comprobadorDerechosSC;
