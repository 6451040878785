import { Col, Container } from "react-bootstrap";
import { CrudButton } from "./CrudButton";
import { handleDownloadExcel } from "./Excel";

function Input(props: any) {
    return (
        <input
            name={props.name}
            type="text"
            className={props.class}
            placeholder={props.placeholder}
        />
    )
}

export { Input }

function InputFilter(props: any) {
    // const ExcelFile = ExportExcel.ExcelFile;
    // const ExcelSheet = ExportExcel.ExcelSheet;
    // const ExcelColumn = ExportExcel.ExcelColumn;

    const { valueFilter, setValueFilter, onChange, nameFile, dataSource, dataColumns } = props;
    return (
        <Container fluid className="form-group row mt-8">
            <Col className="col-12 offset-md-8 col-md-4 d-flex">
                <CrudButton type={'button'} icon="bi bi-file-earmark-excel-fill" className="btn-sm btn-primaryCustom btn-filter" text="Excel" onClick={() => handleDownloadExcel(dataSource, nameFile, nameFile)} />
                <input
                    type="text"
                    className="form-control form-control-solid filter"
                    placeholder="Filtrar"
                    value={valueFilter}
                    onChange={(e: any) => {
                        setValueFilter(e.target.value);
                        const value = e.target.value?.toLowerCase() || '';
                        if (onChange) {
                            onChange(value);
                        }
                    }}
                />                
                {/* <ExcelFile name={nameFile} element={<CrudButton type={'button'} icon="bi bi-file-earmark-excel-fill" className="btn-sm btn-primaryCustom btn-filter" text="Excel" />}>
                    <ExcelSheet data={dataSource} name={nameFile}>
                        {dataColumns.forEach((item: any) => {
                            <ExcelColumn label={item.label} value={item.value} />
                        })}                        
                    </ExcelSheet>
                </ExcelFile> */}
            </Col>
        </Container>
    )
}

export { InputFilter }