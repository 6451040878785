
function nameSplitter(name:string){
    let indexSpace: number = name.indexOf(' ');
    let compoundName = {
        'first': (indexSpace !== -1) ? name.substring(0,indexSpace) : name ,
        'second': (indexSpace !== -1) ? name.substring(indexSpace) : ''
    };
    return compoundName;
}

export default nameSplitter;