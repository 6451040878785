
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export function CrudButton(props) {
    
    const  { type, className, icon, text, onClick, disabled, tabIndex, toolTip } = props;
    

    return (
        <>
            {toolTip ? (
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip  className='fs-9 tooltip-negro'>{toolTip}</Tooltip>}

                >
                    <button type={type ? type : 'button'} className={`btn ${className}`} onClick={onClick} disabled={disabled} tabIndex={tabIndex}>        
                        {icon && <i className={icon}></i>}                        
                        {text}
                    </button>
                </OverlayTrigger>
            ) : (
                <button type={type ? type : 'button'} className={`btn ${className}`} onClick={onClick} disabled={disabled} tabIndex={tabIndex}>
                    {icon && <i className={icon}></i>}
                    {text}
                </button>   
            )}
        </>
    );
}