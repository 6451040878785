import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { authProvider } from '../../utilities';
export default function LoginInProgress() {
  const handleLoginClick = () => {
    authProvider.login();
  };
  return (
    <>
        <div className="scale"  onClick={handleLoginClick}>
          <img  src={toAbsoluteUrl('/media/logos/circulo-amarillo.png')} alt="Logo SiCuentanos" className="h-100px logo-login-progress" />
          <span>Iniciando sesion en Azure B2C...</span>   
        </div>                
    </>
  )
}
