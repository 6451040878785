import { Rol, UserLogin } from "src/app/models";


const CODIGO_ESTADO_SEGUIMIENTO = 'SEG';
export const FormConstantsAtencionWeb = {
    CODIGO_ESTADO_SEGUIMIENTO
}

export interface AtencionesWebContextInterface {
    //Modal Reasignar, anexos y nuevo caso
    UsuarioId: any;
    UsuarioLogueado: any;
    dataCasoCreado:CasoResponseInterface;

    dataSourceReasignacion: any; 
    setDataSourceReasignacionFilter: any;
    dataSourceReasignacionFilter: any;
    setDataEstados: any;
    dataEstados: any;
    valueFilter: any;
    setValueFilter: any;
    
    obtenerDatosAnexos: any;
    dataSourceAnexos: any;
    verAnexos: any;
    asociarAnexoCaso:any;
    

    //WEB
    obtenerDatosUsuarios: any;
    obtenerDatosReasignados: any;
    createReasignacion: any;
    filterDataTable: any;
    dataUsuarios: any;
}

export interface CasoResponseInterface {
    id: number;
    estadoId: number;
    usuarioId: number;
}

export const DefaultCasoResponse: CasoResponseInterface = {
    id: 0,
    estadoId: 0,
    usuarioId: 0,
}
