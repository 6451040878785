import React, { useState, useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import axios from 'axios'
import Swal from "sweetalert2";
import { ActionModal } from "../../../../components/ActionModal";
import RangosForm from "./RangosForm";
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";
import { CrudButton } from "../../../../components/CrudButton";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles, NoRecords, PaginationProperties } from "../../../../components/DataTableConfig";

export function Rangos(props: any) {

    const [dataSource, setDataSource] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [color, setColor] = useState("");
    const [form, setForm] = useState({
        Id: 0,
        ParametroId: 39,
        VcNombre: '',
        TxDescripcion: '',
        DCodigoIterno: 0,
        BEstado: true
    });

    const obtenerRangos = async () => {
        await axios.get(getParametroApiUrl('ParametroDetalle/porCodigoInterno/bRangoGestionCasos'))
            .then((response) => {
                // Obtenemos los datos
                const data = response.data.models ? response.data.models : [];
                setDataSource(data);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const eliminarRangoGestion = async (id: number) => {
        await axios.delete(getParametroApiUrl(`ParametroDetalle/DeleteParametroDetalle/${id}`))
            .then((response) => {
                if (response.status == 200) {
                    obtenerRangos();
                }
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }



    const nuevo = () => {
        setForm({
            Id: 0,
            ParametroId: 39,
            VcNombre: '',
            TxDescripcion: '',
            DCodigoIterno: 0,
            BEstado: true
        });
        setColor("");
        setModalTitle('Agregar Rango de gestión');
        setModalVisible(true);
    }

    const editar = (registro: any) => {
        setForm({
            Id: registro.id,
            ParametroId: registro.parametroId,
            VcNombre: registro.vcNombre,
            TxDescripcion: registro.txDescripcion,
            DCodigoIterno: registro.dCodigoIterno,
            BEstado: registro.bEstado
        });
        setColor(registro.vcNombre);
        setModalTitle('Editar Rango de gestión');
        setModalVisible(true);
    }

    const eliminar = (registro: any) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            //cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            focusConfirm: false,
            focusCancel: true,
            focusDeny: true
        }).then((result: any) => {
            if (result.isConfirmed) {
                eliminarRangoGestion(registro.id)
            }
        })
    }

    useEffect(() => {
        obtenerRangos();
    }, []);

    interface DataRow {
        vcNombre: string;
        txDescripcion: string;
        dCodigoIterno: string;
        acciones: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Descripción',
            cell: (element) => <p>{element.txDescripcion}</p>,
            selector: row => row.txDescripcion,
            sortable: true,
        },
        {
            name: 'Porcentaje',
            cell: (element) => <p>{element.dCodigoIterno}</p>,
            selector: row => row.dCodigoIterno,
            sortable: true,
        },
        {
            name: 'Color',
            cell: (element) => <span className="badge" style={{ backgroundColor: element.vcNombre }}>{element.vcNombre}</span>,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" onClick={() => eliminar(element)} />
                    {" "}
                    <CrudButton className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" onClick={() => editar(element)} />
                </div>,
            sortable: false,
        }
    ];

    return <>
        <Card>
            <Card.Header>
                <Card.Title>Rangos de gestión</Card.Title>
            </Card.Header>
            <Card.Body>
                <Container style={{ textAlign: "right" }}>
                    <CrudButton onClick={() => nuevo()} className="btn-sm btn-primaryCustom" text="Crear" icon="bi bi-plus-circle-fill" />
                </Container>
                <Container className="row mt-8">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={dataSource}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                        />
                    </Col>
                </Container>
            </Card.Body>
        </Card>

        <ActionModal visible={modalVisible} title={modalTitle} handleClose={setModalVisible}>
            <RangosForm obtenerRangos={obtenerRangos} setModalVisible={setModalVisible} form={form} setColor={setColor} color={color} />
        </ActionModal>
    </>
}


