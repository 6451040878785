import { loadAbort } from "../utilities/load-abort-axios.utility";
import axios, { AxiosRequestConfig } from 'axios';
import { ModelResponse, UserLogin, UserSC } from "../models";
import { getAdministradorApiUrl } from "../utilities";
const loginSC = (userB2c: UserSC) => {
    const controller = loadAbort();
    return {
      call: axios.post<ModelResponse<UserLogin>>(getAdministradorApiUrl('Usuario'),userB2c, { signal: controller.signal } as AxiosRequestConfig),
      controller
    };
};

export default loginSC;