import React, {useEffect, useContext, useState} from "react";
import {Col, Container} from "react-bootstrap";
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import {FormikCheckbox, FormikInput, FormikTextArea} from '../../../../components/FormikInput';
import {CrudButton} from "../../../../components/CrudButton";
import DataTable, {TableColumn} from "react-data-table-component";
import {customStyles, NoRecords, PaginationProperties} from "../../../../components/DataTableConfig";
import {bandejaIndividualContext} from "./AtencionesIndividualesBandeja";
import {BandejaAtencionesIndividualContextInterface} from "../submmitInformation/AtencionesIndividualesTypes";
import {InputFilter} from "../../../../components/Input";
import moment from 'moment-timezone';
import {isNullOrUndef} from "chart.js/helpers";

const AtencionIndividualSchema = Yup.object().shape({
    DtFechaRegistro: Yup.date().required('Campo obligatorio'),
    BCierraCaso: Yup.bool().required('Campo obligatorio'),
    VcDescripcion: Yup.string()
        .required('Campo obligatorio')
        .min(50, 'Mínimo 50 caracteres')
        .max(2000, 'Máximo 2000 caracteres')
});

export function AtencionesIndividualesFormSeguimiento() {
    const bandejaIndividualCContext = useContext(bandejaIndividualContext) as BandejaAtencionesIndividualContextInterface;
    var estadoData = bandejaIndividualCContext.dataSourceFilter.filter((element: { id: any; }) => element.id == bandejaIndividualCContext.AtencionIndividualId);

    const [valueFilter, setValueFilter] = useState('');


    useEffect(() => {
        bandejaIndividualCContext.obtenerDatosSeguimiento();

    }, []);

    const initialValues = {
        Id: 0,
        AtencionIndividualId: bandejaIndividualCContext.AtencionIndividualId,
        DtFechaRegistro: moment.tz('America/Bogota').format('YYYY-MM-DD'),
        BCierraCaso: false,
        VcDescripcion: undefined,
        UsuarioId: bandejaIndividualCContext.UsuarioId
    }

    interface DataRow {
        dtFechaRegistro: string;
        vcUsuario: string;
        vcDescripcion: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Fecha',
            cell: (element) => <p>{element.dtFechaRegistro.toString().substring(0, 10)}</p>,
            sortable: true,
        },
        {
            name: 'Usuario',
            cell: (element) => <p>{element.vcUsuario}</p>,
            sortable: false,
        },
        {
            name: 'Asesoría Brindada',
            cell: (element) => <p>{element.vcDescripcion}</p>,
            sortable: true,
        },
    ];

    const filterDataTable = (value: string) => {
        const dataArray = bandejaIndividualCContext.dataSourceSeguimiento.filter((item: any) => {
            return item.dtFechaRegistro?.toString().substring(0, 10).includes(value) ||
                item.vcUsuario?.toLowerCase().includes(value) ||
                item.vcDescripcion?.toLowerCase().includes(value);
        });

        bandejaIndividualCContext.setDataSourceFilterSeguimiento(dataArray);
    }

    return <>
        <div>
            {estadoData[0].estado?.toLowerCase() !== 'cerrado' ?
                <Formik
                    enableReinitialize={true}
                    initialValues={{...initialValues}}
                    onReset={(values, formProps) => {

                    }}
                    validationSchema={AtencionIndividualSchema}
                    onSubmit={(values: any, {setSubmitting}) => {
                        Swal.fire({
                            title: 'Confirmar guardado',
                            text: "¿Desea guardar estos datos?",
                            icon: 'warning',
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sí, guardar',
                            cancelButtonText: 'Cancelar',
                        }).then(async (result: any) => {
                            if (result.isConfirmed) {
                                bandejaIndividualCContext.createSeguimiento(values);
                            }
                        })
                        setSubmitting(false);
                    }}
                >
                    {({
                          isSubmitting,
                          values
                      }) => (
                        <Form>
                            <div className="row">
                                <FormikInput
                                    labelName='Id del caso'
                                    nameSelect='AtencionIndividualId'
                                    className='col-12 col-md-3 mt-5'
                                    readOnly={true}
                                    required={true}
                                />
                                <FormikInput
                                    type="date"
                                    labelName='Fecha Seguimiento'
                                    nameSelect='DtFechaRegistro'
                                    className='col-12 col-md-5 mt-5'
                                    required={true}
                                    readOnly={true}
                                />
                                <FormikCheckbox
                                    labelName='Caso cerrado'
                                    nameSelect='BCierraCaso'
                                    className='col-12 col-md-3 mt-5'
                                    value={values.BCierraCaso}
                                    labelActive={'Si'}
                                    labelInactive={'No'}
                                    required={true}
                                />
                                <FormikTextArea
                                    labelName='Asesoría Brindada'
                                    nameSelect='VcDescripcion'
                                    className='col-12 mt-5'
                                    rows={3}
                                    required={true}
                                />
                                <div className="col-12 text-center mt-1">
                                    <CrudButton type={'submit'} className="btn-sm btn-primaryCustom" text="Guardar"
                                                disabled={isSubmitting}/>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                : null}
            {
                bandejaIndividualCContext.dataSourceSeguimiento.length > 0 ?
                    <InputFilter
                        valueFilter={valueFilter}
                        setValueFilter={setValueFilter}
                        onChange={filterDataTable}
                        nameFile="Bandeja Aten Indiv. Seguimiento"
                        dataSource={bandejaIndividualCContext.dataSourceFilterSeguimiento}
                    />
                    : null
            }

            <Container className="form-group row mt-8">
                <Col>
                    <DataTable
                        columns={columns}
                        data={bandejaIndividualCContext.dataSourceFilterSeguimiento}
                        pagination
                        customStyles={customStyles}
                        striped={true}
                        paginationComponentOptions={PaginationProperties}
                        noDataComponent={<NoRecords/>}
                    />
                </Col>
            </Container>
        </div>
    </>
}

