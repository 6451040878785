import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Card, Col, Container } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { ActionModal } from "../../../components/ActionModal";
import { CrudButton } from "../../../components/CrudButton";
import { customStyles, NoRecords, PaginationProperties } from "../../../components/DataTableConfig";
import UsuariosForm from "./UsuariosForm";
import { DataRowUser, EstadoUsuario, SelectOption, UserLogin, UserLoginEmptyState, UsuariosContextInterface } from 'src/app/models';
import UsuariosFiltroForm from './UsuariosFiltroForm';
import usuarioPorEstadoSC from 'src/app/services/usuario.service';


export const UsuarioContext = React.createContext<UsuariosContextInterface | null>(null);

export function Usuarios(props: any) {
    const [modalVisible, setModalVisible] = useState(false);
    const [dataSource, setDataSource] = useState<UserLogin[]>([]);
    const [selectEstado, setSelectEstado] = useState<number>(EstadoUsuario.Activo);


    const obtenerDatos = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const users: UserLogin[] = response.data.models ?? [];
                setDataSource(users);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const deleteUsuario = async (id: any) => {
        await axios.delete(getAdministradorApiUrl(`Usuario/${id}`))
            .then((response) => {
                // Obtenemos los datos
                obtenerDatos();
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    useEffect(() => {
        obtenerDatosPorEstado(selectEstado, () => {});       
        //obtenerDatos();
    }, []);

    const [form, setForm] = useState({
        Id: 0,
        TipoDocumentoId: '',
        VcDocumento: '',
        VcPrimerNombre: '',
        VcPrimerApellido: '',
        VcSegundoNombre: '',
        VcSegundoApellido: '',
        VcCorreo: '',
        VcTelefono: '',
        VcDireccion: '',
        TipoEntidadId: '',
        EntidadId: '',
        RolId: '',
        UnidadPrestacionServiciosId: '',
        IEstado: 0,
        VcIdAzure: '',
        VcIdpAzure: ''
    });

    const editar = (registro: any) => {
        setForm({
            Id: registro.id,
            TipoDocumentoId: registro.tipoDocumentoId,
            VcDocumento: registro.vcDocumento,
            VcPrimerNombre: registro.vcPrimerNombre,
            VcPrimerApellido: registro.vcPrimerApellido,
            VcSegundoNombre: registro.vcSegundoNombre,
            VcSegundoApellido: registro.vcSegundoApellido,
            VcCorreo: registro.vcCorreo,
            VcTelefono: registro.vcTelefono,
            VcDireccion: registro.vcDireccion,
            TipoEntidadId: registro.tipoEntidadId,
            EntidadId: registro.entidadId,
            RolId: registro.rolId,
            UnidadPrestacionServiciosId: registro.unidadPrestacionServiciosId,
            IEstado: registro.iEstado,
            VcIdAzure: registro.vcIdAzure,
            VcIdpAzure: registro.vcIdpAzure
        });
        setModalVisible(true);
    }
    const eliminar = (registro: any) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteUsuario(registro.id)
            }
        })
    }

    const columns: TableColumn<DataRowUser>[] = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nombre',
            cell: (element) => <p>{element.vcPrimerNombre}</p>,
            sortable: true,
        },
        {
            name: 'Identificación',
            cell: (element) => <p>{element.vcDocumento}</p>,
            sortable: true,
        },
        {
            name: 'Estado',
            cell: (element) => <p>{ EstadoUsuario[element.iEstado] }</p>,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" onClick={() => eliminar(element)} />
                    {" "}
                    <CrudButton className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" onClick={() => editar(element)} />
                </div>,
            sortable: false,
        }
    ];

    const obtenerDatosPorEstado = async (value: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
        setFieldValue("EstadoUsuario", value);
        const { call } = usuarioPorEstadoSC(value);
        const response = await call;        
        const users: UserLogin[] = response.data.models ?? [];
        setDataSource(users);
        setSelectEstado(value);
    }

    const datosContext:UsuariosContextInterface ={
        obtenerDatosPorEstado: obtenerDatosPorEstado,
        selectEstado:selectEstado,
        setModalVisible:setModalVisible, 
        data:form,
    }

    return (
        <UsuarioContext.Provider value={datosContext}>
            <Card>
                <Card.Header>
                    <Card.Title>Usuarios Registrados</Card.Title>
                </Card.Header>
                <Card.Body>

                    <Container className="row mt-8">
                        <UsuariosFiltroForm />
                        <Col className="mt-4">
                            <DataTable
                                columns={columns}
                                data={dataSource}
                                pagination
                                customStyles={customStyles}
                                striped={true}
                                paginationComponentOptions={PaginationProperties}
                                noDataComponent={<NoRecords />}
                            />
                        </Col>
                    </Container>
                </Card.Body>
            </Card>

            <ActionModal visible={modalVisible} title={form.Id > 0 ? 'EDITAR USUARIO' : 'AGREGAR USUARIO'} size='xl' handleClose={setModalVisible}>
                <UsuariosForm  />
            </ActionModal>
        </UsuarioContext.Provider>
    );

}

