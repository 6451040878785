// Render Prop
import { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikCheckbox, FormikInput } from '../../../components/FormikInput';
import { FormConstantsValidation } from '../../../components/FormConstantsValidation';
import { parametroContext } from './Parametros';
const ParametroSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Campo obligatorio'),
  VcCodigoInterno: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Campo obligatorio'),
});

export default function ParametrosForm() {

  const parametroCContext = useContext(parametroContext);

  return (
    <div>
      <Formik
        initialValues={parametroCContext.data}
        validationSchema={ParametroSchema}
        onReset={(values, formProps) => {
          parametroCContext.setModalVisibleParametro(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.Id == 0) {
            parametroCContext.createParametro(values);
          } else {
            parametroCContext.updateParametro(values);
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values
        }) => (
          <Form>
            <div className="form-group row">
              <FormikInput
                  type='text'
                  labelName='Nombre'
                  nameSelect='VcNombre'
                  className='col-12'
                  required={true}
                  placeholder=""
              />
              <FormikInput
                  type='text'
                  labelName='Código interno'
                  nameSelect='VcCodigoInterno'
                  className='col-6'
                  required={true}
                  placeholder=""
              />               

              <FormikCheckbox
                labelName='Estado'
                nameSelect='BEstado'
                className='col-12 mt-5'
                value={values.BEstado}
                required={true}
              />
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};
