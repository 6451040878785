export interface UserSC {
    VcIdAzure: string;
    VcCorreo: string;
    VcPrimerNombre: string;
    VcSegundoNombre: string;
    VcPrimerApellido: string;
    VcSegundoApellido: string;
    VcIdpAzure: string;
}


export type UserB2C = {    
    oid: string;
    emails: string[];
    given_name: string;
    family_name: string;
}


export interface UserLogin {
    id: number;
    rolId: number;
    tipoEntidadId: number;
    entidadId: number;
    tipoDocumentoId: number;
    vcDocumento: string;
    vcPrimerNombre: string;
    vcPrimerApellido: string;
    vcSegundoNombre: string;
    vcSegundoApellido: string;
    vcCorreo: string;
    vcTelefono: string;
    vcDireccion: string;
    vcIdAzure: string;
    VcIdpAzure: string;
    iEstado: EstadoUsuario;
    dtFechaCreacion: number;
    dtFechaActualizacion: number;
    dtFechaAnulacion: number;
    unidadPrestacionServiciosId: number;
    vcCorreoEntidad: string;
    vcNombreContacto: string;
    vcTelefonoContacto: string;
}

export interface LoadingState {
    loading: boolean;
}

export enum EstadoUsuario {
    Registrado = 0,
    Activo = 1,
    Inactivo = 2,
}

export interface DataRowUser {
    id: number;
    vcPrimerNombre: string;
    vcDocumento: string;
    iEstado: EstadoUsuario;

}

export const UserLoginEmptyState: UserLogin = {
    id: 0,
    rolId: 0,
    tipoEntidadId: 0,
    entidadId: 0,
    tipoDocumentoId: 0,
    vcDocumento: '',
    vcPrimerNombre: '',
    vcPrimerApellido: '',
    vcSegundoNombre: '',
    vcSegundoApellido: '',
    vcCorreo: '',
    vcTelefono: '',
    vcDireccion: '',
    vcIdAzure: '',
    iEstado: 0,
    dtFechaCreacion: 0,
    dtFechaActualizacion: 0,
    dtFechaAnulacion: 0,
    unidadPrestacionServiciosId: 0,
    VcIdpAzure: '',
    vcCorreoEntidad: '',
    vcNombreContacto: '',
    vcTelefonoContacto: '',
  };

export interface UsuariosContextInterface {
    obtenerDatosPorEstado: any;
    selectEstado: any;
    setModalVisible:any; 
    data:any;
}

export type SelectOption = {
    value: number;
    label: string;
};
