import {FC} from 'react'

const Error404: FC = () => {
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Página no encontrada</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        La página que buscas no existe <br /> Usa el menú para navegar por la opción que necesitas
      </div>
    </>
  )
}

export  {Error404}
