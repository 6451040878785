import { Field, ErrorMessage } from 'formik';
import { CrudButton } from './CrudButton';
import { EstadoUsuario } from '../models';
export function FormikInput(props) {
    const { type = 'text', className, nameSelect, labelName, placeHolder, required = false, accept = '', readOnly = false, onKeyDown, onKeyUp, tabIndex, onChange } = props;
    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className='text-danger'>*</label> : null}</label>
            <div>
                <Field
                    className="form-control form-control-solid"
                    readOnly={readOnly}
                    type={type}
                    accept={accept}
                    name={nameSelect}
                    placeholder={placeHolder}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    tabIndex={tabIndex}
                />
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <ErrorMessage name={nameSelect} component="div" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function FormikInputGroup(props) {
    const {
        type = 'text',
        className,
        nameSelect,
        placeHolder,
        accept = '',
        readOnly = false,
        onKeyDown,
        onKeyUp,
        tabIndex,
        onClickButton,
        iconButton,
        textButton,
        classNameButton } = props;
    return (
        <div className={className}>
            <Field
                className="form-control form-control-solid"
                readOnly={readOnly}
                type={type}
                accept={accept}
                name={nameSelect}
                placeholder={placeHolder}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                tabIndex={tabIndex}
            />
            <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                    <ErrorMessage name={nameSelect} component="div" />
                </div>
            </div>
            <CrudButton
                type={'button'}
                className={classNameButton}
                text={textButton}
                icon={iconButton}
                onClick={() => {
                    onClickButton()
                }}
            />
        </div>
    );
}

export function FormikInputFile(props) {
    const { className, nameSelect, labelName, placeHolder, required = false, accept = '', onChange, tabIndex } = props;
    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className='text-danger'>*</label> : null}</label>
            <div>
                <input
                    type={'file'}
                    className="form-control form-control-solid"
                    name={nameSelect}
                    placeholder={placeHolder}
                    accept={accept}
                    onChange={onChange}
                    tabIndex={tabIndex}
                />
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <ErrorMessage name={nameSelect} component="div" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export function FormikTextArea(props) {
    const { className, rows = '5', nameSelect, labelName, placeHolder, required = false, tabIndex } = props;
    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className='text-danger'>*</label> : null}</label>
            <div>
                <Field className="form-control form-control-solid" component='textarea' rows={rows} name={nameSelect} placeholder={placeHolder} tabIndex={tabIndex} />
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <ErrorMessage name={nameSelect} component="div" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function FormikCheckbox(props) {
    const { className, value, nameSelect, labelName, required = false, labelActive = 'Activo', labelInactive = 'Inactivo', tabIndex } = props;
    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className='text-danger'>*</label> : null}</label>
            <div className='form-check form-switch form-switch-lg'>
                <Field
                    className="form-check-input"
                    type="checkbox"
                    name={nameSelect}
                    tabIndex={tabIndex}
                />
                {value ? labelActive : labelInactive}
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <ErrorMessage name={nameSelect} component="div" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function FormikCheckboxUsuario(props) {
    const { className, value, nameSelect, labelName, required = false, tabIndex, onChange } = props;
    return (
        <div className={className}>
            <label className="form-label text-azul-oscuro">{labelName} {required ? <label className='text-danger'>*</label> : null}</label>
            <div className='form-check form-switch form-switch-lg'>
                <Field
                    className="form-check-input"
                    type="checkbox"
                    name={nameSelect}
                    tabIndex={tabIndex}
                    checked={value === 1}
                    unchecked={value === 2}
                    onChange={value => {
                        if (onChange) {
                            onChange(value)
                        }
                    }}
                />
                {EstadoUsuario[value]}
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <ErrorMessage name={nameSelect} component="div" />
                    </div>
                </div>
            </div>
        </div>
    );
}