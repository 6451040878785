import { AuthenticationState, IAccountInfo, IAzureADFunctionProps } from 'react-aad-msal';
import { createUserAdapter } from '../adapters';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import { App } from '../App';

import { AuthInProgressPage, AuthPage, Logout } from '../components/auth';
import { PrivateRoutes } from '../routing';
import { useDispatch } from 'react-redux';
import { createUser, resetUser } from '../redux/states/user.state';
import { MenuSC, loginSC } from '../services';
import {  EstadoUsuario, Menu, UserLogin, UserSC } from '../models';
import { removeAuth, setAuth } from '../utilities';
import { useEffect, useState } from 'react';
import { ErrorsPage } from '../components/errors';
import Swal from 'sweetalert2';
import { AxiosError } from 'axios';
import { authProvider } from 'src/app/utilities';
import menuGuardAdapter from '../adapters/menu.adapter';
import { createMenu } from '../redux/states/menu.state';
import { AuthGuard } from '../guards/auth.guard';



/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env



const useAuthProvider =  () => {

  const dispatch = useDispatch();
  const [accountInfo, setAccountInfo] = useState<IAccountInfo | null>(null);

  const persistirLogin = async(userB2c:UserSC) =>{
    try{
      //debugger;
      const { call } = loginSC(userB2c);
      const userLogin = await call;
      verificarEstadoUsuario(userLogin.data.model);
    }catch(error){
      let axiosError = error as AxiosError;
      if (axiosError.response) {
        let responseError = axiosError.response.data;
        Swal.fire({
          title: 'Alerta de inicio de sesión',
          text: `${responseError.mensaje} ¿Desea continuar con el usuario encontrado?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#2d496f',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Sí',
          focusConfirm: false,
          focusCancel: true,
          focusDeny: true
        }).then((result: any) => {
          if (result.isConfirmed){
            verificarEstadoUsuario(responseError.model);
          }else{
            removeAuth();
            authProvider.logout();
          }
          
        });     
      } 
    }

  }
  const verificarEstadoUsuario = async(user:UserLogin) =>{
    if(user.iEstado !== EstadoUsuario.Activo){
      Swal.fire({
        title: 'Alerta de inicio de sesión',
        text: `El usuario ${user.vcCorreo} se encuentra en estado: (${EstadoUsuario[user.iEstado]}). contacte al administrador para activar su cuenta`,
        icon: 'warning',
        confirmButtonColor: '#2d496f',
      }).then((result: any) => {
        removeAuth();
        authProvider.logout();
      });
    }else{
      setAuth(user);
      dispatch(createUser(user));
      //Se almacena Menu:
      const { call } = MenuSC(user);
      const response = await call;
      const menuArray: Menu[] = response.data.models;
      const menuGuard: string[] = menuGuardAdapter(menuArray);
      dispatch(createMenu(menuGuard));
    }
  }

  


  useEffect(() => {
    if (accountInfo) {
      const userB2c = createUserAdapter(accountInfo?.account?.idToken);
      persistirLogin(userB2c);     
    }
  }, [accountInfo]);

  const bodyFunction = (dataFunction: IAzureADFunctionProps) => {

    const { login, logout, authenticationState, error } = dataFunction;

    setAccountInfo(dataFunction.accountInfo);

    switch (authenticationState) {
      case AuthenticationState.Authenticated:
        return ( 
            <>
              <BrowserRouter basename={PUBLIC_URL}>
                <Routes>
                  <Route element={<App />}>
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='logout' element={<Logout />} />
                    <Route element={<AuthGuard />}>
                      <Route path='/*' element={<PrivateRoutes />} />
                    </Route>                    
                    <Route index element={<Navigate to='/dashboard' />} />
                  </Route>
                </Routes>
              </BrowserRouter>
              
            </>         
        );

      case AuthenticationState.Unauthenticated:
        dispatch(resetUser());
        return (
          <>            
            <BrowserRouter basename={PUBLIC_URL}>
              <Routes>
                <Route element={<App />}>
                  <Route path='error/*' element={<ErrorsPage />} />
                  <Route path='logout' element={<Logout />} />
                  <Route path='auth/*' element={<AuthPage  />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </Route>
              </Routes>
            </BrowserRouter>
            
          </>
        );

      case AuthenticationState.InProgress:
        return (
          <>
            <BrowserRouter basename={PUBLIC_URL}>
              <Routes>
                <Route element={<App />}>
                  <Route path='error/*' element={<ErrorsPage />} />
                  <Route path='logout' element={<Logout />} />
                  <Route path='auth/*' element={<AuthInProgressPage  />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </Route>
              </Routes>
            </BrowserRouter>            

          </>
        );
    }
  };

  return {
    bodyFunction
  };
};

export default useAuthProvider;
