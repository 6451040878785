import { Menu } from "../models";

const menuGuardAdapter = (menu:Menu[]) => {
    const menuGuard = renderMenuItems(menu);
    return menuGuard;
}
export default menuGuardAdapter;


function renderMenuItems(menu: Menu[]): string[] {
    const menuGuard: string[] = [];

    menu.forEach((item) => {
        if (item.vcRedireccion !== "#") {
            menuGuard.push(item.vcRedireccion);
        }

        if (item.hijos) {
            // Utiliza 'concat' para fusionar los resultados de la llamada recursiva
            const childRoutes = renderMenuItems(item.hijos);
            menuGuard.push(...childRoutes);
        }
    });

    return menuGuard;
}