import React, { useState, useEffect } from "react";
import { Col, Card , Container, Modal } from "react-bootstrap";
import axios from 'axios'
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getParametroApiUrl } from "../../../../_metronic/helpers/ApiParametroHelpers";
import ActividadesForm from "./ActividadesForm";
import Swal from "sweetalert2";
import { CrudButton } from '../../../components/CrudButton';
//import { ActionModal } from "../../../components/ActionModal";

import DataTable, { TableColumn } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles } from "../../../components/DataTableConfig";
import { SwalToast } from "../../../components/SwalToast";

interface formularioActividad{  
    Id: number;
    VcNombre: string;
    ModuloId: number;
    VcDescripcion: string;
    VcRedireccion: string;
    IconoId: number;
    PadreId: number;
    BEstado: boolean;
    BPublico: boolean;
};

interface OptionIcono {
    value: number;
    label: string;
    icon: string;
};

interface ActividadContextInterface {
    obtenerDatos:any;
    dataPadre:any;
    dataIconos:OptionIcono[];
    modalVisible: any;
    setModalVisible:any;
    dataSourceModulos:any; 
    data:any;
    create:any;
    update: any;
}



const actividadDatosIniciales: formularioActividad = {

    Id: 0,
    VcNombre: '',
    ModuloId: 0,
    VcDescripcion: '',
    VcRedireccion: '',
    IconoId: 0,
    PadreId: 0,
    BEstado: true,
    BPublico: true,
}
  
export const actividadContext = React.createContext<ActividadContextInterface | null>(null);

export function Actividades(props: any) {
    const [modalVisible, setModalVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceModulos, setDataSourceModulos] = useState([]);
    const [dataPadre, setDataPadre] = useState<any>([]);
    const [dataIconos, setDataIconos] = useState<OptionIcono[]>([]);

    const obtenerDatos = async () => {
        await axios.get(getAdministradorApiUrl('Actividad'))
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerIconos = async () => {
        await axios.get(getParametroApiUrl('ParametroDetalle/porCodigoInterno/bIconos/'))
            .then((response) => {
                // Obtenemos los datos 
                const data: any = response.data.models ? response.data.models : [];
                const dataIconos: OptionIcono[] = [];
                data.forEach((element: any) => {
                    dataIconos.push({ value: element.id, label: element.vcNombre, icon: element.vcNombre });
                });
                setDataIconos(dataIconos);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosModulos = async () => {
        await axios.get(getAdministradorApiUrl('Modulo'))
            .then((response) => {
                // Obtenemos los datos
                const data: any = [];
                const models = response.data.models ? response.data.models : [];
                models.forEach((element: any) => {
                    data.push({ value: element.id, label: element.vcNombre });
                });
                setDataSourceModulos(data);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const deleteActividad = async (id: any) => {
        await axios.delete(getAdministradorApiUrl(`Actividad/${id}`))
            .then((response) => {
                // Obtenemos los datos
                obtenerDatos();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede eliminar debido a la integridad referencial'
                });  
            });
    }

    useEffect(() => {
        obtenerDatos();
        obtenerDatosModulos();
        obtenerIconos();
    }, []);
    

    const [form, setForm] = useState(actividadDatosIniciales);


    const nuevo = () => {
    
        setForm(actividadDatosIniciales);

        const data: any = [];
        dataSource.forEach((element: any) => {
            data.push({ value: element.id, label: element.vcNombre });
        });
        setDataPadre(data);

        setModalVisible(true);
    }
    const editar = (registro: any) => {
        setForm({
            Id: registro.id,
            VcNombre: registro.vcNombre,
            ModuloId: registro.moduloId,
            VcDescripcion: registro.vcDescripcion,
            VcRedireccion: registro.vcRedireccion,
            IconoId: registro.iconoId,
            PadreId: registro.padreId,
            BEstado: registro.bEstado,
            BPublico:registro.bPublico,
        });

        const data: any = [];
        dataSource.forEach((element: any) => {
            if (element.id != registro.id) {
                data.push({ value: element.id, label: element.vcNombre });
            }
        });
        setDataPadre(data);

        setModalVisible(true);
    }
    const eliminar = (registro: any) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, eliminar',
            focusConfirm: false,
            focusCancel: true,
            focusDeny: true
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteActividad(registro.id);
            }
        })
    }

    const asignarIcono = (iconoId: number) => {
        let icono = dataIconos.find(({ value }) => value === iconoId);
        return icono !== undefined ? icono.label : '';
    }
    const create = async (data: formularioActividad) => {
        await axios.post(getAdministradorApiUrl('Actividad'), data)
        .then((response) => {
            setModalVisible(false);
            obtenerDatos();
            SwalToast.fire({
                icon: 'success',
                title: response.data.mensaje
            });
        })
        .catch(e => {
            // Capturamos los errores
            console.log(e);
            SwalToast.fire({
                icon: 'error',
                title: 'No se puede crear el registro'
            });          
        });
    }
    
    const update = async (data: formularioActividad) => {
        await axios.put(getAdministradorApiUrl(`Actividad/${data.Id}`), data)
        .then((response) => {
            setModalVisible(false);
            obtenerDatos();
            SwalToast.fire({
                icon: 'success',
                title: response.data.mensaje
            });        
        })
        .catch(e => {
            // Capturamos los errores
            console.log(e);
            console.log(e);
            SwalToast.fire({
                icon: 'error',
                title: 'No se puede editar el registro'
            });         
        });
    }
    

    const datosContext: ActividadContextInterface= {
        obtenerDatos: obtenerDatos,
        dataPadre: dataPadre,
        dataIconos: dataIconos, 
        modalVisible: modalVisible,
        setModalVisible: setModalVisible,
        dataSourceModulos: dataSourceModulos,
        data: form,
        create: create,
        update: update,
    }

    interface DataRow {
        id: number;
        vcNombre: string;
        vcDescripcion: string;
        vcRedireccion: string,
        iconoId: number;
        padreId: number,
        bEstado: string,
        bPublico: string,
    }

    const columns: TableColumn<DataRow>[] = [
        {
                name: 'Id',
                selector: row => row.id,
                sortable: true,
            },
            {
                name: 'Nombre',
                cell:(element) => <p>{element.vcNombre }</p>,
                sortable: true,
            },
            {
                name: 'Descripción',
                cell:(element) => <p>{element.vcDescripcion }</p>,
                sortable: true,
            },
            {
                name: 'Redirección',
                selector: row => row.vcRedireccion,
                sortable: true,
            },
            {
                name: 'Icono',
                cell:(element) =>  <span className="badge bg-success"><i className={`text-white ${asignarIcono(element.iconoId)}`}></i></span>,
                sortable: false,
            },
            {
                name: 'Público',
                cell:(element) => <p>{element.bPublico ? 'Activo' : 'Inactivo'}</p>,
                sortable: true,
            },
            {
                name: 'Estado',
                cell:(element) => <p>{element.bEstado ? 'Activo' : 'Inactivo'}</p>,
                sortable: true,
            },
            {
                name: 'Acciones',
                cell:(element) =>  
                            <>
                                <CrudButton onClick={() => eliminar(element)} className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" />
                                {" "}
                                <CrudButton onClick={() => editar(element)} className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" />
                            </>,
                sortable: false,
            },
        ];
    
    



    return (
        <actividadContext.Provider value={datosContext}> 
            <Card>
                <Card.Header>
                    <Card.Title className="text-azul-oscuro">GESTIÓN DE ACTIVIDADES</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Container style={{ textAlign: "right" }}>
                        <CrudButton onClick={() => nuevo()} className="btn-sm btn-primaryCustom" text="Crear" icon="bi bi-plus-circle-fill" />
                    </Container>
                    <br />

                    <Container>
                        <Col>
                            <DataTable
                                columns={columns}
                                data={dataSource}
                                pagination
                                customStyles={customStyles}
                                striped={true}
                                paginationComponentOptions={PaginationProperties}
                                noDataComponent={<NoRecords />}
                            />
                        </Col>
                    </Container>

                    
                </Card.Body>
            </Card>
            {/* 
            <ActionModal visible={modalVisible} title={form.Id > 0 ? 'EDITAR ACTIVIDAD' : 'AGREGAR ACTIVIDAD'} handleClose={setModalVisible}>
                <ActividadesForm />
            </ActionModal>
            */}

            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
                <Modal.Header>
                    <div>
                        <h5 className="text-azul-oscuro">{form.Id > 0 ? 'EDITAR' : 'AGREGAR'} ACTIVIDAD</h5>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <ActividadesForm/> 
                </Modal.Body>
            </Modal>
        </actividadContext.Provider>
    );

}

