
import { useContext } from "react";
import { bandejaGrupalesContext } from "./AtencionesGrupalesBandeja";
import DataTable, { TableColumn } from 'react-data-table-component';
import { Container, Col } from "react-bootstrap";
import { NoRecords, PaginationProperties, customStyles } from "../../../components/DataTableConfig";

export function AtencionesGrupalesAnexo() {
    const bandejaGrupalesCContext = useContext(bandejaGrupalesContext);

    interface DataRow {   
        acciones: string;
        vcNombre: string;
        vcDescripcion: string;
        iBytes: number,
        dtFechaRegistro: string;
        vcUsuario: string;
        vcRuta:string;
    }

    const columns: TableColumn<DataRow>[] = [
            {
                name: 'Acciones',

                cell:(element) => <a className="btn-icon btn-sm btn-success" href={element.vcRuta} target="_blank"> <i className="bi bi-download"></i></a>,
                sortable: false,
            },
            {
                name: 'Nombre',
                cell:(element) => <p>{element.vcNombre}</p>,
                sortable: true,
            },
            {
                name: 'Descripción',
                cell:(element) => <p>{element.vcDescripcion}</p>,
                sortable: true,
            },
            {
                name: 'Tamaño',
                cell:(element) => <p>{element.iBytes}</p>,
                sortable: true,
            },                                   
            {
                name: 'Fecha de registro',
                cell:(element) => <p>{element.dtFechaRegistro.toString().substring(0, 10)}</p>,
                sortable: true,
            },
            {
                name: 'Usuario',
                cell:(element) => <p>{element.vcUsuario}</p>,
                sortable: true,
            }                                                                                               
    ];

    return (
        <>
            <Container className="form-group row mt-8">
                <Col>
                    <DataTable
                        columns={columns}
                        data={bandejaGrupalesCContext?.dataSourceAnexos}
                        pagination
                        customStyles={customStyles}
                        striped={true}
                        paginationComponentOptions={PaginationProperties}
                        noDataComponent={<NoRecords />}
                    />
                </Col>
            </Container> 
        </>
    );
};

