import { useRef } from "react";
import { Field, Form, Formik, ErrorMessage } from 'formik';
import axios from 'axios'
import * as Yup from 'yup';
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";

const RangoSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(7, 'Mínimo 7 caracteres')
    .max(7, 'Máximo 7 caracteres') 
    .required('Se requiere valor hexadecimal'),
  TxDescripcion: Yup.string()
    .min(1, 'Mínimo 1 caracter')
    .max(500, 'Máximo 500 caracteres')
    .required('Campo obligatorio'),
  DCodigoIterno:Yup
    .number()
    .integer()
    .moreThan(0,'Debe ser mayor a cero')
    .max(9999,'Debe ser menor a 9999')    
    .required('Campo obligatorio'),
});


export default function RangosForm({ obtenerRangos, setModalVisible, form, setColor, color }) {

  const create = async (data) => {
    await axios.post(getParametroApiUrl('ParametroDetalle/PostParametroDetalle'), data)
      .then((response) => {
        if (response.status == 201) {
          obtenerRangos();
          setModalVisible(false);
        }
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const update = async (data) => {
    await axios.put(getParametroApiUrl(`ParametroDetalle/PutParametroDetalle/${data.Id}`), data)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          obtenerRangos();
          setModalVisible(false);
        }
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }
  let inputElement = useRef(null);

  const handleClick = (e) => {
    inputElement.click();
  }

 

  return (
    <div>
      <Formik
        initialValues={{ 
          Id: form.Id, 
          ParametroId: form.ParametroId, 
          VcNombre: form.VcNombre, 
          TxDescripcion: form.TxDescripcion, 
          DCodigoIterno: form.DCodigoIterno, 
          BEstado: form.BEstado 
        }}
        validationSchema={RangoSchema}
        onReset={(values, formProps) => {
          setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          
          console.log(values)

          if (values.Id == 0) {
            create(values);
          } else {
            update(values);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting,
            setFieldValue,
            values
        }) => (
          <Form>
            <div className='form-group row'>
              <div className="col-12 mt-5">
                <label className="text-primary">Nombre del rango </label>
                <Field className="form-control form-control-solid" component="textarea" name="TxDescripcion" placeholder="Seleccione" />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <ErrorMessage name="TxDescripcion" component="div" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <label className="text-primary">Porcentaje</label>
                <Field className="form-control form-control-solid" type="number" name="DCodigoIterno" placeholder="Seleccione" />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <ErrorMessage name="DCodigoIterno" />
                  </div>
                </div>
              </div>              
              <div className="col-12 col-sm-4">
                <label className="text-primary">Color</label>                         
                <Field className="form-control form-control-solid" 
                    type="text" 
                    name="VcNombre" 
                    placeholder="Seleccione" 
                    value={ color } 
                    readOnly={true}
                    onClick={handleClick} />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <ErrorMessage name="VcNombre" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-2">
              <label className="text-primary"> </label>  
                <input 
                    type="color" 
                    className="form-control form-control-color" 
                    value={color} 
                    onChange={e => {setFieldValue("VcNombre", e.target.value); setColor(e.target.value)}}
                    title="Eliga el color"
                    ref={input => inputElement = input}
                  />
              </div>        
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

