import axios from "axios";
import React, { useEffect, useState, useRef } from "react";

import Swal from "sweetalert2";
import { getOrientacionEInformacionApiUrl } from "../../../../../_metronic/helpers/ApiOrientacionEInformacionHelpers";
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";
import { SelectInstance } from "react-select";
import { AtencionesIndividualesContextInterface, 
        PersonaInterface, 
        DefaultPerson, 
        FormConstantsAtencionIndividual, 
        EditPersona, 
        DefaultDatoCaso,
        EditDatoCaso,
        DataRowActores,
        NuevoActorInterface,
        NuevoActorResponse,
        ValidacionParametro, 
        DefaultRol,
        CasoInterface,
        CasoResponseInterface,
        DefaultCasoResponse} from "./AtencionesIndividualesTypes";
import { SwalToast } from "../../../../components/SwalToast";
import { FormikSelectOption, ParametroDetalleInterface } from "../../../../components/FormConstantsValidation";
import { FormularioPersona } from "./FormularioPersona";
import { FormularioCaso } from "./FormularioCaso";
import { rolesPorUsuarioService, rolPorInternoService } from "src/app/pages/administrador/roles/services";
import { ComprobadorResponse, ParametroDetalle, Rol, UserLogin, comprobadorInitialState } from "src/app/models";
import { getAuth, getOrquestadorApiUrl } from "src/app/utilities";
import { ActionModal } from "src/app/components/ActionModal";
import { useSelector } from "react-redux";
import { SiCuentanosStore } from "src/app/redux/store";
import { AtencionesIndividualesFormReasignar } from "./AtencionesIndividualesFormReasignar";
import { AtencionesIndividualesFormAnexos } from "./AtencionesIndividualesFormAnexos";
import { FormikProps } from "formik";
import comprobadorDerechosSC from "src/app/services/comprobador.service";
import { AxiosError } from 'axios';

export const AtencionIndividualContext = React.createContext<AtencionesIndividualesContextInterface | null>(null);


export function IngresarInformacion(props: any) {
    
    // Validaciones
    const [dataRol, setDataRol] = useState<Rol>(DefaultRol);
    const [dataRolesUser, setDataRolesUser] = useState<string[]>([]);
    const [esLSPT, setEsLSPT] = useState<boolean>(false);
    //const [userDto] = useState<UserLogin | undefined>(getAuth());
    const userDto = useSelector((state:SiCuentanosStore)=> state.user); 

    const [dataSourcePersona, setDataSourcePersona] = useState<PersonaInterface>(DefaultPerson);

    // AtencionesIndividualesDI
    const [dataTiposDocumento, setDataTiposDocumento] = useState([]);
    const [formPersonaVisible, setFormPersonaVisible] = useState<Boolean>(false);

    // AtencionesIndividualesDP
    const [dataGenero, setDataGenero] = useState([]);
    const [dataOrientSexual, setDataOrientSexual] = useState([]);
    const [dataSexo, setdataSexo] = useState([]);

    // AtencionesIndividualesDEpS
    const [dataEnfoqPoblSoc, setDataEnfoqPoblSoc] = useState([]);
    const [dataHechVictim, setDataHechVictim] = useState([]);
    const [dataDepartamentoVictima, setDataDepartamentoVictima] = useState([]);
    const [dataMunicipioVictima, setDataMunicipioVictima] = useState([]);
    const [dataEtnia, setDataEtnia] = useState([]);
    const [dataSubTipoEtnia, setDataSubTipoEtnia] = useState([]);
    const [dataPoblPrioInteres, setDataPoblPrioInteres] = useState([]);
    const [dataSubTipoPobPrio, setDataSubTipoPobPrio] = useState([]);

    // AtencionesIndividualesDA
    const [dataRegimen, setDataRegimen] = useState([]);
    const [dataAseguradora, setDataAseguradora] = useState([]);
    const [dataEstadoAfiliacion, setDataEstadoAfiliacion] = useState([]);
    const [dataNivelSisben, setDataNivelSisben] = useState([]);
    const [dataInstInstrVincul, setDataInstInstrVincul] = useState([]);

    // AtencionesIndividualesDU
    const [dataPais, setDataPais] = useState([]);
    const [dataDepartamento, setDataDepartamento] = useState([]);
    const [dataMunicipio, setDataMunicipio] = useState([]);
    const [dataLocalidad, setDataLocalidad] = useState([]);
    const [dataUpz, setDataUpz] = useState([]);
    const [dataBarrio, setDataBarrio] = useState([]);
    const [dataZona, setDataZona] = useState([]);
    const [dataVia, setDataVia] = useState([]);
    const [dataLeteraVia, setDataLeteraVia] = useState([]);
    const [dataLetraAdicionalVial, setDataLetraAdicionalVial] = useState([]);
    const [dataOrientacionVia, setDataOrientacionVia] = useState([]);
    const [dataLetraNumMarca, setDataLEtraNumMarca] = useState([]);
    const [dataOrientacionCasa, setDataOrientacionCasa] = useState([]);

    //Estados para formulario de casos
    const [validacionParametrosRequeridos, setvalidacionParametrosRequeridos] = useState<ValidacionParametro[]>([]);
    const [primeraEtapaFormulario, setPrimeraEtapaFormulario] = useState(true);
    const [dataSourceCaso, setDataSourceCaso] = useState<any>(DefaultDatoCaso);
    const [dataEstadoCaso, setDataEstadoCaso]  = useState<any>([]);
    const [dataTiposActor, setDataTiposActor] = useState([]);
    const [dataTablaActores, setDataTablaActores] = useState<DataRowActores[]>([]);
    
    // Datos del caso en problematica
    const [dataCanalAtencion, setDataCanalAtencion] = useState([]);
    const [dataTipoSolicitud, setDataTipoSolicitud] = useState([]);

    // Motivo de orientación
    const [dataMotivo, setDataMotivo] = useState([]);
    const [dataSubMotivo, setDataSubMotivo] = useState([]);


    // Datos Actores en relación con la problematica en acceso 1
    const [dataRegimenActor1, setDataRegimenActor1] = useState([]);
    const [dataAseguradoraActor1, setDataAseguradoraActor1] = useState([]);

    // Datos Actores en relación con la problematica en acceso 2
    const [dataPrestador, setDataPrestador] = useState([]);
    const [dataSede, setDataSede] = useState([]);

    // Datos Actores en relación con la problematica en acceso 3
    const [dataTipoEntidad, setDataTipoEntidad] = useState([]);
    const [dataEntidad, setDataEntidad] = useState([]);

    //Estados para fomularios de anexos, reasignar y nuevo caso
    const [dataCasoCreado, setDataCasoCreado ] = useState<CasoResponseInterface>(DefaultCasoResponse);
    const [modalReAsignar, setModalReAsignar] = useState(false);
    const [AtencionIndividualId, setAtencionIndividualId] = useState([]);
    const [dataSourceReasignacion, setDataSourceReasignacion] = useState([]);
    const [dataSourceFilterReasignar, setDataSourceFilterReasignar] = useState([]);
    const [dataEstados, setDataEstados] = useState<any>([]);

    const [modalAnexos, setModalAnexos] = useState(false);
    const [dataSourceAnexos, setDataSourceAnexos] = useState([]);  
    const formRef = useRef<FormikProps<any> | null>(null);
    const [dataComprobador, setDataComprobador] = useState(comprobadorInitialState); 
    const [textoComprobador, setTextoComprobador] = useState("");
    const [tituloComprobador, setTituloComprobador] = useState(false);

    const obtenerDataParametroDetalle = async (codigo: any, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInterno/${codigo}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: FormikSelectOption[] = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: ParametroDetalleInterface) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                setData([]);
            });
    }

    const obtenerDataParametroDetallePorPadre = async (idDetalle: number, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/getParametroDetallePorPadre/${idDetalle}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDataParametroDetallePorCodigoInternoDetallePadre = async (codigo: string, idDetalle: number, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInternoDetallePadre/${codigo}/${idDetalle}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: ParametroDetalleInterface) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                setData([]);
            });
    }

    const obtenerDataParametroDetallePorCodigoInternoDetallePadreDefault = async (codigo: string, idDetalle: number, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInternoDetallePadreDefault/${codigo}/${idDetalle}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: FormikSelectOption[] = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: ParametroDetalleInterface) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                setData([]);
            });
    }   
    
    const getDataIndividual = async (TipoDocumentoId: number, VcDocumento: string) => {
        setFormPersonaVisible(false);
        await axios.get(getOrientacionEInformacionApiUrl(`Persona/${TipoDocumentoId}/${VcDocumento}`))
            .then((response) => {
                //EditPersona.view = true;
                setFormPersonaVisible(true);
     
                EditPersona.TipoDocumentoId = TipoDocumentoId;
                EditPersona.VcDocumento = VcDocumento;

                const model = response?.data?.model;
                if (model) {
                    EditPersona.Id = model.id;
                    EditPersona.VcPrimerNombre = model.vcPrimerNombre;
                    EditPersona.VcSegundoNombre = model.vcSegundoNombre;
                    EditPersona.VcPrimerApellido = model.vcPrimerApellido;
                    EditPersona.VcSegundoApellido = model.vcSegundoApellido;
                    EditPersona.GeneroId = model.generoId;
                    EditPersona.VcOtroGenero = model.vcOtroGenero;
                    EditPersona.VcNombreIdentitario = model.vcNombreIdentitario;
                    EditPersona.OrientacionSexualId = model.orientacionSexualId;
                    EditPersona.VcOtraOrientacionSexual = model.vcOtraOrientacionSexual;
                    EditPersona.SexoId = model.sexoId;
                    EditPersona.DtFechaNacimiento = model.dtFechaNacimiento?.toString().substring(0, 10);
                    EditPersona.EnfoquePoblacionalId = model.enfoquePoblacionalId;
                    EditPersona.HechoVictimizanteId = model.hechoVictimizanteId;
                    EditPersona.DepartamentoOrigenVictimaId = model.departamentoOrigenVictimaId;
                    EditPersona.MunicipioOrigenVictimaId = model.municipioOrigenVictimaId;
                    EditPersona.EtniaId = model.etniaId;
                    EditPersona.SubEtniaId = model.subEtniaId;
                    EditPersona.PoblacionPrioritariaId = model.poblacionPrioritariaId;
                    EditPersona.SubPoblacionPrioritariaId = model.subPoblacionPrioritariaId;
                    EditPersona.VcCorreo = model.vcCorreo;
                    EditPersona.VcCorreoEntidad = model.vcCorreoEntidad;
                    EditPersona.VcNombreContacto = model.vcNombreContacto;
                    EditPersona.VcTelefonoContacto = model.vcTelefonoContacto;
                    EditPersona.RegimenId = model.regimenId;
                    EditPersona.AseguradoraId = model.aseguradoraId;
                    EditPersona.EstadoAfiliacionId = model.estadoAfiliacionId;
                    EditPersona.NivelSisbenId = model.nivelSisbenId;
                    EditPersona.InstitucionInstrumentoVinculadoId = model.institucionInstrumentoVinculadoId;
                    EditPersona.PaisId = model.paisId;
                    EditPersona.DepartamentoId = model.departamentoId;
                    EditPersona.LocalidadId = model.localidadId;
                    EditPersona.MunicipioId = model.municipioId;
                    EditPersona.UpzId = model.upzId;
                    EditPersona.BarrioId = model.barrioId;
                    EditPersona.ZonaId = model.zonaId;
                    EditPersona.VcDireccion = model.vcDireccion;
                    EditPersona.TxDatosContactoAclaraciones = model.txDatosContactoAclaraciones;
                    EditPersona.VcTelefono1 = model.vcTelefono1;
                    EditPersona.VcTelefono2 = model.vcTelefono2;
                    let objDireccion = JSON.parse(model.vcDireccion);

                    EditPersona.viaId = Number(objDireccion.viaId);                    
                    EditPersona.vcNumeroVia = objDireccion.vcNumeroVia;
                    EditPersona.letraViaId =  Number(objDireccion.letraViaId);
                    EditPersona.adicionalLetraViaId =  Number(objDireccion.adicionalLetraViaId);
                    EditPersona.orientacionViaId =  Number(objDireccion.orientacionViaId);
                    EditPersona.vcNumMarca = objDireccion.vcNumMarca;
                    EditPersona.letraNumMarcaId =  Number(objDireccion.letraNumMarcaId);
                    EditPersona.vcNumeroCasa = objDireccion.vcNumeroCasa;
                    EditPersona.orientacionCasaId =  Number(objDireccion.orientacionCasaId);

                    setDataSourcePersona(EditPersona);                    
                }else{
                    DefaultPerson.TipoDocumentoId = TipoDocumentoId;
                    DefaultPerson.VcDocumento = VcDocumento;
                    setDataSourcePersona(DefaultPerson);
                }

                SwalToast.fire({
                    icon: model ? 'success' : 'warning',
                    title: response.data.mensaje
                });
                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const create = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl('Persona/crear'), data)
            .then((response) => {
                const data = response.data;
                console.log('ver ver data', data);
                console.log('ver ver response', response);
                //Pasa variables al siguiente estado
                EditDatoCaso.ROTipoDocumentoId = dataSourcePersona.TipoDocumentoId;
                EditDatoCaso.ROVcDocumento = dataSourcePersona.VcDocumento;
                EditDatoCaso.VcPrimerApellido = data.model.vcPrimerApellido;
                EditDatoCaso.VcSegundoApellido = data.model.vcSegundoApellido;
                EditDatoCaso.VcPrimerNombre = data.model.vcPrimerNombre;
                EditDatoCaso.VcSegundoNombre = data.model.vcSegundoNombre;
                EditDatoCaso.PersonaId = data.model.id;
                EditDatoCaso.VcCorreoEntidad =  data.model.vcCorreoEntidad;
                EditDatoCaso.VcNombreContacto =  data.model.vcNombreContacto;
                EditDatoCaso.VcTelefonoContacto =  data.model.vcTelefonoContacto;
                setDataSourceCaso(EditDatoCaso);
                mostrarMensaje(data.titulo, data.mensaje, '1');
            })
            .catch(e => {
                // Capturamos los errores
                const data = e?.response?.data;
                if (data?.codigo == 400) {

                    mostrarMensaje(data.titulo, data.mensaje, '4');
                }
            });
    }

    const mostrarMensaje = (titulo: string, mensaje: string, icon: string) => {
        
        SwalToast.fire({
            icon:  icon == '1' ? 'success' : icon == '2' ? 'info' : icon == '3' ? 'warning' : 'error',
            title: mensaje
        }).then((result: any) => {
            if (icon == '1') {
                setPrimeraEtapaFormulario(false);
            }
        });
    }


    const mostrarMensajeNT = (titulo: string, mensaje: string, icon: string) => {
        
        Swal.fire({
            title: titulo,
            text: mensaje,
            icon: icon == '1' ? 'success' : icon == '2' ? 'info' : icon == '3' ? 'warning' : 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result: any) => {

        });

    }

    const createCaso = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl('AtencionIndividual/crear'), data)
            .then((response) => {
                const data = response.data;
                setDataCasoCreado(data.model);
                mostrarMensajeNT(data.titulo, data.mensaje, '1');
                //Obtener el id del caso creado y el estado
                //mostrar botones de anexo y reasignar

            })
            .catch(e => {
                // Capturamos los errores
                const data = e?.response?.data;
                if (data?.codigo == 400) {
                    mostrarMensaje(data.titulo, data.mensaje, '4');
                }
            });
    }

    const obtenerParametroDetallePorInterno = async(vcCodigoInternoPadre: string, vcCodigoInternoHijo: string) =>{
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInternoDetalle/${vcCodigoInternoPadre}/${vcCodigoInternoHijo}`))
        .then((response) => {
            // Obtenemos los datos
            const data = response.data.model ? response.data.model : [];
        })
        .catch(e => {
            // Capturamos los errores
            console.log(e);
        });
    }

    const obtenerRolLPST = async() =>{
        let existeRol: boolean = false;
        try {
            const { call } = rolPorInternoService(FormConstantsAtencionIndividual.LSPTROL);
            const response = await call;

            const Rol = response.data.model ? response.data.model : DefaultRol;
            existeRol = response.data.model ? true : false;
            setDataRol(Rol);
        }
        catch (error: any) {
            existeRol = false;
            //throw error;
        }

        if(!existeRol){
            Swal.fire({
                title: "¡Algo salió mal!",
                text: `No está configurado el rol de línea salud para todos, (${FormConstantsAtencionIndividual.LSPTROL}) en el módulo administrador. Si continua, las validaciones de ese rol no se realizarán `,
                icon: 'warning' ,
                showCancelButton: true,
                confirmButtonColor: '#28446b',
                cancelButtonColor: '#d50404',
                confirmButtonText: 'continuar',
                cancelButtonText: 'cancelar',
                allowOutsideClick: false
            }).then((result: any) => {
                if (!result.isConfirmed) {
                    window.location.reload();
                }
            });        
        }
    }
    const obtenerArrayRolesUsuario = async() =>{
        try {
            if(userDto!==undefined){
                const { call } = rolesPorUsuarioService(userDto.id);
                const response = await call;
    
                const rolesUser = response.data.models ? response.data.models : [];
                setDataRolesUser(rolesUser);
            }

        }
        catch (error: any) {
            //throw error;
            console.log(error);
        }
    }
    

    const validarParametrosBD = async () =>{
        await obtenerRolLPST();  
        await obtenerArrayRolesUsuario();  

    }

    const obtenerDatosComprobador = async(vcDocumento:string, setFieldValue: (field: string, value: any) => void) => {
        try{
            setTextoComprobador("");
            const { call } = comprobadorDerechosSC(vcDocumento);
            const parametro = await call;
            const data: ComprobadorResponse = parametro.data.model;
            setDataComprobador(data);
            setTextoComprobador(parametro.data.mensaje);
            if(parametro.data.titulo == 'Bien Hecho!'){
                setTituloComprobador(true);
            }else{
                setTituloComprobador(false);
            }
            dataRegimen.forEach((element: ParametroDetalle) => {
                if(element.codigo == data.codigoRegimen){
                    setFieldValue('RegimenId', element.value);
                    setFieldValue("AseguradoraId", '');
                    obtenerDataParametroDetallePorCodigoInternoDetallePadre('bAseguradora',element.value, setDataAseguradora);                                     
                }
            });
        }catch(error){
            const mensaje = `No se encontró información para el usuario`;
            setTextoComprobador(mensaje);
            let axiosError = error as AxiosError;
            if (axiosError.response) {
              Swal.fire({
                title: '¡Algo salió mal!',
                text: mensaje,
                icon: 'warning'
              });     
            } 
        }
    }

    useEffect(() => {
        if (dataAseguradora.length > 0) {
            dataAseguradora.forEach((element: ParametroDetalle) => {
                if (element.codigo == dataComprobador.codigoEPS) {
                    formRef.current?.setFieldValue('AseguradoraId', element.value);
                }
            });
        }
    }, [dataAseguradora, dataComprobador]);

    useEffect(() => {
        setEsLSPT(dataRolesUser.includes(FormConstantsAtencionIndividual.LSPTROL));        
    }, [dataRolesUser]);

    useEffect(() => {
        validarParametrosBD();
        obtenerDataParametroDetalle('bGenero', setDataGenero);
        obtenerDataParametroDetalle('bOrientacionSexual', setDataOrientSexual);
        obtenerDataParametroDetalle('bSexo', setdataSexo);

        obtenerDataParametroDetalle('bPoblacionEspecial', setDataEnfoqPoblSoc);
        obtenerDataParametroDetalle('bHechosVictimizantes', setDataHechVictim);
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bDepartamento', FormConstantsAtencionIndividual.CODIGO_PAIS_COLOMBIA, setDataDepartamento);
        obtenerDataParametroDetalle('bEtnia', setDataEtnia);
        obtenerDataParametroDetalle('bPoblacionPrioritaria', setDataPoblPrioInteres);

        obtenerDataParametroDetalle('bRegimen', setDataRegimen);
        obtenerDataParametroDetalle('bNivelSISBEN', setDataNivelSisben);
        obtenerDataParametroDetalle('bEstadoAfiliacion', setDataEstadoAfiliacion);
        obtenerDataParametroDetalle('bInstituciónInstrumentoVinculado ', setDataInstInstrVincul);

        obtenerDataParametroDetalle('bPais', setDataPais);
        obtenerDataParametroDetalle('bTipoZona', setDataZona);

        obtenerDataParametroDetalle('bClaseVia', setDataVia);
        obtenerDataParametroDetalle('bAbecedario', setDataLeteraVia);
        obtenerDataParametroDetalle('bSufijoLetrasViaPrincipal', setDataLetraAdicionalVial);
        obtenerDataParametroDetalle('bSufijoDirecciones', setDataOrientacionVia);
        obtenerDataParametroDetalle('bAbecedario', setDataLEtraNumMarca);
        obtenerDataParametroDetalle('bSufijoDirecciones', setDataOrientacionCasa);
        obtenerDataParametroDetalle('bTipoActor', setDataTiposActor);
       


    }, []);


    const selectDepartamentoRef = useRef<SelectInstance<FormikSelectOption> | null>(null);
    const selectMunicipioRef = useRef<SelectInstance<FormikSelectOption> | null>(null);
    const selectLocalidadRef = useRef<SelectInstance<FormikSelectOption> | null>(null);
    const selectUpzRef = useRef<SelectInstance<FormikSelectOption> | null>(null);    


    const handleChangeDepartamentoFormikSelect = () => {
        let departamentoValue = selectDepartamentoRef.current?.getValue()[0]?.value;
        if(departamentoValue){
            llenarMunicipios(departamentoValue);
            
        }
    };

    const llenarMunicipios = (selectedOption:number) =>{
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bMunicipio', selectedOption, setDataMunicipio);
    }

    const handleChangeMunicipioFormikSelect = () => {
        let municipioValue = selectMunicipioRef.current?.getValue()[0]?.value;
        if(municipioValue){
            llenarLocalidades(municipioValue);
        }
    };    

    const llenarLocalidades = (selectedOption:number) =>{
        obtenerDataParametroDetallePorCodigoInternoDetallePadreDefault('bLocalidad', selectedOption, setDataLocalidad);
    }

    const handleChangeLocalidadFormikSelect = () => {
        let departamentoValue = selectLocalidadRef.current?.getValue()[0]?.value;
        if(departamentoValue){
            llenarUpz(departamentoValue);
            
        }
    };

    const llenarUpz = (selectedOption:number) =>{
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bUpz', selectedOption, setDataUpz);
    }

    const handleChangeUpzFormikSelect = () => {
        let upzValue = selectUpzRef.current?.getValue()[0]?.value;
        if(upzValue){
            llenarBarrio(upzValue);
            
        }
    };

    const llenarBarrio = (selectedOption:number) =>{
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bBarrio', selectedOption, setDataBarrio);
    }    

    


    useEffect(()=>{
        handleChangeDepartamentoFormikSelect();
    },[formPersonaVisible]);

    useEffect(()=>{
        handleChangeMunicipioFormikSelect();
    },[dataMunicipio]);

    useEffect(()=>{
        handleChangeLocalidadFormikSelect();
    },[dataLocalidad]);   

    useEffect(()=>{
        handleChangeUpzFormikSelect();
    },[dataUpz]);    
    

    const buscarPrestadores = async (VcNombrePrestador:string) =>{
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCoincidencia/bPrestador/${VcNombrePrestador}`))
        .then((response) => {
            // Obtenemos los datos
            const dataArray: any = [];
            const data = response.data.models ? response.data.models : [];
            data.forEach((element: any) => {
                dataArray.push({ value: element.id, label: element.vcNombre });
            });
            setDataPrestador(dataArray);

            SwalToast.fire({
                icon: dataArray ? 'success' : 'warning',
                title: response.data.mensaje
            });
        })
        .catch(e => {
            // Capturamos los errores
            console.log(e);
        });
    }

    const crearPadreHijoParametro = async (dataPadreHijo:NuevoActorInterface) =>{
        return new Promise(async (resolve,reject)=>{
            await axios.post(getParametroApiUrl(`ParametroDetalle/crearPadreHijo`),dataPadreHijo)
            .then((response) => {
                // Obtenemos los datos                
                const data: NuevoActorResponse = response.data.model ? response.data.model : { hijoId:0, padreId:0};
                resolve(data);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                reject(e);
            });
        });        
    }

    //Funciones de modals de anexos, reasignar y nuevo caso
    const reAsignar = (element: any) => {
        setAtencionIndividualId(element.id);
        setModalReAsignar(true);
    }

    const obtenerDatosReasignados = async () => {
        setDataSourceReasignacion([]);
        setDataSourceFilterReasignar([]);

        await axios.get(getOrquestadorApiUrl(`AtencionIndividualReasignacion/porAtencionIndividualId/${AtencionIndividualId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceReasignacion(response.data.models ? response.data.models : []);
                setDataSourceFilterReasignar(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }
    const createReasignacion = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl(`AtencionIndividualReasignacion/crear/`), data)
            .then((response) => {
                const data = response.data;
                mostrarMensajeNT(data.titulo, data.mensaje, '1');
                obtenerDatosReasignados();
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo === 400) {
                    mostrarMensajeNT(data.titulo, data.mensaje, '4');
                }
            });
    }  
    
    const obtenerDatosAnexos = async () => {
        setDataSourceAnexos([]);
        await axios.get(getOrquestadorApiUrl(`AtencionIndividualAnexo/bandeja/${AtencionIndividualId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceAnexos(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }
    
    const verAnexos = (element: any) => {
        setAtencionIndividualId(element.id);
        setModalAnexos(true);
    } 
    
    const asociarAnexoCaso = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl('AtencionIndividual/asociarAnexoCaso'), data)
            .then((response) => {
                const data = response.data;
                mostrarMensajeNT(data.titulo, data.mensaje, '1');
                obtenerDatosAnexos();

            })
            .catch(e => {
                // Capturamos los errores
                const data = e?.response?.data;
                if (data?.codigo == 400) {
                    mostrarMensaje(data.titulo, data.mensaje, '4');
                }
            });
    }    

    const dataAtencionesIndividualesContextInterface:AtencionesIndividualesContextInterface  ={
        dataSourcePersona: dataSourcePersona,
        setDataSourcePersona: setDataSourcePersona,
        setFormPersonaVisible: setFormPersonaVisible,
        obtenerDataParametroDetalle: obtenerDataParametroDetalle,
        dataTiposDocumento: dataTiposDocumento,
        setDataTiposDocumento: setDataTiposDocumento,
        getDataIndividual: getDataIndividual,
        create: create,
        formPersonaVisible: formPersonaVisible,
        dataGenero: dataGenero,
        dataOrientSexual: dataOrientSexual,
        dataSexo: dataSexo,
        dataEnfoqPoblSoc: dataEnfoqPoblSoc,
        dataHechVictim: dataHechVictim,
        obtenerDataParametroDetallePorCodigoInternoDetallePadre: obtenerDataParametroDetallePorCodigoInternoDetallePadre,
        setDataDepartamentoVictima: setDataDepartamentoVictima,
        dataDepartamentoVictima: dataDepartamentoVictima,
        setDataMunicipioVictima: setDataMunicipioVictima,
        dataMunicipioVictima: dataMunicipioVictima,
        dataEtnia: dataEtnia,
        setDataSubTipoEtnia: setDataSubTipoEtnia,
        dataSubTipoEtnia: dataSubTipoEtnia,
        dataPoblPrioInteres: dataPoblPrioInteres,
        setDataSubTipoPobPrio: setDataSubTipoPobPrio,
        dataSubTipoPobPrio: dataSubTipoPobPrio,
        dataRegimen: dataRegimen,
        setDataAseguradora: setDataAseguradora,
        dataAseguradora: dataAseguradora,
        dataEstadoAfiliacion: dataEstadoAfiliacion,
        dataNivelSisben: dataNivelSisben,
        setDataInstInstrVincul: setDataInstInstrVincul,
        dataInstInstrVincul: dataInstInstrVincul,
        dataPais: dataPais,
        setDataDepartamento: setDataDepartamento,
        dataDepartamento: dataDepartamento,
        llenarMunicipios: llenarMunicipios,
        selectDepartamentoRef: selectDepartamentoRef,
        dataMunicipio: dataMunicipio,
        llenarLocalidades: llenarLocalidades,
        selectMunicipioRef: selectMunicipioRef,
        dataLocalidad: dataLocalidad,
        llenarUpz: llenarUpz,
        selectLocalidadRef: selectLocalidadRef,
        dataUpz: dataUpz,
        llenarBarrio: llenarBarrio,
        selectUpzRef: selectUpzRef,
        dataBarrio: dataBarrio,
        dataZona: dataZona,
        dataVia: dataVia,
        dataLeteraVia: dataLeteraVia,
        dataLetraAdicionalVial: dataLetraAdicionalVial,
        dataOrientacionVia: dataOrientacionVia,
        dataLetraNumMarca: dataLetraNumMarca,
        dataOrientacionCasa : dataOrientacionCasa,
        
        setPrimeraEtapaFormulario:setPrimeraEtapaFormulario,
        formRef: formRef,
        dataSourceCaso:dataSourceCaso,

        // Datos del caso en problematica
        dataCanalAtencion: dataCanalAtencion,
        setDataCanalAtencion: setDataCanalAtencion,
        dataTipoSolicitud: dataTipoSolicitud,
        setDataTipoSolicitud: setDataTipoSolicitud,
        obtenerDataParametroDetallePorPadre:obtenerDataParametroDetallePorPadre,
        dataEstadoCaso:dataEstadoCaso,
        setDataEstadoCaso: setDataEstadoCaso,
        createCaso:createCaso,
        setDataSourceCaso:setDataSourceCaso,
        setDataTiposActor:setDataTiposActor,
        dataTiposActor:dataTiposActor,
        crearPadreHijoParametro:crearPadreHijoParametro,
        // Motivo y submotivo
        dataMotivo: dataMotivo,
        setDataMotivo: setDataMotivo,
        dataSubMotivo: dataSubMotivo,
        setDataSubMotivo:setDataSubMotivo,
        setDataTablaActores:setDataTablaActores,
        dataTablaActores:dataTablaActores, 

        // Datos Actores en relación con la problematica en acceso 1
        dataRegimenActor1: dataRegimenActor1,
        setDataRegimenActor1: setDataRegimenActor1,
        dataAseguradoraActor1: dataAseguradoraActor1,
        setDataAseguradoraActor1: setDataAseguradoraActor1,

        // Datos Actores en relación con la problematica en acceso 2
        dataPrestador: dataPrestador,
        setDataPrestador: setDataPrestador,
        dataSede: dataSede,
        setDataSede: setDataSede,

        // Datos Actores en relación con la problematica en acceso 3
        dataTipoEntidad: dataTipoEntidad,
        setDataTipoEntidad: setDataTipoEntidad,
        dataEntidad: dataEntidad,
        setDataEntidad : setDataEntidad,
        buscarPrestadores:buscarPrestadores,
        userDto:userDto,
        esLSPT:esLSPT,
        dataCasoCreado:dataCasoCreado,
        setDataCasoCreado:setDataCasoCreado,
        DefaultCasoResponse:DefaultCasoResponse,
        primeraEtapaFormulario:primeraEtapaFormulario,
        //Datos para reasignar
        AtencionIndividualId: AtencionIndividualId,
        UsuarioId: userDto.id,
        UsuarioLogueado: (userDto.vcPrimerNombre || '') + ' ' + (userDto.vcSegundoNombre || '') + ' ' + (userDto.vcPrimerApellido || '') + ' ' + (userDto.vcSegundoApellido || ''),
        reAsignar:reAsignar,
        modalReAsignar: modalReAsignar,
        setModalReAsignar: setModalReAsignar,
        obtenerDatosReasignados: obtenerDatosReasignados,
        dataSourceReasignacion: dataSourceReasignacion,
        createReasignacion: createReasignacion,
        setDataSourceFilterReasignar: setDataSourceFilterReasignar,
        dataSourceFilterReasignar: dataSourceFilterReasignar,
        setDataEstados:setDataEstados,
        dataEstados:dataEstados,
        obtenerDatosAnexos:obtenerDatosAnexos,
        dataSourceAnexos:dataSourceAnexos,
        verAnexos:verAnexos,
        asociarAnexoCaso:asociarAnexoCaso,
        obtenerDatosComprobador:obtenerDatosComprobador,
        dataComprobador:dataComprobador,
        textoComprobador:textoComprobador,
        setTextoComprobador:setTextoComprobador,
        tituloComprobador:tituloComprobador,
        setTituloComprobador:setTituloComprobador,
    }


    return (
        <AtencionIndividualContext.Provider value={dataAtencionesIndividualesContextInterface}>
            {primeraEtapaFormulario ? <FormularioPersona /> : <FormularioCaso /> }  
            <ActionModal visible={modalReAsignar} title={'Asignaciones del caso'} size='xl' handleClose={setModalReAsignar}>
                <AtencionesIndividualesFormReasignar />
            </ActionModal>    
            <ActionModal visible={modalAnexos} title={'Anexos del caso'} size='xl' handleClose={setModalAnexos}>
                <AtencionesIndividualesFormAnexos />
            </ActionModal>                                     
        </AtencionIndividualContext.Provider>
    );
}

