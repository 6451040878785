// Render Prop
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import Swal from "sweetalert2";
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikInput, FormikCheckbox } from '../../../components/FormikInput';
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { FormConstantsValidation } from '../../../components/FormConstantsValidation';

const UsuarioContratoSchema = Yup.object().shape({
  INumero: Yup.number()
    .required('Campo obligatorio')
    .nullable('Campo obligatorio')
    .test('superior', 'Campo obligatorio', function (f) {
      return f > 0;
    }),
  IAño: Yup.number()
    .required('Campo obligatorio')
    .nullable('Campo obligatorio')
    .test('superior', 'Campo obligatorio', function (f) {
      return f > 0;
    }),
  DtFechaInicio: Yup.date().required('Campo obligatorio').nullable('Campo obligatorio'),
  DtFechaFin: Yup.date().required('Campo obligatorio').nullable('Campo obligatorio')
    .test('superior', 'La fecha fin debe ser mayor que la de inicio', function (f) {
      const ref = Yup.ref('DtFechaInicio');
      return f > this.resolve(ref);
    }),
  BProrroga: Yup.bool(),
  BTerminacion: Yup.bool(),
});

export default function UsuariosFormContratos({ obtenerDatos, setModalVisible, data }) {
  const [dataAnios, setDataAnios] = useState([]);

  const create = async (data) => {
    await axios.post(getAdministradorApiUrl('Contrato'), data)
      .then((response) => {
        setModalVisible(false);
        obtenerDatos();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const update = async (data) => {
    await axios.put(getAdministradorApiUrl(`Contrato/${data.Id}`), data)
      .then((response) => {
        setModalVisible(false);
        obtenerDatos();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  useEffect(() => {
    let anio = new Date().getFullYear() + 1;
    const dataArray = [];
    for (let i = anio; i >= anio - 6; i--) {
      dataArray.push({ value: i, label: i.toString() });
    }
    setDataAnios(dataArray);
  }, []);

  const mostrarMensaje = (title, text) => {
    Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar'
    })
  }

  return (
    <div>
      <Formik
        initialValues={{ Id: data.Id, UsuarioId: data.UsuarioId, INumero: data.INumero, IAño: data.IAño, DtFechaInicio: data.DtFechaInicio, DtFechaFin: data.DtFechaFin, BProrroga: data.BProrroga, DtFechaProrroga: data.DtFechaProrroga, BTerminacion: data.BTerminacion, DtFechaTerminacion: data.DtFechaTerminacion }}
        validationSchema={UsuarioContratoSchema}
        onReset={(values, formProps) => {
          setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!data.BProrroga && !data.BTerminacion && values.BProrroga && values.BTerminacion) {
            mostrarMensaje('¡No valido!', 'Solo puede seleccionar fecha prorroga o fecha terminación');
            setSubmitting(false);
            return;
          }

          if (data.BTerminacion && !data.BProrroga && values.BProrroga && values.BTerminacion) {
            mostrarMensaje('¡No valido!', 'No puede seleccionar fecha prorroga, porque ya tiene fecha terminación');
            setSubmitting(false);
            return;
          }

          if (values.BProrroga) {
            if (!(values.DtFechaProrroga > values.DtFechaFin)) {
              mostrarMensaje('¡No valido!', 'La fecha prorroga debe ser mayor a la fecha fin del contrato');
              setSubmitting(false);
              return;
            }
          }

          if (values.BTerminacion) {
            if (values.BProrroga && !(values.DtFechaTerminacion < values.DtFechaProrroga)) {
              mostrarMensaje('¡No valido!', 'La fecha terminación debe ser menor a la fecha de la prorroga');
              setSubmitting(false);
              return;
            }

            if (!values.BProrroga && !(values.DtFechaTerminacion < values.DtFechaFin)) {
              mostrarMensaje('¡No valido!', 'La fecha terminación debe ser menor a la fecha fin del contrato');
              setSubmitting(false);
              return;
            }
          }

          if (values.Id == 0) {
            create(values);
          } else {
            update(values);
          }
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <div className='form-group row'>
              <FormikInput
                labelName='Número contrato'
                nameSelect='INumero'
                className='col-12 col-md-6'
                onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                required={true}
              />
              <FormikSelect
                options={dataAnios}
                onChange={selectedOption => setFieldValue("IAño", selectedOption.value)}
                value={values.IAño}
                labelName='Año'
                nameSelect='IAño'
                className='col-12 col-md-6'
                placeHolder='Seleccione'
                required={true}
              />
            </div>
            <div className='form-group row'>
              <FormikInput
                type='date'
                labelName='Fecha inicio contrato'
                nameSelect='DtFechaInicio'
                className='col-12 col-md-6 mt-5'
                required={true}
              />
              <FormikInput
                type='date'
                labelName='Fecha fin contrato'
                nameSelect='DtFechaFin'
                className='col-12 col-md-6 mt-5'
                required={true}
              />
            </div>
            <div className='form-group row'>
              {values.Id > 0 ?
                <FormikCheckbox
                  labelName='¿Tiene prórroga?'
                  nameSelect='BProrroga'
                  className='col-12 col-md-6 mt-5'
                  value={values.BProrroga}
                  required={true}
                /> : null
              }
              {values.Id > 0 && values.BProrroga ?
                <FormikInput
                  type='date'
                  labelName='Fecha prórroga'
                  nameSelect='DtFechaProrroga'
                  className='col-12 col-md-6 mt-5'
                  required={true}
                /> : null
              }
            </div>
            <div className='form-group row'>
              {values.Id > 0 ?
                <FormikCheckbox
                  labelName='¿Tiene terminaci&oacute;n anticipada?'
                  nameSelect='BTerminacion'
                  className='col-12 col-md-6 mt-5'
                  value={values.BTerminacion}
                  required={true}
                /> : null
              }
              {values.Id > 0 && values.BTerminacion ?
                <FormikInput
                  type='date'
                  labelName='Fecha terminaci&oacute;n anticipada'
                  nameSelect='DtFechaTerminacion'
                  className='col-12 col-md-6 mt-5'
                  required={true}
                /> : null
              }
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};