import { useState, useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import axios from 'axios'
import Select from "react-select";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { SwalToast } from "../../../components/SwalToast";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles, NoRecords, PaginationProperties } from "../../../components/DataTableConfig";


export function UsuariosRoles() {
    const [UsuarioItem, setUsuarioItem] = useState<any>({});
    const [dataSourceUsuarios, setDataSourceUsuarios] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const obtenerDatosUsuarios = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models || []
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcPrimerNombre + ' ' + element.vcPrimerApellido });
                });
                setDataSourceUsuarios(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosRolesPorUsuarioId = async (usuarioId: any) => {
        setDataSource([]);
        await axios.get(getAdministradorApiUrl(`UsuarioRol/porUsuarioId/${usuarioId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models || []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const createRolUsu = async (data: any) => {
        await axios.post(getAdministradorApiUrl('UsuarioRol'), data)
            .then((response) => {
                //onChangeOk(data.actividadId, true);
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede crear el registro'
                });
            });
    }

    const onChangeChecked = (element: any, $event: any) => {
        const payload = {
            rolId: element.rolId,
            usuarioId: UsuarioItem.value
        }

        onChangeOk(payload.rolId, $event.target.checked);
        createRolUsu(payload);
    }

    const onChangeOk = (rolId: number, value: boolean) => {
        const data: any = [...dataSource];
        for (let i = 0; i < data.length; i++) {
            if (data[i].rolId == rolId) {
                data[i].asignado = value;
                break;
            }
        }
        setDataSource(data);
    }

    useEffect(() => {
        obtenerDatosUsuarios();
    }, []);

    interface DataRow {
        rolId: number;
        asignado: boolean;
        nombreRol: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: '# Rol',
            cell: (element) => <p>{element.rolId}</p>,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Asignado',
            cell: (element) =>
                <div className='form-check form-switch form-switch-lg'>
                    <input type={'checkbox'} className="form-check-input" checked={element.asignado} name={'check' + element.rolId} onChange={($event: any) => onChangeChecked(element, $event)} />
                    {element.asignado == true ? 'Si' : 'No'}
                </div>,
            sortable: true,
            width: '250px'
        },
        {
            name: 'Nombre',
            cell: (element) => <p>{element.nombreRol}</p>,
            sortable: true,
        }
    ];

    return <>
        <Card>
            <Card.Header>
                <Card.Title>USUARIOS</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <div className="col-12 col-md-7">
                        <Select
                            name='selectParamId'
                            placeholder='Seleccione usuario'
                            onChange={(option: any) => {
                                setUsuarioItem(option)
                                obtenerDatosRolesPorUsuarioId(option.value);
                            }}
                            options={dataSourceUsuarios}
                            value={UsuarioItem}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>

        <Card className="mt-5">
            <Card.Header>
                <Card.Title>ROLES</Card.Title>
            </Card.Header>
            <Card.Body>
                <Container className="row">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={dataSource}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                        />
                    </Col>
                </Container>
            </Card.Body>
        </Card>
    </>
}

