import { LoadingState, UserLogin,  } from "../models";
import { configureStore } from '@reduxjs/toolkit';
import { userSlice } from "./states/user.state";
import { loadingSlice } from "./states/loading.state";
import { menuSlice } from "./states/menu.state";

export interface SiCuentanosStore{
    user: UserLogin;
    loading:LoadingState;
    menu: string[];
}

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    loading: loadingSlice.reducer,
    menu: menuSlice.reducer,
  },
});