import React, { useState, useEffect, useContext } from "react";
import { Row, Card, Col, Button, Modal, Container, Table, FormGroup } from "react-bootstrap";
import { Field, Form, Formik, ErrorMessage } from 'formik';
import axios from 'axios'
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { getAdministradorApiUrl } from "../../../../../_metronic/helpers/ApiAdministradorHelpers";
import { FormikCheckbox, FormikInput } from "../../../../components/FormikInput";
import { FormConstantsValidation } from "../../../../components/FormConstantsValidation";

export function ANS(props: any) {

    const obtenerDatos = async () => {
        await axios.get(getAdministradorApiUrl('Configuracion'))
            .then((response) => {
                // Obtenemos los datos
                const data = response.data.models[0];
                if (data) {
                    setForm({
                        Id: data.id,
                        IDiasLimiteRespuesta: data.iDiasLimiteRespuesta,
                        BDomingoLaboral: data.bDomingoLaboral,
                        BSabadoLaboral: data.bSabadoLaboral,
                        BEstado: data.bEstado
                    })
                }
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const guardadoOk = (titulo: any, mensaje: any) => {
        Swal.fire({
            title: titulo,
            text: mensaje,
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar'
        })
    }

    const createANS = async (data: any) => {
        await axios.post(getAdministradorApiUrl('Configuracion/'), data)
            .then((response) => {
                if (response.status == 201) {
                    obtenerDatos();
                    guardadoOk(response.data.titulo, response.data.mensaje);
                }
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const updateANS = async (data: any) => {
        await axios.put(getAdministradorApiUrl(`Configuracion/${data.Id}`), data)
            .then((response) => {
                if (response.status == 200) {
                    guardadoOk(response.data.titulo, response.data.mensaje);
                }
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const [form, setForm] = useState({
        Id: 0,
        IDiasLimiteRespuesta: 0,
        BSabadoLaboral: true,
        BDomingoLaboral: true,
        BEstado: true
    });

    const ANSSchema = Yup.object().shape({
        IDiasLimiteRespuesta: Yup.number()
            .required('Campo obligatorio')
            .integer("Debe ser un número entero")
            .test('superior', 'Debe ser mayor a 0', function (f: any) {
                return f > 0;
            })
    });

    useEffect(() => {
        obtenerDatos();
    }, []);

    return <>
        <Card>
            <Card.Header>
                <Card.Title>Parametrizaci&oacute;n de ANS</Card.Title>
            </Card.Header>
            <Card.Body>
                <Formik
                    enableReinitialize
                    initialValues={form}
                    validationSchema={ANSSchema}
                    onReset={(values, formProps) => {

                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        console.log(values)
                        if (values.Id == 0) {
                            createANS(values);
                        } else {
                            updateANS(values);
                        }
                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        values
                    }) => (
                        <Form>
                            <div className="form-group row">
                                <FormikInput
                                    labelName='Días Hábiles Respuesta'
                                    nameSelect='IDiasLimiteRespuesta'
                                    className='col-12 col-lg-3'
                                    onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                    required={true}
                                />
                                <FormikCheckbox
                                    labelName='Sábado es NO hábil'
                                    nameSelect='BSabadoLaboral'
                                    className='col-12 col-lg-3'
                                    value={values.BSabadoLaboral}
                                    labelActive='Si'
                                    labelInactive='No'
                                    required={true}
                                />
                                <FormikCheckbox
                                    labelName='Domingo es NO hábil'
                                    nameSelect='BDomingoLaboral'
                                    className='col-12 col-lg-3'
                                    value={values.BDomingoLaboral}
                                    labelActive='Si'
                                    labelInactive='No'
                                    required={true}
                                />
                                <div className="col-12 col-lg-3">
                                    {/* <label><b>Guardar</b></label> */}
                                    <button className="btn btn-sm btn-block btn-primaryCustom"
                                        disabled={isSubmitting}>
                                        <i className="bi bi-save-fill"></i>
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}

                </Formik>
            </Card.Body>
        </Card>
    </>
}

