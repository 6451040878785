export const safeOnChange = (setFieldValue: (field: string, value: any) => void, field: string, additionalCallback?: () => void) => {
    return (selectedOption: any) => {
      if (selectedOption) {
        setFieldValue(field, selectedOption.value);
      } else {
        setFieldValue(field, '');
      }
  
      // Ejecutar el callback adicional si se proporciona.
      if (additionalCallback) {
        additionalCallback();
      }
    };
  };