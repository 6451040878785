import React, { useEffect, useRef } from 'react';

// @ts-ignore
const InactivityTimer = ({ onTimeout}) => {
    const timeoutRef = useRef(null);
    const TIMEOUT_DURATION = 1800000; // 5 minutes in milliseconds

    const resetTimer = () => {
        // @ts-ignore
        clearTimeout(timeoutRef.current);
        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
            // Lógica a ejecutar cuando se acaba el tiempo de inactividad
            onTimeout();
        }, TIMEOUT_DURATION);
        
    };


    useEffect(() => {
        const handleActivity = () => resetTimer();

        resetTimer();

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('click', handleActivity);
        window.addEventListener('keypress', handleActivity);

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            window.addEventListener('click', handleActivity);
            // @ts-ignore
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return null; // Este componente no renderiza nada visualmente
};


export default InactivityTimer;