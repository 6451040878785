import { AzureAD } from 'react-aad-msal';
// Hooks
import { useAuthProvider } from '../../hooks';
// Utilities
import { authProvider } from '../../utilities';





const AzureAuth = () => {
  const { bodyFunction } = useAuthProvider();
  return (
    <AzureAD provider={authProvider} forceLogin={false}>
      {bodyFunction}
    </AzureAD>
  );
};
export default AzureAuth;
