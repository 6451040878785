import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

import ReactDOM from 'react-dom';
import InactivityTimer from './services/inactivity.service';

import { authProvider } from 'src/app/utilities';

import Swal from 'sweetalert2';

const App = () => {

    let popupopened =false;
    const handleInactivityTimeout = () => {

        console.log(authProvider.authenticationState)
        if(!popupopened)
        {
            popupopened=true;
            if(authProvider.authenticationState=="Authenticated")
            {
                Swal.fire({
                    title: '¡Tiempo Limite Alcanzado!',
                    text: 'Ha estado mucho tiempo inactivo, se cerrará la sesión actual',
                    icon: 'info',
                    confirmButtonText: 'Cerrar'

                }).finally(() => {
                    popupopened=false;
                    authProvider.logout();
                    console.log('El popup se ha cerrado.');

                });;
            }
        }




        // Puedes realizar acciones adicionales aquí, como cerrar sesión o mostrar una notificación
    };
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
            <Outlet />
            <MasterInit />
            <InactivityTimer onTimeout={handleInactivityTimeout} />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}
ReactDOM.render(<App />, document.getElementById('root'));
export {App}
