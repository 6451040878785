import React, { useState, useEffect } from "react";
import { Card, Container, Col } from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikInput } from '../../../components/FormikInput';
import { CrudButton } from "../../../components/CrudButton";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getOrquestadorApiUrl } from "../../../../_metronic/helpers/ApiOrquestadorHelper";
import { ActionModal } from "../../../components/ActionModal";
import moment from "moment";
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles, MAX_TEXT_LENGTH } from "../../../components/DataTableConfig";
import { handleDownloadExcel } from "../../../components/Excel";
import { AtencionesGrupalesAnexo } from "./AtencionesGrupalesAnexo";
import { PdfBase64 } from "../../../components/PdfBase64";
import { InputFilter } from "../../../components/Input";
import { CustomExpanderGeneric } from "src/app/utilities";

const currentDate = moment();

const BandejaGrupalSchema = Yup.object().shape({
    DtFechaInicio: Yup.date()
        .required('Campo obligatorio')
        .max(currentDate, 'La fecha no puede ser mayor a la fecha actual')
        .test('valida_fecha', 'Fecha inicio no puede ser mayor a fecha fin', function (dtFechaInicio: any) {
            const refDtFechaFin: string = this.resolve(Yup.ref('DtFechaFin'));
            var momentA = moment(dtFechaInicio, "DD/MM/YYYY");
            var momentB = moment(refDtFechaFin, "DD/MM/YYYY");
            console.log(momentA > momentB);
            if (momentA > momentB) {

                return false;
            }
            return true;
        }),
    DtFechaFin: Yup.date()
        .required('Campo obligatorio')
        .test('valida_fecha', 'Fecha fin no puede ser menor a fecha inicio', function (dtFechaFin: any) {
            const refDtFechaInicio: string = this.resolve(Yup.ref('DtFechaInicio'));
            var momentA = moment(dtFechaFin, "DD/MM/YYYY");
            var momentB = moment(refDtFechaInicio, "DD/MM/YYYY");
            //console.log(momentB < momentA);
            if (momentA < momentB) {

                return false;
            }
            return true;
        }),
    UsuarioId: Yup.number()
});

interface BandejaAtencionesGrupalesContextInterface {
    dataUsuarios: any;
    setDataUsuarios: any;
    modalVisible: any;
    setModalVisible: any;
    dataSourceAnexos: any;
    modalVisiblePdf: any;
    dataSourcePdf: any;
    setModalVisiblePdf: any;
}

export const bandejaGrupalesContext = React.createContext<BandejaAtencionesGrupalesContextInterface | null>(null);

export function BandejaAtencionesGrupales(props: any) {
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceAnexos, setDataSourceAnexos] = useState([]);
    const [dataSourcePdf, setDataSourcePdf] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisiblePdf, setModalVisiblePdf] = useState(false);
    const [dataSourceFilter, setDataSourceFilter] = useState([]);
    const [valueFilter, setValueFilter] = useState('');
    const obtenerDatosUsuarios = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data: any = response.data.models || [];
                dataArray.push({ value: 0, label: 'TODOS' });
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcPrimerNombre + ' ' + element.vcPrimerApellido });
                });
                setDataUsuarios(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerBandeja = async (data: any) => {
        setDataSource([]);
        setDataSourceFilter([]);

        data.dtFechaFin = moment(data.DtFechaFin).format('YYYY-MM-DD[T23:59:59]');
        await axios.post(getOrquestadorApiUrl('AtencionGrupal/bandeja'), data)
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ?? []);
                setDataSourceFilter(response.data.models ?? []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    useEffect(() => {
        obtenerDatosUsuarios();
        obtenerBandeja(initialValues);
    }, []);

    const initialValues = {
        DtFechaInicio: moment().add(-1, 'M').format('YYYY-MM-DD'),
        DtFechaFin: moment().format('YYYY-MM-DD'),
        UsuarioId: 0
    }

    const abrirAnexo = async (atencionGrupalId: number) => {
        setDataSourceAnexos([]);
        await axios.get(getOrquestadorApiUrl(`AtencionGrupalAnexo/bandeja/${atencionGrupalId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceAnexos(response.data.models ?? []);
                setModalVisible(true);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    const imprimirCaso = async (atencionGrupalId: number) => {
        setDataSourceAnexos([]);
        await axios.get(getOrquestadorApiUrl(`AtencionGrupal/pdf/${atencionGrupalId}`))
            .then((response) => {

                // Obtenemos los datos
                console.log(response);
                setDataSourcePdf(response.data.pdf ?? null);
                setModalVisiblePdf(true);
                //setModalVisible(true);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    const datosBandejaGrupalContext: BandejaAtencionesGrupalesContextInterface = {
        dataUsuarios: dataUsuarios,
        setDataUsuarios: setDataUsuarios,
        modalVisible: modalVisible,
        setModalVisible: setModalVisible,
        dataSourceAnexos: dataSourceAnexos,
        modalVisiblePdf: modalVisiblePdf,
        dataSourcePdf: dataSourcePdf,
        setModalVisiblePdf: setModalVisiblePdf,
    }

    interface DataRow {
        id: number;
        acciones: string;
        anexos: string;
        dtFechaOrientacion: string;
        iNumeroUsuarios: string,
        tipoActividad: string;
        localidad: string;
        vcLugar: string;
        tiempoDuracion: string;
        tipoSolicitud: string;
        motivo: string;
        subMotivo: string;
        txAclaracionMotivo: string;
        dtFechaRegistro: string;
        iAnexos: string;
        usuario: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) => <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-printer" onClick={() => imprimirCaso(element.id)} toolTip="imprimir caso" />,
            sortable: false,
        },
        {
            name: 'Anexos',
            cell: (element) => <CrudButton className="btn-icon btn-sm btn-primary" text={element.iAnexos} onClick={() => abrirAnexo(element.id)} toolTip="Ver anexos del caso" />,
            sortable: false,
        },
        {
            name: 'Fecha de orientación',
            cell: (element) => <p>{element.dtFechaOrientacion.toString().substring(0, 10)}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: '# de usuarios',
            cell: (element) => <span className="badge bg-success">{element.iNumeroUsuarios}</span>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Tipo de actividad',
            cell: (element) => <p>{element.tipoActividad}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Localidad',
            cell: (element) => <p>{element.localidad}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Lugar',
            cell: (element) => <p>{element.vcLugar}</p>,
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Tiempo de duración',
            cell: (element) => <p>{element.tiempoDuracion}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Tema Brindado',
            cell: (element) => <p>{element.tipoSolicitud}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Motivo',
            cell: (element) => <p>{element.motivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Sub motivo',
            cell: (element) => <p>{element.subMotivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Aclaraciones',
            cell: (element) => <p>{element.txAclaracionMotivo.length > MAX_TEXT_LENGTH ? `${element.txAclaracionMotivo.slice(0, MAX_TEXT_LENGTH)}...` : element.txAclaracionMotivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Usuario que registró',
            cell: (element) => <p>{element.usuario}</p>,
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Fecha de registro',
            cell: (element) => <p>{moment(element.dtFechaRegistro).format('YYYY-MM-DD HH:mm:ss')}</p>,
            sortable: true,
            minWidth: '150px'
        },
    ];




    const filterDataTable = (value: string) => {
        const dataArray = dataSource.filter((item: DataRow) => {
            return item.dtFechaOrientacion.toString().substring(0, 10).includes(value) ||
                item.tipoActividad?.toLowerCase().includes(value) ||
                item.localidad?.toLowerCase().includes(value) ||
                item.vcLugar?.toLowerCase().includes(value) ||
                item.tiempoDuracion?.toLowerCase().includes(value) ||
                item.tipoSolicitud?.toLowerCase().includes(value) ||
                item.motivo?.toLowerCase().includes(value) ||
                item.subMotivo?.toLowerCase().includes(value) ||
                item.txAclaracionMotivo?.toLowerCase().includes(value) ||
                item.dtFechaRegistro?.toString().substring(0, 10).includes(value) ||
                item.usuario?.toLowerCase().includes(value);
        });
        setDataSourceFilter(dataArray);
    }


    return (
        <bandejaGrupalesContext.Provider value={datosBandejaGrupalContext}>
            <h1 className="page-heading d-flex text-azul-oscuro fw-bold fs-3 my-0 flex-column justify-content-center">
                Bandeja de atenciones grupales - capacitaciones
            </h1>
            <Card className="mt-5">
                <Card.Header>
                    <Card.Title className="fw-bold text-azul-oscuro">
                        Tabla de atenciones grupales - capacitaciones
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ ...initialValues }}
                        onReset={(values, formProps) => {

                        }}
                        validationSchema={BandejaGrupalSchema}
                        onSubmit={(values: any, { setSubmitting }) => {
                            const payload = {
                                dtFechaInicio: values.DtFechaInicio,
                                dtFechaFin: values.DtFechaFin,
                                usuarioId: values.UsuarioId
                            }
                            obtenerBandeja(payload);
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            setFieldValue,
                            values
                        }) => (
                            <Form>

                                <div className="form-group row">
                                    <FormikInput
                                        type='date'
                                        labelName='Fecha de inicio'
                                        nameSelect='DtFechaInicio'
                                        className='col-12 col-md-3 mt-5'
                                        required={true}
                                    />
                                    <FormikInput
                                        type='date'
                                        labelName='Fecha de fin'
                                        nameSelect='DtFechaFin'
                                        className='col-12 col-md-3 mt-5'
                                        required={true}
                                    />
                                    <FormikSelect
                                        options={dataUsuarios}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("UsuarioId", selectedOption.value);
                                        }}
                                        value={values.UsuarioId}
                                        labelName='Usuario registró'
                                        nameSelect='UsuarioId'
                                        className='col-12 col-md-6 mt-5'
                                        placeHolder='Seleccione'
                                    />
                                    <div className="col-12 mt-5">
                                        <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end mx-2" text="Buscar" disabled={isSubmitting} />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                    {dataSource.length > 0 ?
                        <InputFilter
                            valueFilter={valueFilter}
                            setValueFilter={setValueFilter}
                            onChange={filterDataTable}
                            nameFile="Bandeja Atenciones Grupales"
                            dataSource={dataSourceFilter}
                        />
                        : null
                    }
                    <Container fluid className="form-group row mt-10">

                        <Col>
                            <DataTable
                                columns={columns}
                                data={dataSourceFilter}
                                pagination
                                customStyles={customStyles}
                                paginationComponentOptions={PaginationProperties}
                                noDataComponent={<NoRecords />}
                                expandableRows
                                expandableRowsComponent={CustomExpanderGeneric}

                            />
                        </Col>
                    </Container>



                </Card.Body>
            </Card>

            <ActionModal visible={modalVisible} title={'Anexo'} size={'xl'}>
                <AtencionesGrupalesAnexo />
                <CrudButton className="btn-sm btn-primary" text="Cancelar" onClick={() => setModalVisible(false)} />
            </ActionModal>

            <ActionModal visible={modalVisiblePdf} title={'Documento PDF del caso'} size={'xl'} handleClose={setModalVisiblePdf}>
                <PdfBase64 pdfBase64={dataSourcePdf} />
            </ActionModal>

        </bandejaGrupalesContext.Provider>
    );
}

