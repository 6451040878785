import { Form, Formik } from 'formik';
import { Col} from "react-bootstrap";
import * as Yup from 'yup';
import { FormikSelect } from '../../../components/FormikSelect';
import { EstadoUsuario, UsuariosContextInterface } from 'src/app/models';
import { useContext } from 'react';
import { UsuarioContext } from './Usuarios';


const UsuarioSchema = Yup.object().shape({
  IEstado: Yup.number().required('Campo obligatorio'),
  
});

export default function UsuariosFiltroForm() {
  
  const context = useContext(UsuarioContext) as UsuariosContextInterface;

  type SelectOption = {
      value: number;
      label: string;
  };

  let options = Object.entries(EstadoUsuario)
    .filter(([key, value]) => !isNaN(Number(value))) // Solo las entradas donde el valor es un número.
    .map(([key, value]) => ({
      value: Number(value), // Aquí convierte el value a tipo número.
      label: key
    }));

  

  return (
    <div>
      <Formik
        initialValues={{
          EstadoUsuario: EstadoUsuario.Activo
        }}
        validationSchema={UsuarioSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);          
        }}
      >
        {({
          setFieldValue,
          values
        }) => (
          <Form autoComplete='off'>
            <Col>
              <FormikSelect
                options={options} 
                onChange={(selectedOption: SelectOption) => context.obtenerDatosPorEstado(selectedOption.value, setFieldValue)}
                value={values.EstadoUsuario}                  
                labelName='Estado de Usuario'
                nameSelect='EstadoUsuario'
                className='col-12 col-sm-6 col-md-4 mt-5'
                placeHolder='Seleccione'
                required={true}
              />
            </Col>
          </Form>
        )}
      </Formik>    
    </div>
  );
};