import { useContext, useState } from "react";
import Swal from "sweetalert2";
import { Form, Formik, FormikValues, useFormikContext } from 'formik';
import { useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";

import * as Yup from 'yup';
import { CrudButton } from "../../../../components/CrudButton";
import { FormikInput, FormikTextArea, FormikInputFile, FormikInputGroup } from '../../../../components/FormikInput';
import { FormikSelect } from '../../../../components/FormikSelect';
import { AtencionIndividualContext } from './IngresarInformacion';
import { AtencionesIndividualesContextInterface, FormConstantsAtencionIndividual, DataRowActores, NuevoActorInterface, NuevoActorResponse } from "./AtencionesIndividualesTypes";
import { FormConstantsValidation } from "../../../../components/FormConstantsValidation";
import DataTable, { TableColumn } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles } from "../../../../components/DataTableConfig";
import { SwalToast } from "../../../../components/SwalToast";
import { useNavigate } from "react-router";




const dataCharacterization = Yup.object().shape({
    CanalAtencionId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    VcTurnoSat: Yup.string(),
    TipoSolicitudId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Campo obligatorio')
        .min(1, "Campo obligatorio"),

    MotivoId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    SubMotivoId: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required('Campo obligatorio')
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    TxAclaracionMotivo: Yup.string()
        .required('campo obligatorio')
        .min(50, "Mínimo 50 caracteres")
        .max(4000, "Máximo 4000 caracteres"),
    TxGestionRealizada: Yup.string()
        .test('', 'Mínimo 50 caracteres y Máximo 4000 caracteres', function (f: any) {
            const refTipoSolicitudId = this.resolve(Yup.ref('TipoSolicitudId'));

            if (refTipoSolicitudId == FormConstantsAtencionIndividual.TIPO_SOLICITUD_PROBLEMATICA) {
                if (f?.length >= 50 && f?.length <= 4000) {
                    return true;
                } else
                    return false;
            }

            return true;
        }),
    EstadoId: Yup.number().required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('', 'Campo obligatorio', function (f: any) {
            return f > 0
        }),
    Anexo: Yup.mixed()
        .test('fileFormat', 'Formato de archivo debe ser pdf', value => {
            return !value ? true : FormConstantsValidation.TYPEPDF == value.type;
        })
        .test('fileSize', 'El archivo debe ser de máximo 2MB', value => {
            return !value ? true : value.size <= FormConstantsValidation.TWOMBFILESIZE;
        }),
    VcValidaActores: Yup.number()
        .test('', 'debe registrar al menos un actor', function (f: any) {
            //debugger;
            const TipoSolicitudIdValue = this.resolve(Yup.ref('TipoSolicitudId'));
            let valida: boolean = true;
            if (TipoSolicitudIdValue === FormConstantsAtencionIndividual.TIPO_SOLICITUD_PROBLEMATICA) {
                valida = f >= 1;
            }
            return valida;
        }),
});


export function FormularioCaso() {
    const context = useContext(AtencionIndividualContext) as AtencionesIndividualesContextInterface;

    //const { setFieldValue } = useFormikContext();

    const [nombreRegimen, setNombreRegimen] = useState("");
    const [nombreAseguradora, setNombreAseguradora] = useState("");
    const [nombrePrestador, setNombrePrestador] = useState("");
    const [nombreSede, setNombreSede] = useState("");
    const [nombreTipoActor, setNombreTipoActor] = useState("");
    const [nombreTipoEntidad, setNombreTipoEntidad] = useState("");
    const [nombreEntidad, setNombreEntidad] = useState("");
    const navigate = useNavigate();



    useEffect(() => {
        window.scrollTo(0, 0);
        context.obtenerDataParametroDetalle('bCanalesAtencion', context.setDataCanalAtencion);
        context.obtenerDataParametroDetalle('bTemaBrindado', context.setDataTipoSolicitud);
        context.obtenerDataParametroDetalle('bEstadoCaso', context.setDataEstadoCaso);
        context.obtenerDataParametroDetalle('bRegimen', context.setDataRegimenActor1);
        context.obtenerDataParametroDetalle('bTipoEntidadActor', context.setDataTipoEntidad);
        context.obtenerDataParametroDetalle('bEstadoCaso', context.setDataEstados);
    }, []);


    const agregarActor = (values: FormikValues) => {

        let tipoActorIdValue = values.tipoActorId;
        let tipoIdValue = 0;
        let sedeIdValue = 0;
        let tipoNombre = '';
        let sedeNombre = '';
        let tipoActor = nombreTipoActor;

        if (values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR1) {
            tipoIdValue = values.RegimenId;
            sedeIdValue = values.AseguradoraId;
            tipoNombre = nombreRegimen;
            sedeNombre = nombreAseguradora;
        }

        if (values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR2) {
            tipoIdValue = values.PrestadorId;
            sedeIdValue = values.SedeId;
            tipoNombre = nombrePrestador;
            sedeNombre = nombreSede;
        }

        if (values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR3) {
            tipoIdValue = values.TipoEntidadId;
            sedeIdValue = values.EntidadId;
            tipoNombre = nombreTipoEntidad;
            sedeNombre = nombreEntidad;
        }

        if (values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR4) {
            let nuevoActor: NuevoActorInterface = {
                ParametroIdPadre: FormConstantsAtencionIndividual.CODIGO_NUEVO_ENTIDAD_ACTOR,
                ParametroIdHijo: FormConstantsAtencionIndividual.CODIGO_NUEVO_SEDE_ENTIDAD_ACTOR,
                VcNombrePadre: values.VcNombreEntidad,
                VcNombreHijo: values.VcNombreSede
            };
            tipoNombre = values.VcNombreEntidad;
            sedeNombre = values.VcNombreSede;

            //Función con promesa
            context.crearPadreHijoParametro(nuevoActor)
                .then((data: NuevoActorResponse) => {
                    agregarActorTabla(
                        data.padreId,
                        data.hijoId,
                        tipoActorIdValue,
                        tipoNombre,
                        sedeNombre,
                        tipoActor
                    );
                }).catch((err: any) => { console.log(err) });
        }

        agregarActorTabla(
            tipoIdValue,
            sedeIdValue,
            tipoActorIdValue,
            tipoNombre,
            sedeNombre,
            tipoActor
        );
    }

    const agregarActorTabla = (
        tipoIdValue: number,
        sedeIdValue: number,
        tipoActorIdValue: number,
        tipoNombre: string,
        sedeNombre: string,
        tipoActor: string,
    ) => {
        let idActor = context.dataTablaActores.length > 0 ? context.dataTablaActores[context.dataTablaActores.length - 1].id + 1 : 1;
        let existe: boolean = context.dataTablaActores.some((actor: DataRowActores) => {
            return actor.tipoId == tipoIdValue && actor.sedeId == sedeIdValue;
        })
        if (tipoIdValue > 0 && sedeIdValue > 0 && !existe) {
            let actor: DataRowActores = {
                id: idActor,
                atencionIndividualId: 0,
                tipoActorId: tipoActorIdValue,
                tipoId: tipoIdValue,
                sedeId: sedeIdValue,
                usuarioId: 0,
                vcNombreTipo: tipoNombre,
                vcNombreSede: sedeNombre,
                vcNombreTipoActor: tipoActor
            }

            context.setDataTablaActores((oldArray: DataRowActores[]) => [...oldArray, actor]);
        } else {
            let mensaje = existe ? 'El actor ya existe en la lista' : 'Seleccione un par de valores para actor ';
            SwalToast.fire({
                icon: 'warning',
                title: mensaje
            });
        }
    }

    const eliminarActor = (id: number) => {
        Swal.fire({
            title: 'Confirmar eliminación Actor',
            text: `¿Desea eliminar el actor con id ${id}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2d496f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                context.setDataTablaActores((current: DataRowActores[]) =>
                    current.filter((actor) => actor.id !== id)
                );
                //setFieldValue('VcValidaActores',context.dataTablaActores.length == 0 ? 1 : context.dataTablaActores.length);
            }
        });
    }

    const columns: TableColumn<DataRowActores>[] = [
        {
            name: 'Id',
            cell: (element) => <p>{element.id}</p>,
            sortable: true,
        },

        {
            name: 'Tipo Actor',
            cell: (element) => <p>{element.vcNombreTipoActor}</p>,
            sortable: false,
        },
        {
            name: 'Tipo / Entidad',
            cell: (element) => <p>{element.vcNombreTipo}</p>,
            sortable: false,
        },
        {
            name: 'Entidad / Sede',
            cell: (element) => <p>{element.vcNombreSede}</p>,
            sortable: false,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" onClick={() => eliminarActor(element.id)} />
                </div>,
            sortable: false
        }
    ];


    return (
        <section>
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={context.dataSourceCaso}
                    validationSchema={dataCharacterization}
                    onReset={(values, formProps) => {

                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {

                        try {
                            dataCharacterization.validate(values, { context: { context } });
                            // submit data
                        } catch (err: any) {
                            setErrors(err);
                        }
                        setSubmitting(false);

                        Swal.fire({
                            title: 'Confirmar guardado',
                            text: "¿Desea guardar datos del caso?",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#2d496f',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sí, guardar',
                            cancelButtonText: 'Cancelar'
                        }).then(async (result: any) => {
                            if (result.isConfirmed) {

                                let formData = new FormData();

                                formData.append('Id', values.Id);
                                formData.append('PersonaId', values.PersonaId);
                                formData.append('DtFechaRegistro', values.DtFechaRegistro); 
                                formData.append('UsuarioId', !context.userDto ? '0' : context.userDto.id.toString());
                                formData.append('CanalAtencionId', values.CanalAtencionId);
                                formData.append('VcTurnoSat', values.VcTurnoSat);
                                formData.append('TipoSolicitudId', values.TipoSolicitudId);
                                formData.append('MotivoId', values.MotivoId);
                                formData.append('SubMotivoId', values.SubMotivoId);
                                formData.append('TxAclaracionMotivo', values.TxAclaracionMotivo);
                                formData.append('TxGestionRealizada', values.TxGestionRealizada);
                                formData.append('VcRadicadoBte', values.VcRadicadoBte);
                                formData.append('TxActores', JSON.stringify(context.dataTablaActores));
                                formData.append('EstadoId', values.EstadoId);

                                if (values.Anexo) {
                                    formData.append('Anexo', values.Anexo);
                                }

                                //values.VcDireccion = JSON.stringify(FormConstantsValidation.elementsToArray(`#camposDireccion`));
                                context.createCaso(formData);
                            }
                        });

                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        setFieldValue,
                        values,
                        resetForm,
                        setFieldTouched
                    }) => (
                        <Form autoComplete='off'>
                            <h6>Caracterización del caso</h6>
                            {/* Datos de persona */}
                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId != 8 ? (
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos de la persona</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <FormikSelect
                                            options={context.dataTiposDocumento}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("ROTipoDocumentoId", selectedOption.value);
                                            }}
                                            value={values.ROTipoDocumentoId}
                                            labelName='Tipo de documento'
                                            nameSelect='ROTipoDocumentoId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            readOnly={true}
                                        />
                                        <div className="col-12 col-md-3 mt-5"></div>
                                        <FormikInput
                                            type='number'
                                            labelName='Número de documento de identidad'
                                            nameSelect='ROVcDocumento'
                                            className='col-12 col-md-3 mt-5'
                                            readOnly={true}
                                            value={values.ROVcDocumento}
                                        />
                                    </div>

                                    <div className="row">
                                        <FormikInput
                                            labelName='Primer apellido'
                                            nameSelect='VcPrimerApellido'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoApellido.toUpperCase();
                                                values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                                            }}
                                            readOnly={true}
                                        />
                                        <FormikInput
                                            labelName='Segundo apellido'
                                            nameSelect='VcSegundoApellido'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoApellido.toUpperCase();
                                                values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                                            }}
                                            readOnly={true}
                                        />
                                        <FormikInput
                                            labelName='Primer nombre'
                                            nameSelect='VcPrimerNombre'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcPrimerNombre.toUpperCase();
                                                values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                                            }}
                                            readOnly={true}
                                        />
                                        <FormikInput
                                            labelName='Segundo nombre'
                                            nameSelect='VcSegundoNombre'
                                            className='col-12 col-md-3 mt-5'
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoNombre.toUpperCase();
                                                values.VcSegundoNombre = values.VcSegundoNombre.toUpperCase();
                                            }}
                                            readOnly={true}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                            ) : null
                            }
                            {context.formPersonaVisible && context.dataSourcePersona.TipoDocumentoId == 8 ? (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos de la entidad</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <FormikSelect
                                                options={context.dataTiposDocumento}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("ROTipoDocumentoId", selectedOption.value);
                                                }}
                                                value={values.ROTipoDocumentoId}
                                                labelName='Tipo de documento'
                                                nameSelect='ROTipoDocumentoId'
                                                className='col-12 col-md-3 mt-5'
                                                placeHolder='Seleccione'
                                                readOnly={true}
                                            />
                                            <div className="col-12 col-md-3 mt-5"></div>
                                            <FormikInput
                                                type='number'
                                                labelName='Número de documento de identidad'
                                                nameSelect='ROVcDocumento'
                                                className='col-12 col-md-3 mt-5'
                                                readOnly={true}
                                                value={values.ROVcDocumento}
                                            />
                                        </div>

                                        <div className="row">
                                            <FormikInput
                                                labelName='Nombre Entidad'
                                                nameSelect='VcPrimerNombre'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcPrimerNombre.toUpperCase();
                                                    values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                                                }}
                                                readOnly={true}
                                            />
                                            <FormikInput
                                                labelName='Correo'
                                                nameSelect='VcCorreoEntidad'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcCorreoEntidad.toUpperCase();
                                                    values.VcCorreoEntidad = values.VcCorreoEntidad.toUpperCase();
                                                }}
                                                readOnly={true}
                                            />
                                            <FormikInput
                                                labelName='Nombre Contacto'
                                                nameSelect='VcNombreContacto'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcNombreContacto.toUpperCase();
                                                    values.VcNombreContacto = values.VcNombreContacto.toUpperCase();
                                                }}
                                                readOnly={true}
                                            />
                                            <FormikInput
                                                labelName='Teléfono Contacto'
                                                nameSelect='VcTelefonoContacto'
                                                className='col-12 col-md-3 mt-5'
                                                onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    event.currentTarget.value = values.VcTelefonoContacto.toUpperCase();
                                                    values.VcTelefonoContacto = values.VcTelefonoContacto.toUpperCase();
                                                }}
                                                readOnly={true}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                            ) : null
                            }

                            {/* Datos del caso en problematica */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos del caso</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <FormikSelect
                                            options={context.dataTipoSolicitud}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("TipoSolicitudId", selectedOption.value);
                                                setFieldValue("MotivoId", null);
                                                setFieldValue("SubMotivoId", null);
                                                context.obtenerDataParametroDetallePorPadre(selectedOption.value, context.setDataMotivo);
                                            }}
                                            value={values.TipoSolicitudId}
                                            labelName='Seleccione el tipo de solicitud'
                                            nameSelect='TipoSolicitudId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={context.dataCanalAtencion}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("CanalAtencionId", selectedOption.value);
                                            }}
                                            value={values.CanalAtencionId}
                                            labelName='Seleccione canal de atención'
                                            nameSelect='CanalAtencionId'
                                            className='col-12 col-md-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        {values.CanalAtencionId === FormConstantsAtencionIndividual.CODIGO_CANAL_PRESENCIAL && (
                                            <FormikInput
                                                labelName='Turno SAT'
                                                nameSelect='VcTurnoSat'
                                                className='col-12 col-md-3 mt-5'
                                            />
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* Motivo de orientación */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Motivos de
                                        {values.TipoSolicitudId === FormConstantsAtencionIndividual.TIPO_SOLICITUD_PROBLEMATICA ? ' problemática en salud' : ' orientación e información'}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <FormikSelect
                                            options={context.dataMotivo}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("MotivoId", selectedOption.value);
                                                setFieldValue("SubMotivoId", null);
                                                context.obtenerDataParametroDetallePorPadre(selectedOption.value, context.setDataSubMotivo);
                                            }}
                                            value={values.MotivoId}
                                            labelName='Motivo'
                                            nameSelect='MotivoId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={11}
                                        />
                                        <FormikSelect
                                            options={context.dataSubMotivo}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("SubMotivoId", selectedOption.value);
                                            }}
                                            value={values.SubMotivoId}
                                            labelName='Sub motivo'
                                            nameSelect='SubMotivoId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={12}
                                        />

                                        <FormikTextArea
                                            rows='4'
                                            labelName='Aclaraciones y gestión motivo de orientación'
                                            nameSelect='TxAclaracionMotivo'
                                            className='col-12 col-md-6 mt-5'
                                            required={true}
                                        />
                                        {values.TipoSolicitudId === FormConstantsAtencionIndividual.TIPO_SOLICITUD_PROBLEMATICA && (
                                            <FormikTextArea
                                                rows='4'
                                                labelName='Gestión realizada resolución problemática en acceso'
                                                nameSelect='TxGestionRealizada'
                                                className='col-12 col-md-6 mt-5'
                                                required={true}
                                            />
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>


                            {/* Datos Actores en relación con la problematica en acceso */}
                            {values.TipoSolicitudId === FormConstantsAtencionIndividual.TIPO_SOLICITUD_PROBLEMATICA && (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Datos Actores en relación con la problemática en acceso</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className='row'>

                                            <div className="d-flex mt-5">
                                                <FormikSelect
                                                    options={context.dataTiposActor}
                                                    onChange={(selectedOption: any) => {
                                                        setFieldValue("tipoActorId", selectedOption.value);
                                                        setNombreTipoActor(selectedOption.label);
                                                        setFieldValue("CodigoTipoActor", selectedOption.codigo);
                                                    }}
                                                    value={values.tipoActorId}
                                                    labelName='Datos Actores en relación con la problemática en acceso'
                                                    nameSelect='tipoActorId'
                                                    className='col-12 col-md-12 mt-5'
                                                    placeHolder='Seleccione el actor'
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className='row my-6'>
                                            {values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR1 && (
                                                <div>
                                                    <h6 className="fw-bolder">Entidades de afiliación en salud</h6><div className="row">
                                                        <FormikSelect
                                                            options={context.dataRegimenActor1}
                                                            onChange={(selectedOption: any) => {
                                                                setFieldValue("RegimenId", selectedOption.value);
                                                                setFieldValue("AseguradoraId", '');
                                                                context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bAseguradora', selectedOption.value, context.setDataAseguradoraActor1);
                                                                setNombreRegimen(selectedOption.label);
                                                            }}
                                                            value={values.RegimenId}
                                                            labelName='Régimen'
                                                            nameSelect='RegimenId'
                                                            className='col-12 col-md-6 mt-5'
                                                            placeHolder='Seleccione'
                                                            required={true}
                                                        />

                                                        <FormikSelect
                                                            options={context.dataAseguradoraActor1}
                                                            onChange={(selectedOption: any) => {
                                                                setFieldValue("AseguradoraId", selectedOption.value);
                                                                setNombreAseguradora(selectedOption.label);
                                                            }}
                                                            value={values.AseguradoraId}
                                                            labelName='Aseguradora'
                                                            nameSelect='AseguradoraId'
                                                            className='col-12 col-md-6 mt-5'
                                                            placeHolder='Seleccione'
                                                            required={true} />

                                                    </div>
                                                </div>
                                            )}

                                            {values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR2 && (
                                                <div className="row my-6">
                                                    <div className="row">

                                                        <FormikInputGroup
                                                            labelName='Ingrese texto de búsqueda para el nombre del prestador'
                                                            nameSelect='VcNombrePrestador'
                                                            className='input-group mb-3'
                                                            required={true}
                                                            onClickButton={() => {
                                                                context.buscarPrestadores(values.VcNombrePrestador);
                                                                setFieldValue("PrestadorId", '');
                                                                setFieldValue("SedeId", '');
                                                            }}
                                                            iconButton="bi bi-search"
                                                            textButton="Buscar"
                                                            classNameButton='btn-sm btn-primaryCustom'
                                                        />
                                                    </div>



                                                    <div className="row">
                                                        <FormikSelect
                                                            options={context.dataPrestador}
                                                            onChange={(selectedOption: any) => {
                                                                setFieldValue("PrestadorId", selectedOption.value);
                                                                setFieldValue("SedeId", '');
                                                                context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bPrestadorSede', selectedOption.value, context.setDataSede);
                                                                setNombrePrestador(selectedOption.label);
                                                            }}
                                                            value={values.PrestadorId}
                                                            labelName='Prestador'
                                                            nameSelect='PrestadorId'
                                                            className='col-12 col-md-6 mt-5'
                                                            placeHolder='Seleccione'
                                                            required={true}
                                                        />
                                                        <FormikSelect
                                                            options={context.dataSede}
                                                            onChange={(selectedOption: any) => {
                                                                setFieldValue("SedeId", selectedOption.value);
                                                                setNombreSede(selectedOption.label);
                                                            }}
                                                            value={values.SedeId}
                                                            labelName='Sede'
                                                            nameSelect='SedeId'
                                                            className='col-12 col-md-6 mt-5'
                                                            placeHolder='Seleccione'
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR3 && (
                                                <div className="row my-6">
                                                    <div className="row">
                                                        <FormikSelect
                                                            options={context.dataTipoEntidad}
                                                            onChange={(selectedOption: any) => {
                                                                setFieldValue("TipoEntidadId", selectedOption.value);
                                                                setFieldValue("EntidadId", '');
                                                                context.obtenerDataParametroDetallePorCodigoInternoDetallePadre('bentidadActor', selectedOption.value, context.setDataEntidad);
                                                                setNombreTipoEntidad(selectedOption.label);
                                                            }}
                                                            value={values.TipoEntidadId}
                                                            labelName='Tipo de entidad'
                                                            nameSelect='TipoEntidadId'
                                                            className='col-12 col-md-6 mt-5'
                                                            placeHolder='Seleccione'
                                                            required={true}
                                                        />
                                                        <FormikSelect
                                                            options={context.dataEntidad}
                                                            onChange={(selectedOption: any) => {
                                                                setFieldValue("EntidadId", selectedOption.value);
                                                                setNombreEntidad(selectedOption.label);
                                                            }}
                                                            value={values.EntidadId}
                                                            labelName='Entidad'
                                                            nameSelect='EntidadId'
                                                            className='col-12 col-md-6 mt-5'
                                                            placeHolder='Seleccione'
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {values.CodigoTipoActor == FormConstantsAtencionIndividual.CODIGO_TIPO_ACTOR4 && (
                                                <div className="row my-6">
                                                    <FormikInput
                                                        labelName='Nombre entidad'
                                                        nameSelect='VcNombreEntidad'
                                                        className='col-12 col-md-6 mt-5'
                                                        required={true}
                                                    />
                                                    <FormikInput
                                                        labelName='Nombre / Sede / Área'
                                                        nameSelect='VcNombreSede'
                                                        className='col-12 col-md-6 mt-5'
                                                        required={true}
                                                    />
                                                </div>
                                            )}

                                            <div className="row mt-5">
                                                <div className="col-12">
                                                    <CrudButton type={'button'}
                                                        className="btn-sm btn-primaryCustom float-end mx-2"
                                                        text="Agregar"
                                                        onClick={() => {
                                                            agregarActor(values);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <Container fluid className="form-group row mt-6">
                                            <Col>
                                                <DataTable
                                                    columns={columns}
                                                    data={context.dataTablaActores}
                                                    pagination
                                                    customStyles={customStyles}
                                                    paginationComponentOptions={PaginationProperties}
                                                    noDataComponent={<NoRecords />}
                                                />
                                            </Col>
                                        </Container>
                                        <FormikInput
                                            labelName='Número de actores'
                                            nameSelect='VcValidaActores'
                                            className='col-12 col-md-4 mt-5 input-validacion'
                                           
                                            readOnly={true}
                                            required={false}
                                        />

                                    </Card.Body>
                                </Card>
                            )}

                            {/* Estado */}
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Estado</Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <FormikInputFile
                                        labelName='Cargar archivos'
                                        nameSelect='Anexo'
                                        className='col-12 mt-5'
                                        accept="application/pdf"
                                        onChange={(e: any) => {
                                            setFieldValue("Anexo", e.target.files[0]);
                                        }}
                                    />

                                    <FormikSelect
                                        options={context.dataEstadoCaso}
                                        onChange={(selectedOption: any) => {
                                            setFieldValue("EstadoId", selectedOption.value);
                                        }}
                                        value={values.EstadoId}
                                        labelName='Estado del caso'
                                        nameSelect='EstadoId'
                                        className='col-12 col-md-3 mt-5 align-items-end'
                                        placeHolder='Seleccione'
                                        required={true}
                                        tabIndex={17}
                                    />
                                    {context.dataCasoCreado.id == 0 && (
                                        <div className="d-flex mt-5 float-end">
                                            <div>
                                                <CrudButton
                                                    type={'button'}
                                                    className="btn-sm btn-primaryCustom mx-2"
                                                    text="Volver"
                                                    icon="bi bi-arrow-left-circle-fill"
                                                    onClick={() => context.setPrimeraEtapaFormulario(true)}
                                                />
                                            </div>
                                            <div>
                                                <CrudButton
                                                    type={'submit'}
                                                    className="btn-sm btn-primaryCustom mx-2"
                                                    text="Guardar"
                                                    disabled={isSubmitting}
                                                    icon="bi bi-save-fill" 
                                                    onClick={() => {
                                                        setFieldValue("VcValidaActores", context.dataTablaActores.length); 
                                                    }} 
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {/*<p>Model: {JSON.stringify(context.dataCasoCreado)} </p>*/}
                                </Card.Body>
                            </Card>
                            {context.dataCasoCreado.id > 0 && (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Acciones sobre el caso</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row mt-5">

                                            <div className="col-12">
                                                <CrudButton
                                                    type={'button'}
                                                    className="btn-sm btn-primaryCustom mx-2"
                                                    text="Adjuntar Archivos"
                                                    disabled={isSubmitting}
                                                    icon="bi bi-file-earmark-pdf-fill" 
                                                    onClick={() => {
                                                        context.verAnexos(context.dataCasoCreado);

                                                    }} 
                                                />  
                                                {  context.dataEstados.filter((item: any) => item.codigo === FormConstantsAtencionIndividual.CODIGO_ESTADO_SEGUIMIENTO)[0].value == context.dataCasoCreado.estadoId && (  
                                                    <CrudButton
                                                        type={'button'}
                                                        className="btn-sm btn-primaryCustom mx-2"
                                                        text="Asignar Caso"
                                                        disabled={isSubmitting}
                                                        icon="bi bi-person" 
                                                        onClick={() => {
                                                            context.reAsignar(context.dataCasoCreado);
                                                        }} 
                                                    /> 
                                                )}
                                                <CrudButton
                                                    type={'button'}
                                                    className="btn-sm btn-primaryCustom mx-2"
                                                    text="Pasar a nuevo registro"
                                                    disabled={isSubmitting}
                                                    icon="bi bi-repeat" 
                                                    onClick={() => {
                                                        //codigo para redireccionar desde reat router dom /orientacion/individuales/registro
                                                        context.setFormPersonaVisible(false);
                                                        context.setDataCasoCreado(context.DefaultCasoResponse);
                                                        context.setPrimeraEtapaFormulario(true);

                                                        resetForm();

                                                        if (context.formRef.current) {
                                                            context.formRef.current.resetForm();
                                                            context.setTextoComprobador("");
                                                        }
                                                        navigate("/orientacion/individuales/registro");                                                         
                                                    }} 
                                                />                                                                                                                 
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>      
                            )}                              

                        </Form>
                    )}
                </Formik>
            </div>
        </section>
    );
}

