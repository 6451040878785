import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { Col, Container } from "react-bootstrap";
import DataTable, { TableColumn, ExpanderComponentProps } from "react-data-table-component";
import { getOrquestadorApiUrl } from "../../../../../_metronic/helpers/ApiOrquestadorHelper";
import { CrudButton } from "../../../../components/CrudButton";
import { customStyles, NoRecords, PaginationProperties, MAX_TEXT_LENGTH } from "../../../../components/DataTableConfig";
import { ActionModal } from "../../../../components/ActionModal";
import { PdfBase64 } from "../../../../components/PdfBase64";
import { AtencionesIndividualesFormAnexos } from "./AtencionesIndividualesFormAnexos";
import { BandejaAtencionesIndividualContextInterface } from "../submmitInformation/AtencionesIndividualesTypes";
import { bandejaIndividualContext } from "./AtencionesIndividualesBandeja";

export function AtencionesIndividualesFormOtrosCasos() {
    const bandejaIndividualCContext = useContext(bandejaIndividualContext) as BandejaAtencionesIndividualContextInterface;

    const [dataSourcePdf, setDataSourcePdf] = useState(null);
    const [modalVisiblePdf, setModalVisiblePdf] = useState(false);
    const [modalAnexos, setModalAnexos] = useState(false);

    useEffect(() => {
        bandejaIndividualCContext.obtenerDatosOtrosCasos();
    }, []);

    interface DataRow {
        id: number;
        acciones: string;
        iAnexos: string;
        dtFechaOrientacion: string;
        estado: string,
        diasSinRespuesta: any;
        diasSinRespuestaColor: any;
        tipoIdentificacion: string;
        identificacion: string;
        vcNombreCompleto: string;
        tipoSolicitud: string;
        motivo: string;
        subMotivo: string;
        usuarioRegistro: string;
        usuarioActual: string;
        txAclaracionMotivo: string;
        dtFechaRegistro: string;
        txAsuntoCorreo: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Id del caso',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-printer" onClick={() => imprimirCaso(element.id)} />
                </div>,
            sortable: false,
        },
        {
            name: 'Anexos',
            cell: (element) => <CrudButton className="btn-icon btn-sm btn-primary" text={element.iAnexos} onClick={() => verAnexos(element)} />,
            sortable: false,
        },
        {
            name: 'Fecha de caso',
            cell: (element) => <p>{element.dtFechaRegistro?.toString().substring(0, 10)}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Días sin respuesta',
            cell: (element) => <span className="badge" style={{ backgroundColor: element.diasSinRespuestaColor }}>{element.diasSinRespuesta}</span>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Tipo de identificación',
            cell: (element) => <p>{element.tipoIdentificacion}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Identificación',
            cell: (element) => <p>{element.identificacion}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Nombres y Apellidos',
            cell: (element) => <p>{element.vcNombreCompleto}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Tipo de solicitud',
            cell: (element) => <p>{element.tipoSolicitud}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Motivo',
            cell: (element) => <p>{element.motivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Sub motivo',
            cell: (element) => <p>{element.subMotivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Usuario asignado',
            cell: (element) => <p>{element.usuarioActual}</p>,
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Usuario que registró',
            cell: (element) => <p>{element.usuarioRegistro}</p>,
            sortable: false,
        },
        {
            name: 'Fecha de registro',
            cell: (element) => <p>{element.dtFechaRegistro?.toString().substring(0, 10)}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Aclaraciones',
            cell: (element) => <p>{element.txAclaracionMotivo?.length > MAX_TEXT_LENGTH ? `${element.txAclaracionMotivo.slice(0, MAX_TEXT_LENGTH)}...` : element.txAclaracionMotivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
    ];
    const CustomExpander = ({ data }: ExpanderComponentProps<DataRow>) => (
        <div>
            <p><b className="fw-bold text-azul-oscuro">Información adicional del caso:</b></p>
            <p><b className="fw-bold text-azul-oscuro">Id:</b> {data.id}</p>
            <p><b className="fw-bold text-azul-oscuro">Asunto:</b> {data.txAsuntoCorreo}</p>
            <p><b className="fw-bold text-azul-oscuro">Aclaración:</b> {data.txAclaracionMotivo}</p>
            <p><b className="fw-bold text-azul-oscuro">Tema brindado:</b> {data.tipoSolicitud}</p>
            <p><b className="fw-bold text-azul-oscuro">Motivo:</b> {data.motivo}</p>
            <p><b className="fw-bold text-azul-oscuro">Sub Motivo:</b> {data.subMotivo}</p>
            <p><b className="fw-bold text-azul-oscuro">Fecha registro:</b> {data.dtFechaRegistro.toString().substring(0, 10)}</p>
            <p><b className="fw-bold text-azul-oscuro">Fecha Orientación:</b> {data.dtFechaOrientacion.toString().substring(0, 10)}</p>
        </div>
    );

    const imprimirCaso = async (atencionGrupalId: number) => {
        await axios.get(getOrquestadorApiUrl(`AtencionWeb/pdf/${atencionGrupalId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourcePdf(response.data.pdf ?? null);
                setModalVisiblePdf(true);
                //setModalVisible(true);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    const verAnexos = (element: any) => {
        //setPersonaWebId(element.personaIndividualId);
        //setAtencionWebId(element.id);
        setModalAnexos(true);
    }

    return <>
        <Container fluid className="form-group row mt-8">
            <Col>
                <DataTable
                    columns={columns}
                    data={bandejaIndividualCContext.dataSourceOtrosCasos}
                    pagination
                    customStyles={customStyles}
                    paginationComponentOptions={PaginationProperties}
                    noDataComponent={<NoRecords />}
                    expandableRows
                    expandableRowsComponent={CustomExpander}
                />
            </Col>
        </Container>
        <ActionModal visible={modalAnexos} title={'Anexos del caso'} size='xl' handleClose={setModalAnexos}>
            <AtencionesIndividualesFormAnexos />
        </ActionModal>
        <ActionModal visible={modalVisiblePdf} title={'Documento PDF del caso'} size={'xl'} handleClose={setModalVisiblePdf}>
            <PdfBase64 pdfBase64={dataSourcePdf} />
        </ActionModal>
    </>
}

