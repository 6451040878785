/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { useSelector } from 'react-redux';
import { SiCuentanosStore } from 'src/app/redux/store';
import Gravatar from 'react-gravatar';
import { authProvider } from 'src/app/utilities';
const HeaderUserMenu: FC = () => {
  const userState = useSelector((state:SiCuentanosStore)=> state.user); 
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 menu-user-login'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <Gravatar email={userState?.vcCorreo} />
            
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userState?.vcPrimerNombre}  {userState?.vcSegundoNombre} {userState?.vcPrimerApellido} {userState?.vcSegundoApellido}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {userState?.vcCorreo}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={authProvider.logout} className='menu-link px-5'>
          <i className="bi bi-box-arrow-left"></i>
          Cerrar Sesión
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
