// Render Prop
import { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikCheckbox, FormikInput, FormikTextArea } from '../../../components/FormikInput';
import { parametroContext } from './Parametros';
import { FormConstantsValidation } from '../../../components/FormConstantsValidation';
const ParametroDetalleSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(150, 'Máximo 150 caracteres')
    .required('Campo obligatorio'),
  TxDescripcion: Yup.string()
    .required('Campo obligatorio')
    .min(2, 'Mínimo 2 caracteres')
    .max(500, 'Máximo 500 caracteres'),
  VcCodigoInterno: Yup.string()
    .required('Campo obligatorio')
    .max(500, 'Máximo 500 caracteres')
});

export default function ParametrosDetalleForm() {

  const parametroCContext = useContext(parametroContext);

  return (
    <div>
      <Formik
        initialValues={parametroCContext.dataDetalle}
        validationSchema={ParametroDetalleSchema}
        onReset={(values, formProps) => {
          parametroCContext.setModalVisibleParametroDetalle(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.RangoDesde = values.RangoDesde == "" ? 0 : values.RangoDesde;
          values.RangoHasta = values.RangoHasta == "" ? 0 : values.RangoHasta;
          values.IdPadre = values.IdPadre == 0 ? null : values.IdPadre;
          if (values.Id == 0) {

            parametroCContext.createParametroDetalle(values);
          } else {
            parametroCContext.updateParametroDetalle(values);
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <div className="form-group row">
              <FormikInput
                type='text'
                labelName='Nombre'
                nameSelect='VcNombre'
                className='col-12'
                required={true}
                placeholder=""
              />
              <FormikTextArea
                labelName='Descripción'
                nameSelect='TxDescripcion'
                className='col-12 mt-5'
                required={true}
                rows="4"
              />
            </div>
            <div className='form-group row mt-5'>
              <div className="col-12">
                <FormikSelect
                  options={parametroCContext.dataPadre}
                  onChange={selectedOption => setFieldValue("IdPadre", selectedOption.value)}
                  value={values.IdPadre}
                  labelName='Articulado a'
                  nameSelect='IdPadre'
                  className='col-12 mt-5'
                  placeHolder=''
                />
              </div>
            </div>
            <div className='form-group row mt-5'>

              <FormikInput
                type='text'
                labelName='Código interno'
                nameSelect='VcCodigoInterno'
                className='col-6'
                required={true}
                placeholder=""
              />
              <FormikInput
                type='number'
                labelName='D. Código interno'
                nameSelect='DCodigoIterno'
                className='col-6'
                required={false}
                placeholder=""
                onKeyDown={FormConstantsValidation.handleOnlyNumbers}
              />
            </div>
            <div className='form-group row mt-5'>
              <FormikCheckbox
                labelName='Estado'
                nameSelect='BEstado'
                className='col-12'
                value={values.BEstado}
                required={true}
              />
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
