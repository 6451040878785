import React, { useState, useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import axios from 'axios'
import Swal from "sweetalert2";
import { ActionModal } from "../../../../components/ActionModal";
import FestivosForm from "./FestivosForm";
import moment from "moment";
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";
import 'moment/locale/es'
import DataTable, { TableColumn } from "react-data-table-component";
import { CrudButton } from "../../../../components/CrudButton";
import { customStyles, NoRecords, PaginationProperties } from "../../../../components/DataTableConfig";
moment.locale('es')

export function Festivos(props: any) {
    const [dataSourceAnios, setDataSourceAnios] = useState([2022, 2023, 2024, 2025]);
    const [dataSource, setDataSource] = useState([]);
    const [anioId, setAnioId] = useState(0);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [form, setForm] = useState({
        Id: 0,
        ParametroId: 23,
        VcNombre: '',
        TxDescripcion: '',
        VcCodigoInterno: '',
        BEstado: true
    });

    const obtenerDiasFestivos = async (vcCodigoInterno: string) => {
        if (vcCodigoInterno != '0') {
            await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInternoDetalle/bDiasFestivos/${vcCodigoInterno}`))
                .then((response) => {
                    // Obtenemos los datos
                    const data = response.data.models ? response.data.models : [];
                    setDataSource(data);
                })
                .catch(e => {
                    // Capturamos los errores
                    console.log(e);
                });
        }
    }

    const eliminarDiaFestivo = async (id: number) => {
        await axios.delete(getParametroApiUrl(`ParametroDetalle/DeleteParametroDetalle/${id}`))
            .then((response) => {
                if (response.status == 200) {
                    obtenerDiasFestivos(anioId.toString());
                }
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const handleChangeAnioId = (e: any) => {
        setAnioId(e.target.value);
        setDataSource([]);
        obtenerDiasFestivos(e.target.value);
    }

    const nuevo = () => {
        setForm({
            Id: 0,
            ParametroId: 23,
            VcNombre: '',
            TxDescripcion: '',
            VcCodigoInterno: '',
            BEstado: true
        });
        setModalTitle('Agregar día festivo');
        setModalVisible(true);
    }

    const editar = (registro: any) => {
        setForm({
            Id: registro.id,
            ParametroId: registro.parametroId,
            VcNombre: registro.vcNombre,
            TxDescripcion: registro.txDescripcion,
            VcCodigoInterno: registro.vcCodigoInterno,
            BEstado: registro.bEstado
        });
        setModalTitle('Editar día festivo');
        setModalVisible(true);
    }

    const eliminar = (registro: any) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            //cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            focusConfirm: false,
            focusCancel: true,
            focusDeny: true
        }).then((result: any) => {
            if (result.isConfirmed) {
                eliminarDiaFestivo(registro.id)
            }
        })
    }

    useEffect(() => {

    }, []);

    interface DataRow {
        vcNombre: string;
        txDescripcion: string;
        acciones: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Día',
            cell: (element) => <p>{element.vcNombre}</p>,
            selector: row => row.vcNombre,
            sortable: true,
        },
        {
            name: 'Descripción',
            cell: (element) => <p>{moment(element.vcNombre).format('dddd, DD [de] MMMM [de] YYYY')}: {element.txDescripcion}</p>,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" onClick={() => editar(element)} />
                    {" "}
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" onClick={() => eliminar(element)} />
                </div>,
            sortable: false,
        }
    ];

    return <>
        <Card>
            <Card.Header>
                <Card.Title>D&iacute;as no h&aacute;biles</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <div className="col-sm-4 mt-3">
                        <select className="form-control form-control-solid" name="AnioId"
                            placeholder="Seleccione año"
                            value={anioId} onChange={handleChangeAnioId}>
                            <option value="0" disabled>Seleccione año</option>
                            {dataSourceAnios.map((value: any) => {
                                return (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-sm-4 mt-3">
                        <a href="#" className="btn btn-icon btn-primaryCustom"
                            onClick={() => nuevo()}>
                            <i className="bi bi-plus-lg fs-5"></i>
                        </a>
                    </div>
                </div>

                <Container className="row mt-8">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={dataSource}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                        />
                    </Col>
                </Container>
            </Card.Body>
        </Card>

        <ActionModal visible={modalVisible} title={modalTitle} handleClose={setModalVisible}>
            <FestivosForm anioId={anioId} obtenerDiasFestivos={obtenerDiasFestivos} setModalVisible={setModalVisible} form={form} />
        </ActionModal>
    </>
}

