import { loadAbort } from "../utilities/load-abort-axios.utility";
import axios, { AxiosRequestConfig } from 'axios';
import { ListModelResponse, Menu, UserLogin } from "../models";
import { getOrquestadorApiUrl } from "../utilities";


const MenuSC = (user: UserLogin) => {

    const controller = loadAbort();
    return {
      call: axios.get<ListModelResponse<Menu>>(`${getOrquestadorApiUrl('Actividad/porUsuarioId')}/${user.id}`, { signal: controller.signal } as AxiosRequestConfig),
      controller
    };
};

export default MenuSC;