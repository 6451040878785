import React, { useState, useEffect  } from "react";
import { Card, Col, Modal, Container, } from "react-bootstrap";
import axios from 'axios'
import ParametrosForm from "./ParametrosForm";
import ParametrosDetalleForm from "./ParametrosDetalleForm";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getParametroApiUrl } from "../../../../_metronic/helpers/ApiParametroHelpers";
import { CrudButton } from '../../../components/CrudButton';

import DataTable, { TableColumn } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles } from "../../../components/DataTableConfig";
import { SwalToast } from "../../../components/SwalToast";

interface formParametro {
    Id: number;
    VcNombre: string;
    VcCodigoInterno: string;
    BEstado: boolean;
} 

interface formParametroDetalle {
    Id: number;
    ParametroId: number;
    VcNombre: string;
    TxDescripcion: string;
    VcCodigoInterno: string;
    DCodigoInterno: string;
    BEstado: true,
    RangoDesde: number;
    RangoHasta: number;
    IdPadre: number;
}

const dataFormParametroInicial: formParametro = {
    Id: 0,
    VcNombre: '',
    VcCodigoInterno: '',
    BEstado: true
};

const dataFormParametroDetalleInicial: formParametroDetalle = {
    Id: 0,
    ParametroId: 0,
    VcNombre: '',
    TxDescripcion: '',
    VcCodigoInterno: '',
    DCodigoInterno: '',
    BEstado: true,
    RangoDesde: 0,
    RangoHasta: 0,
    IdPadre: 0
};


interface ParametroContextInterface {
    createParametro:any; 
    updateParametro:any; 
    setModalVisibleParametro:any; 
    data:any;
    createParametroDetalle:any; 
    updateParametroDetalle:any;
    setModalVisibleParametroDetalle:any;
    dataDetalle:any;
    dataPadre:any;
}



export const parametroContext = React.createContext<ParametroContextInterface | null>(null);

export function Parametros(props: any) {
    //const selectRef = useRef(null);
    const navigate = useNavigate();
    const [param, setParam] = useState<any>(0);
    const [modalVisibleParametro, setModalVisibleParametro] = useState(false);
    const [modalVisibleParametroDetalle, setModalVisibleParametroDetalle] = useState(false);
    const [dataSourceParametros, setDataSourceParametros] = useState([]);
    const [dataSourceParametrosDetalle, setDataSourceParametrosDetalle] = useState([]);
    const [dataSourceParametrosDetalleTodos, setDataSourceParametrosDetalleTodos] = useState<any>([]);
    const [dataPadre, setDataPadre] = useState<any>([]);

    const [formParametro, setFormParametro] = useState(dataFormParametroInicial);

    const [formParametroDetalle, setFormParametroDetalle] = useState(dataFormParametroDetalleInicial);

    const obtenerDatosParametros = async () => {
        await axios.get(getParametroApiUrl('Parametro'))
            .then((response) => {
                const data = response.data.models ? response.data.models : [];
                // Obtenemos los datos
                setDataSourceParametros(data);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosParametrosDetalle = async () => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porParametroId/${param.id}`))
            .then((response) => {
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });

                // Obtenemos los datos
                setDataSourceParametrosDetalle(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se encontraron registros asociados'
                });                 
            });
    }

    const obtenerTodosDatosParametrosDetalle = async (id: number) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/GetParametroDetalle/`))
            .then((response) => {
                // Obtenemos los datos
                const data: any = [];
                response.data?.models.forEach((element: any) => {
                    if (element.id != id) {
                        data.push({ value: element.id, label: element.vcNombre });
                    }
                });
                setDataPadre(data);
                //setDataSourceParametrosDetalleTodos(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    const createParametro = async (data: formParametro) => {
        await axios.post(getParametroApiUrl('Parametro'), data)
            .then((response) => {
                setModalVisibleParametro(false);
                obtenerDatosParametros();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede crear el registro'
                });                  
            });
    }

    const updateParametro = async (data: formParametro) => {
        await axios.put(getParametroApiUrl(`Parametro/${data.Id}`) , data)
            .then((response) => {
                setModalVisibleParametro(false);
                obtenerDatosParametros();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede editar el registro'
                });                 
            });
    }

    const deleteParametro = async () => {
        await axios.delete(getParametroApiUrl(`Parametro/${ param.id}`))
            .then((response) => {
                setParam("");
                setDataSourceParametrosDetalle([]);
                obtenerDatosParametros();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede eliminar debido a la integridad referencial'
                });                 
            });
    }

    useEffect(() => {
        obtenerDatosParametros();
    }, []);

    const nuevoParametro = () => {
        setFormParametro({
            Id: 0,
            VcNombre: '',
            VcCodigoInterno: '',
            BEstado: true
        });
        setModalVisibleParametro(true);
    }

    const editarParametro = () => {
        if (param?.id > 0) {
            setFormParametro({
                Id: param.id,
                VcNombre: param.vcNombre,
                VcCodigoInterno: param.vcCodigoInterno,
                BEstado: param.bEstado
            });
            setModalVisibleParametro(true);
        }
    }
    const eliminarParametro = () => {
        if (param?.id == 0) {
            Swal.fire({
                title: 'No Valido',
                text: "Seleccione un parametro para eliminar.",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
            });
            return;
        }

        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sí, eliminar',
            focusConfirm: false,
            focusCancel: true,
            focusDeny: true
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteParametro();
                              
            }
        })
    }

    const irExcel = () => {
        navigate('/parametrosexcel');
    }

    // const handleChangeParamId = (e: any) => {
    //     setParamId(e.target.value);
    //     setDataSourceParametrosDetalle([]);
    // }

    const buscarParametroDetalle = () => {
        if (param?.id == 0) {
            Swal.fire({
                title: 'No Valido',
                text: "Seleccione un parametro.",
                icon: 'info',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
            });
            return;
        }

        obtenerDatosParametrosDetalle();
    }

    const nuevoParametroDetalle = () => {
        if (!(param?.id > 0)) {
            Swal.fire({
                title: 'No Valido',
                text: "Seleccione un parametro.",
                icon: 'info',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
            });
            return;
        }

        obtenerTodosDatosParametrosDetalle(0);

        setFormParametroDetalle({
            Id: 0,
            ParametroId: param?.id,
            VcNombre: '',
            TxDescripcion: '',
            VcCodigoInterno: '',
            DCodigoInterno: '',
            BEstado: true,
            RangoDesde: 0,
            RangoHasta: 0,
            IdPadre: 0
        });
        setModalVisibleParametroDetalle(true);
    }

    const editarParametroDetalle = (registro: any) => {
        setFormParametroDetalle({
            Id: registro.id,
            ParametroId: registro.parametroId,
            VcNombre: registro.vcNombre,
            TxDescripcion: registro.txDescripcion,
            VcCodigoInterno: registro.vcCodigoInterno,
            DCodigoInterno: registro.dCodigoIterno,
            BEstado: registro.bEstado,
            RangoDesde: registro.rangoDesde,
            RangoHasta: registro.rangoHasta,
            IdPadre: registro.idPadre
        });

        obtenerTodosDatosParametrosDetalle(registro.id);

        setModalVisibleParametroDetalle(true);
    }

    const createParametroDetalle = async (data: formParametroDetalle) => {
        await axios.post(getParametroApiUrl('ParametroDetalle/PostParametroDetalle/'), data)
            .then((response) => {
                setModalVisibleParametroDetalle(false);
                obtenerDatosParametrosDetalle();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede crear el registro'
                });                 
            });
    }

    const updateParametroDetalle = async (data: formParametroDetalle) => {
        await axios.put(getParametroApiUrl(`ParametroDetalle/PutParametroDetalle/${data.Id}`), data)
            .then((response) => {
                setModalVisibleParametroDetalle(false);
                obtenerDatosParametrosDetalle();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede editar el registro'
                });                 
            });
    }

    const datosParametroContext : ParametroContextInterface = {
        createParametro:createParametro, 
        updateParametro:updateParametro, 
        setModalVisibleParametro:setModalVisibleParametro, 
        data:formParametro,
        createParametroDetalle:createParametroDetalle, 
        updateParametroDetalle:updateParametroDetalle, 
        setModalVisibleParametroDetalle:setModalVisibleParametroDetalle, 
        dataDetalle:formParametroDetalle, 
        dataPadre:dataPadre,
    }

    interface DataRow {
        id: number;
        vcNombre: string;
        txDescripcion: string;
        vcCodigoInterno:string;
        dCodigoIterno:string;
        padre:string;
        padreTipo:string;
        bEstado:string;
        acciones:string;
    }

    const columns: TableColumn<DataRow>[] = [
    {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Nombre',
            cell:(element) => <p>{element.vcNombre}</p>,
            sortable: true,
        },
        {
            name: 'Descripción',
            selector: row => row.txDescripcion,
            width: "120px",
            wrap: true,
            sortable: true,
        },
        {
            name: 'Código Interno Texto',
            selector: row => row.vcCodigoInterno,
            sortable: true,
            width: "120px",
            wrap: true,            
        },
        {
            name: 'Código Interno Número',
            selector: row => row.dCodigoIterno,
            sortable: true,
            width: "120px",
            wrap: true,
        },
        {
            name: 'Articulado a (Padre)',
            cell:(element) => <p>{element.padre} - {element.padreTipo}</p>,
            sortable: false,
            width: "120px",
            wrap: true,
        },
        {
            name: 'Estado',
            cell:(element) => <p>{element.bEstado ? 'Activo' : 'Inactivo'}</p>,
            sortable: false,
        },
        {
            name: 'Acciones',
            cell:(element) =>  
                        <>
                            <CrudButton onClick={() => editarParametroDetalle(element)} className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" />
                        </>,
            sortable: false,
        },
    ];



    return (
        <parametroContext.Provider value={datosParametroContext}>
            <Card>
                <Card.Body>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <Select
                                name='selectParamId'
                                placeholder='Seleccione parámetro'
                                getOptionLabel={(option: any) => option.vcNombre}
                                getOptionValue={(option: any) => option.id}
                                onChange={(value: any) => {
                                    setParam(value);
                                    setDataSourceParametrosDetalle([]);
                                }}
                                options={dataSourceParametros}
                                value={param}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <a href="#" className="btn btn-icon btn-primary"
                                onClick={() => buscarParametroDetalle()}>
                                <i className="bi bi-search fs-5"></i>
                            </a>
                            <a href="#" className="btn btn-icon btn-primaryCustom"
                                onClick={() => nuevoParametro()}>
                                <i className="bi bi-plus-lg fs-5"></i>
                            </a>
                            <a href="#" className="btn btn-icon btn-primary"
                                onClick={() => editarParametro()}>
                                <i className="bi bi-pencil-fill fs-5"></i>
                            </a>
                            <a href="#" className="btn btn-icon btn-danger"
                                onClick={() => eliminarParametro()}>
                                <i className="bi bi-trash-fill fs-5"></i>
                            </a>

                            <a href="#" className="btn btn-icon btn-success ml-5"
                                onClick={() => irExcel()}>
                                <i className="bi bi-file-excel fs-5"></i>
                            </a>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            <Card className="mt-5">
                <Card.Header>
                    <Card.Title className="text-azul-oscuro">Resultados</Card.Title>
                    <a href="#" className="btn btn-icon btn-sm btn-primaryCustom flex-end mt-5"
                        onClick={() => nuevoParametroDetalle()}>
                        <i className="bi bi-plus-lg fs-5"></i>
                    </a>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <Col>
                            <DataTable
                                columns={columns}
                                data={dataSourceParametrosDetalle}
                                pagination
                                customStyles={customStyles}
                                striped={true}
                                paginationComponentOptions={PaginationProperties}
                                noDataComponent={<NoRecords />}
                            />
                        </Col>
                    </Container>                 
                </Card.Body>
            </Card>

            <Modal show={modalVisibleParametro}>
                <Modal.Header>
                    <div>
                        <h5 className="text-azul-oscuro">{formParametro.Id > 0 ? 'EDITAR' : 'AGREGAR'} PARAMETRO</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ParametrosForm />
                </Modal.Body>
            </Modal>

            <Modal show={modalVisibleParametroDetalle}>
                <Modal.Header>
                    <div>
                        <h5 className="text-azul-oscuro">{formParametroDetalle.Id > 0 ? 'EDITAR' : 'AGREGAR'} PARAMETRO DETALLE</h5>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <ParametrosDetalleForm  />
                </Modal.Body>
            </Modal>
        </parametroContext.Provider>
    );
}

