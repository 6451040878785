import React, { useState, useEffect, useContext } from "react";
import { Row, Card, Col, Button, Modal, Container, Table, FormGroup } from "react-bootstrap";
import { Field, Form, Formik, ErrorMessage } from 'formik';
import axios from 'axios'
import Swal from "sweetalert2";
import * as Yup from 'yup';
import * as moment from 'moment';
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";


const FestivoSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(150, 'Máximo 50 caracteres')
    .required('Campo obligatorio'),
  TxDescripcion: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(500, 'Máximo 20 caracteres')
    .required('Campo obligatorio'),
});

export default function FestivosForm({ anioId, obtenerDiasFestivos, setModalVisible, form }) {

  const create = async (data) => {
    await axios.post(getParametroApiUrl('ParametroDetalle/PostParametroDetalle'), data)
      .then((response) => {
        if (response.status == 201) {
          obtenerDiasFestivos(anioId);
          setModalVisible(false);
        }
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const update = async (data) => {
    await axios.put(getParametroApiUrl(`ParametroDetalle/PutParametroDetalle/${data.Id}`), data)
      .then((response) => {
        console.log(response);
        if (response.status == 200) {
          obtenerDiasFestivos(anioId);
          setModalVisible(false);
        }
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  return (
    <div>
      <Formik
        initialValues={{ Id: form.Id, ParametroId: form.ParametroId, VcNombre: form.VcNombre, TxDescripcion: form.TxDescripcion, VcCodigoInterno: form.VcCodigoInterno, BEstado: form.BEstado }}
        validationSchema={FestivoSchema}
        onReset={(values, formProps) => {
          setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          values.VcNombre = moment(values.VcNombre).format('YYYY-MM-DD');
          values.VcCodigoInterno = moment(values.VcNombre).format('YYYY');
          console.log(values)

          if (values.Id == 0) {
            create(values);
          } else {
            update(values);
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className='form-group row'>
              <div className="col-12 col-sm-6">
                <label className="text-primary">Fecha</label>
                <Field className="form-control form-control-solid" type="date" name="VcNombre" placeholder="Seleccione" />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <ErrorMessage name="VcNombre" />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5">
                <label className="text-primary">Descripci&oacute;n</label>
                <Field className="form-control form-control-solid" component="textarea" name="TxDescripcion" placeholder="Seleccione" />
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <ErrorMessage name="TxDescripcion" component="div" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

