import { useState, useEffect, useContext } from "react";
import { Col, Container } from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import Swal from "sweetalert2";
import DataTable, { TableColumn } from "react-data-table-component";
import { InputFilter } from "src/app/components";
import { FormikInput } from "src/app/components/FormikInput";
import { FormikSelect } from "src/app/components/FormikSelect";
import { CrudButton } from "src/app/components/CrudButton";
import { NoRecords, PaginationProperties, customStyles } from "src/app/components/DataTableConfig";
import { AtencionesWebContextInterface } from "../AtencionesWebTypes";
import { AtencionWebContext } from "./AtencionesWeb";


const AtencionWebSchema = Yup.object().shape({
    UsuarioAsigna: Yup.string().required('Campo obligatorio'),
    UsuarioActualId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    UsuarioAsignaId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        })
});

export function AtencionesWebFormReasignar() {

    const context = useContext(AtencionWebContext) as AtencionesWebContextInterface;


    useEffect(() => {
        context.obtenerDatosReasignados();
        context.obtenerDatosUsuarios();
    }, []);

    const initialValues = {
        Id: 0,
        AtencionWebId: context.dataCasoCreado.id,
        UsuarioAsigna: context.UsuarioLogueado,
        UsuarioAsignaId: context.UsuarioId,
        UsuarioActualId: undefined
    }

    interface DataRow {
        usuarioAsigna: string;
        usuarioActual: string;
        dtFechaAsignacion: string;
        dtFechaReAsignacion: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Usuario Asigna',
            cell: (element) => <p>{element.usuarioAsigna}</p>,
            sortable: false,
        },
        {
            name: 'Usuario Actual',
            cell: (element) => <p>{element.usuarioActual}</p>,
            sortable: false,
        },
        {
            name: 'Fecha Asignación',
            cell: (element) => <p>{element.dtFechaAsignacion.toString().substring(0, 10)}</p>,
            sortable: true,
        },
        {
            name: 'Fecha Reasignación',
            cell: (element) => <p>{element.dtFechaReAsignacion}</p>,
            sortable: true,
        },
    ];



    return <>
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...initialValues }}
                onReset={(values, formProps) => {

                }}
                validationSchema={AtencionWebSchema}
                onSubmit={(values: any, { setSubmitting }) => {
                    Swal.fire({
                        title: 'Confirmar guardado',
                        text: "¿Desea guardar estos datos?",
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí, guardar',
                        cancelButtonText: 'Cancelar'
                    }).then(async (result: any) => {
                        if (result.isConfirmed) {
                            const payload: any = {
                                Id: 0,
                                AtencionWebId: context.dataCasoCreado.id,
                                UsuarioActualId: values.UsuarioActualId,
                                UsuarioAsignaId: values.UsuarioAsignaId,
                            }
                            context.createReasignacion(payload);
                        }
                    })
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    setFieldValue,
                    values
                }) => (
                    <Form>
                        <div className="row">
                            <FormikInput
                                labelName='Id del caso'
                                nameSelect='AtencionWebId'
                                className='col-12 col-md-3 mt-5'
                                readOnly={true}
                                required={true}
                            />
                            <FormikInput
                                labelName='Usuario Actual'
                                nameSelect='UsuarioAsigna'
                                className='col-12 col-md-3 mt-5'
                                readOnly={true}
                                required={true}
                            />
                            <FormikSelect
                                options={context.dataUsuarios}
                                onChange={(selectedOption: any) => {
                                    setFieldValue("UsuarioActualId", selectedOption.value);
                                }}
                                value={values.UsuarioActualId}
                                labelName='Asignar a'
                                nameSelect='UsuarioActualId'
                                className='col-12 col-md-6 mt-5'
                                placeHolder='Seleccione'
                                required={true}
                            />
                            <div className="col-12 col-md-4 mt-5">
                                <label className="form-label text-azul-oscuro d-block">Asignar</label>
                                <CrudButton type={'submit'} className="btn-sm btn-primaryCustom" text="Guardar" disabled={isSubmitting} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            {context.dataSourceReasignacion.length > 0 ?
                <InputFilter
                    valueFilter={context.valueFilter}
                    setValueFilter={context.setValueFilter}
                    onChange={context.filterDataTable}
                    nameFile="Reasignaciones Atenciones Web"
                    dataSource={context.dataSourceReasignacionFilter}
                />
                : null
            }
            <Container className="form-group row mt-8">
                <Col>
                    <DataTable
                        columns={columns}
                        data={context.dataSourceReasignacionFilter}
                        pagination
                        customStyles={customStyles}
                        striped={true}
                        paginationComponentOptions={PaginationProperties}
                        noDataComponent={<NoRecords />}
                    />
                </Col>
            </Container>
        </div>
    </>
}

