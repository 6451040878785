import { createSlice } from '@reduxjs/toolkit';


export const menuSlice = createSlice({
  name: 'menu',
  initialState: [],
  reducers: {
    createMenu: (state, action) => action.payload,
    modifyMenu: (state, action) => ({ ...state, ...action.payload }),
    resetMenu: () => []
  }
});

export const { createMenu, modifyMenu, resetMenu } = menuSlice.actions;

export default menuSlice.reducer;