import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import { authProvider, removeAuth } from '../../utilities';
export default function Logout() {


  useEffect(() => {
    removeAuth();
    authProvider.logout();    
  }, [])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
