import { useState, useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import axios from 'axios'
import Select from "react-select";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { SwalToast } from "../../../components/SwalToast";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles, NoRecords, PaginationProperties } from "../../../components/DataTableConfig";


export function RolesActividades(props: any) {
    const [RolItem, setRolItem] = useState<any>({});
    const [dataSourceRoles, setDataSourceRoles] = useState([]);
    const [dataSourceActividades, setDataSourceActividades] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const obtenerDatosRoles = async () => {
        await axios.get(getAdministradorApiUrl('Rol'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models || []
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, moduloId: element.moduloId });
                });
                setDataSourceRoles(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosActividadesPorModulo = async (rolId: number, moduloId: number) => {
        setDataSource([]);
        await axios.get(getAdministradorApiUrl(`Actividad/porModuloId/${moduloId}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray = response.data.models || [];
                dataArray.map((item: any) => {
                    item.asignar = item.actividadRoles?.filter((act: any) => act.actividadId == item.id && act.rolId == rolId)[0] ? true : false;
                    return item;
                });
                setDataSource(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.status);
                setDataSource([]);
            });
    }

    const createActRol = async (data: any) => {
        await axios.post(getAdministradorApiUrl('ActividadRol'), data)
            .then((response) => {
                //onChangeOk(data.actividadId, true);
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede crear el registro'
                });
            });
    }

    const deleteActRol = async (data: any) => {
        await axios.delete(getAdministradorApiUrl(`ActividadRol/${data.rolId}/${data.actividadId}`))
            .then((response) => {
                //onChangeOk(data.actividadId, false);
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede eliminar el registro'
                });
            });
    }

    const onChangeChecked = (element: any, $event: any) => {
        const payload = {
            actividadId: element.id,
            rolId: RolItem.value
        }

        onChangeOk(payload.actividadId, $event.target.checked);

        if ($event.target.checked) {
            createActRol(payload);
        } else {
            deleteActRol(payload);
        }
    }

    const onChangeOk = (id: number, value: boolean) => {
        const data: any = [...dataSource];
        for (let i = 0; i < data.length; i++) {
            if (data[i].id == id) {
                data[i].asignar = value;
                break;
            }
        }
        setDataSource(data);
    }

    useEffect(() => {
        obtenerDatosRoles();
    }, []);

    interface DataRow {
        id: number;
        asignar: boolean;
        vcNombre: string;
        vcDescripcion: string;
        vcRedireccion: string;
        modulo: any;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: '#',
            cell: (element) => <p>{element.id}</p>,
            sortable: true,
        },
        {
            name: 'Asignar',
            cell: (element) =>
                <div className='form-check form-switch form-switch-lg'>
                    <input type={'checkbox'} className="form-check-input" checked={element.asignar} name={'check' + element.id} onChange={($event: any) => onChangeChecked(element, $event)} />
                    {element.asignar ? 'Si' : 'No'}
                </div>,
            sortable: true,
        },
        {
            name: 'Nombre',
            cell: (element) => <p>{element.vcNombre}</p>,
            sortable: true,
        },
        {
            name: 'Descripción',
            cell: (element) => <p>{element.vcDescripcion}</p>,
            sortable: true,
        },
        {
            name: 'Ruta',
            cell: (element) => <p>{element.vcRedireccion}</p>,
            sortable: true,
        },        
        {
            name: 'Módulo',
            cell: (element) => <p>{element.modulo?.vcNombre}</p>,
            sortable: true,
        }
    ];

    return <>
        <Card>
            <Card.Header>
                <Card.Title>ROLES</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <div className="col-12 col-md-7">
                        <Select
                            name='selectParamId'
                            placeholder='Seleccione rol'
                            onChange={(option: any) => {
                                setRolItem(option)
                                obtenerDatosActividadesPorModulo(option.value, option.moduloId);
                            }}
                            options={dataSourceRoles}
                            value={RolItem}
                        />
                    </div>
                </div>
            </Card.Body>
        </Card>

        <Card className="mt-5">
            <Card.Header>
                <Card.Title>ACTIVIDADES</Card.Title>
            </Card.Header>
            <Card.Body>
                <Container className="row">
                    <Col>
                        <DataTable
                            columns={columns}
                            data={dataSource}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                        />
                    </Col>
                </Container>
            </Card.Body>
        </Card>
    </>
}

