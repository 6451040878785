import { UserSC } from "../models";
import { nameSplitter } from "../utilities";


const createUserAdapter = (user:any) =>{
    let nombres = nameSplitter(user.given_name);
    let apellidos = nameSplitter(user.family_name);
    const formattedUser: UserSC = {
        VcIdAzure: user.oid,
        VcCorreo: user.emails[0],
        VcPrimerNombre: nombres.first,
        VcPrimerApellido: apellidos.first,
        VcSegundoNombre: nombres.second,
        VcSegundoApellido: apellidos.second,
        VcIdpAzure: user.idp,
    }
    return formattedUser;
}
export default createUserAdapter;