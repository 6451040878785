import { useState, useEffect } from "react";
import { Card, Col, Container } from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { FormikSelect } from '../../../../components/FormikSelect';
import { FormikInput } from '../../../../components/FormikInput';
import { CrudButton } from "../../../../components/CrudButton";
import { getOrquestadorApiUrl } from "../../../../../_metronic/helpers/ApiOrquestadorHelper";
import { ActionModal } from "../../../../components/ActionModal";
import moment from "moment";
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";
import React from "react";
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles, MAX_TEXT_LENGTH } from "../../../../components/DataTableConfig";
import { PdfBase64 } from "../../../../components/PdfBase64";
import { BandejaAtencionesIndividualContextInterface } from "../submmitInformation/AtencionesIndividualesTypes";
import { AtencionesIndividualesFormReasignar } from "./AtencionesIndividualesFormReasignar";
import { AtencionesIndividualesFormSeguimiento } from "./AtencionesIndividualesFormSeguimiento";
import { getAdministradorApiUrl } from "../../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getOrientacionEInformacionApiUrl } from "../../../../../_metronic/helpers/ApiOrientacionEInformacionHelpers";
import Swal from "sweetalert2";
import { AtencionesIndividualesFormOtrosCasos } from "./AtencionesIndividualesFormOtrosCasos";
import { AtencionesIndividualesFormAnexos } from "./AtencionesIndividualesFormAnexos";
import { FormConstantsValidation } from "../../../../components/FormConstantsValidation";
import { InputFilter } from "../../../../components/Input";
import { CustomExpanderGeneric } from "src/app/utilities";
import { useSelector } from "react-redux";
import { SiCuentanosStore } from "src/app/redux/store";



export const bandejaIndividualContext = React.createContext<BandejaAtencionesIndividualContextInterface | null>(null);
const currentDate = moment();

const BandejaIndividualSchema = Yup.object().shape({
    DtFechaInicio: Yup.date()
        .required('Campo obligatorio')
        .max(currentDate, 'La fecha no puede ser mayor a la fecha actual')
        .test('valida_fecha', 'Fecha inicio no puede ser mayor a fecha fin', function (dtFechaInicio: any) {
            const refDtFechaFin: string = this.resolve(Yup.ref('DtFechaFin'));
            var momentA = moment(dtFechaInicio, "DD/MM/YYYY");
            var momentB = moment(refDtFechaFin, "DD/MM/YYYY");
            console.log(momentA > momentB);
            if (momentA > momentB) {

                return false;
            }
            return true;
        }),
    DtFechaFin: Yup.date()
        .required('Campo obligatorio')
        .test('valida_fecha', 'Fecha fin no puede ser menor a fecha inicio', function (dtFechaFin: any) {
            const refDtFechaInicio: string = this.resolve(Yup.ref('DtFechaInicio'));
            var momentA = moment(dtFechaFin, "DD/MM/YYYY");
            var momentB = moment(refDtFechaInicio, "DD/MM/YYYY");
            //console.log(momentB < momentA);
            if (momentA < momentB) {

                return false;
            }
            return true;
        }),
    EstadoId: Yup.number()
        .min(0, "Campo obligatorio"),
    VcDocumento: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        }),
    UsuarioId: Yup.number()
        .min(0, "Campo obligatorio")
});

export function AtencionesIndividualesBandeja(props: any) {
    const userDto = useSelector((state:SiCuentanosStore)=> state.user); 
    const [modo, setModo] = useState('');
    const [AtencionIndividualId, setAtencionIndividualId] = useState([]);
    const [PersonaIndividualId, setPersonaIndividualId] = useState([]);
    const [dataEstados, setDataEstados] = useState<any>([]);
    const [dataSource, setDataSource] = useState([]);
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [modalReAsignar, setModalReAsignar] = useState(false);
    const [dataSourceReasignacion, setDataSourceReasignacion] = useState([]);
    const [modalSeguimiento, setModalSeguimiento] = useState(false);
    const [dataSourceSeguimiento, setDataSourceSeguimiento] = useState([]);
    const [modalOtrosCasos, setModalOtrosCasos] = useState(false);
    const [modalAnexos, setModalAnexos] = useState(false);
    const [dataSourceAnexos, setDataSourceAnexos] = useState([]);
    const [dataSourcePdf, setDataSourcePdf] = useState(null);
    const [modalVisiblePdf, setModalVisiblePdf] = useState(false);
    const [dataSourceOtrosCasos, setDataSourceOtrosCasos] = useState([]);
    const [dataSourceFilter, setDataSourceFilter] = useState([]);
    const [valueFilter, setValueFilter] = useState([]);
    const [dataSourceFilterSeguimiento, setDataSourceFilterSeguimiento] = useState([]);
    const [dataSourceFilterReasignar, setDataSourceFilterReasignar] = useState([]);
    const [payload, setPayload] = useState({
        DtFechaInicio: moment().add(-1, 'M').format('YYYY-MM-DD'),
        DtFechaFin: moment().format('YYYY-MM-DD'),
        EstadoId: 0,
        VcDocumento: '',
        UsuarioId: props.modo === '1' ? userDto.id : 0
    });


    const obtenerBandeja = async (payload:any) => {
        const datos = { ...payload };

        datos.DtFechaFin = moment(datos.DtFechaFin).format('YYYY-MM-DD[T23:59:59]');


        await axios.post(getOrquestadorApiUrl('AtencionIndividual/bandeja'), datos)
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ?? []);
                setDataSourceFilter(response.data.models ?? []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    const obtenerDataParametroDetalle = async (codigo: any, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInterno/${codigo}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                if (codigo === 'bEstadoCaso') {
                    dataArray.push({ value: 0, label: 'TODOS', codigo: '' });
                }
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }


    const obtenerDatosUsuarios = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data: any = response.data.models || [];

                dataArray.push({ value: 0, label: 'TODOS' });
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcPrimerNombre + ' ' + element.vcPrimerApellido });
                });
                setDataUsuarios(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosReasignados = async () => {
        setDataSourceReasignacion([]);
        setDataSourceFilterReasignar([]);

        await axios.get(getOrquestadorApiUrl(`AtencionIndividualReasignacion/porAtencionIndividualId/${AtencionIndividualId}`))
            .then((response) => {
                // Obtenemos los datos
                console.log(response)
                setDataSourceReasignacion(response.data.models ? response.data.models : []);
                setDataSourceFilterReasignar(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }
    const createReasignacion = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl(`AtencionIndividualReasignacion/crear/`), data)
            .then((response) => {
                const data = response.data;
                mostrarMensaje(data.titulo, data.mensaje, '1', false);
                obtenerDatosReasignados();
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo === 400) {
                    mostrarMensaje(data.titulo, data.mensaje, '4', false);
                }
            });
    }

    const obtenerDatosSeguimiento = async () => {
        setDataSourceSeguimiento([]);
        setDataSourceFilterSeguimiento([]);

        await axios.get(getOrquestadorApiUrl(`AtencionIndividualSeguimiento/porAtencionIndividualId/${AtencionIndividualId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceSeguimiento(response.data.models ? response.data.models : []);
                setDataSourceFilterSeguimiento(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }
    const createSeguimiento = async (data: any) => {
        const bCierraCaso = data.BCierraCaso;
        const codEstado = bCierraCaso ? 'CER' : 'SEG';

        try {
            data.estadoId = dataEstados.filter((item: any) => item.codigo === codEstado)[0].value;
        
            await axios.post(getOrientacionEInformacionApiUrl(`AtencionIndividualSeguimiento/crear/`), data)
            .then((response) => {
                const data = response.data;
                mostrarMensaje(data.titulo, data.mensaje, '1', bCierraCaso);
                obtenerDatosSeguimiento();
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo === 400) {
                    mostrarMensaje(data.titulo, data.mensaje, '4', false);
                }
            });
           
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: '¡Algo salió Mal!',
                text: 'No está configurado los códigos CER y SEG para los estados del caso en el módulo administrador',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false
            }).then((result: any) => {

            });            
        }


    }

    const obtenerDatosAnexos = async () => {
        setDataSourceAnexos([]);
        await axios.get(getOrquestadorApiUrl(`AtencionIndividualAnexo/bandeja/${AtencionIndividualId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceAnexos(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosOtrosCasos = async () => {
        setDataSourceOtrosCasos([]);
        await axios.get(getOrquestadorApiUrl(`AtencionIndividual/otrosCasos/${PersonaIndividualId}/${AtencionIndividualId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceOtrosCasos(response.data.models ?? []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    const mostrarMensaje = (titulo: string, mensaje: string, icon: string, bCierraCaso: any) => {
        Swal.fire({
            title: titulo,
            text: mensaje,
            icon: icon == '1' ? 'success' : icon === '2' ? 'info' : icon === '3' ? 'warning' : 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        }).then((result: any) => {
            if (result.isConfirmed) {
                if (bCierraCaso == true) {
                    setModalSeguimiento(false);
                    obtenerBandeja(payload);
                }
            }
        });
    }

    useEffect(() => {
        setModo(props.modo);
        setPayload({
            DtFechaInicio: moment().add(-1, 'M').format('YYYY-MM-DD'),
            DtFechaFin: moment().format('YYYY-MM-DD'),
            EstadoId: 0,
            VcDocumento: '',
            UsuarioId: props.modo === '1' ? userDto.id : 0
        });
    }, [props.modo]);

    useEffect(() => {
        obtenerDataParametroDetalle('bEstadoCaso', setDataEstados);
        if (modo === '2') {
            obtenerDatosUsuarios();
        }
        obtenerBandeja(payload);
    }, [modo]);

    const reAsignar = (element: any) => {
        setAtencionIndividualId(element.id);
        setModalReAsignar(true);
    }
    const seguimiento = (element: any) => {
        setAtencionIndividualId(element.id);
        setModalSeguimiento(true);
    }
    const otrosCasos = (element: any) => {
        setPersonaIndividualId(element.personaIndividualId);
        setAtencionIndividualId(element.id);
        setModalOtrosCasos(true);
    }
    const verAnexos = (element: any) => {
        setAtencionIndividualId(element.id);
        setModalAnexos(true);
    }

    const imprimirCaso = async (atencionGrupalId: number) => {
        await axios.get(getOrquestadorApiUrl(`AtencionIndividual/pdf/${atencionGrupalId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourcePdf(response.data.pdf ?? null);
                setModalVisiblePdf(true);
                //setModalVisible(true);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }


    const datosBandejaIndividualContext: BandejaAtencionesIndividualContextInterface = {
        UsuarioId: userDto.id,
        UsuarioLogueado: (userDto.vcPrimerNombre || '') + ' ' + (userDto.vcSegundoNombre || '') + ' ' + (userDto.vcPrimerApellido || '') + ' ' + (userDto.vcSegundoApellido || ''),
        AtencionIndividualId: AtencionIndividualId,
        PersonaIndividualId: PersonaIndividualId,
        dataSource: dataSource,
        setDataSource: setDataEstados,
        dataUsuarios: dataUsuarios,
        setDataSourceFilter: setDataSourceFilter,
        dataSourceFilter: dataSourceFilter,
        setDataSourceFilterSeguimiento: setDataSourceFilterSeguimiento,
        dataSourceFilterSeguimiento: dataSourceFilterSeguimiento,
        setDataSourceFilterReasignar: setDataSourceFilterReasignar,
        dataSourceFilterReasignar: dataSourceFilterReasignar,

        modalReAsignar: modalReAsignar,
        setModalReAsignar: setModalReAsignar,
        obtenerDatosReasignados: obtenerDatosReasignados,
        dataSourceReasignacion: dataSourceReasignacion,
        createReasignacion: createReasignacion,

        modalSeguimiento: modalSeguimiento,
        setModalSeguimiento: setModalSeguimiento,
        obtenerDatosSeguimiento: obtenerDatosSeguimiento,
        dataSourceSeguimiento: dataSourceSeguimiento,
        createSeguimiento: createSeguimiento,

        modalOtrosCasos: modalOtrosCasos,
        setModalOtrosCasos: setModalOtrosCasos,
        dataSourceOtrosCasos: dataSourceOtrosCasos,
        obtenerDatosOtrosCasos: obtenerDatosOtrosCasos,

        modalAnexos: modalAnexos,
        setModalAnexos: setModalAnexos,
        dataSourceAnexos: dataSourceAnexos,
        obtenerDatosAnexos: obtenerDatosAnexos,

        modalVisiblePdf: modalVisiblePdf,
        dataSourcePdf: dataSourcePdf,
        setModalVisiblePdf: setModalVisiblePdf,
    }

    interface DataRow {
        id: number;
        acciones: string;
        iAnexos: string;
        dtFechaOrientacion: string;
        estado: string,
        vcCorreo: string;
        diasSinRespuesta: any;
        diasSinRespuestaColor: any;
        tipoIdentificacion: string;
        identificacion: string;
        vcNombreCompleto: string;
        tipoSolicitud: string;
        motivo: string;
        subMotivo: string;
        usuarioRegistro: string;
        usuarioActual: string;
        txAclaracionMotivo: string;
        dtFechaRegistro: string;
        txAsuntoCorreo: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Id del caso',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-printer" onClick={() => imprimirCaso(element.id)} toolTip="imprimir caso" />
                    {element.estado?.toLowerCase() !== 'cerrado' ?
                        <div>
                            <CrudButton className="btn-icon btn-sm btn-success mt-1" icon="bi bi-person" onClick={() => reAsignar(element)} toolTip="Asignar caso" />
                        </div>
                        : null}
                    <CrudButton className="btn-icon btn-sm btn-primary mt-1" icon="bi bi-search" onClick={() => seguimiento(element)} toolTip="Registrar seguimiento al caso" />
                    <CrudButton className="btn-icon btn-sm btn-primaryCustom mt-1" icon="bi bi-bootstrap-reboot" onClick={() => otrosCasos(element)} toolTip="Ver otros casos de la persona" />
                </div>,
            sortable: false,
        },
        {
            name: 'Anexos',
            cell: (element) => <CrudButton className="btn-icon btn-sm btn-primary" text={element.iAnexos} onClick={() => verAnexos(element)} toolTip="Ver anexos del caso" />,
            sortable: false,
        },
        {
            name: 'Fecha de caso',
            cell: (element) => <p>{moment(element.dtFechaRegistro).format('YYYY-MM-DD HH:mm:ss')}</p>,
            selector: row => row.dtFechaRegistro,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Estado del caso',
            cell: (element) => <p>{element.estado}</p>,
            selector: row => row.estado,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Días sin respuesta',
            cell: (element) => <span className="badge" style={{ backgroundColor: element.diasSinRespuestaColor }}>{element.diasSinRespuesta}</span>,
            selector: row => row.diasSinRespuesta,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Tipo de identificación',
            cell: (element) => <p>{element.tipoIdentificacion}</p>,
            selector: row => row.tipoIdentificacion,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Identificación',
            cell: (element) => <p>{element.identificacion}</p>,
            selector: row => row.identificacion,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Nombres y Apellidos',
            cell: (element) => <p>{element.vcNombreCompleto}</p>,
            selector: row => row.vcNombreCompleto,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Tipo de solicitud',
            cell: (element) => <p>{element.tipoSolicitud}</p>,
            selector: row => row.tipoSolicitud,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Motivo',
            cell: (element) => <p>{element.motivo}</p>,
            selector: row => row.motivo,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Sub motivo',
            cell: (element) => <p>{element.subMotivo}</p>,
            selector: row => row.subMotivo,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Usuario asignado',
            cell: (element) => <p>{element.usuarioActual}</p>,
            selector: row => row.usuarioActual,          
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Usuario que registró',
            cell: (element) => <p>{element.usuarioRegistro}</p>,
            selector: row => row.usuarioRegistro,
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Fecha de registro',
            cell: (element) => <p>{moment(element.dtFechaRegistro).format('YYYY-MM-DD HH:mm:ss')}</p>,
            selector: row => row.dtFechaRegistro,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Aclaraciones',
            cell: (element) => <p>{element.txAclaracionMotivo?.length > MAX_TEXT_LENGTH ? `${element.txAclaracionMotivo.slice(0, MAX_TEXT_LENGTH)}...` : element.txAclaracionMotivo}</p>,
            sortable: false,
            minWidth: '250px'
        },
    ];

    const filterDataTable = (value: string) => {
        const dataArray = dataSource.filter((item: any) => {
            return item.id.toString().includes(value) ||
                item.dtFechaOrientacion?.toString().substring(0, 10).includes(value) ||
                item.estado?.toLowerCase().includes(value) ||
                item.diasSinRespuesta?.toString().includes(value) ||
                item.tipoIdentificacion?.toLowerCase().includes(value) ||
                item.identificacion?.toString().includes(value) ||
                item.vcNombreCompleto?.toLowerCase().includes(value) ||
                item.tipoSolicitud?.toLowerCase().includes(value) ||
                item.motivo?.toLowerCase().includes(value) ||
                item.subMotivo?.toLowerCase().includes(value);
        });
        setDataSourceFilter(dataArray);
    }

    return <>
        <bandejaIndividualContext.Provider value={datosBandejaIndividualContext}>
            <Container fluid>
                <Card className="mt-5">
                    <Card.Header>
                        <Card.Title className="fw-bold text-azul-oscuro">
                            {modo === '1' ? 'Bandeja de casos propios de atenciones individuales' : ''}
                            {modo === '2' ? 'Bandeja de seguimiento atenciones individuales' : ''}
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{ ...payload }}
                            onReset={(values, formProps) => {

                            }}
                            validationSchema={BandejaIndividualSchema}
                            onSubmit={async (values: any, { setSubmitting }) => {
                                obtenerBandeja({
                                    DtFechaInicio: values.DtFechaInicio,
                                    DtFechaFin: values.DtFechaFin,
                                    EstadoId: values.EstadoId,
                                    VcDocumento: values.VcDocumento,
                                    UsuarioId: values.UsuarioId
                                });
                                setSubmitting(false);
                            }}
                        >
                            {({
                                isSubmitting,
                                setFieldValue,
                                values
                            }) => (
                                <Form>

                                    <div className="form-group row">
                                        <FormikInput
                                            type='date'
                                            labelName='Fecha de inicio'
                                            nameSelect='DtFechaInicio'
                                            className='col-12 col-sm-6 col-lg-3 mt-5'
                                            required={true}
                                        />
                                        <FormikInput
                                            type='date'
                                            labelName='Fecha de fin'
                                            nameSelect='DtFechaFin'
                                            className='col-12 col-sm-6 col-lg-3 mt-5'
                                            required={true}
                                        />
                                        <FormikSelect
                                            options={dataEstados}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("EstadoId", selectedOption.value);
                                            }}
                                            value={values.EstadoId}
                                            labelName='Estado'
                                            nameSelect='EstadoId'
                                            className='col-12 col-sm-6 col-lg-3 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                        />
                                        <FormikInput
                                            labelName='# de documento'
                                            nameSelect='VcDocumento'
                                            className='col-12 col-sm-6 col-lg-3 mt-5'
                                        />
                                        {modo === '2' ?
                                            <FormikSelect
                                                options={dataUsuarios}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("UsuarioId", selectedOption.value);
                                                }}
                                                value={values.UsuarioId}
                                                labelName='Usuario asignado'
                                                nameSelect='UsuarioId'
                                                className='col-12 col-sm-6 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                            /> : null
                                        }
                                        <div className="col-12 mt-5">
                                            <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end mx-2" text="Buscar" disabled={isSubmitting} />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        {dataSource.length > 0 ?
                            <InputFilter
                                valueFilter={valueFilter}
                                setValueFilter={setValueFilter}
                                onChange={filterDataTable}
                                nameFile="Bandeja Atenciones Individuales"
                                dataSource={dataSourceFilter}
                            />
                            : null
                        }

                        <Container fluid className="form-group row mt-8">
                            <Col>
                                <DataTable
                                    columns={columns}
                                    data={dataSourceFilter}
                                    pagination
                                    customStyles={customStyles}
                                    paginationComponentOptions={PaginationProperties}
                                    noDataComponent={<NoRecords />}
                                    expandableRows
                                    expandableRowsComponent={CustomExpanderGeneric}
                                />
                            </Col>
                        </Container>
                    </Card.Body>
                </Card>
            </Container>


            <ActionModal visible={modalReAsignar} title={'Asignaciones del caso'} size='xl' handleClose={setModalReAsignar}>
                <AtencionesIndividualesFormReasignar />
            </ActionModal>
            <ActionModal visible={modalSeguimiento} title={'Registrar seguimiento al caso'} size='lg' handleClose={setModalSeguimiento}>
                <AtencionesIndividualesFormSeguimiento />
            </ActionModal>
            <ActionModal visible={modalOtrosCasos} title={'Otros casos del usuario'} size='xl' fullscreen={true} handleClose={setModalOtrosCasos}>
                <AtencionesIndividualesFormOtrosCasos />
            </ActionModal>
            <ActionModal visible={modalAnexos} title={'Anexos del caso'} size='xl' handleClose={setModalAnexos}>
                <AtencionesIndividualesFormAnexos />
            </ActionModal>

            <ActionModal visible={modalVisiblePdf} title={'Documento PDF del caso'} size={'xl'} fullscreen={true} handleClose={setModalVisiblePdf}>
                <PdfBase64 pdfBase64={dataSourcePdf} />
            </ActionModal>

        </bandejaIndividualContext.Provider>
    </>
}

