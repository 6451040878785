import { useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CrudButton } from "../../../../components/CrudButton";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles, NoRecords, PaginationProperties } from "../../../../components/DataTableConfig";
import { AtencionIndividualContext } from "./IngresarInformacion";
import { AtencionesIndividualesContextInterface } from "./AtencionesIndividualesTypes";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { FormikInput, FormikInputFile } from "src/app/components/FormikInput";
import { FormConstantsValidation } from "src/app/components/FormConstantsValidation";

const AtencionIndividualAnexoSchema = Yup.object().shape({
    Anexo: Yup.mixed().required('Campo obligatorio')
        .test('fileFormat', 'Formato de archivo debe ser pdf', value => {
            return !value ? true : FormConstantsValidation.TYPEPDF == value.type;
        })
        .test('fileSize', 'El archivo debe ser de máximo 2MB', value => {
            return !value ? true : value.size <= FormConstantsValidation.TWOMBFILESIZE;
        }),
});


export function AtencionesIndividualesFormAnexos() {
    const context = useContext(AtencionIndividualContext) as AtencionesIndividualesContextInterface;

    useEffect(() => {
        context.obtenerDatosAnexos();
    }, []);

    const initialValues = {
        AtencionIndividualId: context.AtencionIndividualId,
        Anexo: null,
    }

    interface DataRow {
        acciones: string;
        vcNombre: string;
        dtFechaRegistro: string;
        vcUsuario: string;
        vcDescripcion: string;
        iBytes: number;
        vcRuta: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Acciones',
            cell: (element) => <a className="btn-icon btn-sm btn-success" href={element.vcRuta} target="_blank"> <i className="bi bi-download"></i></a>,
            sortable: false,
        },
        {
            name: 'Nombre',
            cell: (element) => <p>{element.vcNombre}</p>,
            sortable: false,
        },
        {
            name: 'Fecha',
            cell: (element) => <p>{element.dtFechaRegistro.toString().substring(0, 10)}</p>,
            sortable: true,
        },
        {
            name: 'Usuario',
            cell: (element) => <p>{element.vcUsuario}</p>,
            sortable: true,
        },
        {
            name: 'Descripción',
            cell: (element) => <p>{element.vcDescripcion}</p>,
            sortable: true,
        },
        {
            name: 'Tamaño Bytes',
            cell: (element) => <p>{element.iBytes}</p>,
            sortable: true,
        },
    ];

    return <>
        <Container className="form-group row mt-8">
            <Row>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...initialValues }}
                    onReset={(values, formProps) => {

                    }}
                    validationSchema={AtencionIndividualAnexoSchema}
                    onSubmit={(values: any, { setSubmitting }) => {
                        Swal.fire({
                            title: 'Confirmar guardado',
                            text: "¿Desea guardar el anexo?",
                            icon: 'warning',
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sí, guardar',
                            cancelButtonText: 'Cancelar',
                        }).then(async (result: any) => {
                            if (result.isConfirmed) {

                                let formData = new FormData();

                                formData.append('Id', context.AtencionIndividualId.toString());
                                formData.append('PersonaId', "0");
                                formData.append('DtFechaRegistro', "0");
                                formData.append('UsuarioId', context.UsuarioId.toString());
                                formData.append('CanalAtencionId', "0");
                                formData.append('VcTurnoSat', "");
                                formData.append('TipoSolicitudId', "0");
                                formData.append('MotivoId', "0");
                                formData.append('SubMotivoId', "0");
                                formData.append('TxAclaracionMotivo', "0");
                                formData.append('TxGestionRealizada', "");
                                formData.append('VcRadicadoBte', "");
                                formData.append('TxActores', "");
                                formData.append('EstadoId', "0");

                                formData.append('Anexo', values.Anexo);                             
                                context.asociarAnexoCaso(formData);                                
                            }
                        })
                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        setFieldValue,
                        values
                    }) => (
                        <Form>
                            <div className="row">
                                <FormikInput
                                    labelName='Id del caso'
                                    nameSelect='AtencionIndividualId'
                                    className='col-12 col-md-3 mt-5'
                                    readOnly={true}
                                    required={true}
                                />
                                <FormikInputFile
                                    labelName='Anexo'
                                    nameSelect='Anexo'
                                    className='col-12 mt-5'
                                    accept="application/pdf"
                                    onChange={(e: any) => {
                                        setFieldValue("Anexo", e.target.files[0]);
                                    }}
                                />

                                <div className="col-12 col-md-3 mt-5">
                                    <label className="form-label text-azul-oscuro d-block"></label>
                                    <CrudButton type={'submit'} className="btn-sm btn-primaryCustom" text="Guardar" disabled={isSubmitting} />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>            
            </Row>
            <Row>
                <DataTable
                    columns={columns}
                    data={context.dataSourceAnexos}
                    pagination
                    customStyles={customStyles}
                    striped={true}
                    paginationComponentOptions={PaginationProperties}
                    noDataComponent={<NoRecords />}
                />
            </Row>
        </Container>
    </>
}

