import { Form, Formik } from 'formik';
import { useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import * as Yup from 'yup';
import { CrudButton } from "../../../../components/CrudButton";
import { FormConstantsValidation } from '../../../../components/FormConstantsValidation';
import { FormikInput } from '../../../../components/FormikInput';
import { FormikSelect } from '../../../../components/FormikSelect';
import { AtencionesIndividualesContextInterface } from "./AtencionesIndividualesTypes";
import { AtencionIndividualContext } from './IngresarInformacion';
import axios from "axios";
import {getOrientacionEInformacionApiUrl} from "../../../../../_metronic/helpers/ApiOrientacionEInformacionHelpers";


const getPersonInfo = Yup.object().shape({
    TipoDocumentoId: Yup.string().required('Campo obligatorio'),

    VcDocumento: Yup.string().required('Campo obligatorio')
        .test('valida_ti', 'El número de identificación debe ser númerico con 10 u 11 digitos', function (f: any) {
            const refCodigoTipoDocumento = this.resolve(Yup.ref('CodigoTipoDocumento'));
            if (refCodigoTipoDocumento === 'TI' && ((f?.length !== 10 && f?.length !== 11) || isNaN(f))) {

                return false;
            }
            return true;
        })
        .test('valida_cc', 'El número de identificación debe ser númerico con 6, 7, 8 o 10 digitos', function (f: any) {
            const refCodigoTipoDocumento = this.resolve(Yup.ref('CodigoTipoDocumento'));
            if (refCodigoTipoDocumento === 'CC' && ((f?.length !== 6 && f?.length !== 7 && f?.length !== 8 && f?.length !== 10) || isNaN(f))) {

                return false;
            }
            return true;
        })
        .test('valida_rc_nuip', 'El número de identificación debe tener máximo 15 digitos', function (f: any) {
            const refCodigoTipoDocumento = this.resolve(Yup.ref('CodigoTipoDocumento'));
            if ((refCodigoTipoDocumento === 'RC' || refCodigoTipoDocumento === 'NUIP')) {
                if (f?.length > 15 || isNaN(f) ) {
                    return false;
                }
            }
            return true;
        })
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        }),
});

var mensaje: any = "";

const contarCasosPersona = async (idTipoDocumento: { idTipoDocumento: any }, numDocumento: { numDocumento: any }) => {
    let parametro1 = idTipoDocumento.idTipoDocumento;
    let parametro2 = numDocumento.numDocumento;
    await axios.get(getOrientacionEInformacionApiUrl(`AtencionIndividual/contarCasosPersona/${parametro1}/${parametro2}/2968`))
        .then((response) => {
            mensaje = response.data.mensaje
        })
        .catch(e => {
            console.log('ver e: ', e);
        });
}

export function AtencionesIndividualesDI(props: any) {

    const AtencionIndividualCContext = useContext(AtencionIndividualContext) as AtencionesIndividualesContextInterface;
    const context = useContext(AtencionIndividualContext) as AtencionesIndividualesContextInterface;

    useEffect(() => {
        AtencionIndividualCContext.obtenerDataParametroDetalle('bTipoDocumento', AtencionIndividualCContext.setDataTiposDocumento);
        mensaje = null;
    }, []);

    return <>
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    TipoDocumentoId: '',
                    VcDocumento: '',
                }}
                validationSchema={getPersonInfo}
                onReset={(values, formProps) => {

                }}
                onSubmit={(values, { setSubmitting }) => {
                    AtencionIndividualCContext.getDataIndividual(Number(values.TipoDocumentoId), values.VcDocumento);
                    AtencionIndividualCContext.setTextoComprobador("");
                    contarCasosPersona(
                        {idTipoDocumento: values.TipoDocumentoId},
                        {numDocumento: values.VcDocumento}
                );
                    setSubmitting(false);
                    context.setTituloComprobador(false);
                }}
            >
                {({
                    isSubmitting,
                    setFieldValue,
                    values
                }) => (
                    <Form autoComplete='off'>
                        <h6>Ingresar información de la ciudadanía {AtencionIndividualCContext.esLSPT ? `Rol LSPT` : `Rol DSC`}</h6>
                        <Card className="mt-5">
                            <Card.Header>
                                <Card.Title>Datos de identificación</Card.Title>
                                <p>{mensaje}</p>
                            </Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <FormikSelect
                                        options={AtencionIndividualCContext.dataTiposDocumento}
                                        onChange={(selectedOption: any) => {
                                            if (selectedOption) {
                                                setFieldValue("TipoDocumentoId", selectedOption.value);
                                                setFieldValue("CodigoTipoDocumento", selectedOption.codigo);
                                            }else{
                                                setFieldValue("TipoDocumentoId", '');
                                                setFieldValue("CodigoTipoDocumento", '');
                                            }
                                        }}
                                        value={values.TipoDocumentoId}
                                        labelName='Tipo de identificación'
                                        nameSelect='TipoDocumentoId'
                                        className='col-12 col-md-4 mt-5'
                                        placeHolder='Seleccione'
                                        required={true}
                                        isClearable={false}
                                    />
                                    <FormikInput
                                        labelName='Identificación'
                                        nameSelect='VcDocumento'
                                        className='col-12 col-md-4 mt-5'
                                        required={true}
                                    />
                                </div>

                                <div className="row mt-5">
                                    <div className="col-12">
                                        <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end mx-2" text="Buscar" disabled={isSubmitting} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Form>
                )}
            </Formik>
        </div>
    </>
}

