// Render Prop
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import { FormikSelect } from '../../../components/FormikSelect';
import { FormikInput } from '../../../components/FormikInput';
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";

const UsuarioAreaSchema = Yup.object().shape({
  AreaId: Yup.number()
    .required('Campo obligatorio')
    .test('superior', 'Campo obligatorio', function (f) {
      return f > 0;
    }),
  DtFechaInicio: Yup.date().required('Campo obligatorio').nullable('Campo obligatorio'),
  DtFechaFin: Yup.date().required('Campo obligatorio').nullable('Campo obligatorio')
    .test('superior', 'La fecha fin debe ser mayor que la de inicio', function (f) {
      const ref = Yup.ref('DtFechaInicio');
      return f > this.resolve(ref);
    }),
});

export default function UsuariosFormAreas({ dataAreas, obtenerDatos, setModalVisible, data }) {

  const create = async (data) => {
    await axios.post(getAdministradorApiUrl('UsuarioArea'), data)
      .then((response) => {
        setModalVisible(false);
        obtenerDatos();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const update = async (data) => {
    await axios.put(getAdministradorApiUrl(`UsuarioArea/${data.Id}`), data)
      .then((response) => {
        setModalVisible(false);
        obtenerDatos();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  return (
    <div>
      <Formik
        initialValues={{ Id: data.Id, UsuarioId: data.UsuarioId, AreaId: data.AreaId, DtFechaInicio: data.DtFechaInicio, DtFechaFin: data.DtFechaFin }}
        validationSchema={UsuarioAreaSchema}
        onReset={(values, formProps) => {
          setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (values.Id == 0) {
            create(values);
          } else {
            update(values);
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form>
            <div className='form-group row'>
              <FormikSelect
                options={dataAreas}
                onChange={selectedOption => setFieldValue("AreaId", selectedOption.value)}
                value={values.AreaId}
                labelName='Area'
                nameSelect='AreaId'
                className='col-12 col-md-12'
                placeHolder='Seleccione'
                required={true}
              />
              <FormikInput
                type='date'
                labelName='Fecha inicio'
                nameSelect='DtFechaInicio'
                className='col-12 col-md-6 mt-5'
                required={true}
              />
              <FormikInput
                type='date'
                labelName='Fecha fin'
                nameSelect='DtFechaFin'
                className='col-12 col-md-6 mt-5'
                required={true}
              />
            </div>

            <div className="row mt-5">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting}>
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};