import axios from 'axios';
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getParametroApiUrl } from "../../../../_metronic/helpers/ApiParametroHelpers";

export function ParametrosExcel(props: any) {
    const navigate = useNavigate();

    const [file, setFile] = useState({
        Nombre: '',
        Files: []
    });

    const [responseExcel, setResponseExcel] = useState({
        Titulo: '',
        Mensaje: '',
        Registros: 0,
        CountErrores: 0,
        Errores: []
    });

    useEffect(() => {
    }, []);

    const handleChangeFile = (e: any) => {
        setFile({
            Nombre: e.target.value,
            Files: e.target.files
        });
    }

    const subirExcel = async () => {
        if (file.Files.length > 0) {
            const formData = new FormData();
            formData.append("files", file.Files[0]);

            await axios.post(getParametroApiUrl('Parametro/cargar'), formData)
                .then((response) => {
                    console.log(response)
                    const data = response.data;
                    setResponseExcel({
                        Titulo: data.titulo,
                        Mensaje: data.mensaje,
                        CountErrores: data.errores?.length,
                        Registros: data.registros,
                        Errores: data.errores ? data.errores : []
                    })
                })
                .catch(e => {
                    // Capturamos los errores
                    const data = e.response.data;
                    console.log(data)
                    setResponseExcel({
                        Titulo: data.titulo,
                        Mensaje: data.mensaje,
                        CountErrores: data.errores?.length,
                        Registros: data.registros,
                        Errores: data.errores ? data.errores : []
                    })
                })
        }
    }

    const irParametro = () => {
        navigate('/parametros');
    }

    return <>
        <Card>
            <Card.Body>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <input type={'file'} className="form-control form-control-solid" name="file"
                            value={file.Nombre} onChange={handleChangeFile} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <a href="#" className="btn btn-icon btn-primary"
                            onClick={() => subirExcel()}>
                            <i className="bi bi-file-arrow-up fs-1"></i>
                        </a>

                        <a href="#" className="btn btn-icon btn-success ml-5"
                            onClick={() => irParametro()}>
                            <i className="bi bi-arrow-return-right fs-1"></i>
                        </a>
                    </div>
                </div>
            </Card.Body>
        </Card>

        <Card className="mt-10">
            <Card.Body>
                <div className="row">
                    <div className="col-11">
                        <div className="table-responsive">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><b>Titulo:</b> {responseExcel.Titulo}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Mensaje:</b> {responseExcel.Mensaje}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Registros modificados:</b>  {responseExcel.Registros}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Errores:</b> {responseExcel.CountErrores}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-11">
                        <div className="table-responsive">
                            <table>
                                <tbody>
                                    {responseExcel.Errores.map((value: any, index: number) => {
                                        return (
                                            <tr>
                                                <td><b>Error {index + 1} - </b>{value}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

