// Render Prop
import { useContext } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikSelectIcon } from '../../../components/FormikSelect';
import { CrudButton } from "../../../components/CrudButton";
import { FormikCheckbox, FormikInput, FormikTextArea} from '../../../components/FormikInput';
import { moduloContext } from './Modulos';

const ModuloSchema = Yup.object().shape({
  VcNombre: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Campo obligatorio'),
  VcDescripcion: Yup.string()
    .min(2, 'Mínimo 2 caracteres')
    .max(200, 'Máximo 200 caracteres')
    .required('Campo obligatorio'),
  IconoId: Yup.string()
    .required('Campo obligatorio')
    .notOneOf([0, '0'], 'Campo obligatorio'),
});

export default function ModulosForm() {
  const moduloCContext = useContext(moduloContext);
  return (
    <div>
      <Formik
        initialValues={moduloCContext.data}
        validationSchema={ModuloSchema}
        onReset={(values, formProps) => {
          moduloCContext.setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true); 
          if (values.Id == 0) {
            moduloCContext.create(values);
          } else {
            moduloCContext.update(values);
          }
        }}
      >
        {({
          isSubmitting ,
          setFieldValue,
          values
        }) => (
          <Form>
            <div className="form-group row">
              <FormikInput
                  labelName='Nombre'
                  nameSelect='VcNombre'
                  className='col-12'
                  placeHolder="Tipo de módulo"
                  required={true}
              />
              <FormikTextArea
                  labelName='Descripción'
                  nameSelect='VcDescripcion'
                  className='col-12 mt-5'
                  placeholder="Descripcion"
                  required={true}
              />             
              <FormikSelectIcon
                options={moduloCContext.dataIconos}
                onChange={selectedOption => setFieldValue("IconoId", selectedOption.value)}
                value={values.IconoId}
                labelName='Icono'
                nameSelect='IconoId'
                className='col-12 mt-5'
                placeHolder='Seleccione un icono'
                required={true}
              />
              <FormikCheckbox
                labelName='Estado'
                nameSelect='BEstado'
                className='col-12 mt-5'
                value={values.BEstado}
                required={true}
              />
            </div>

            <div className="row mt-2">
              <div className="col-12">
                <CrudButton type={'submit'} className="btn-sm btn-primary float-end mx-2" text="Guardar"  disabled={isSubmitting} />
                <CrudButton type={'reset'} className="btn-sm btn-light float-end" text="Cancelar" />

              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
};