import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { CrudButton } from "../../../../components/CrudButton";
import { customStyles, NoRecords, PaginationProperties } from "../../../../components/DataTableConfig";
import { FormConstantsValidation } from "../../../../components/FormConstantsValidation";
import { FormikInput } from '../../../../components/FormikInput';
import { FormikSelect } from '../../../../components/FormikSelect';
import { InputFilter } from "../../../../components/Input";
import axios from 'axios'
import { getAdministradorApiUrl } from '../../../../../_metronic/helpers/ApiAdministradorHelpers';
import { AtencionIndividualContext } from './IngresarInformacion';
import { AtencionesIndividualesContextInterface } from './AtencionesIndividualesTypes';


const AtencionIndividualSchema = Yup.object().shape({
    UsuarioAsigna: Yup.string().required('Campo obligatorio'),
    UsuarioActualId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    UsuarioAsignaId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        })
});

export function AtencionesIndividualesFormReasignar() {
    const bandejaIndividualCContext = useContext(AtencionIndividualContext) as AtencionesIndividualesContextInterface;

    const [valueFilter, setValueFilter] = useState('');
    const [dataUsuarios, setDataUsuarios] = useState([]);

    const obtenerDatosUsuarios = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data: any = response.data.models || [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcPrimerNombre + ' ' + element.vcPrimerApellido });
                });
                setDataUsuarios(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }


    useEffect(() => {
        bandejaIndividualCContext.obtenerDatosReasignados();
        obtenerDatosUsuarios();
    }, []);

    const initialValues = {
        Id: 0,
        AtencionIndividualId: bandejaIndividualCContext.AtencionIndividualId,
        UsuarioAsigna: bandejaIndividualCContext.UsuarioLogueado,
        UsuarioAsignaId: bandejaIndividualCContext.UsuarioId,
        UsuarioActualId: undefined
    }

    interface DataRow {
        usuarioAsigna: string;
        usuarioActual: string;
        dtFechaAsignacion: string;
        dtFechaReAsignacion: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Usuario Asigna',
            cell: (element) => <p>{element.usuarioAsigna}</p>,
            sortable: false,
        },
        {
            name: 'Usuario Actual',
            cell: (element) => <p>{element.usuarioActual}</p>,
            sortable: false,
        },
        {
            name: 'Fecha Asignación',
            cell: (element) => <p>{element.dtFechaAsignacion.toString().substring(0, 10)}</p>,
            sortable: true,
        },
        {
            name: 'Fecha Reasignación',
            cell: (element) => <p>{FormConstantsValidation.getIfDate(element.dtFechaReAsignacion.toString())}</p>,
            sortable: true,
        }
    ];

    const filterDataTable = (value: string) => {
        const dataArray = bandejaIndividualCContext.dataSourceReasignacion.filter((item: any) => {
            return item.usuarioAsigna?.toLowerCase().includes(value) ||
                item.usuarioActual?.toLowerCase().includes(value) ||
                item.dtFechaAsignacion?.toString().substring(0, 10).includes(value) ||
                item.dtFechaReAsignacion?.toString().substring(0, 10).includes(value);
        });

        bandejaIndividualCContext.setDataSourceFilterReasignar(dataArray);
    }

    return <>
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...initialValues }}
                onReset={(values, formProps) => {

                }}
                validationSchema={AtencionIndividualSchema}
                onSubmit={(values: any, { setSubmitting }) => {
                    Swal.fire({
                        title: 'Confirmar guardado',
                        text: "¿Desea guardar estos datos?",
                        icon: 'warning',
                        showCancelButton: true,
                        focusConfirm: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí, guardar',
                        cancelButtonText: 'Cancelar',
                    }).then(async (result: any) => {
                        if (result.isConfirmed) {
                            const payload: any = {
                                Id: 0,
                                AtencionIndividualId: bandejaIndividualCContext.AtencionIndividualId,
                                UsuarioActualId: values.UsuarioActualId,
                                UsuarioAsignaId: values.UsuarioAsignaId,
                            }
                            bandejaIndividualCContext.createReasignacion(payload);
                        }
                    })
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    setFieldValue,
                    values
                }) => (
                    <Form>
                        <div className="row">
                            <FormikInput
                                labelName='Id del caso'
                                nameSelect='AtencionIndividualId'
                                className='col-12 col-md-3 mt-5'
                                readOnly={true}
                                required={true}
                            />
                            <FormikInput
                                labelName='Usuario Actual'
                                nameSelect='UsuarioAsigna'
                                className='col-12 col-md-3 mt-5'
                                readOnly={true}
                                required={true}
                            />
                            <FormikSelect
                                options={dataUsuarios}
                                onChange={(selectedOption: any) => {
                                    setFieldValue("UsuarioActualId", selectedOption.value);
                                }}
                                value={values.UsuarioActualId}
                                labelName='Asignar a'
                                nameSelect='UsuarioActualId'
                                className='col-12 col-md-3 mt-5'
                                placeHolder='Seleccione'
                                required={true}
                            />
                            <div className="col-12 col-md-3 mt-5">
                                <label className="form-label text-azul-oscuro d-block">Asignar</label>
                                <CrudButton type={'submit'} className="btn-sm btn-primaryCustom" text="Guardar" disabled={isSubmitting} />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            {bandejaIndividualCContext.dataSourceReasignacion.length > 0 ?
                <InputFilter
                    valueFilter={valueFilter}
                    setValueFilter={setValueFilter}
                    onChange={filterDataTable}
                    nameFile="Bandeja Aten. Indiv. Registro"
                    dataSource={bandejaIndividualCContext.dataSourceFilterReasignar}
                />
                : null
            }

            <Container className="form-group row mt-8">
                <Col>
                    <DataTable
                        columns={columns}
                        data={bandejaIndividualCContext.dataSourceFilterReasignar}
                        pagination
                        customStyles={customStyles}
                        striped={true}
                        paginationComponentOptions={PaginationProperties}
                        noDataComponent={<NoRecords />}
                    />
                </Col>
            </Container>
        </div>
    </>
}

