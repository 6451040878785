import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Actividades } from "../pages/administrador/actividades/Actividades"
import { Modulos } from '../pages/administrador/modulos/Modulos'
import { Parametros } from "../pages/administrador/parametros/Parametros"
import { ParametrosExcel } from '../pages/administrador/parametros/ParametrosExcel'
import { PreCargarUsuarios } from '../pages/administrador/parametros/PreCargarUsuarios'
import { Roles } from '../pages/administrador/roles/Roles'
import { RolesActividades } from '../pages/administrador/roles/RolesActividades'
import { ANS } from '../pages/administrador/semaforizacion/ANS/ANS'
import { Festivos } from '../pages/administrador/semaforizacion/festivos/Festivos'
import { Rangos } from '../pages/administrador/semaforizacion/rangos/Rangos'
import { Usuarios } from '../pages/administrador/usuarios/Usuarios'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { AtencionesGrupales } from '../pages/orientacionEInformacion/atencionesGrupales/AtencionesGrupales'
import { BandejaAtencionesGrupales } from '../pages/orientacionEInformacion/atencionesGrupales/AtencionesGrupalesBandeja'
import { AtencionesWeb } from '../pages/orientacionEInformacion/atencionesWeb/formulario/AtencionesWeb'
import { BandejaAtencionesWeb } from '../pages/orientacionEInformacion/atencionesWeb/AtencionesWebBandeja'
import { AtencionesIndividualesBandeja } from '../pages/orientacionEInformacion/atencionIndividuales/bandejaCasos/AtencionesIndividualesBandeja'
import { CaracterizacionCaso } from '../pages/orientacionEInformacion/atencionIndividuales/caseCharacterization/CaracterizacionCaso'
import { IngresarInformacion } from '../pages/orientacionEInformacion/atencionIndividuales/submmitInformation/IngresarInformacion'
import { ParametrosExcelT } from '../pages/administrador/parametros/ParametrosExcelT'
import { ModuloReportes } from '../pages/reportes/ModuloReportes'
import { UsuariosRoles } from '../pages/administrador/usuarios/UsuariosRoles'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='parametros' element={<Parametros />} />
        <Route path='parametrosexcel' element={<ParametrosExcel />} />
        <Route path='precargarusuarios' element={<PreCargarUsuarios />} />
        <Route path='actividades' element={<Actividades />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='modulos' element={<Modulos nombre="" />} />
        <Route path='roles' element={<Roles nombre="" />} />
        <Route path='actividadesPorRoles' element={<RolesActividades />} />
        <Route path='usuarios' element={<Usuarios />} />
        <Route path='usuariosPorRoles' element={<UsuariosRoles />} />
        <Route path='semaforizacion/parametrizacionANS' element={<ANS nombre="" />} />
        <Route path='semaforizacion/festivos' element={<Festivos nombre="" />} />
        <Route path='semaforizacion/rangos' element={<Rangos nombre="" />} />

        <Route path='orientacion/web/registro' element={<AtencionesWeb />} />
        <Route path='orientacion/web/bandeja' element={<BandejaAtencionesWeb modo={'1'} />} />
        <Route path='orientacion/web/seguimiento' element={<BandejaAtencionesWeb modo={'2'} />} />
        <Route path='orientacion/grupales/registro' element={<AtencionesGrupales />} />
        <Route path='orientacion/grupales/bandeja' element={<BandejaAtencionesGrupales />} />
        <Route path='orientacion/grupales/seguimiento' element={<BandejaAtencionesGrupales />} />
        <Route path='orientacion/individuales/registro' element={<IngresarInformacion />} />
        <Route path='orientacion/individuales/bandeja' element={<AtencionesIndividualesBandeja modo={'1'} />} />
        <Route path='orientacion/individuales/seguimiento' element={<AtencionesIndividualesBandeja modo={'2'} />} />
        <Route path='orientacion/individuales/caracterizacion' element={<CaracterizacionCaso />} />
        <Route path='reportes/orientacion' element={<ModuloReportes />} />

        <Route path='parametrosexcelt' element={<ParametrosExcelT />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }

