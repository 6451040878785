import moment from "moment";

const TWOMBFILESIZE: number = 2 * 1024 * 1024;
const ONLYLETTERS: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'BACKSPACE', 'DELETE', 'ARROWLEFT', 'ARROWRIGHT', 'HOME', 'END', 'TAB'];
const ONLYLETTERSANDSPACE: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', ' ', 'BACKSPACE', 'DELETE', 'ARROWLEFT', 'ARROWRIGHT', 'HOME', 'END', 'TAB'];
const ONLYLETTERSNUMBERSSPACEHASHANDDASH: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', ' ', 'BACKSPACE', 'DELETE', 'ARROWLEFT', 'ARROWRIGHT', 'HOME', 'END', 'TAB','#','-','0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
const ONLYNUMBERS: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'BACKSPACE', 'DELETE', 'ARROWLEFT', 'ARROWRIGHT', 'HOME', 'END', 'TAB','-'];
const TYPEPDF: string = 'application/pdf';
const TYPEPDFWORDANDEXCEL: string[] = [
  'application/pdf', 
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const handleOnlyLetters = (event: KeyboardEvent) => {
  if (ONLYLETTERS.indexOf(event.key.toUpperCase()) < 0) {
    event.preventDefault();
  }
};
const isValidOnlyLetters = (texto: string) => {
  for(let i = 0; i < texto?.length; i++) {
    if (ONLYLETTERS.indexOf(texto[i].toUpperCase()) < 0) {
      return false;
    }
  }
  return true;
};

const handleOnlyLettersAndSpace = (event: KeyboardEvent) => {
  if (ONLYLETTERSANDSPACE.indexOf(event.key.toUpperCase()) < 0) {
    event.preventDefault();
  }
};
const isValidOnlyLettersAndSpace = (texto: string) => {
  for(let i = 0; i < texto?.length; i++) {
    if (ONLYLETTERSANDSPACE.indexOf(texto[i].toUpperCase()) < 0) {
      return false;
    }
  }
  return true;
};

const handleOnlyNumbers = (event: KeyboardEvent) => {
  if (ONLYNUMBERS.indexOf(event.key.toUpperCase()) < 0) {
    event.preventDefault();
  }
};
const isValidOnlyNumbers = (texto: string) => {
  for(let i = 0; i < texto?.length; i++) {
    if (ONLYNUMBERS.indexOf(texto[i].toUpperCase()) < 0) {
      return false;
    }
  }
  return true;
};

const isValidOnlyLettersSpaceHashAndDash = (texto: string) => {
  for(let i = 0; i < texto?.length; i++) {
    if (ONLYLETTERSNUMBERSSPACEHASHANDDASH.indexOf(texto[i].toUpperCase()) < 0) {
      return false;
    }
  }
  return true;
};

const handleOnlyLettersSpaceHashAndDash = (event: KeyboardEvent) => {
  if (ONLYLETTERSNUMBERSSPACEHASHANDDASH.indexOf(event.key.toUpperCase()) < 0) {
    event.preventDefault();
  }
};


export type TypeWithKey<T> = { [key: string]: T };

const elementsToArray = (element: string) => {
  let campos: TypeWithKey<string> = {};

  var inputs = document.querySelectorAll(`${element}  input,${element}  textarea`) as NodeListOf<HTMLInputElement>;
  for (let input of inputs) {
    if (input.name.length > 0) {
      if (input.type == 'checkbox') {
        campos[input.name] = input.checked.toString();
      } else {
        campos[input.name] = input.value;
      }
    }
  }
  return campos;
}


function elementsToArrayByRow(container: string, row: string) {
  var datos: TypeWithKey<string>[] = [];
  var rows = document.querySelectorAll(`${container} ${row}`) as NodeListOf<HTMLInputElement>;

  for (let row of rows) {
    let element = row.className;
    let campos = elementsToArray(element);
    if (Object.keys(campos).length != 0)
      datos.push(campos);
  }
  return datos;
}

function getIfDate(dtDate: any) {
  if (moment(dtDate).year() < 2000) {
    return "";
  }
  return moment(dtDate).format('YYYY-MM-DD');
}

export const FormConstantsValidation = {
  TWOMBFILESIZE,
  ONLYLETTERS,
  ONLYNUMBERS,
  TYPEPDF,
  TYPEPDFWORDANDEXCEL,
  handleOnlyLetters,
  isValidOnlyLetters,
  handleOnlyLettersAndSpace,
  isValidOnlyLettersAndSpace,
  handleOnlyNumbers,
  isValidOnlyNumbers,
  elementsToArray,
  elementsToArrayByRow,
  getIfDate,
  isValidOnlyLettersSpaceHashAndDash,
  handleOnlyLettersSpaceHashAndDash,
};

export interface FormikSelectOption {
  value: number;
  label: string;
  codigo: string;
}

export interface ParametroDetalleInterface {
  bEstado: boolean;
  dCodigoIterno: number;
  id: number;
  idPadre: number;
  padre: string;
  padreTipo: string;
  parametroId: number;
  rangoDesde: number;
  rangoHasta: number;
  txDescripcion: string;
  vcCodigoInterno: string;
  vcNombre: string;
}




