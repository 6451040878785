/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import { AuthLayout, LoginInProgress } from '.'


const AuthInProgressPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login-progress' element={<LoginInProgress />} />
      <Route index element={<LoginInProgress />} />
    </Route>
  </Routes>
)

export {AuthInProgressPage}

