import { ComprobadorResponse, Rol, UserLogin } from "src/app/models";

const OTRO_GENERO: number = 1592;
const TRANSGENERO_GENERO: number = 1591;
const OTRA_ORIENTACION_SEXUAL: number = 1599;
const SISBEN_ESTUDIO_SOCIAL_CASO: number = 1729;
const ZONA_DIRECCION: number = 29;
const CODIGO_PAIS_COLOMBIA: number = 31;
const CODIGO_DEPARTAMENTO_BOGOTA: number = 67;
const CODIGO_MUNICIPIO_BOGOTA: number = 1217;
const CODIGO_ENFOQUE_VICTIMA_1: number = 3007;
const CODIGO_ENFOQUE_VICTIMA_2: number = 3008;
const ARRAY_ENFOQUE_VICTIMA: number[] = [CODIGO_ENFOQUE_VICTIMA_1, CODIGO_ENFOQUE_VICTIMA_2];
const TIPO_SOLICITUD_PROBLEMATICA: number = 1399;
const CODIGO_CANAL_PRESENCIAL: number = 3025;
const CODIGO_NUEVO_ENTIDAD_ACTOR: number = 65;
const CODIGO_NUEVO_SEDE_ENTIDAD_ACTOR: number = 66;
const LSPTROL: string = 'RINDIVIDUAL_LSPT';
const CODIGO_TIPO_ACTOR1: string = 'pEntidadesActores1';
const CODIGO_TIPO_ACTOR2: string = 'pEntidadesActores2';
const CODIGO_TIPO_ACTOR3: string = 'pEntidadesActores3';
const CODIGO_TIPO_ACTOR4: string = 'pEntidadesActores4';
const CODIGO_ESTADO_SEGUIMIENTO = 'SEG';
export const FormConstantsAtencionIndividual = {
    OTRO_GENERO,
    TRANSGENERO_GENERO,
    OTRA_ORIENTACION_SEXUAL,
    SISBEN_ESTUDIO_SOCIAL_CASO,
    ZONA_DIRECCION,
    CODIGO_PAIS_COLOMBIA,
    CODIGO_DEPARTAMENTO_BOGOTA,
    CODIGO_MUNICIPIO_BOGOTA,
    ARRAY_ENFOQUE_VICTIMA,
    TIPO_SOLICITUD_PROBLEMATICA,
    CODIGO_CANAL_PRESENCIAL,
    LSPTROL,
    CODIGO_NUEVO_ENTIDAD_ACTOR,
    CODIGO_NUEVO_SEDE_ENTIDAD_ACTOR,
    CODIGO_TIPO_ACTOR1,
    CODIGO_TIPO_ACTOR2,
    CODIGO_TIPO_ACTOR3,
    CODIGO_TIPO_ACTOR4,
    CODIGO_ESTADO_SEGUIMIENTO
}

export interface AtencionesIndividualesContextInterface {
    dataSourcePersona: any;
    setDataSourcePersona: any;
    setFormPersonaVisible: any;
    obtenerDataParametroDetalle: any;
    dataTiposDocumento: any;
    setDataTiposDocumento: any;
    getDataIndividual: any;
    create: any;
    formPersonaVisible: any;
    dataGenero: any;
    dataOrientSexual: any;
    dataSexo: any;
    dataEnfoqPoblSoc: any;
    dataHechVictim: any;
    obtenerDataParametroDetallePorCodigoInternoDetallePadre: any;
    setDataDepartamentoVictima: any;
    dataDepartamentoVictima: any;
    setDataMunicipioVictima: any;
    dataMunicipioVictima: any;
    dataEtnia: any;
    setDataSubTipoEtnia: any;
    dataSubTipoEtnia: any;
    dataPoblPrioInteres: any;
    setDataSubTipoPobPrio: any;
    dataSubTipoPobPrio: any;
    dataRegimen: any;
    setDataAseguradora: any;
    dataAseguradora: any;
    dataEstadoAfiliacion: any;
    dataNivelSisben: any;
    setDataInstInstrVincul: any;
    dataInstInstrVincul: any;
    dataPais: any;
    setDataDepartamento: any;
    dataDepartamento: any;
    llenarMunicipios: any;
    selectDepartamentoRef: any;
    dataMunicipio: any;
    llenarLocalidades: any;
    selectMunicipioRef: any;
    dataLocalidad: any;
    llenarUpz: any;
    selectLocalidadRef: any;
    dataUpz: any;
    llenarBarrio: any;
    selectUpzRef: any;
    dataBarrio: any;
    dataZona: any;
    dataVia: any;
    dataLeteraVia: any;
    dataLetraAdicionalVial: any;
    dataOrientacionVia: any;
    dataLetraNumMarca: any;
    dataOrientacionCasa: any;

    setPrimeraEtapaFormulario: any;
    formRef: any;
    dataSourceCaso: any;

    // Datos del caso en problematica
    dataCanalAtencion: any;
    setDataCanalAtencion: any;
    dataTipoSolicitud: any;
    setDataTipoSolicitud: any;
    obtenerDataParametroDetallePorPadre: any;
    dataEstadoCaso: any;
    setDataEstadoCaso: any;
    createCaso: any;
    setDataSourceCaso: any;
    setDataTiposActor: any;
    dataTiposActor: any;
    setDataTablaActores: any;
    dataTablaActores: any;
    crearPadreHijoParametro: any;
    // Motivo y submotivo
    dataMotivo: any;
    setDataMotivo: any;
    dataSubMotivo: any;
    setDataSubMotivo: any;

    // Datos Actores en relación con la problematica en acceso 1
    dataRegimenActor1: any;
    setDataRegimenActor1: any;
    dataAseguradoraActor1: any;
    setDataAseguradoraActor1: any;

    // Datos Actores en relación con la problematica en acceso 2
    dataPrestador: any;
    setDataPrestador: any; // NO ESTÁ SETTEADO
    dataSede: any;
    setDataSede: any;

    // Datos Actores en relación con la problematica en acceso 3
    dataTipoEntidad: any;
    setDataTipoEntidad: any;
    dataEntidad: any;
    setDataEntidad: any;
    buscarPrestadores: any;
    userDto:UserLogin | undefined;
    esLSPT:boolean;

    //Modal Reasignar, anexos y nuevo caso
    UsuarioId: any;
    UsuarioLogueado: any;
    AtencionIndividualId: any;    
    dataCasoCreado:CasoResponseInterface;
    setDataCasoCreado:any;
    DefaultCasoResponse: any;
    primeraEtapaFormulario: any;
    reAsignar: any;
    modalReAsignar: any;
    setModalReAsignar: any;
    obtenerDatosReasignados: any;
    dataSourceReasignacion: any;
    createReasignacion: any;    
    setDataSourceFilterReasignar: any;
    dataSourceFilterReasignar: any;
    setDataEstados: any;
    dataEstados: any;

    obtenerDatosAnexos: any;
    dataSourceAnexos: any;
    verAnexos: any;
    asociarAnexoCaso:any;
    obtenerDatosComprobador:any;
    dataComprobador: ComprobadorResponse;
    textoComprobador:string;
    setTextoComprobador:any;
    tituloComprobador:any,
    setTituloComprobador:any;
}

export interface PersonaInterface {
    Id: number;
    TipoDocumentoId: number,
    VcDocumento: string,
    VcPrimerApellido: string,
    VcSegundoApellido: string,
    VcPrimerNombre: string,
    VcSegundoNombre: string,
    GeneroId: number,
    VcOtroGenero: string,
    VcNombreIdentitario: string,
    OrientacionSexualId: number,
    VcOtraOrientacionSexual: string,
    SexoId: number,
    DtFechaNacimiento: string,
    EnfoquePoblacionalId: number,
    HechoVictimizanteId: number,
    DepartamentoOrigenVictimaId: number,
    MunicipioOrigenVictimaId: number,
    EtniaId: number,
    SubEtniaId: number,
    PoblacionPrioritariaId: number,
    SubPoblacionPrioritariaId: number,
    VcCorreo: string,
    UsuarioId: number,
    UsuarioActualizacionId: number,
    RegimenId: number,
    AseguradoraId: number,
    EstadoAfiliacionId: number,
    NivelSisbenId: number,
    InstitucionInstrumentoVinculadoId: number,
    PaisId: number,
    DepartamentoId: number,
    MunicipioId: number,
    LocalidadId: number,
    UpzId: number,
    BarrioId: number,
    ZonaId: number,
    TxDatosContactoAclaraciones: string,
    VcTelefono1: string,
    VcTelefono2: string,
    VcCorreoEntidad: string,
    VcNombreContacto: string,
    VcTelefonoContacto: string,

    VcDireccion: string,
    viaId: number,
    vcNumeroVia: string,
    letraViaId: number,
    adicionalLetraViaId: number,
    orientacionViaId: number,
    vcNumMarca: string,
    letraNumMarcaId: number,
    vcNumeroCasa: string,
    orientacionCasaId: number,
}


export const DefaultPerson: PersonaInterface =
{
    Id: 0,
    TipoDocumentoId: 0,
    VcDocumento: "",
    VcPrimerApellido: "",
    VcSegundoApellido: "",
    VcPrimerNombre: "",
    VcSegundoNombre: "",
    GeneroId: 0,
    VcOtroGenero: "",
    VcNombreIdentitario: "",
    OrientacionSexualId: 0,
    VcOtraOrientacionSexual: "",
    SexoId: 0,
    DtFechaNacimiento: "",
    EnfoquePoblacionalId: 0,
    HechoVictimizanteId: 0,
    DepartamentoOrigenVictimaId: 0,
    MunicipioOrigenVictimaId: 0,
    EtniaId: 0,
    SubEtniaId: 0,
    PoblacionPrioritariaId: 0,
    SubPoblacionPrioritariaId: 0,
    VcCorreo: "",
    UsuarioId: 0,
    UsuarioActualizacionId: 0,
    RegimenId: 0,
    AseguradoraId: 0,
    EstadoAfiliacionId: 0,
    NivelSisbenId: 0,
    InstitucionInstrumentoVinculadoId: 0,
    PaisId: CODIGO_PAIS_COLOMBIA,
    DepartamentoId: CODIGO_DEPARTAMENTO_BOGOTA,
    MunicipioId: CODIGO_MUNICIPIO_BOGOTA,
    LocalidadId: 0,
    UpzId: 0,
    BarrioId: 0,
    ZonaId: 0,
    TxDatosContactoAclaraciones: "",
    VcTelefono1: "",
    VcTelefono2: "",
    VcCorreoEntidad: '',
    VcNombreContacto: '',
    VcTelefonoContacto: '',

    VcDireccion: "",
    viaId: 0,
    vcNumeroVia: "",
    letraViaId: 0,
    adicionalLetraViaId: 0,
    orientacionViaId: 0,
    vcNumMarca: "",
    letraNumMarcaId: 0,
    vcNumeroCasa: "",
    orientacionCasaId: 0,
}


export const EditPersona: PersonaInterface =
{
    Id: 0,
    TipoDocumentoId: 0,
    VcDocumento: '',
    VcPrimerNombre: '',
    VcSegundoNombre: '',
    VcPrimerApellido: '',
    VcSegundoApellido: '',
    GeneroId: 0,
    VcOtroGenero: '',
    VcNombreIdentitario: '',
    OrientacionSexualId: 0,
    VcOtraOrientacionSexual: '',
    SexoId: 0,
    DtFechaNacimiento: '',
    EnfoquePoblacionalId: 0,
    HechoVictimizanteId: 0,
    DepartamentoOrigenVictimaId: 0,
    MunicipioOrigenVictimaId: 0,
    EtniaId: 0,
    SubEtniaId: 0,
    PoblacionPrioritariaId: 0,
    SubPoblacionPrioritariaId: 0,
    VcCorreo: '',
    UsuarioId: 0,
    UsuarioActualizacionId: 0,
    RegimenId: 0,
    AseguradoraId: 0,
    EstadoAfiliacionId: 0,
    NivelSisbenId: 0,
    InstitucionInstrumentoVinculadoId: 0,
    PaisId: CODIGO_PAIS_COLOMBIA,
    DepartamentoId: CODIGO_DEPARTAMENTO_BOGOTA,
    MunicipioId: CODIGO_MUNICIPIO_BOGOTA,
    LocalidadId: 0,
    UpzId: 0,
    BarrioId: 0,
    ZonaId: 0,
    VcDireccion: '',
    viaId: 0,
    vcNumeroVia: '',
    letraViaId: 0,
    adicionalLetraViaId: 0,
    orientacionViaId: 0,
    vcNumMarca: '',
    letraNumMarcaId: 0,
    vcNumeroCasa: '',
    orientacionCasaId: 0,
    TxDatosContactoAclaraciones: '',
    VcTelefono1: '',
    VcTelefono2: '',
    VcCorreoEntidad: '',
    VcNombreContacto: '',
    VcTelefonoContacto: '',
};


export interface CasoInterface {
    Id: number;
    ROTipoDocumentoId: number;
    ROVcDocumento: string;
    VcPrimerApellido: string;
    VcSegundoApellido: string;
    VcPrimerNombre: string;
    VcSegundoNombre: string;
    PersonaId: number;
    UsuarioId: number;
    CanalAtencionId: number;
    VcTurnoSat: string;
    TipoSolicitudId: number;
    MotivoId: number;
    SubMotivoId: number;
    TxAclaracionMotivo: string;
    TxGestionRealizada: string;
    VcRadicadoBte: string;
    EstadoId: number;
    Anexo: undefined;
    TipoActor: string;
    RegimenId: number;
    AseguradoraId: number;
    TipoPrestador: string;
    VcNombrePrestador: string;
    PrestadorId: number;
    SedeId: number;
    TipoEntidadId: number;
    EntidadId: number;
    VcNombreEntidad: string;
    VcNombreSedeArea: string;
    VcTablaActores: number;
    VcTelefonoContacto: string;
    VcNombreContacto: string;
    VcCorreoEntidad: string;
}


export interface CasoResponseInterface {
    id: number;
    estadoId: number;
    usuarioId: number;
}

export const DefaultCasoResponse: CasoResponseInterface = {
    id: 0,
    estadoId: 0,
    usuarioId: 0,
}

export const DefaultDatoCaso: CasoInterface = {
    Id: 0,
    ROTipoDocumentoId: 0,
    ROVcDocumento: '',
    VcPrimerApellido: '',
    VcSegundoApellido: '',
    VcPrimerNombre: '',
    VcSegundoNombre: '',
    PersonaId: 0,
    UsuarioId: 0,
    CanalAtencionId: 0,
    VcTurnoSat: '',
    TipoSolicitudId: 0,
    MotivoId: 0,
    SubMotivoId: 0,
    TxAclaracionMotivo: '',
    TxGestionRealizada: '',
    VcRadicadoBte: '',
    EstadoId: 0,
    Anexo: undefined,
    TipoActor: '1',
    RegimenId: 0,
    AseguradoraId: 0,
    TipoPrestador: '',
    VcNombrePrestador: '',
    PrestadorId: 0,
    SedeId: 0,
    TipoEntidadId: 0,
    EntidadId: 0,
    VcNombreEntidad: '',
    VcNombreSedeArea: '',
    VcTablaActores: 0,
    VcTelefonoContacto: '',
    VcNombreContacto: '',
    VcCorreoEntidad: '',
}

export const EditDatoCaso: CasoInterface = {
    Id: 0,
    ROTipoDocumentoId: 0,
    ROVcDocumento: '',
    VcPrimerApellido: '',
    VcSegundoApellido: '',
    VcPrimerNombre: '',
    VcSegundoNombre: '',
    PersonaId: 0,
    UsuarioId: 0,
    CanalAtencionId: 0,
    VcTurnoSat: '',
    TipoSolicitudId: 0,
    MotivoId: 0,
    SubMotivoId: 0,
    TxAclaracionMotivo: '',
    TxGestionRealizada: '',
    VcRadicadoBte: '',
    EstadoId: 0,
    Anexo: undefined,
    TipoActor: '1',
    RegimenId: 0,
    AseguradoraId: 0,
    TipoPrestador: '',
    VcNombrePrestador: '',
    PrestadorId: 0,
    SedeId: 0,
    TipoEntidadId: 0,
    EntidadId: 0,
    VcNombreEntidad: '',
    VcNombreSedeArea: '',
    VcTablaActores: 0,
    VcTelefonoContacto: '',
    VcNombreContacto: '',
    VcCorreoEntidad: '',
}


export interface DataRowActores {
    id: number;
    atencionIndividualId: number;
    tipoActorId: number;
    tipoId: number;
    sedeId: number;
    usuarioId: number;
    vcNombreTipo: string;
    vcNombreSede: string;
    vcNombreTipoActor: string;
}

export interface NuevoActorInterface {
    ParametroIdPadre: number;
    VcNombrePadre: string;
    ParametroIdHijo: number;
    VcNombreHijo: string;
}

export interface NuevoActorResponse {
    padreId: number;
    hijoId: number;
}

export interface BandejaAtencionesIndividualContextInterface {
    UsuarioId: any;
    UsuarioLogueado: any;
    AtencionIndividualId: any;
    PersonaIndividualId: any;

    dataSource: any;
    dataSourceFilter: any;
    setDataSourceFilter: any;
    dataSourceFilterSeguimiento: any;
    setDataSourceFilterSeguimiento: any;
    dataSourceFilterReasignar: any;
    setDataSourceFilterReasignar: any;
    setDataSource: any;
    dataUsuarios: any;

    modalReAsignar: any;
    setModalReAsignar: any;
    obtenerDatosReasignados: any;
    dataSourceReasignacion: any;
    createReasignacion: any;

    modalSeguimiento: any;
    setModalSeguimiento: any;
    obtenerDatosSeguimiento: any;
    dataSourceSeguimiento: any;
    createSeguimiento: any;

    modalOtrosCasos: any;
    setModalOtrosCasos: any;
    dataSourceOtrosCasos: any;
    obtenerDatosOtrosCasos: any;

    modalAnexos: any;
    setModalAnexos: any;
    dataSourceAnexos: any;
    obtenerDatosAnexos: any;

    modalVisiblePdf: any;
    dataSourcePdf: any;
    setModalVisiblePdf: any;
}


export interface ValidacionParametro{
    obligatorio: boolean;
    VcCodigoInterno: string;
    VcMensaje: string;
}

export const DefaultRol : Rol = 
{
    id:0,
    bEstado:false,
    dtFechaActualizacion:'',
    dtFechaAnulacion:'',
    dtFechaCreacion:'',
    moduloId:0,
    vcCodigoInterno:'',
    vcNombre:'',
}
