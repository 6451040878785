/* eslint-disable react/jsx-no-target-blank */
import {useState, useEffect} from 'react'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { MenuSC } from '../../../../app/services'
import {UserLogin, Menu} from '../../../../app/models'
import { getAuth } from "src/app/utilities";





export function AsideMenuMain() {
  
  const [userDto] = useState<UserLogin | undefined>(getAuth());
  const [menu, setMenu] = useState<Menu[]>([]);

  const obtenerMenu = async(user:UserLogin) =>{
    const { call } = MenuSC(user);
    const response = await call;
    const menuArray: Menu[] = response.data.models;
    setMenu(menuArray);
  }  


  function renderMenuItems(menu: Menu[]) {
    if(menu.length == 0)
      return false;

    return menu.map((item) => {
      if (item.hijos !==null) {
        return (
            <AsideMenuItemWithSub title={item.vcNombre} to={item.vcRedireccion} key={item.id}  fontIcon={item.icono} >
              {renderMenuItems(item.hijos)}
            </AsideMenuItemWithSub>
        );
        }
      else {
        return (
            <AsideMenuItem to={item.vcRedireccion} title={item.vcNombre} hasBullet={true} key={item.id}/>
        );
      }
    });
  }
  
  useEffect(() => {
    if(userDto != null && userDto != undefined)
      obtenerMenu(userDto);
  }, [userDto]);
  
  return (
    <>
      {renderMenuItems(menu)}
      {/* 
      <AsideMenuItem
        to='/dashboard'
        title='Inicio'
        icon='/media/icons/duotune/art/art002.svg'
        fontIcon='bi-house-heart-fill'
      />
      <AsideMenuItem
        to='/builder'
        title='Tutoriales'
        fontIcon='bi-play-btn-fill'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administrador</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Personas'
        fontIcon='bi-person'
      >
        <AsideMenuItem to="/usuarios" title="Usuarios" hasBullet={true} />
        <AsideMenuItem to="/usuariosPorRoles" title="Usuarios Roles" hasBullet={true} />
      </AsideMenuItemWithSub>


      <AsideMenuItemWithSub
        to='/error'
        title='Configuración'
        fontIcon='bi-gear-fill'
      >
        <AsideMenuItem to='/modulos' title='Modulos' hasBullet={true} />
        <AsideMenuItem to='/roles' title='Roles' hasBullet={true} />
        <AsideMenuItem to='/actividades' title='Actividades' hasBullet={true} />
        <AsideMenuItem to='/actividadesPorRoles' title='Actividades Roles' hasBullet={true} />
        <AsideMenuItem to='/parametros' title='Parámetros' hasBullet={true} />
        <AsideMenuItemWithSub
          title='Semaforización'
          to='/crafted/pages/profile'
          hasBullet={true}
        >
          <AsideMenuItem to='/semaforizacion/parametrizacionANS' title='Parametrización ANS' hasBullet={true} />
          <AsideMenuItem to='/semaforizacion/festivos' title='Festivos' hasBullet={true} />
          <AsideMenuItem to='/semaforizacion/rangos' title='Rangos de Gestión' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Orientación</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Atenciones Individuales'
        fontIcon='bi-life-preserver'
      >
        <AsideMenuItem to='/orientacion/individuales/registro' title='Registro' hasBullet={true} />
        <AsideMenuItem to='/orientacion/individuales/bandeja' title='Bandeja Casos' hasBullet={true} />
        <AsideMenuItem to='/orientacion/individuales/seguimiento' title='Seguimiento' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='#'
        title='Orientaciones Web'
        fontIcon='bi-send-check'
      >
        <AsideMenuItem to='/orientacion/web/registro' title='Registro' hasBullet={true} />
        <AsideMenuItem to='/orientacion/web/bandeja' title='Bandeja Casos' hasBullet={true} />
        <AsideMenuItem to='/orientacion/web/seguimiento' title='Seguimiento' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='#'
        title='Grupales y Capacitaciones'
        fontIcon='bi-megaphone-fill'
      >
        <AsideMenuItem to='/orientacion/grupales/registro' title='Registro' hasBullet={true} />
        <AsideMenuItem to='/orientacion/grupales/bandeja' title='Bandeja Casos' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        fontIcon='bi-file-earmark-spreadsheet'
        to='/apps/user-management/users'
        title='Reportes'
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      */}
      
    </>
  )
}
