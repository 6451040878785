import { createSlice } from '@reduxjs/toolkit';
import { UserSC, UserLogin, UserLoginEmptyState } from 'src/app/models';

export const userSlice = createSlice({
  name: 'user',
  initialState: UserLoginEmptyState,
  reducers: {
    createUser: (state, action) => action.payload,
    modifyUser: (state, action) => ({ ...state, ...action.payload }),
    resetUser: () => UserLoginEmptyState
  }
});

export const { createUser, modifyUser, resetUser } = userSlice.actions;

export default userSlice.reducer;