import { useSelector } from "react-redux";
import { SiCuentanosStore } from "../redux/store";
import { Navigate, Outlet, useLocation } from "react-router";

const PrivateValidationFragment = <Outlet />;
const PublicValidationFragment = <Navigate to='/dashboard' />;
const DASHBOARD_ROUTE = '/dashboard';
export const AuthGuard = () => {
    const location = useLocation();
    const menuState = useSelector((state: SiCuentanosStore) => state.menu);
    return menuState.includes(location.pathname) || location.pathname == DASHBOARD_ROUTE  ? PrivateValidationFragment : PublicValidationFragment;
};