import axios from 'axios';
import store from '../redux/store';
import { setLoading } from '../redux/states/loading.state';

export const AxiosInterceptor = () => {


  axios.interceptors.request.use(
    (request) => {
    store.dispatch(setLoading(true));
    //console.log('Request', request);
    return request;
    },
    (error) => {
      store.dispatch(setLoading(false));
      //console.log('Error', error);
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
        store.dispatch(setLoading(false));
        //console.log('Response', response);
        return response;
    },
    (error) => {
        store.dispatch(setLoading(false));
        //console.log('Error', error);
        return Promise.reject(error);
    }
  );
};