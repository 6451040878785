import React, { useState, useEffect } from "react";
import { Col, Card,  Modal, Container } from "react-bootstrap";
import axios from 'axios'
import RolesForm from "./RolesForm";


import Swal from "sweetalert2";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { CrudButton } from '../../../components/CrudButton';
import DataTable, { TableColumn } from 'react-data-table-component';
import { NoRecords, PaginationProperties, customStyles } from "../../../components/DataTableConfig";
import { SwalToast } from "../../../components/SwalToast";


interface formularioRoles{
    Id: number,
    VcNombre: string,
    ModuloId: number,
    VcCodigoInterno: string,
    BEstado: boolean
}


interface RolesContextInterface{
    createRol:any;
    updateRol:any;
    setModalVisible:any;
    dataSourceModulos:any;
    data:any;
}

export const rolesContext = React.createContext<RolesContextInterface | null>(null);


export function Roles(props: any) 
{

    const [modalVisible, setModalVisible] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceModulos, setDataSourceModulos] = useState([]);

    const obtenerDatos = async () => {
        await axios.get(getAdministradorApiUrl('Rol'))
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosModulos = async () => {
        await axios.get(getAdministradorApiUrl('Modulo'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : []
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre });
                });
                setDataSourceModulos(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const createRol = async (data: formularioRoles) => {
        await axios.post(getAdministradorApiUrl('Rol'), data)
            .then((response) => {
                setModalVisible(false);
                obtenerDatos();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede crear el registro'
                });                  
            });
    }

    const updateRol = async (data: formularioRoles) => {
        await axios.put(getAdministradorApiUrl(`Rol/${data.Id}`), data)
            .then((response) => {
                setModalVisible(false);
                obtenerDatos();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede editar el registro'
                });                 
            });
    }

    const deleteRol = async (id: any) => {
        await axios.delete(getAdministradorApiUrl(`Rol/${id}`))
            .then((response) => {
                // Obtenemos los datos
                obtenerDatos();
                SwalToast.fire({
                    icon: 'success',
                    title: response.data.mensaje
                });                
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
                SwalToast.fire({
                    icon: 'error',
                    title: 'No se puede eliminar debido a la integridad referencial'
                });                   
            });
    }

    useEffect(() => {
        obtenerDatos();
        obtenerDatosModulos();
    }, []);

    const [form, setForm] = useState({
        Id: 0,
        VcNombre: '',
        ModuloId: 0,
        VcCodigoInterno: '',
        BEstado: true
    });

    const nuevo = () => {
        setForm({
            Id: 0,
            VcNombre: '',
            ModuloId: 0,
            VcCodigoInterno: '',
            BEstado: true
        });
        setModalVisible(true);
    }

    const editar = (registro: any) => {
        setForm({
            Id: registro.id,
            VcNombre: registro.vcNombre,
            ModuloId: registro.moduloId,
            VcCodigoInterno: registro.vcCodigoInterno,
            BEstado: registro.bEstado
        });
        setModalVisible(true);
    }

    const eliminar = (registro: any) => {
        Swal.fire({
            title: 'Confirmar eliminación',
            text: "No podrás recuperar este registro.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteRol(registro.id)
            }
        })
    }

    const datosRolesContext: RolesContextInterface = {
        createRol: createRol,
        updateRol: updateRol,
        setModalVisible: setModalVisible,
        dataSourceModulos: dataSourceModulos,
        data: form,
    }

    interface DataRow {
        id: number,
        vcNombre: string,
        ModuloId: number,
        VcCodigoInterno: string,
        bEstado: boolean
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: '#',
            selector: row => row.id,
            sortable: true,  //ordenar de mayor amenor 
        },
        {
            name: 'Nombre',
            selector: row => row.vcNombre,
            sortable: true,  //ordenar de mayor amenor 
        },
        {
            name: 'Estado',
            cell:(element) => <p>{element.bEstado ? 'Activo' : 'Inactivo'}</p>,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell:(element) =>  
            <>
                <CrudButton onClick={() => eliminar(element)} className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" />
                {" "}
                <CrudButton onClick={() => editar(element)} className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" />
            </>,
            sortable: false,
        },
    ];

    
    return (
    <rolesContext.Provider value={datosRolesContext}>
        <Card>
            <Card.Header>
                <Card.Title className="text-azul-oscuro">GESTIÓN DE ROLES</Card.Title>
            </Card.Header>
            <Card.Body>
                <Container style={{ textAlign: "right" }}>
                    <CrudButton onClick={() => nuevo()} className="btn-sm btn-primaryCustom" text="Crear" icon="bi bi-plus-circle-fill" />
                </Container>
                <br />

                <Container>
                    <Col>
                        <DataTable
                            columns={columns}
                            data={dataSource}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                        />
                    </Col>
                </Container>
                
            </Card.Body>
        </Card>

        <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
            <Modal.Header>
                <div>
                    <h5 className="text-azul-oscuro">{form.Id > 0 ? 'EDITAR' : 'AGREGAR'} ROL</h5>
                </div>
            </Modal.Header>

            <Modal.Body>
                <RolesForm/> 
            </Modal.Body>
        </Modal>
    </rolesContext.Provider>
    );  
}

