export const customStyles = {
    tableWrapper: {
        style: {
            display: 'block',
        },
    },
};

export const PaginationProperties = { 
    rowsPerPageText: 'Filas por página:', 
    rangeSeparatorText: 'de', 
    noRowsPerPage: false, 
    selectAllRowsItem: false, 
    selectAllRowsItemText: 'Todo' 
}


export function NoRecords() {
    return <div>No hay registros disponibles</div>;
}

export const MAX_TEXT_LENGTH = 50;