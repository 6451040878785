export function PdfBase64({pdfBase64})
{
    let dataSrc = `data:application/pdf;base64,${pdfBase64}`;
    return (
        <>
            { 
                pdfBase64 ? 
                <embed src={dataSrc}   width="100%" height="700px" />
                : null
            }
        </>
    );
}