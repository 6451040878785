// Render Prop
import axios from 'axios';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Card, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { ActionModal } from '../../../components/ActionModal';
import { FormikCheckboxUsuario, FormikInput } from '../../../components/FormikInput';
import { FormikSelect } from '../../../components/FormikSelect';
import DataTable from "react-data-table-component";
import { getAdministradorApiUrl } from "../../../../_metronic/helpers/ApiAdministradorHelpers";
import { getParametroApiUrl } from "../../../../_metronic/helpers/ApiParametroHelpers";
import { CrudButton } from "../../../components/CrudButton";
import { customStyles, NoRecords, PaginationProperties } from "../../../components/DataTableConfig";
import { FormConstantsValidation } from '../../../components/FormConstantsValidation';
import UsuariosFormAreas from "./UsuariosFormAreas";
import UsuariosFormCargos from './UsuariosFormCargos';
import UsuariosFormContratos from './UsuariosFormContratos';
import UsuariosFormPuntosAtencion from './UsuariosFormPuntosAtencion';
import { UsuarioContext } from './Usuarios';


const UsuarioSchema = Yup.object().shape({
  TipoDocumentoId: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Campo obligatorio'),
  VcDocumento: Yup.string()
    .required('Campo obligatorio')
    .nullable()
    .test('valida_ti', 'El número de identificación debe ser númerico con 10 u 11 digitos', function (f) {
      const refTipoDocumento = this.resolve(Yup.ref('TipoDocumentoId'));
      if (refTipoDocumento === 16 && ((f?.length !== 10 && f?.length !== 11) || isNaN(f) || !FormConstantsValidation.isValidOnlyNumbers(f?.toString()))) {
        return false;
      } else {
        return true;
      }

    })
    .test('valida_cc', 'El número de identificación debe ser númerico con 6, 7, 8 o 10 digitos', function (f) {
      const refTipoDocumento = this.resolve(Yup.ref('TipoDocumentoId'));

      if (refTipoDocumento === 3 && ((f?.length !== 6 && f?.length !== 7 && f?.length !== 8 && f?.length !== 10) || isNaN(f) || !FormConstantsValidation.isValidOnlyNumbers(f?.toString()))) {
        return false;
      } else {
        return true;
      }

    })
    .test('valida_rc_nuip', 'El número de identificación debe ser alfanúmerico con 10 caracteres o númerico con 11 digitos', function (f) {
      const refTipoDocumento = this.resolve(Yup.ref('TipoDocumentoId'));
      if ((refTipoDocumento === 12 || refTipoDocumento === 'NUIP')) {
        if (f?.length !== 10 || !isNaN(f)) {
          if (f?.length !== 11 || isNaN(f)) {
            return false;
          }
        }
      }
      return true;
    }),
  VcPrimerNombre: Yup.string()
    .test('', 'Caracteres inválidos', function (f) {
      return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
    })
    .required('Campo obligatorio'),
  VcSegundoNombre: Yup.string()
    .test('', 'Caracteres inválidos', function (f) {
      return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
    }),
  VcPrimerApellido: Yup.string()
    .test('', 'Caracteres inválidos', function (f) {
      return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
    })
    .required('Campo obligatorio'),
  VcSegundoApellido: Yup.string()
    .test('', 'Caracteres inválidos', function (f) {
      return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
    }),
  VcCorreo: Yup.string().email('Ingrese un correo válido').required('Campo obligatorio'),
  VcTelefono: Yup.string()
    .required('Campo obligatorio')
    .nullable()
    .test('', 'Teléfono no válido', function (f) {
      return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
    })
    .max(10, "Máximo 10 caracteres"),
  VcDireccion: Yup.string()
    .required('Campo obligatorio')
    .nullable()
    .test('', 'Dirección no válida, permitidos números letras, # y -', function (f) {
      return FormConstantsValidation.isValidOnlyLettersSpaceHashAndDash(f?.toString() || '');
    }),
  TipoEntidadId: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Campo obligatorio'),
  EntidadId: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Campo obligatorio'),
  UnidadPrestacionServiciosId: Yup.number().transform((value) => (isNaN(value) ? undefined : value)).required('Campo obligatorio'),
});

export default function UsuariosForm() {
  const context = useContext(UsuarioContext);

  useEffect(() => {
    obtenerDataParametroDetalle('bTipoEntidad', setDataTiposEntidad);
    obtenerRoles();
    obtenerDataParametroDetalle('bTipoDocumento', setDataTiposDocumento);

    if (context.data.Id > 0) {
      obtenerDataParametroDetalle('bCargos', setDataCargos);
      //obtenerDataParametroDetalle('bPuntoAtencion', setDataPuntosAtencion);
      obtenerDataParametroDetalle('bAreas', setDataAreas);

      //obtenerUsuarioCargos();
      //obtenerUsuarioAreas();
      obtenerUsuarioPuntosAtencion();
      obtenerContratos();

      if (context.data.TipoEntidadId > 0) {
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bEntidad', context.data.TipoEntidadId, setDataEntidad);
      }

      if (context.data.EntidadId > 0) {
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bUnidadPrestacionServicio', context.data.EntidadId, setDataUnidPrestacion);
      }

      if (context.data.UnidadPrestacionServiciosId > 0) {
        obtenerDataParametroDetallePorCodigoInternoDetallePadre('bPuntoAtencion', context.data.UnidadPrestacionServiciosId, setDataPuntosAtencion);
      }      
    }
  }, []);


  const [dataTiposEntidad, setDataTiposEntidad] = useState([]);
  const [dataUnidPrestacion, setDataUnidPrestacion] = useState([]);
  const [dataAreas, setDataAreas] = useState([]);
  const [dataEntidad, setDataEntidad] = useState([]);
  const [dataRoles, setDataRoles] = useState([]);
  const [dataTiposDocumento, setDataTiposDocumento] = useState([]);


  const obtenerDataParametroDetalle = async (codigo, setData) => {
    await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInterno/${codigo}`))
      .then((response) => {
        // Obtenemos los datos
        const dataArray = [];
        const data = response.data.models ? response.data.models : [];
        data.forEach((element) => {
          dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
        });
        setData(dataArray);
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const obtenerDataParametroDetallePorCodigoInternoDetallePadre = async (codigo, idDetalle, setData) => {
    await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInternoDetallePadre/${codigo}/${idDetalle}`))
      .then((response) => {
        // Obtenemos los datos
        const dataArray = [];
        const data = response.data.models ? response.data.models : [];
        data.forEach((element) => {
          dataArray.push({ value: element.id, label: element.vcNombre });
        });
        setData(dataArray);
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const obtenerRoles = async () => {
    await axios.get(getAdministradorApiUrl('Rol'))
      .then((response) => {
        // Obtenemos los datos
        const dataArray = [];
        const data = response.data.models ? response.data.models : [];
        data.forEach(element => {
          dataArray.push({ value: element.id, label: element.vcNombre });
        });
        setDataRoles(dataArray);
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const createUsuario = async (data) => {
    await axios.post(getAdministradorApiUrl('Usuario'), data)
      .then((response) => {
        context.setModalVisible(false);
        context.obtenerDatosPorEstado(context.selectEstado, () => {}); ;
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const updateUsuario = async (data) => {
    await axios.put(getAdministradorApiUrl(`Usuario/${data.Id}`), data)
      .then((response) => {
        context.setModalVisible(false);
        context.obtenerDatosPorEstado(context.selectEstado, () => {}); 
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  //#region Area
  const [dataUsuarioAreas, setDataUsuarioAreas] = useState([]);
  const [visibleArea, setVisibleArea] = useState(false);
  const [titleArea, setTitleArea] = useState('');
  const [formArea, setFormArea] = useState({
    Id: 0,
    UsuarioId: context.data.Id,
    AreaId: 0,
    DtFechaInicio: null,
    DtFechaFin: null
  });

  const obtenerUsuarioAreas = async () => {
    await axios.get(getAdministradorApiUrl(`UsuarioArea/porUsuarioId/${context.data.Id}`))
      .then((response) => {
        console.log(response);
        // Obtenemos los datos
        const data = response.data.models ? response.data.models : [];
        setDataUsuarioAreas(data);
      })
      .catch(e => {
        setDataUsuarioAreas([]);
        // Capturamos los errores
        console.log(e);
      });
  }

  const nuevaArea = () => {
    setFormArea({
      Id: 0,
      UsuarioId: context.data.Id,
      AreaId: 0,
      DtFechaInicio: null,
      DtFechaFin: null
    });
    setTitleArea('AGREGAR ÁREA');
    setVisibleArea(true);
  }

  const editarArea = (registro) => {
    setFormArea({
      Id: registro.id,
      UsuarioId: registro.usuarioId,
      AreaId: registro.areaId,
      DtFechaInicio: registro.dtFechaInicio ? registro.dtFechaInicio.substring(0, 10) : null,
      DtFechaFin: registro.dtFechaFin ? registro.dtFechaFin.substring(0, 10) : null
    });

    setTitleArea('EDITAR ÁREA');
    setVisibleArea(true);
  }

  const eliminarArea = (registro) => {
    Swal.fire({
      title: 'Confirmar eliminación',
      text: "No podrás recuperar este registro.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteArea(registro.id)
      }
    })
  }

  const deleteArea = async (id) => {
    await axios.delete(getAdministradorApiUrl(`UsuarioArea/${id}`))
      .then((response) => {
        // Obtenemos los datos
        obtenerUsuarioAreas();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const asignarArea = (id) => {
    let obj = dataAreas.find(({ value }) => value === id);
    return obj !== undefined ? obj.label : '';
  }
  //#endregion

  //#region Cargo
  const [dataUsuarioCargos, setDataUsuarioCargos] = useState([]);
  const [dataCargos, setDataCargos] = useState([]);
  const [visibleCargo, setVisibleCargo] = useState(false);
  const [titleCargo, setTitleCargo] = useState('');
  const [formCargo, setFormCargo] = useState({
    Id: 0,
    UsuarioId: context.data.Id,
    CargoId: 0,
    DtFechaInicio: new Date(),
    DtFechaFin: new Date()
  });

  const obtenerUsuarioCargos = async () => {
    await axios.get(getAdministradorApiUrl(`UsuarioCargo/porUsuarioId/${context.data.Id}`))
      .then((response) => {
        // Obtenemos los datos
        const data = response.data.models ? response.data.models : [];
        setDataUsuarioCargos(data);
      })
      .catch(e => {
        setDataUsuarioCargos([]);
        // Capturamos los errores
        console.log(e);
      });
  }

  const nuevoCargo = () => {
    setFormCargo({
      Id: 0,
      UsuarioId: context.data.Id,
      CargoId: 0,
      DtFechaInicio: null,
      DtFechaFin: null
    });
    setTitleCargo('AGREGAR CARGO');
    setVisibleCargo(true);
  }

  const editarCargo = (registro) => {
    setFormCargo({
      Id: registro.id,
      UsuarioId: registro.usuarioId,
      CargoId: registro.cargoId,
      DtFechaInicio: registro.dtFechaInicio ? registro.dtFechaInicio.substring(0, 10) : null,
      DtFechaFin: registro.dtFechaFin ? registro.dtFechaFin.substring(0, 10) : null
    });

    setTitleCargo('EDITAR CARGO');
    setVisibleCargo(true);
  }

  const eliminarCargo = (registro) => {
    Swal.fire({
      title: 'Confirmar eliminación',
      text: "No podrás recuperar este registro.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCargo(registro.id)
      }
    })
  }

  const deleteCargo = async (id) => {
    await axios.delete(getAdministradorApiUrl(`UsuarioCargo/${id}`))
      .then((response) => {
        // Obtenemos los datos
        obtenerUsuarioCargos();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const asignarCargo = (id) => {
    let obj = dataCargos.find(({ value }) => value === id);
    return obj !== undefined ? obj.label : '';
  }
  //#endregion

  //#region Contrato
  const [dataContratos, setDataContratos] = useState([]);
  const [visibleContrato, setVisibleContrato] = useState(false);
  const [titleContrato, setTitleContrato] = useState('');
  const [formContrato, setFormContrato] = useState({
    Id: 0,
    UsuarioId: context.data.Id,
    INumero: null,
    IAño: null,
    DtFechaInicio: null,
    DtFechaFin: null,
    BProrroga: false,
    DtFechaProrroga: null,
    BTerminacion: false,
    DtFechaTerminacion: null
  });

  const obtenerContratos = async () => {
    await axios.get(getAdministradorApiUrl(`Contrato/GetUsuarioId/${context.data.Id}`))
      .then((response) => {
        console.log(response)
        // Obtenemos los datos
        const data = response.data.models ? response.data.models : [];
        setDataContratos(data);
      })
      .catch(e => {
        setDataContratos([]);
        // Capturamos los errores
        console.log(e);
      });
  }

  const nuevoContrato = () => {
    setFormContrato({
      Id: 0,
      UsuarioId: context.data.Id,
      INumero: null,
      IAño: null,
      DtFechaInicio: null,
      DtFechaFin: null,
      BProrroga: false,
      DtFechaProrroga: null,
      BTerminacion: false,
      DtFechaTerminacion: null
    })
    setTitleContrato('AGREGAR CONTRATO');
    setVisibleContrato(true);
  }

  const editarContrato = (registro) => {
    setFormContrato({
      Id: registro.id,
      UsuarioId: registro.usuarioId,
      INumero: registro.iNumero,
      IAño: registro.iAño,
      DtFechaInicio: registro.dtFechaInicio ? registro.dtFechaInicio.substring(0, 10) : null,
      DtFechaFin: registro.dtFechaFin ? registro.dtFechaFin.substring(0, 10) : null,
      BProrroga: registro.bProrroga,
      DtFechaProrroga: registro.dtFechaProrroga ? registro.dtFechaProrroga.substring(0, 10) : null,
      BTerminacion: registro.bTerminacion,
      DtFechaTerminacion: registro.dtFechaTerminacion ? registro.dtFechaTerminacion.substring(0, 10) : null
    })
    setTitleContrato('EDITAR CONTRATO');
    setVisibleContrato(true);
  }

  const eliminarContrato = (registro) => {
    Swal.fire({
      title: 'Confirmar eliminación',
      text: "No podrás recuperar este registro.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteContrato(registro.id)
      }
    })
  }

  const deleteContrato = async (id) => {
    await axios.delete(getAdministradorApiUrl(`Contrato/${id}`))
      .then((response) => {
        // Obtenemos los datos
        obtenerContratos();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }
  //#endregion

  //#region Punto Atencion
  const [dataUsuarioPuntosAtencion, setDataUsuarioPuntosAtencion] = useState([]);
  const [dataPuntosAtencion, setDataPuntosAtencion] = useState([]);
  const [visiblePuntoAtencion, setVisiblePuntoAtencion] = useState(false);
  const [titlePuntoAtencion, setTitlePuntoAtencion] = useState('');
  const [formPuntoAtencion, setFormPuntoAtencion] = useState({
    Id: 0,
    UsuarioId: context.data.Id,
    PuntoAtencionId: 0,
    DtFechaInicio: null,
    DtFechaFin: null
  });

  const obtenerUsuarioPuntosAtencion = async () => {
    await axios.get(getAdministradorApiUrl(`UsuarioPuntoAtencion/porUsuarioId/${context.data.Id}`))
      .then((response) => {
        // Obtenemos los datos
        const data = response.data.models ? response.data.models : [];
        setDataUsuarioPuntosAtencion(data);
      })
      .catch(e => {
        setDataUsuarioPuntosAtencion([])
        // Capturamos los errores
        console.log(e);
      });
  }

  const nuevoPuntoAtencion = () => {
    setFormPuntoAtencion({
      Id: 0,
      UsuarioId: context.data.Id,
      PuntoAtencionId: 0,
      DtFechaInicio: null,
      DtFechaFin: null
    });
    setTitlePuntoAtencion('AGREGAR PUNTO DE ATENCIÓN');
    setVisiblePuntoAtencion(true);
  }

  const editarPuntoAtencion = (registro) => {
    setFormPuntoAtencion({
      Id: registro.id,
      UsuarioId: context.data.Id,
      PuntoAtencionId: registro.puntoAtencionId,
      DtFechaInicio: registro.dtFechaInicio ? registro.dtFechaInicio.substring(0, 10) : null,
      DtFechaFin: registro.dtFechaFin ? registro.dtFechaFin.substring(0, 10) : null
    });

    setTitlePuntoAtencion('EDITAR PUNTO DE ATENCIÓN');
    setVisiblePuntoAtencion(true);
  }

  const eliminarPuntoAtencion = (registro) => {
    Swal.fire({
      title: 'Confirmar eliminación',
      text: "No podrás recuperar este registro.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        deletePuntoAtencion(registro.id)
      }
    })
  }

  const deletePuntoAtencion = async (id) => {
    await axios.delete(getAdministradorApiUrl(`UsuarioPuntoAtencion/${id}`))
      .then((response) => {
        // Obtenemos los datos
        obtenerUsuarioPuntosAtencion();
      })
      .catch(e => {
        // Capturamos los errores
        console.log(e);
      });
  }

  const asignarPuntoAtencion = (id) => {
    let obj = dataPuntosAtencion.find(({ value }) => value === id);
    return obj !== undefined ? obj.label : '';
  }
  //#endregion

  return (
    <div>
      <Formik
        initialValues={context.data}
        validationSchema={UsuarioSchema}
        onReset={(values, formProps) => {
          context.setModalVisible(false);
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          if (values.Id == 0) {
            createUsuario(values);
          } else {
            updateUsuario(values);
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          setFieldValue,
          values
        }) => (
          <Form autoComplete='off'>
            <div className='row'>
              <div className='col-12'>
                <h5>ENTIDAD</h5>
                <Card>
                  <Card.Body>
                    <div className="row">
                      <FormikSelect
                        options={dataTiposEntidad}
                        onChange={selectedOption => {
                          setFieldValue("TipoEntidadId", selectedOption?.value);
                          setFieldValue("EntidadId", null);
                          setFieldValue("UnidadPrestacionServiciosId", null);
                          obtenerDataParametroDetallePorCodigoInternoDetallePadre('bEntidad', selectedOption?.value, setDataEntidad);
                        }}
                        value={values.TipoEntidadId}
                        labelName='Tipo de entidad'
                        nameSelect='TipoEntidadId'
                        className='col-12 col-sm-6 col-md-4 mt-5'
                        placeHolder='Seleccione'
                        required={true}
                      />
                      <FormikSelect
                        options={dataEntidad}
                        onChange={selectedOption => {
                          setFieldValue("EntidadId", selectedOption.value);
                          setFieldValue("UnidadPrestacionServiciosId", null);
                          obtenerDataParametroDetallePorCodigoInternoDetallePadre('bUnidadPrestacionServicio', selectedOption.value, setDataUnidPrestacion);
                        }}
                        value={values.EntidadId}
                        labelName='Entidad'
                        nameSelect='EntidadId'
                        className='col-12 col-sm-6 col-md-4 mt-5'
                        placeHolder='Seleccione'
                        required={true}
                      />
                      <FormikSelect
                        options={dataUnidPrestacion}
                        onChange={selectedOption => setFieldValue("UnidadPrestacionServiciosId", selectedOption.value)}
                        value={values.UnidadPrestacionServiciosId}
                        labelName='Unidad prestación de servicios'
                        nameSelect='UnidadPrestacionServiciosId'
                        className='col-12 col-sm-6 col-md-4 mt-5'
                        placeHolder='Seleccione'
                        required={true}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-12'>
                <h5>DATOS DEL USUARIO</h5>
                <Card>
                  <Card.Body>
                    <div className="row">
                      <FormikSelect
                        options={dataTiposDocumento}
                        onChange={selectedOption => {
                          setFieldValue("TipoDocumentoId", selectedOption.value);
                        }}
                        value={values.TipoDocumentoId}
                        labelName='Tipo de identificación'
                        nameSelect='TipoDocumentoId'
                        className='col-12 col-md-6 mt-5'
                        placeHolder='Seleccione'
                        required={true}
                      />
                      <FormikInput
                        labelName='Número de identificación'
                        nameSelect='VcDocumento'
                        className='col-12 col-md-6 mt-5'
                        required={true}
                      />
                      <FormikInput
                        labelName='Primer nombre'
                        nameSelect='VcPrimerNombre'
                        className='col-12 col-sm-6 col-md-3 mt-5'
                        onKeyDown={FormConstantsValidation.handleOnlyLetters}
                        onKeyUp={(event) => {
                          event.target.value = values.VcPrimerNombre.toUpperCase();
                          values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                        }}
                        required={true}
                      />
                      <FormikInput
                        labelName='Segundo nombre'
                        nameSelect='VcSegundoNombre'
                        className='col-12 col-sm-6 col-md-3 mt-5'
                        onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                        onKeyUp={(event) => {
                          event.target.value = values.VcSegundoNombre.toUpperCase();
                          values.VcSegundoNombre = values.VcSegundoNombre.toUpperCase();
                        }}
                      />
                      <FormikInput
                        labelName='Primer apellido'
                        nameSelect='VcPrimerApellido'
                        className='col-12 col-sm-6 col-md-3 mt-5'
                        onKeyDown={FormConstantsValidation.handleOnlyLetters}
                        onKeyUp={(event) => {
                          event.target.value = values.VcPrimerApellido.toUpperCase();
                          values.VcPrimerApellido = values.VcPrimerApellido.toUpperCase();
                        }}
                        required={true}
                      />
                      <FormikInput
                        labelName='Segundo apellido'
                        nameSelect='VcSegundoApellido'
                        className='col-12 col-sm-6 col-md-3 mt-5'
                        onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                        onKeyUp={(event) => {
                          event.target.value = values.VcSegundoApellido.toUpperCase();
                          values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                        }}
                      />
                      <FormikInput
                        labelName='Correo electrónico'
                        nameSelect='VcCorreo'
                        className='col-12 col-md-6 mt-5'
                        required={true}
                      />
                      <FormikInput
                        labelName='Teléfono'
                        nameSelect='VcTelefono'
                        className='col-12 col-md-6 mt-5'
                        required={true}
                        onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                      />
                      <FormikInput
                        labelName='Dirección'
                        nameSelect='VcDireccion'
                        className='col-12 col-md-6 mt-5'
                        required={true}
                      />
                      <FormikCheckboxUsuario
                        labelName='Estado'
                        nameSelect='IEstado'
                        className='col-12 col-md-6 mt-5'
                        value={values.IEstado}
                        required={true}
                        onChange={input => {
                          //debugger;
                          if(!input.target.checked) {
                              setFieldValue('IEstado', 2);
                          } else {
                              setFieldValue('IEstado', 1);
                          }
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>

            {context.data.Id > 0 ?
              <div className='row mt-5'>
                <div className='col-12'>
                  <Tabs defaultActiveKey="tabPuntosAtencion" id="uncontrolled-tab-example">
                    <Tab eventKey="tabPuntosAtencion" title="PUNTOS DE ATENCIÓN">
                      <div className="row">
                        <div className='col-12 col-md-7'>
                          <h5>PUNTOS DE ATENCIÓN</h5>
                        </div>
                        <div className='col-12 col-md-5' style={{ textAlign: "right" }}>
                          <a href="#" onClick={() => nuevoPuntoAtencion()} className="btn btn-xs btn-icon btn-primaryCustom">
                            <i className="bi bi-plus-lg fs-5"></i>
                          </a>
                        </div>
                        <div className="col-12">
                          <DataTable
                            columns={[
                              {
                                name: 'Id',
                                cell: (element) => <p>{element.id}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Punto De Atención',
                                cell: (element) => <p>{asignarPuntoAtencion(element.puntoAtencionId)}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Fecha Inicio',
                                cell: (element) => <p>{element.dtFechaInicio.substring(0, 10)}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Fecha Fin',
                                cell: (element) => <p>{element.dtFechaFin.substring(0, 10)}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Acciones',
                                cell: (element) =>
                                  <div>
                                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" onClick={() => eliminarPuntoAtencion(element)} />
                                    {" "}
                                    <CrudButton className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" onClick={() => editarPuntoAtencion(element)} />
                                  </div>,
                                sortable: false,
                              }
                            ]}
                            data={dataUsuarioPuntosAtencion}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="tabContratos" title="CONTRATOS">
                      <div className="row">
                        <div className='col-12 col-md-7'>
                          <h5>CONTRATOS</h5>
                        </div>
                        <div className='col-12 col-md-5' style={{ textAlign: "right" }}>
                          <a href="#" onClick={() => nuevoContrato()} className="btn btn-xs btn-icon btn-primaryCustom">
                            <i className="bi bi-plus-lg fs-5"></i>
                          </a>
                        </div>
                        <div className="col-12">
                          <DataTable
                            columns={[
                              {
                                name: '# Contrato',
                                cell: (element) => <p>{element.iNumero}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Año',
                                cell: (element) => <p>{element.iAño}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Fecha Inicio',
                                cell: (element) => <p>{element.dtFechaInicio.substring(0, 10)}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Fecha Fin',
                                cell: (element) => <p>{element.dtFechaFin.substring(0, 10)}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Fecha prórroga',
                                cell: (element) => <p>{element.bProrroga ? element.dtFechaProrroga?.substring(0, 10) : ''}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Fecha terminación ant.',
                                cell: (element) => <p>{element.bTerminacion ? element.dtFechaTerminacion?.substring(0, 10) : ''}</p>,
                                sortable: true,
                              },
                              {
                                name: 'Acciones',
                                cell: (element) =>
                                  <div>
                                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-trash-fill" onClick={() => eliminarContrato(element)} />
                                    {" "}
                                    <CrudButton className="btn-icon btn-sm btn-primary" icon="bi bi-pencil-fill" onClick={() => editarContrato(element)} />
                                  </div>,
                                sortable: false,
                              }
                            ]}
                            data={dataContratos}
                            pagination
                            customStyles={customStyles}
                            striped={true}
                            paginationComponentOptions={PaginationProperties}
                            noDataComponent={<NoRecords />}
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div> : null}

            <div className="row mt-2">
              <div className="col-12">
                <button type="submit" className="btn btn-sm btn-primary float-end mx-2" disabled={isSubmitting} >
                  Guardar
                </button>
                <button type='reset' className="btn btn-sm btn-light float-end">
                  Cancelar
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <ActionModal visible={visibleArea} title={titleArea} handleClose={setVisibleArea}>
        <UsuariosFormAreas dataAreas={dataAreas} obtenerDatos={obtenerUsuarioAreas} setModalVisible={setVisibleArea} data={formArea} />
      </ActionModal>

      <ActionModal visible={visibleCargo} title={titleCargo} handleClose={setVisibleCargo}>
        <UsuariosFormCargos dataCargos={dataCargos} obtenerDatos={obtenerUsuarioCargos} setModalVisible={setVisibleCargo} data={formCargo} />
      </ActionModal>

      <ActionModal visible={visibleContrato} title={titleContrato} size={'lg'} handleClose={setVisibleContrato}>
        <UsuariosFormContratos obtenerDatos={obtenerContratos} setModalVisible={setVisibleContrato} data={formContrato} />
      </ActionModal>

      <ActionModal visible={visiblePuntoAtencion} title={titlePuntoAtencion} handleClose={setVisiblePuntoAtencion}>
        <UsuariosFormPuntosAtencion dataPuntosAtencion={dataPuntosAtencion} obtenerDatos={obtenerUsuarioPuntosAtencion} setModalVisible={setVisiblePuntoAtencion} data={formPuntoAtencion} />
      </ActionModal>
    </div>
  );
};