import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import Swal from "sweetalert2";
import { FormikSelect } from '../../../../components/FormikSelect';
import { FormikInput, FormikCheckbox, FormikTextArea, FormikInputFile } from '../../../../components/FormikInput';
import { CrudButton } from "../../../../components/CrudButton";
import { getParametroApiUrl } from "../../../../../_metronic/helpers/ApiParametroHelpers";
import { getOrientacionEInformacionApiUrl } from "../../../../../_metronic/helpers/ApiOrientacionEInformacionHelpers";
import { FormConstantsValidation } from "../../../../components/FormConstantsValidation";
import moment from "moment";
import { AUTH_LOCAL_STORAGE_KEY, getAdministradorApiUrl, getOrquestadorApiUrl } from "src/app/utilities";
import { ActionModal } from "src/app/components";
import { AtencionesWebFormAnexos } from "./AtencionesWebFormAnexos";
import { AtencionesWebFormReasignar } from "./AtencionesWebFormReasignar";
import { AtencionesWebContextInterface, CasoResponseInterface, DefaultCasoResponse, FormConstantsAtencionWeb } from "../AtencionesWebTypes";
import { useNavigate } from 'react-router-dom'; 

export const AtencionWebContext = React.createContext<AtencionesWebContextInterface | null>(null);

const TipoSolicitudIdProblematica: number = 1399;

const currentDate = moment();
const OneMonthAgoDate = moment().add(-1, 'M').format('YYYY-MM-DD');

const AtencionWebSchema = Yup.object().shape({
    VcTelefono1: Yup.string()
        .test('', 'Celular no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
    VcTelefono2: Yup.string()
        .test('', 'Teléfono no válido', function (f: any) {
            return FormConstantsValidation.isValidOnlyNumbers(f?.toString() || '');
        })
        .max(10, "Máximo 10 caracteres"),
    VcPrimerNombre: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
        }),
    VcSegundoNombre: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
        }),
    VcPrimerApellido: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLetters(f?.toString() || '');
        }),
    VcSegundoApellido: Yup.string()
        .test('', 'Caracteres inválidos', function (f: any) {
            return FormConstantsValidation.isValidOnlyLettersAndSpace(f?.toString() || '');
        }),
    VcCorreo: Yup.string().email('Correo no válido').required('Campo obligatorio'),
    TxAsuntoCorreo: Yup.string()
        .required('Campo obligatorio')
        .max(4000, "Máximo 4000 caracteres"),
    DtFechaOrientacion: Yup.date()
        .required('Campo obligatorio')
        .min(OneMonthAgoDate, 'La fecha no puede ser menor a la de un mes')
        .max(currentDate, 'La fecha no puede ser mayor a la fecha actual'),
    TipoSolicitudId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    MotivoId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    TipoDataRedireccionarCasoId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    SubMotivoId: Yup.number()
        .required('Campo obligatorio')
        .transform((value) => (isNaN(value) ? undefined : value))
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    CanalAtencionId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    BProcesoFallido: Yup.bool(),
    TipoProcesoFallidoId: Yup.number()
        .test('', 'Campo obligatorio', function (f: any) {
            const refBProcesoFallido: any = this.resolve(Yup.ref('BProcesoFallido'));
            return (refBProcesoFallido === false) || (f > 0 && refBProcesoFallido === true);
        }),
    TipoGestionId: Yup.number()
        .test('', 'Campo obligatorio', function (f: any) {
            const refBProcesoFallido: any = this.resolve(Yup.ref('BProcesoFallido'));
            return (refBProcesoFallido === true) || (f > 0 && refBProcesoFallido === false);
        }),
    EstadoId: Yup.number()
        .required('Campo obligatorio')
        .test('superior', 'Campo obligatorio', function (f: any) {
            return f > 0;
        }),
    TxAclaracionMotivo: Yup.string()
        .required('Campo obligatorio')
        .min(50, "Mínimo 50 caracteres")
        .max(4000, "Máximo 4000 caracteres"),
    Anexo: Yup.mixed()
    .test('fileFormat', 'Formato de archivo debe ser pdf', value => {
        return !value ? true : FormConstantsValidation.TYPEPDF == value.type;
    })
    .test('fileSize', 'El archivo debe ser de máximo 2MB', value => {
        return !value ? true : value.size <= FormConstantsValidation.TWOMBFILESIZE;
    })
    .test('', 'Campo obligatorio', function (f: any) {
        const refTipoSolicitudId: any = this.resolve(Yup.ref('TipoSolicitudId'));
        return refTipoSolicitudId !== TipoSolicitudIdProblematica || (f && refTipoSolicitudId === TipoSolicitudIdProblematica);
    }),

});

export function AtencionesWeb(props: any) {
    const [userDto] = useState<any>(JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || ''))
    const [dataTemaBrindado, setDataTemaBrindado] = useState([]);
    const [dataTipoCanal, setDataTipoCanal] = useState([]);
    const [dataMotivo, setDataMotivo] = useState([]);
    const [dataRedireccionarCaso, setDataRedireccionarCaso] = useState([]);
    const [dataSubMotivo, setDataSubMotivo] = useState([]);
    const [dataTipoGestion, setDataTipoGestion] = useState([]);
    const [dataTipoProcesoFallido, setDataTipoProcesoFallido] = useState([]);

    //Estados para cargar anexos y reasignación de casos
    const [modalAnexos, setModalAnexos] = useState(false);
    const [modalReAsignar, setModalReAsignar] = useState(false);
    const [dataSourceAnexos, setDataSourceAnexos] = useState([]);
    const [dataSourceReasignacion, setDataSourceReasignacion] = useState([]);
    const [dataSourceReasignacionFilter, setDataSourceReasignacionFilter] = useState([]);
    const [dataUsuarios, setDataUsuarios] = useState([]);
    const [valueFilter, setValueFilter] = useState('');
    const [dataEstados, setDataEstados] = useState<any>([]);
    const [dataCasoCreado, setDataCasoCreado ] = useState<CasoResponseInterface>(DefaultCasoResponse);


    const navigate = useNavigate();

    const obtenerDataParametroDetalle = async (codigo: any, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/porCodigoInterno/${codigo}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    if(codigo == 'bTipoGestion' && element.idPadre == null){
                        dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                    }
                    if(codigo == 'bTipoGestion' && element.idPadre != null){

                    }
                    if(codigo != 'bTipoGestion'){
                        dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                    }
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDataRedireccionarCaso = async (codigo: any, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/getParametroDetallePorPadre/${codigo}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre, codigo: element.vcCodigoInterno });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const create = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl(`AtencionWeb/crear/`), data)
            .then((response) => {
                const data = response.data;
                setDataCasoCreado(data.model);
                mostrarMensajeCallback(data.titulo, data.mensaje, '1');
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo === 400) {
                    mostrarMensajeCallback(data.titulo, data.mensaje, '4');
                }
            });
    }

    useEffect(() => {
        obtenerDataParametroDetalle('bTipoCanalWeb', setDataTipoCanal);
        obtenerDataParametroDetalle('bTemaBrindado', setDataTemaBrindado);
        obtenerDataParametroDetalle('bTipoProcesoFallido', setDataTipoProcesoFallido);
        obtenerDataParametroDetalle('bTipoGestion', setDataTipoGestion);
        obtenerDataParametroDetalle('bEstadoCaso', setDataEstados);
    }, []);

    const obtenerDataParametroDetallePorPadre = async (idDetalle: number, setData: any) => {
        await axios.get(getParametroApiUrl(`ParametroDetalle/getParametroDetallePorPadre/${idDetalle}`))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data = response.data.models ? response.data.models : [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcNombre });
                });
                setData(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosUsuarios = async () => {
        await axios.get(getAdministradorApiUrl('Usuario'))
            .then((response) => {
                // Obtenemos los datos
                const dataArray: any = [];
                const data: any = response.data.models || [];
                data.forEach((element: any) => {
                    dataArray.push({ value: element.id, label: element.vcPrimerNombre + ' ' + element.vcPrimerApellido });
                });
                setDataUsuarios(dataArray);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const obtenerDatosReasignados = async () => {
        setDataSourceReasignacion([]);
        setDataSourceReasignacionFilter([]);
        setValueFilter('');
        await axios.get(getOrquestadorApiUrl(`AtencionWebReasignacion/porAtencionWebId/${dataCasoCreado.id}`))
            .then((response) => {
                // Obtenemos los datos
                const data = response.data?.models || [];
                data.map((item: any) => {
                    item.dtFechaReAsignacion = FormConstantsValidation.getIfDate(item.dtFechaReAsignacion.toString())
                })
                setDataSourceReasignacion(data);
                setDataSourceReasignacionFilter(data);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const createReasignacion = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl(`AtencionWebReasignacion/crear/`), data)
            .then((response) => {
                const data = response.data;
                mostrarMensajeCallback(data.titulo, data.mensaje, '1');
                obtenerDatosReasignados();
            })
            .catch(e => {
                // Capturamos los errores
                const data = e.response.data;
                if (data?.codigo === 400) {
                    mostrarMensajeCallback(data.titulo, data.mensaje, '4');
                }
            });
    } 
    
    const mostrarMensajeCallback = (titulo: string, mensaje: string, icon: string, callback : any = ()=>{}) => {
        Swal.fire({
            title: titulo,
            text: mensaje,
            icon: icon == '1' ? 'success' : icon === '2' ? 'info' : icon === '3' ? 'warning' : 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false
        }).then((result: any) => {
            callback();
        });
    }
    
    const filterDataTable = (value: string) => {
        const dataArray = dataSourceReasignacion.filter((item: any) => {
            return item.usuarioAsigna?.toLowerCase().includes(value) ||
                item.usuarioActual?.toLowerCase().includes(value) ||
                item.dtFechaAsignacion?.toString().substring(0, 10).includes(value) ||
                item.dtFechaReAsignacion?.toLowerCase().includes(value);
        });
        setDataSourceReasignacionFilter(dataArray);
    }

    const obtenerDatosAnexos = async () => {
        setDataSourceAnexos([]);
        await axios.get(getOrquestadorApiUrl(`AtencionWebAnexo/bandeja/${dataCasoCreado.id}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourceAnexos(response.data.models ? response.data.models : []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e);
            });
    }

    const reAsignar = () => {
        setModalReAsignar(true);
    }    

    const verAnexos = () => {
        //setAtencionIndividualId(element.id);
        setModalAnexos(true);
    } 
    
    const asociarAnexoCaso = async (data: any) => {
        await axios.post(getOrientacionEInformacionApiUrl('AtencionWeb/asociarAnexoCaso'), data)
            .then((response) => {
                const data = response.data;
                mostrarMensajeCallback(data.titulo, data.mensaje, '1');
                obtenerDatosAnexos();

            })
            .catch(e => {
                // Capturamos los errores
                const data = e?.response?.data;
                if (data?.codigo == 400) {
                    mostrarMensajeCallback(data.titulo, data.mensaje, '4');
                }
            });
    }     


    const initialValues = {
        Id: 0,
        VcPrimerNombre: '',
        VcSegundoNombre: '',
        VcPrimerApellido: '',
        VcSegundoApellido: '',
        VcCorreo: '',
        VcTelefono1: '',
        VcTelefono2: '',
        TxAsuntoCorreo: '',
        DtFechaOrientacion: undefined,
        UsuarioId: userDto.id,
        TipoSolicitudId: 0,
        MotivoId: 0,
        SubMotivoId: 0,
        CanalAtencionId: undefined,
        BProcesoFallido: false,
        TipoProcesoFallidoId: undefined,
        TipoGestionId: undefined,
        TipoDataRedireccionarCasoId: undefined,
        EstadoId: undefined,
        TxAclaracionMotivo: '',
        Anexo: undefined
    }

    const dataAtencionesWebContext :AtencionesWebContextInterface = {
        UsuarioId:userDto.id,
        UsuarioLogueado:(userDto.vcPrimerNombre || '') + ' ' + (userDto.vcSegundoNombre || '') + ' ' + (userDto.vcPrimerApellido || '') + ' ' + (userDto.vcSegundoApellido || ''),
        dataCasoCreado:dataCasoCreado,
        
        dataSourceReasignacion:dataSourceReasignacion,
        setDataSourceReasignacionFilter:setDataSourceReasignacionFilter,
        dataSourceReasignacionFilter:dataSourceReasignacionFilter,
        setDataEstados:setDataEstados,
        dataEstados:dataEstados,
        obtenerDatosReasignados:obtenerDatosReasignados,
        obtenerDatosUsuarios:obtenerDatosUsuarios,
        createReasignacion:createReasignacion,
        filterDataTable:filterDataTable,
        dataUsuarios:dataUsuarios,
        setValueFilter:setValueFilter,
        valueFilter:valueFilter,

        dataSourceAnexos:dataSourceAnexos,
        obtenerDatosAnexos:obtenerDatosAnexos,
        verAnexos:verAnexos,
        asociarAnexoCaso:asociarAnexoCaso,
    }

    return (

        <AtencionWebContext.Provider value={dataAtencionesWebContext}>
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ ...initialValues }}
                    onReset={(values, formProps) => {

                    }}
                    validationSchema={AtencionWebSchema}
                    onSubmit={(values: any, { setSubmitting }) => {
                        Swal.fire({
                            title: 'Confirmar guardado',
                            text: "¿Desea guardar estos datos?",
                            icon: 'warning',
                            showCancelButton: true,
                            focusConfirm: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sí, guardar',
                            cancelButtonText: 'Cancelar'
                        }).then(async (result: any) => {
                            if (result.isConfirmed) {
                                let formData = new FormData();
                                formData.append('Id', values.Id);
                                if (values.VcPrimerNombre) {
                                    formData.append('VcPrimerNombre', values.VcPrimerNombre);
                                }
                                if (values.VcSegundoNombre) {
                                    formData.append('VcSegundoNombre', values.VcSegundoNombre);
                                }
                                if (values.VcPrimerApellido) {
                                    formData.append('VcPrimerApellido', values.VcPrimerApellido);
                                }
                                if (values.VcSegundoApellido) {
                                    formData.append('VcSegundoApellido', values.VcSegundoApellido);
                                }
                                formData.append('VcCorreo', values.VcCorreo);
                                formData.append('VcTelefono1', values.VcTelefono1);
                                formData.append('VcTelefono2', values.VcTelefono2);
                                formData.append('TxAsuntoCorreo', values.TxAsuntoCorreo);
                                formData.append('DtFechaOrientacion', values.DtFechaOrientacion);
                                formData.append('UsuarioId', values.UsuarioId);
                                formData.append('TipoSolicitudId', values.TipoSolicitudId);
                                formData.append('MotivoId', values.MotivoId);
                                formData.append('SubMotivoId', values.SubMotivoId);
                                formData.append('CanalAtencionId', values.CanalAtencionId);
                                formData.append('BProcesoFallido', values.BProcesoFallido);
                                formData.append('TipoProcesoFallidoId', values.BProcesoFallido ? values.TipoProcesoFallidoId : 0);
                                formData.append('TipoGestionId', values.BProcesoFallido ? 0 : values.TipoGestionId);
                                formData.append('TipoDataRedireccionarCasoId', values.TipoDataRedireccionarCasoId);
                                formData.append('EstadoId', values.EstadoId);
                                formData.append('TxAclaracionMotivo', values.TxAclaracionMotivo);
                                if (values.TipoSolicitudId === TipoSolicitudIdProblematica) {
                                    formData.append('Anexo', values.Anexo);
                                }
                                create(formData);
                            }
                        })
                        setSubmitting(false);
                    }}
                >
                    {({
                        isSubmitting,
                        setFieldValue,
                        resetForm,
                        values
                    }) => (
                        <Form>
                            <h6>Ingresar información de la orientación web</h6>
                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos de la persona</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <FormikInput
                                                    type='email'
                                                    labelName='Correo electrónico'
                                                    nameSelect='VcCorreo'
                                                    className='col-12 mt-5'
                                                    required={true}
                                                    tabIndex={1}
                                                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                        event.currentTarget.value = values.VcCorreo.toLowerCase();
                                                        values.VcCorreo = values.VcCorreo.toLowerCase();
                                                    }}
                                                />
                                            </div>
                                            <div className="row">
                                                <FormikInput
                                                    labelName='Celular'
                                                    nameSelect='VcTelefono1'
                                                    className='col-6 mt-3'
                                                    tabIndex={3}
                                                    onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                />
                                                <FormikInput
                                                    labelName='Teléfono'
                                                    nameSelect='VcTelefono2'
                                                    className='col-6 mt-3'
                                                    tabIndex={4}
                                                    onKeyDown={FormConstantsValidation.handleOnlyNumbers}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <FormikTextArea
                                                rows='5'
                                                labelName='Asunto correo electrónico'
                                                nameSelect='TxAsuntoCorreo'
                                                className='col-12 mt-5'
                                                required={true}
                                                tabIndex={2}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <FormikInput
                                            labelName='Primer nombre'
                                            nameSelect='VcPrimerNombre'
                                            className='col-3 mt-5'
                                            tabIndex={5}
                                            onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcPrimerNombre.toUpperCase();
                                                values.VcPrimerNombre = values.VcPrimerNombre.toUpperCase();
                                            }}
                                        />
                                        <FormikInput
                                            labelName='Segundo nombre'
                                            nameSelect='VcSegundoNombre'
                                            className='col-3 mt-5'
                                            tabIndex={6}
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoNombre.toUpperCase();
                                                values.VcSegundoNombre = values.VcSegundoNombre.toUpperCase();
                                            }}
                                        />
                                        <FormikInput
                                            labelName='Primer apellido'
                                            nameSelect='VcPrimerApellido'
                                            className='col-3 mt-5'
                                            tabIndex={7}
                                            onKeyDown={FormConstantsValidation.handleOnlyLetters}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcPrimerApellido.toUpperCase();
                                                values.VcPrimerApellido = values.VcPrimerApellido.toUpperCase();
                                            }}
                                        />
                                        <FormikInput
                                            labelName='Segundo apellido'
                                            nameSelect='VcSegundoApellido'
                                            className='col-3 mt-5'
                                            tabIndex={8}
                                            onKeyDown={FormConstantsValidation.handleOnlyLettersAndSpace}
                                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                event.currentTarget.value = values.VcSegundoApellido.toUpperCase();
                                                values.VcSegundoApellido = values.VcSegundoApellido.toUpperCase();
                                            }}
                                        />

                                    </div>
                                </Card.Body>
                            </Card>

                            <Card className="mt-5">
                                <Card.Header>
                                    <Card.Title>Datos del caso</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="form-group row">
                                        <FormikInput
                                            type='date'
                                            labelName='Fecha de la orientación'
                                            nameSelect='DtFechaOrientacion'
                                            className='col-12 col-md-6 mt-5'
                                            required={true}
                                            tabIndex={9}
                                        />
                                        <FormikSelect
                                            options={dataTemaBrindado}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("TipoSolicitudId", selectedOption.value);
                                                setFieldValue("MotivoId", null);
                                                setFieldValue("SubMotivoId", null);
                                                obtenerDataParametroDetallePorPadre(selectedOption.value, setDataMotivo);
                                            }}
                                            value={values.TipoSolicitudId}
                                            labelName='Tipo solicitud'
                                            nameSelect='TipoSolicitudId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={10}
                                        />
                                        <FormikSelect
                                            options={dataMotivo}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("MotivoId", selectedOption.value);
                                                setFieldValue("SubMotivoId", null);
                                                obtenerDataParametroDetallePorPadre(selectedOption.value, setDataSubMotivo);
                                            }}
                                            value={values.MotivoId}
                                            labelName='Motivo'
                                            nameSelect='MotivoId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={11}
                                        />
                                        <FormikSelect
                                            options={dataSubMotivo}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("SubMotivoId", selectedOption.value);
                                            }}
                                            value={values.SubMotivoId}
                                            labelName='Sub motivo'
                                            nameSelect='SubMotivoId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={12}
                                        />
                                        <FormikSelect
                                            options={dataTipoCanal}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("CanalAtencionId", selectedOption.value);
                                            }}
                                            value={values.CanalAtencionId}
                                            labelName='Canal'
                                            nameSelect='CanalAtencionId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={13}
                                        />
                                        <FormikCheckbox
                                            labelName='Proceso fallido o desistido'
                                            nameSelect='BProcesoFallido'
                                            className='col-12 col-md-6 mt-5'
                                            value={values.BProcesoFallido}
                                            required={true}
                                            tabIndex={14}
                                        />
                                        {values.BProcesoFallido ?
                                            <FormikSelect
                                                options={dataTipoProcesoFallido}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("TipoProcesoFallidoId", selectedOption.value);
                                                }}
                                                value={values.TipoProcesoFallidoId}
                                                labelName='Tipo de proceso fallido'
                                                nameSelect='TipoProcesoFallidoId'
                                                className='col-12 col-md-6 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                tabIndex={15}
                                            /> : null
                                        }
                                        {!values.BProcesoFallido ?
                                            <FormikSelect
                                                options={dataTipoGestion}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("TipoGestionId", selectedOption.value);
                                                    obtenerDataRedireccionarCaso(selectedOption.value , setDataRedireccionarCaso)
                                                }}
                                                value={values.TipoGestionId}
                                                labelName='Tipo de gestión'
                                                nameSelect='TipoGestionId'
                                                className='col-12 col-md-6 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                tabIndex={16}
                                            /> : null
                                        }
                                        {values.TipoGestionId == 22 || values.TipoGestionId == 23 ?
                                            <FormikSelect
                                                options={dataRedireccionarCaso}
                                                onChange={(selectedOption: any) => {
                                                    setFieldValue("TipoDataRedireccionarCasoId", selectedOption.value);
                                                }}
                                                value={values.TipoDataRedireccionarCasoId}
                                                labelName='redireccionar el caso'
                                                nameSelect='TipoDataRedireccionarCasoId'
                                                className='col-12 col-md-6 mt-5'
                                                placeHolder='Seleccione'
                                                required={true}
                                                tabIndex={17}
                                            /> : null
                                        }
                                        <FormikSelect
                                            options={dataEstados}
                                            onChange={(selectedOption: any) => {
                                                setFieldValue("EstadoId", selectedOption.value);
                                            }}
                                            value={values.EstadoId}
                                            labelName='Estado del caso'
                                            nameSelect='EstadoId'
                                            className='col-12 col-md-6 mt-5'
                                            placeHolder='Seleccione'
                                            required={true}
                                            tabIndex={18}
                                        />
                                        {values.TipoSolicitudId === TipoSolicitudIdProblematica ?
                                            <FormikInputFile
                                                labelName='Cargar archivos'
                                                nameSelect='Anexo'
                                                className='col-12 mt-5'
                                                accept="application/pdf"
                                                onChange={(e: any) => {
                                                    setFieldValue("Anexo", e.target.files[0]);
                                                    console.log(e)
                                                }}
                                                required={true}
                                                tabIndex={19}
                                            /> : null
                                        }
                                        <FormikTextArea
                                            labelName='Aclaraciones - gestión'
                                            nameSelect='TxAclaracionMotivo'
                                            className='col-12 col-md-6 mt-5'
                                            required={true}
                                            tabIndex={20}
                                        />
                                    </div>
                                    {dataCasoCreado.id == 0 && (
                                        <div className="form-group row mt-5">
                                            <div className="col-12">
                                                <CrudButton type={'submit'} className="btn-sm btn-primaryCustom float-end mx-2" text="Guardar" disabled={isSubmitting} tabIndex={20} />
                                            </div>
                                        </div>
                                    )}
                                    {/*<p>Model: {JSON.stringify(dataCasoCreado)} </p>*/}
                                </Card.Body>
                            </Card>
                            {dataCasoCreado.id > 0 && (
                                <Card className="mt-5">
                                    <Card.Header>
                                        <Card.Title>Acciones sobre el caso</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="row mt-5">

                                            <div className="col-12">
                                                <CrudButton
                                                    type={'button'}
                                                    className="btn-sm btn-primaryCustom mx-2"
                                                    text="Adjuntar Archivos"
                                                    disabled={isSubmitting}
                                                    icon="bi bi-file-earmark-pdf-fill" 
                                                    onClick={() => {
                                                        verAnexos();

                                                    }} 
                                                />  
                                                {  dataEstados.filter((item: any) => item.codigo === FormConstantsAtencionWeb.CODIGO_ESTADO_SEGUIMIENTO)[0].value == dataCasoCreado.estadoId && (  
                                                    <CrudButton
                                                        type={'button'}
                                                        className="btn-sm btn-primaryCustom mx-2"
                                                        text="Asignar Caso"
                                                        disabled={isSubmitting}
                                                        icon="bi bi-person" 
                                                        onClick={() => {
                                                            reAsignar();
                                                        }} 
                                                    /> 
                                                )}
                                                <CrudButton
                                                    type={'button'}
                                                    className="btn-sm btn-primaryCustom mx-2"
                                                    text="Pasar a nuevo registro"
                                                    disabled={isSubmitting}
                                                    icon="bi bi-repeat" 
                                                    onClick={() => {
                                                        //codigo para redireccionar desde reat router dom /orientacion/individuales/registro
                                                        setDataCasoCreado(DefaultCasoResponse);
                                                        resetForm();
                                                        navigate("/orientacion/web/registro"); 
                                                        
                                                    }} 
                                                />                                                                                                                 
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>      
                            )}                               
                        </Form>
                    )}
                </Formik>

                <ActionModal visible={modalAnexos} title={'Anexos del caso'} size='xl' handleClose={setModalAnexos}>
                    <AtencionesWebFormAnexos />
                </ActionModal>
                <ActionModal visible={modalReAsignar} title={'Asignaciones del caso'} size='xl' handleClose={setModalReAsignar}>
                    <AtencionesWebFormReasignar />
                </ActionModal>            

            </div>
        </AtencionWebContext.Provider>
    ); 
}

