import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Table } from "react-bootstrap";
import DataTable, { TableColumn, ExpanderComponentProps } from "react-data-table-component";
import { getOrquestadorApiUrl } from "../../../../_metronic/helpers/ApiOrquestadorHelper";
import { CrudButton } from "../../../components/CrudButton";
import { customStyles, NoRecords, PaginationProperties, MAX_TEXT_LENGTH } from "../../../components/DataTableConfig";
import { bandejaWebContext } from "./AtencionesWebBandeja";
import { ActionModal } from "../../../components/ActionModal";
import { PdfBase64 } from "../../../components/PdfBase64";
import { AtencionesWebFormAnexos } from "./AtencionesWebFormAnexos";

export function AtencionesWebFormOtrosCasos(props: any) {

    //const bandejaWebCContext = useContext(bandejaWebContext);

    const [dataSource, setDataSource] = useState([]);
    const [dataSourcePdf, setDataSourcePdf] = useState(null);
    const [modalVisiblePdf, setModalVisiblePdf] = useState(false);       
    const [modalAnexos, setModalAnexos] = useState(false);
    const [AtencionWebId, setAtencionWebId] = useState([]);
    const [PersonaWebId, setPersonaWebId] = useState([]);

    const obtenerOtrosCasos = async () => {
        await axios.get(getOrquestadorApiUrl(`AtencionWeb/otrosCasos/${props.PersonaWebId}/${props.AtencionWebId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSource(response.data.models ?? []);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }

    useEffect(() => {
        obtenerOtrosCasos();
    }, []);

    interface DataRow {
        id: number;
        acciones: string;
        iAnexos: string;
        dtFechaOrientacion: string;
        estado: string,
        vcCorreo: string;
        diasSinRespuesta: any;
        tipoGestion: string;
        tipoProcesoFallido: string;
        vcNombreCompleto: string;
        tipoSolicitud: string;
        motivo: string;
        subMotivo: string;
        txAclaracionMotivo: string;
        usuario: string;
        usuarioActual: string;
        dtFechaRegistro: string;
        txAsuntoCorreo: string;
    }

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Id del caso',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Acciones',
            cell: (element) =>
                <div>
                    <CrudButton className="btn-icon btn-sm btn-danger" icon="bi bi-printer" onClick={()=>imprimirCaso(element.id)}  />
                </div>,
            sortable: false,
        },
        {
            name: 'Anexos',
            cell: (element) => <CrudButton className="btn-icon btn-sm btn-primary" text={element.iAnexos} onClick={() => verAnexos(element)}/>,
            sortable: false,
        },
        {
            name: 'Fecha de orientación',
            cell: (element) => <p>{element.dtFechaOrientacion.toString().substring(0, 10)}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Correo',
            cell: (element) => <p>{element.vcCorreo}</p>,
            sortable: true,
            minWidth: '220px'
        },
        {
            name: 'Motivo',
            cell: (element) => <p>{element.motivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Sub motivo',
            cell: (element) => <p>{element.subMotivo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Aclaraciones',
            cell:(element) => <p>{element.txAclaracionMotivo.length > MAX_TEXT_LENGTH ? `${element.txAclaracionMotivo.slice(0,MAX_TEXT_LENGTH)}...` : element.txAclaracionMotivo}</p>,
            sortable: true,
            minWidth: '250px'
        },        
        {
            name: 'Tipo Gestión',
            cell: (element) => <p>{element.tipoGestion}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Tipo proceso fallido',
            cell: (element) => <p>{element.tipoProcesoFallido}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Nombres y Apellidos',
            cell: (element) => <p>{element.vcNombreCompleto}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Estado del caso',
            cell: (element) => <p>{element.estado}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Tipo de solicitud',
            cell: (element) => <p>{element.tipoSolicitud}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Usuario asignado',
            cell: (element) => <p>{element.usuarioActual}</p>,
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Usuario que registró',
            cell: (element) => <p>{element.usuario}</p>,
            sortable: true,
            minWidth: '180px'
        },
        {
            name: 'Fecha de registro',
            cell: (element) => <p>{element.dtFechaRegistro.toString().substring(0, 10)}</p>,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: 'Asunto',
            cell:(element) => <p>{element.txAsuntoCorreo.length > MAX_TEXT_LENGTH ? `${element.txAsuntoCorreo.slice(0,MAX_TEXT_LENGTH)}...` : element.txAsuntoCorreo}</p>,
            sortable: true,
            minWidth: '250px'
        },
        {
            name: 'Días sin respuesta',
            cell: (element) => <span className="badge" style={{backgroundColor: element.diasSinRespuesta.color}}>{element.diasSinRespuesta.dias}</span>,
            sortable: true,
            minWidth: '150px'
        },         
    ];
    const CustomExpander = ({ data }: ExpanderComponentProps<DataRow>) => (
        <div>
            <p><b className="fw-bold text-azul-oscuro">Información adicional del caso:</b></p>
            <p><b className="fw-bold text-azul-oscuro">Id:</b> {data.id}</p>
            <p><b className="fw-bold text-azul-oscuro">Asunto:</b> {data.txAsuntoCorreo}</p>
            <p><b className="fw-bold text-azul-oscuro">Aclaración:</b> {data.txAclaracionMotivo}</p>
            <p><b className="fw-bold text-azul-oscuro">Tema brindado:</b> {data.tipoSolicitud}</p>
            <p><b className="fw-bold text-azul-oscuro">Motivo:</b> {data.motivo}</p>
            <p><b className="fw-bold text-azul-oscuro">Sub Motivo:</b> {data.subMotivo}</p>
            <p><b className="fw-bold text-azul-oscuro">Fecha registro:</b> {data.dtFechaRegistro.toString().substring(0, 10)}</p>
            <p><b className="fw-bold text-azul-oscuro">Fecha Orientación:</b> {data.dtFechaOrientacion.toString().substring(0, 10)}</p>
        </div>
    );   

    const imprimirCaso = async (atencionGrupalId: number) => {
        await axios.get(getOrquestadorApiUrl(`AtencionWeb/pdf/${atencionGrupalId}`))
            .then((response) => {
                // Obtenemos los datos
                setDataSourcePdf(response.data.pdf ?? null); 
                setModalVisiblePdf(true);
                //setModalVisible(true);
            })
            .catch(e => {
                // Capturamos los errores
                console.log(e.response);
            });
    }  
    
    const verAnexos = (element: any) => {
        setPersonaWebId(element.personaWebId);
        setAtencionWebId(element.id);
        setModalAnexos(true);
    }

    return <>
        <Container fluid className="form-group row mt-8">
            <Col>
                <DataTable
                    columns={columns}
                    data={dataSource}
                    pagination
                    customStyles={customStyles}
                    paginationComponentOptions={PaginationProperties}
                    noDataComponent={<NoRecords />}
                    expandableRows
                    expandableRowsComponent={CustomExpander}     
                />
            </Col>
        </Container>
        <ActionModal visible={modalAnexos} title={'Anexos del caso'} size='xl' handleClose={setModalAnexos}>
                <AtencionesWebFormAnexos AtencionWebId={AtencionWebId} />
            </ActionModal>
        <ActionModal visible={modalVisiblePdf} title={'Documento PDF del caso'} size={'xl'} handleClose={setModalVisiblePdf}> 
            <PdfBase64 pdfBase64={dataSourcePdf} />
        </ActionModal>

    </>
}

